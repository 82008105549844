<template>
    <div class="master-template">
        <div class="master-subtitle-div d-flex justify-content-between align-items-center">
            <h5> Terminals </h5>

            <div>
                <button class="new-item-btn btn btn-primary" @click="promptNewTerminal">
                    <i class="fas fa-plus-circle"></i>Add New Terminal
                </button>
            </div>
        </div>

        <div class="master-content">
            <div class="data-table mt-3">
                <div class="data-table-data">
                    <el-skeleton :loading="gettingTerminals" :throttle="200" animated>
                        <template #template>
                            <el-skeleton-item variant="h3" v-for="row in 7" :key="row" style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                        </template>

                        <template #default>
                            <el-table :data="terminals" style="width: 100%">
                                <el-table-column prop="name" label="Terminal Name"/>
                                <el-table-column prop="created_at" label="Date Created"/>
                                <el-table-column label="Actions">
                                    <template #default="scope">
                                        <el-button size="small" type="primary" :icon="Edit"
                                                   @click="promptUpdateTerminal(scope.$index)"
                                        >Update
                                        </el-button>
                                        <el-button
                                            size="small"
                                            type="danger"
                                            :icon="Delete"
                                            :disabled="terminals.length < 2"
                                            @click="promptRemoveTerminal(scope.$index)">
                                            Remove
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </el-skeleton>
                </div>
            </div>
        </div>

        <!-- Start Of New Terminal Model -->
        <el-dialog
            v-model="showAddTerminalDialog"
            title="New Terminal"
            width="30%"
        >
            <form @submit.prevent="addTerminal"
                  v-loading="addingTerminal"
                  element-loading-text="Adding Terminal"
                  id="new-terminal-form"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="terminal-name"> Terminal Name </label>
                    <input type="text" id="terminal-name" class="form-control" v-model="newTerminal.name">
                    <div class="invalid-feedback"> {{ newTerminalNameErrorMessage }}</div>
                </div>

                <div class="form-group">
                    <label class="required" for="terminal-pin"> Terminal PIN </label>
                    <div class="input-group" id="login-pwd-input-grp">
                        <input :type="visiblePin ? 'text' : 'password'" inputmode="numeric" name="terminal-pin" id="terminal-pin" class="form-control"
                               v-model="newTerminal.pin">
                        <span class="input-group-text toggle-password" @click="togglePinVisibility">
                            <i class="far fa-eye-slash" v-if="visiblePin"></i>
                            <i class="far fa-eye" v-else></i>
                        </span>
                    </div>
                    <div class="form-text"> A minimum of 4 digits is required</div>
                    <div class="invalid-feedback"> Terminal PIN is required</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showAddTerminalDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addTerminal"> Add Terminal</button>
                </div>
            </template>
        </el-dialog>
        <!-- End Of Add New terminal Modal -->

        <!-- Start Of Update Terminal Model -->
        <el-dialog
            v-model="showUpdateTerminalDialog"
            title="Update Terminal"
            width="30%"
        >
            <form @submit.prevent="updateTerminal"
                  v-loading="updatingTerminal"
                  element-loading-text="Saving Changes"
                  id="update-terminal-form"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="update-terminal-name"> Terminal Name </label>
                    <input type="text" id="update-terminal-name" class="form-control" v-model="activeTerminal.name">
                    <div class="invalid-feedback"> Terminal name is required </div>
                </div>

                <div class="form-group">
                    <label style="display: block;"> Change Terminal PIN </label>
                    <el-radio-group v-model="changePin" size="large">
                        <el-radio-button :label="true"> Yes </el-radio-button>
                        <el-radio-button :label="false"> No </el-radio-button>
                    </el-radio-group>
                </div>

                <div class="form-group" v-if="changePin">
                    <label class="required" for="terminal-pin"> New Terminal PIN </label>
                    <div class="input-group" id="login-pwd-input-grp">
                        <input :type="visiblePin ? 'text' : 'password'" inputmode="numeric" name="terminal-pin" id="update-terminal-pin" class="form-control"
                               v-model="newTerminal.pin">
                        <span class="input-group-text toggle-password" @click="togglePinVisibility">
                            <i class="far fa-eye-slash" v-if="visiblePin"></i>
                            <i class="far fa-eye" v-else></i>
                        </span>
                    </div>
                    <div class="form-text"> A minimum of 4 digits is required</div>
                    <div class="invalid-feedback"> Terminal PIN is required</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showUpdateTerminalDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="updateTerminal"> Save Changes </button>
                </div>
            </template>
        </el-dialog>
        <!-- End Of Update terminal Modal -->
    </div>
</template>

<script>
import {Delete, Edit} from "@element-plus/icons-vue";
import {mapGetters, mapState} from "vuex";
import $ from "jquery";

export default {
    name: "BusinessTerminals",

    data() {
        return {
            baseUrl: '/api/pos/terminals',
            showAddTerminalDialog: false,
            visiblePin: false,
            newTerminal: {
                name: null,
                pin: null
            },
            addingTerminal: false,
            newTerminalNameErrorMessage: null,
            showUpdateTerminalDialog: false,
            activeTerminal: {},
            updatingTerminal: false,
            changePin: false,
        }
    },

    computed: {
        ...mapState('system', ['terminals', 'gettingTerminals']),
        ...mapGetters('system', ['getBasicPayload']),

        Edit() {
            return Edit
        },

        Delete() {
            return Delete
        },
    },

    created() {
        if (!this.terminals || this.terminals.length === 0) {
            this.$store.dispatch('system/getTerminals')
        }
    },

    mounted() {
        $("#terminal-pin").focus(() => {
            $("#login-pwd-input-grp .toggle-password").addClass('focused')
        })

        $("#terminal-pin").focusout(() => {
            $("#login-pwd-input-grp .toggle-password").removeClass('focused')
        })
    },

    methods: {
        promptNewTerminal() {
            this.newTerminal = {}
            this.showAddTerminalDialog = true
        },

        togglePinVisibility(e) {
            e.preventDefault()

            this.visiblePin = !this.visiblePin
        },

        addTerminal(e) {
            e.preventDefault()

            let dataIsValid = true
            if (!this.newTerminal.name) {
                this.newTerminalNameErrorMessage = 'Terminal name is required'
                $("#terminal-name").addClass('is-invalid')
            } else {
                $("#terminal-name").removeClass('is-invalid')
            }

            if (!this.newTerminal.pin) {
                $("#terminal-pin").addClass('is-invalid')
            } else {
                $("#terminal-pin").removeClass('is-invalid')
            }

            if (dataIsValid) {
                let payload = {...this.newTerminal, ...this.getBasicPayload}
                this.addingTerminal = true
                axios.post(`${this.baseUrl}/add`, payload).then(res => {
                    this.addingTerminal = false
                    this.$store.commit('system/addTerminal', res.data.data)

                    this.$message.success('Terminal added successfully')
                    this.showAddTerminalDialog = false
                }).catch(error => {
                    this.addingTerminal = false
                    this.$message.error(this.serverErrorMessage())
                })
            }
        },

        promptUpdateTerminal(index) {
            this.activeTerminal = JSON.parse(JSON.stringify(this.terminals[index]))
            this.activeTerminal.pin = null
            this.showUpdateTerminalDialog = true
        },

        updateTerminal(e) {
            e.preventDefault()

            let dataIsValid = true
            if (!this.activeTerminal.name) {
                $("#update-terminal-name").addClass('is-invalid')
            } else {
                $("#update-terminal-name").removeClass('is-invalid')
            }

            if (dataIsValid) {
                this.activeTerminal.change_pin = this.changePin ? 1 : 0
                let payload = {...this.activeTerminal, ...this.getBasicPayload}
                this.updatingTerminal = true
                axios.post(`${this.baseUrl}/update`, payload).then(res => {
                    this.updatingTerminal = false
                    let index = this.terminals.findIndex(_t => _t.id === res.data.data.id)
                    if (index !== -1) {
                        this.$store.commit('system/updateTerminal', {index: index, terminal: res.data.data})
                    }

                    this.$message.success('Terminal updated successfully')
                    this.showUpdateTerminalDialog = false
                }).catch(error => {
                    this.updatingTerminal = false
                    this.$message.error(this.serverErrorMessage())
                })
            }
        },

        promptRemoveTerminal(index) {
            this.$confirm(
                'This action is permanent. Continue?',
                'Remove Terminal',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(() => {
                    axios.post(`${this.baseUrl}/remove`, {id: this.terminals[index].id})
                        .then(response => {
                            this.$store.commit('system/removeTerminal', index)
                            this.$message.success('Terminal removed successfully')
                        }).catch(error => {
                        this.$message.error(this.serverErrorMessage())
                    })
                })
                .catch(() => {
                    //
                })
        },
    },
}
</script>

<style scoped>

</style>
