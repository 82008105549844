<template>
    <div id="add-stock" class="master-template">
        <div class="master-title-div d-flex justify-content-between align-items-center">
            <h5 class="master-title"> Add Stock </h5>

            <div class="actions">
                <a href="/stock/levels" class="btn btn-outline-primary new-item-btn mr-12">
                    <i class="fas fa-backward"></i>Back to Item List
                </a>
            </div>
        </div>

        <div class="master-content">
            <div class="master-tip">
                <svg-symbols></svg-symbols>
                <tip type="primary">
                    Not sure how to best add a stock item? Contact us and we will assist!
                </tip>
            </div>

            <!-- Entries -->
            <div class="stock-entries" v-loading="dataTableLoading" element-loading-text="Adding Stock">
                <div class="card bg-white shadow-sm w-100">
                    <div class="entry" v-for="(entry, index) in entries" :key="index">
                        <div class="d-flex flex-wrap">
                            <div class="form-group" v-if="entry.item_type === 'stock'">
                                <label> Item Code </label>
                                <input type="text" v-model="entry.item_code" class="form-control">
                            </div>

                            <div class="form-group">
                                <label> Item Name </label>
                                <input type="text" v-model="entry.label" class="form-control" required>
                            </div>

                            <div class="form-group">
                                <label> Item Type </label>
                                <el-select v-model="entry.item_type" filterable size="large">
                                    <el-option v-for="type in stockItemTypes" :key="type" :label="capitalizeFirstWord(type)"
                                               :value="type"></el-option>
                                </el-select>
                            </div>

                            <div class="form-group">
                                <label> Category </label>
                                <div class="d-flex">
                                    <el-select v-model="entry.category_id" filterable size="large"
                                               style="margin-right: 5px;">
                                        <el-option v-for="category in productCategories" :key="category.id"
                                                   :label="category.name" :value="category.id"></el-option>
                                    </el-select>
                                    <el-button plain type="primary" size="large" style="font-weight: bold;"
                                               @click="promptNewCategory(index)"> Add New
                                    </el-button>
                                </div>
                            </div>

                            <div class="form-group">
                                <label> Sub Category </label>
                                <div class="d-flex">
                                    <el-select v-model="entry.sub_category_id" filterable size="large"
                                               style="margin-right: 5px;">
                                        <el-option v-for="subCategory in displayableSubcategories(index)"
                                                   :key="subCategory.id" :label="subCategory.name"
                                                   :value="subCategory.id"></el-option>
                                    </el-select>
                                    <el-button plain type="primary" size="large" style="font-weight: bold;"
                                               @click="promptNewSubCategory(index)"> Add New
                                    </el-button>
                                </div>
                            </div>

                            <div class="form-group" v-if="entry.item_type === 'stock'">
                                <label> Stocking Unit </label>
                                <div class="d-flex">
                                    <el-select
                                        v-model="entry.unit_id"
                                        default-first-option
                                        filterable
                                        size="large"
                                    >
                                        <el-option
                                            v-for="unit in uoms"
                                            :key="unit.id"
                                            :label="unit.unit_name"
                                            :value="unit.id"
                                        />
                                    </el-select>
                                    <!--                                    <el-button plain type="primary" size="large" style="font-weight: bold; margin-left: 5px;" @click="promptNewConversion(index)">-->
                                    <!--                                        Add Conversion-->
                                    <!--                                    </el-button>-->
                                </div>
                            </div>

                            <div class="form-group" v-if="entry.item_type === 'stock'">
                                <label style="display: block;"> Item can be stocked </label>
                                <el-radio-group v-model="entry.can_stock">
                                    <el-radio :label="true" size="large" border>Yes</el-radio>
                                    <el-radio :label="false" size="large" border>No</el-radio>
                                </el-radio-group>
                            </div>


                            <div class="form-group" v-if="(entry.item_type === 'stock') && (entry.can_stock)">
                                <label for="quantity"> Quantity </label>
                                <input type="text" class="form-control" v-model="entry.quantity" id="quantity">
                            </div>

                            <div class="form-group" v-if="entry.item_type === 'stock'">
                                <label> Restocking Method </label>
                                <div class="d-flex">
                                    <el-select v-model="entry.restocking_method" filterable size="large">
                                        <el-option v-for="method in ['purchasing', 'producing', 'preparation']" :key="method"
                                                   :label="capitalizeFirstWord(method)"
                                                   :value="method"></el-option>
                                    </el-select>
                                </div>
                            </div>

                            <div class="form-group"
                                 v-if="(entry.item_type === 'stock') && (entry.restocking_method === 'purchasing') && (entry.can_stock)">
                                <label for="bp"> Buying Price </label>
                                <input type="text" class="form-control" v-model="entry.buying_price" id="bp">
                            </div>

                            <div class="form-group" v-if="entry.item_type === 'stock'">
                                <label style="display: block;"> Item can be sold </label>
                                <el-radio-group v-model="entry.can_sell">
                                    <el-radio :label="true" size="large" border>Yes</el-radio>
                                    <el-radio :label="false" size="large" border>No</el-radio>
                                </el-radio-group>
                            </div>

                            <div class="form-group" v-if="entry.can_sell">
                                <label for="sp" v-if="entry.item_type === 'stock'"> Selling Price </label>
                                <label for="sp" v-else> Service Cost </label>
                                <div class="d-flex">
                                    <input type="text" class="form-control" v-model="entry.selling_price" id="sp"
                                           style="margin-right: 5px;">
                                    <el-button plain type="primary" size="large" style="font-weight: bold;"
                                               @click="promptSellingVariants(index)"
                                               v-if="businessHasEnabledSellingVariants"> Add Selling Variants
                                    </el-button>
                                </div>
                            </div>

                            <div class="form-group" v-if="(entry.item_type === 'service') && (businessHasEnabledAttendantCommissions)">
                                <label for="commission"> Attendant Commission (%) </label>
                                <input type="text" class="form-control" v-model="entry.attendant_commission" id="commission">
                            </div>

                            <div class="form-group" v-if="(entry.item_type === 'stock') && (entry.can_stock)">
                                <label for="rl"> Reorder Level </label>
                                <input type="text" class="form-control" v-model="entry.reorder_level" id="rl">
                            </div>

                            <div class="form-group" v-if="businessTracksExpiryDates && (entry.item_type === 'stock')">
                                <label class="display-block"> Expiry Date </label>
                                <el-date-picker
                                    v-model="entry.expiry_date"
                                    type="date"
                                    placeholder="Pick a day"
                                    format="MMM D, YYYY"
                                    value-format="YYYY-MM-DD"
                                    size="large"
                                    style="width: 100%"
                                >
                                </el-date-picker>
                            </div>

                            <div class="form-group" v-if="businessHasEnabledManualSellOuts && (entry.item_type === 'stock') && (entry.can_stock)">
                                <label style="display: block;"> Item requires manual sell out </label>
                                <el-radio-group v-model="entry.sells_out_manually">
                                    <el-radio :label="true" size="large" border>Yes</el-radio>
                                    <el-radio :label="false" size="large" border>No</el-radio>
                                </el-radio-group>
                            </div>

                        </div>

                        <div class="d-flex justify-content-end mt-2 w-100">
                            <button class="btn btn-sm btn-outline-danger new-item-btn f-16 fw-600"
                                    @click="removeEntry(index)" v-if="entries.length > 1">
                                <i class="fas fa-trash"></i>Remove Entry
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end mt-3">
                <button class="btn btn-sm btn-outline-primary new-item-btn f-18 fw-700" @click="addEntry">
                    <i class="fas fa-plus-circle"></i>Add Entry
                </button>
            </div>

            <div class="d-flex justify-content-end mt-4" v-if="businessHasMultipleStores && can('manage_multiple_stores', currentUser)">
                <button class="btn btn-primary action-btn f-20 fw-700" @click="addStock()">
                    Save For Current Store ({{ currentUser.store_name }})
                </button>

                <button class="btn btn-primary action-btn f-20 fw-700 ml-12" @click="addStock(true)"> Save For All Stores</button>
            </div>

            <div class="d-flex justify-content-end mt-4" v-else>
                <button class="btn btn-primary action-btn f-20 fw-700" @click="addStock">Save Entries</button>
            </div>
        </div>

        <!-- Add entry conversion Dialog -->
        <el-dialog
            v-model="showAddEntryConversionDialog"
            :title="'Add Conversion for ' + conversionDetails.entryLabel"
            width="30%"
        >
            <form @submit.prevent="saveConversionDetails" novalidate>
                <div class="form-group">
                    <label class="required" for="subcategory-name"> Conversion Unit </label>
                    <el-select
                        v-model="conversionDetails.unit_id"
                        default-first-option
                        filterable
                        size="large"
                    >
                        <el-option
                            v-for="unit in uoms"
                            :key="unit.id"
                            :label="unit.unit_name"
                            :value="unit.id"
                        />
                    </el-select>
                </div>

                <div class="form-group">
                    <label class="required"> Conversion Quantity </label>
                    <input type="text" class="form-control" v-model="conversionDetails.conversion_quantity">
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="discardConversionDetails" size="large">Discard</el-button>
                    <button class="btn btn-primary" @click="saveConversionDetails"> Save Conversion</button>
                </div>
            </template>
        </el-dialog>

        <!-- START OF ADD NEW Category MODAL -->
        <el-dialog
            v-model="showAddCategoryDialog"
            title="Add Category"
            width="30%"
        >
            <form @submit.prevent="addNewCategory"
                  v-loading="addingNewCategory"
                  element-loading-text="Adding Category"
                  id="new-category-form"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="category-name"> Category Name </label>
                    <input type="text" id="category-name" class="form-control" v-model="newCategory.name"
                           placeholder="Category name">
                    <div class="invalid-feedback"> {{ categoryNameErrorMessage }}</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showAddCategoryDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addNewCategory"> Add Category</button>
                </div>
            </template>
        </el-dialog>
        <!-- END OF ADD NEW CATEGORY MODAL -->

        <!-- START OF ADD NEW SubCategory MODAL -->
        <el-dialog
            v-model="showAddSubCategoryDialog"
            title="Add Sub Category"
            width="30%"
        >
            <form @submit.prevent="addNewSubCategory"
                  v-loading="addingNewSubCategory"
                  element-loading-text="Adding Sub Category"
                  id="new-subcategory-form"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="subcategory-name"> Sub Category Name </label>
                    <input type="text" id="subcategory-name" class="form-control" v-model="newSubCategory.name"
                           placeholder="Sub category name">
                    <div class="invalid-feedback"> {{ subCategoryNameErrorMessage }}</div>
                </div>

                <div class="form-group">
                    <label class="required"> Category </label>
                    <el-select v-model="newSubCategory.category_id" filterable size="large" disabled>
                        <el-option v-for="category in productCategories" :key="category.id" :label="category.name"
                                   :value="category.id"></el-option>
                    </el-select>
                    <div class="invalid-feedback" id="subcategory-category-id"> Please select a category</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showAddSubCategoryDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addNewSubCategory"> Add Sub Category</button>
                </div>
            </template>
        </el-dialog>
        <!-- END OF ADD NEW Sub CATEGORY MODAL -->

        <!-- Selling Variants modal -->
        <el-dialog
            v-model="showingEntrySellingVariantsModal"
            :title="'Selling variants for ' + activeEntry.label ?? ''"
            width="70%"
            v-if="activeEntry"
        >
            <el-table style="width: 100%;" :data="activeEntry.selling_variants" empty-text="No selling variants added">
                <el-table-column label="Variant Name">
                    <template #default="scope">
                        <input type="text" class="form-control" v-model="scope.row.variant_name">
                    </template>
                </el-table-column>

                <el-table-column label="Stocking Unit">
                    <template #default="scope">
                        <el-select
                            v-model="scope.row.stocking_unit_id"
                            default-first-option
                            filterable
                            size="large"
                        >
                            <el-option
                                v-for="unit in uoms"
                                :key="unit.id"
                                :label="unit.unit_name"
                                :value="unit.id"
                            />
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column label="Selling Price">
                    <template #default="scope">
                        <input type="text" class="form-control" v-model="scope.row.price">
                    </template>
                </el-table-column>

                <el-table-column label="Quantity to deduct from main item">
                    <template #default="scope">
                        <input type="text" class="form-control" v-model="scope.row.deduction_quantity">
                    </template>
                </el-table-column>

                <el-table-column label="">
                    <template #default="scope">
                        <el-button plain type="danger" size="large" style="font-weight: bold;"
                                   @click="activeEntry.selling_variants.splice(scope.row.$index, 1)"> Remove Variant
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="d-flex justify-content-end mt-3">
                <button class="btn btn-sm btn-outline-primary new-item-btn f-18 fw-700"
                        @click="activeEntry.selling_variants.push({})">
                    <i class="fas fa-plus-circle"></i>Add Variant
                </button>
            </div>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showingEntrySellingVariantsModal = false" size="large">Close</el-button>
                    <button class="btn btn-primary f-18 fw-700" @click="showingEntrySellingVariantsModal = false"> Save
                        Variants
                    </button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import $ from "jquery";
import {Delete} from "@element-plus/icons-vue";
import SvgSymbols from "../../shared/SvgSymbols.vue";
import Tip from "../../shared/Tip.vue";

export default {
    name: "AddStock",

    data() {
        return {
            dataTableLoading: false,
            entries: [],
            disabledDate(time) {
                return time.getTime() < Date.now()
            },
            showImportStockDialog: false,
            importingStock: false,
            uploadedFile: null,
            newCategory: {},
            showAddCategoryDialog: false,
            addingNewCategory: false,
            categoryNameErrorMessage: 'Category name is required',
            newSubCategory: {},
            showAddSubCategoryDialog: false,
            addingNewSubCategory: false,
            subCategoryNameErrorMessage: 'Sub category name is required',
            gettingProducts: false,
            products: [],
            showNewProductDialog: false,
            newProduct: {},
            addingProduct: false,
            newProductNameErrorMessage: '',
            locations: [
                {
                    id: 0,
                    name: 'Shelf'
                }
            ],
            showAddEntryConversionDialog: false,
            showAddMoreEntryDialog: false,
            conversionDetails: {},
            moreEntryOptions: {},
            storageLocations: [],
            showingEntrySellingVariantsModal: false,
            activeEntry: null,

            stockItemTypes: []
        }
    },

    components: {SvgSymbols, Tip},

    computed: {
        ...mapState('system', ['currentUser', 'settings', 'stores']),
        ...mapState('stock', ['productCategories', 'productSubCategories', 'uoms']),

        ...mapGetters('system', ['getBasicPayload', 'businessHasMultipleStores']),
        ...mapGetters('stock', ['getStockLevelsBaseUrl', 'getClassificationBaseUrl']),

        businessTracksExpiryDates() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'TRACK_EXPIRY_DATES')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        businessHasEnabledSellingVariants() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'ENABLE_SELLING_VARIANTS')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        businessHasEnabledManualSellOuts() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'ENABLE_MANUAL_SELL_OUTS')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        businessHasEnabledAttendantCommissions() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'ENABLE_ATTENDANT_COMMISSIONS')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        getCategoriesBaseUrl() {
            return `${this.getClassificationBaseUrl}/categories`
        },

        getSubCategoriesBaseUrl() {
            return `${this.getClassificationBaseUrl}/subcategories`
        },

        Delete() {
            return Delete
        },
    },

    created() {
        if (this.currentUser) {
            this.getMetaData()
        }
    },

    mounted() {
        this.addEntry()
    },

    methods: {
        getMetaData() {
            this.fetchItemTypes()

            if (!this.productCategories || this.empty(this.productCategories)) {
                this.$store.dispatch('stock/getProductCategories')
            }

            if (!this.productSubCategories || this.empty(this.productSubCategories)) {
                this.$store.dispatch('stock/getProductSubCategories')
            }

            this.$store.dispatch('stock/getProductUoms')

            if (!this.settings || this.empty(this.settings)) {
                this.$store.dispatch('system/getSettings')
            }

            if (!this.stores || this.empty(this.stores)) {
                this.$store.dispatch('system/fetchStores')
            }
        },

        fetchItemTypes() {
            axios.get('/api/stock/items/types').then(res => {
                this.stockItemTypes = res.data.data
            }).catch(_ => {
            })
        },

        displayableSubcategories(index) {
            return this.productSubCategories.filter(subCat => subCat.category_id === this.entries[index].category_id)
        },

        hasVariant(variant) {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'APPLICABLE_PRODUCT_VARIATIONS')
            if (setting && (setting.value !== "")) {
                return (setting.value.split(",")).includes(variant)
            }

            return false
        },

        addEntry() {
            this.entries.push({
                buying_price: 0,
                selling_price: 0,
                quantity: 0,
                selling_variants: [],
                sells_out_manually: false,
                item_type: 'stock',
                attendant_commission: 0,
                restocking_method: 'purchasing',
                can_sell: true,
                can_stock: true,
            })
        },

        removeEntry(index) {
            this.entries.splice(index, 1)
        },

        addStock(addForAllStores = false) {
            this.entries.forEach(entry => {
                // Format can_sell field to be backend compatible
                entry.can_sell = entry.can_sell ? 1 : 0
                entry.can_stock = entry.can_stock ? 1 : 0

                let variantsToRemove = []
                entry.selling_variants.forEach((variant, index) => {
                    if (!variant.variant_name || !variant.stocking_unit_id || !variant.price) {
                        variantsToRemove.push(index)
                    }
                })

                variantsToRemove.forEach(variantIndex => {
                    entry.selling_variants.splice(variantIndex, 1)
                })
            })

            let payload = {
                user: this.currentUser,
                entries: this.entries,
                add_for_all_stores: addForAllStores ? 1 : 0
            }

            this.dataTableLoading = true
            axios.post(`${this.getStockLevelsBaseUrl}/add`, {data: JSON.stringify(payload)}).then(res => {
                this.dataTableLoading = false
                this.$message.success('Stock entries added successfully')
                this.entries = []

                this.addEntry()
            }).catch(error => {
                this.dataTableLoading = false
                this.$message.error('An error was encountered. Please try again')
            })
        },

        promptSellingVariants(entryIndex) {
            this.activeEntry = this.entries[entryIndex]
            this.showingEntrySellingVariantsModal = true
        },

        promptNewCategory(index) {
            this.newCategory = {
                entry_index: index
            }

            this.showAddCategoryDialog = true
        },

        promptNewSubCategory(index) {
            this.newSubCategory = {
                entry_index: index,
                category_id: this.entries[index].category_id
            }
            this.showAddSubCategoryDialog = true
        },

        addNewCategory(e) {
            e.preventDefault()

            // Validation
            let dataIsValid = true
            this.categoryNameErrorMessage = 'Category name is required'
            if (!this.newCategory.name || this.newCategory.name === '') {
                dataIsValid = false
                $("#category-name").addClass('is-invalid')
            } else {
                $("#category-name").removeClass('is-invalid')
            }

            if (dataIsValid) {
                let payload = {
                    ...this.getBasicPayload, ...{
                        name: this.newCategory.name
                    }
                }

                this.addingNewCategory = true
                axios.post(`${this.getCategoriesBaseUrl}/add`, payload).then(response => {
                    this.$store.commit('stock/addCategory', response.data.data)
                    this.entries[this.newCategory.entry_index].category_id = response.data.data.id

                    this.addingNewCategory = false
                    this.showAddCategoryDialog = false
                    this.$message.success('Category added successfully')
                }).catch(error => {
                    this.addingNewCategory = false

                    if (error.response && error.response.status === 422) {
                        this.categoryNameErrorMessage = 'A category with this name already exists'
                        $("#category-name").addClass('is-invalid')
                        this.$message.error(this.categoryNameErrorMessage)
                    } else {
                        this.$message.error('An error was encountered. Please try again')
                    }
                })
            } else {
                this.$message.warning('Please fill all fields')
            }
        },

        addNewSubCategory(e) {
            e.preventDefault()

            // Validation
            let dataIsValid = true
            this.subCategoryNameErrorMessage = 'Sub category name is required'
            if (!this.newSubCategory.name || this.newSubCategory.name === '') {
                dataIsValid = false
                $("#subcategory-name").addClass('is-invalid')
            } else {
                $("#subcategory-name").removeClass('is-invalid')
            }

            if (!this.newSubCategory.category_id) {
                dataIsValid = false
                $("#subcategory-category-id").css('display', 'block')
            } else {
                $("#subcategory-category-id").css('display', 'none')
            }

            if (dataIsValid) {
                let payload = {
                    store_id: this.currentUser.store_id,
                    user_id: this.currentUser.id,
                    name: this.newSubCategory.name,
                    category_id: this.newSubCategory.category_id
                }

                this.addingNewSubCategory = true
                axios.post(`${this.getSubCategoriesBaseUrl}/add`, payload).then(response => {
                    this.$store.commit('stock/addSubCategory', response.data.data)
                    this.entries[this.newSubCategory.entry_index].sub_category_id = response.data.data.id

                    this.addingNewSubCategory = false
                    this.showAddSubCategoryDialog = false
                    this.$message.success('Sub category added successfully')
                }).catch(error => {
                    this.addingNewSubCategory = false

                    if (error.response && error.response.status === 422) {
                        this.subcategoryNameErrorMessage = 'A sub category with this name already exists'
                        $("#subcategory-name").addClass('is-invalid')
                        this.$message.error(this.subcategoryNameErrorMessage)
                    } else {
                        this.$message.error('An error was encountered. Please try again')
                    }
                })
            } else {
                this.$message.warning('Please fill all fields')
            }
        },

        fillUnitsOnProductChange(productId, index) {
            let product = this.products.find(_p => _p.id === productId)
            if (product) {
                this.entries[index].unit_id = product.primary_unit_id
                let unit = this.uoms.find(uom => uom.id === product.primary_unit_id)
                if (unit) {
                    this.entries[index].units.push(unit)
                }
            }
        },

        promptNewConversion(index) {
            this.entries[index].has_conversion = true
        },

        removeNewConversion(index) {
            this.entries[index].has_conversion = false
            this.entries[index].units = []
            this.fillUnitsOnProductChange(this.entries[index].product_id, index)
        },

        conversionUnits(index) {
            let product = this.products.find(_p => _p.id === this.entries[index].product_id)
            if (product) {
                return this.uoms.filter(uom => uom.id !== product.primary_unit_id)
            }

            return this.uoms
        },

        fillStockableUnit(_, index) {
            if (this.entries[index].units.length > 1) {
                this.entries[index].units.pop()
                this.fillUnitsOnProductChange(this.entries[index].product_id, index)
            }

            let unit = this.uoms.find(uom => uom.id === this.entries[index].conversion.conversion_unit_id)
            if (unit) {
                this.entries[index].units.push(unit)
            }
        },

        promptMoreEntryOptions(command, index) {
            let entry = this.entries[index]
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            if (commandName === 'convert') {
                this.promptAddEntryDialog(entry, commandIndex)
            }

            if (commandName === 'more') {
                this.promptAddMoreEntryDialog(entry, commandIndex)
            }
        },

        promptAddEntryDialog(entry, index) {
            this.conversionDetails.entryIndex = index
            this.conversionDetails.entryLabel = entry.label
            this.conversionDetails.unit_id = entry.conversion_unit_id
            this.conversionDetails.conversion_quantity = entry.conversion_quantity

            this.showAddEntryConversionDialog = true
        },

        saveConversionDetails() {
            let entry = this.entries[this.conversionDetails.entryIndex]
            if (entry.unit_id === this.conversionDetails.unit_id) {
                return this.$message.error('The conversion unit cannot be the same as entry unit')
            }

            try {
                if (!this.conversionDetails.conversion_quantity || isNaN(parseFloat(this.conversionDetails.conversion_quantity))) {
                    return this.$message.error('Please put a valid conversion quantity')
                }
            } catch (_) {
                return this.$message.error('Please put a valid conversion quantity')
            }

            entry.conversion_unit_id = this.conversionDetails.unit_id
            entry.conversion_quantity = this.conversionDetails.conversion_quantity

            this.discardConversionDetails()
        },

        discardConversionDetails() {
            this.showAddEntryConversionDialog = false
            this.conversionDetails = {}
        },

        promptAddMoreEntryDialog(entry, index) {
            this.moreEntryOptions.entry_index = index
            this.moreEntryOptions.entry_label = entry.label
            this.moreEntryOptions.reorder_level = entry.reorder_level
            this.moreEntryOptions.batch_expiry_date = entry.batch_expiry_date

            this.showAddMoreEntryDialog = true
        },

        saveMoreEntryOptions() {
            let entry = this.entries[this.moreEntryOptions.entry_index]
            entry.reorder_level = this.moreEntryOptions.reorder_level
            entry.batch_expiry_date = this.moreEntryOptions.batch_expiry_date

            this.discardMoreEntryOptions()
        },

        discardMoreEntryOptions() {
            this.showAddMoreEntryDialog = false
            this.moreEntryOptions = {}
        },
    },
}
</script>

<style>
.entry {
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    padding: 12px 0;
}

.entry .form-group {
    margin-right: 15px;
}
</style>
