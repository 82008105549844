<script>
import {defineComponent} from 'vue'
import {mapGetters, mapState} from "vuex";

export default defineComponent({
    name: "AccountsReceivableView",

    data() {
        return {
            report: null,
            fetchingReport: false,
            errorFetchingReport: false,
        }
    },

    computed: {
        ...mapGetters('system', ['getBasicPayload']),
        ...mapState('system', ['currentUser']),
    },

    created() {
        this.fetchReport()
    },

    methods: {
        fetchReport() {
            this.errorFetchingReport = false

            let payload = {
                store_id: this.currentUser.store_id
            }
            this.fetchingReport = true
            axios.get('/api/accounting/accounts-receivable', {params: payload}).then(res => {
                this.fetchingReport = false
                this.report = res.data.data
            }).catch(error => {
                this.fetchingReport = false
                this.errorFetchingReport = true

                this.$message.error('An error was encountered. Please try again.')
            })
        },
    },
})
</script>

<template>
    <div class="master-template is-report-page" v-loading="fetchingReport" element-loading-text="Generating report">
        <div v-if="errorFetchingReport" class="d-flex flex-column">
            <p> An error occurred while generating report. </p>
            <button class="btn btn-primary new-item-btn" @click="fetchReport"> Try Again </button>
        </div>

        <div class="report-content position-relative" v-else>
            <div class="w-100 d-flex justify-content-between align-items-center">
                <h5 class="fw-700 f-22 mb-0"> Accounts Receivable </h5>
            </div>

            <div class="card shadow position-relative w-100 mt-4">
                <div id="report-header" class="d-flex flex-column w-100">
                    <div id="business-name" class="d-flex w-100 text-center justify-content-center align-items-center">
                        {{ currentUser.business_name }}
                    </div>
                    <div id="report-title" class="d-flex w-100 text-center justify-content-center align-items-center">
                        Accounts Receivable
                    </div>

                    <div id="report-period" class="d-flex w-100 text-center justify-content-center align-items-center" v-if="report">
                        {{ report.date }}
                    </div>
                </div>

                <div id="report-details-header" class="w-100 d-flex justify-content-end">
                    <span> TOTALS </span>
                </div>

                <div id="report-details" class="w-100" v-if="report">
                    <div class="report-item d-flex justify-content-between align-items-center">
                        <span class="label"> Unpaid Credit Sales </span>
                        <span class="value"> {{ formatAmount(report.unpaid_credit_sales) }} </span>
                    </div>
                </div>

                <div id="report-total-section" class="w-100" v-if="report">
                    <div class="w-100 d-flex justify-content-between align-items-center report-total-item">
                        <span class="label"> Total Accounts Receivable </span>
                        <span class="value"> {{ formatAmount(report.total_accounts_receivable) }} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
