<template>
    <div class="master-template">
        <div class="master-title-div d-flex justify-content-between align-items-center">
            <h5> Production Recipes </h5>

            <div class="actions">
                <button class="btn btn-primary new-item-btn" @click="promptNewRecipe">
                    <i class="fas fa-plus-circle"></i>New Recipe
                </button>
            </div>
        </div>

        <div class="master-content">
            <div class="data-table-filters card shadow bg-white">
                <h5> Search Recipes </h5>

                <div class="row">
                    <div class="form-group col-md-5">
                        <label> Search by product name </label>
                        <input type="text" class="form-control" v-model="filters.name" placeholder="Search by product name">
                    </div>
                </div>

                <div class="mt-3 d-flex">
                    <button class="btn btn-secondary mr-12" @click="getRecipes"> Search Recipes</button>
                    <el-button size="large" @click="resetRecipeFilters">Reset Filters</el-button>
                </div>
            </div>

            <div class="data-table-summary d-flex justify-content-end mt-4 px-1">
                <span class="summary"> Showing {{ recipes.length }} out of {{ pagination.total_items }} recipes. </span>
            </div>


            <div class="data-table">
                <el-skeleton :loading="gettingRecipes" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" v-for="row in 7" :key="row" style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                    </template>
                    <template #default>
                        <el-table :data="recipes" style="width: 100%" empty-text="No recipes found.">
                            <el-table-column label="Finished Product" width="250">
                                <template #default="scope">
                                    <span>{{ scope.row.item.label }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Base Production Cost" width="180">
                                <template #default="scope">
                                    <span>{{ scope.row.item.display_buying_price }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Selling Price" width="180">
                                <template #default="scope">
                                    <span>{{ scope.row.item.display_selling_price }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Actions">
                                <template #default="scope">
                                    <button class="btn btn-primary new-item-btn mr-12" @click="promptNewProduction(scope.$index)">
                                        <i class="fas fa-blender"></i> Produce
                                    </button>

                                    <button class="btn btn-outline-secondary new-item-btn mr-12">
                                        <i class="far fa-edit"></i> Update recipe
                                    </button>

                                    <button class="btn btn-outline-danger new-item-btn" @click="promptRemoveRecipe(scope.$index)">
                                        <i class="far fa-trash-alt"></i> Remove Recipe
                                    </button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-skeleton>
            </div>

            <!-- Pagination -->
            <div class="data-table-pagination d-flex justify-content-end mt-3">
                <el-pagination background
                               layout="prev, pager, next"
                               :total="pagination.total_items"
                               :page-size="10" v-model:current-page="pagination.current_page"
                               @current-change="handlePaginationChange"
                               hide-on-single-page/>
            </div>
        </div>

        <el-dialog v-model="showNewRecipeDialog" width="70%" title="New Production Recipe">
            <div v-loading="addingRecipe" element-loading-text="Making recipe">
                <h5 class="section-header mb-2"> Finished Product </h5>
                <el-radio-group v-model="newRecipe.new_product" size="large">
                    <el-radio-button :label="false">Select an existing product</el-radio-button>
                    <el-radio-button :label="true">Create a new product</el-radio-button>
                </el-radio-group>

                <!-- Finished Product -->
                <div v-if="newRecipe.new_product">
                    <div class="card shadow-lg mb-4 mt-4" v-if="newRecipe.new_product_details">
                        <div class="row d-flex flex-wrap">
                            <div class="form-group col-md-2">
                                <label> Product Name </label>
                                <input type="text" class="form-control" v-model="newRecipe.new_product_details.product_name">
                            </div>

                            <div class="form-group col-md-2">
                                <label> Category </label>
                                <el-select
                                    v-model="newRecipe.new_product_details.category_id"
                                    default-first-option
                                    filterable
                                    size="large"
                                >
                                    <el-option
                                        v-for="category in productCategories"
                                        :key="category.id"
                                        :label="category.name"
                                        :value="category.id"
                                    />
                                </el-select>
                            </div>

                            <div class="form-group col-md-2">
                                <label> Sub Category </label>
                                <el-select
                                    v-model="newRecipe.new_product_details.sub_category_id"
                                    default-first-option
                                    filterable
                                    size="large"
                                    :no-data-text="newRecipe.new_product_details.category_id ? 'No sub categories found' : 'Select a category first'"
                                >
                                    <el-option
                                        v-for="subCategory in displayableSubcategories()"
                                        :key="subCategory.id"
                                        :label="subCategory.name"
                                        :value="subCategory.id"
                                    />
                                </el-select>
                            </div>

                            <div class="form-group col-md-2">
                                <label> Primary Units </label>
                                <el-select
                                    v-model="newRecipe.new_product_details.unit_id"
                                    default-first-option
                                    filterable
                                    allow-create
                                    size="large"
                                >
                                    <el-option
                                        v-for="unit in uoms"
                                        :key="unit.id"
                                        :label="unit.unit_name"
                                        :value="unit.id"
                                    />
                                </el-select>
                            </div>

                            <div class="form-group col-md-2">
                                <label class="block"> Can Sell </label>
                                <el-radio-group v-model="newRecipe.new_product_details.can_sell" size="large">
                                    <el-radio-button :label="true">Yes</el-radio-button>
                                    <el-radio-button :label="false">No</el-radio-button>
                                </el-radio-group>
                            </div>

<!--                            <div class="form-group col-md-2">-->
<!--                                <label> Production Cost </label>-->
<!--                                <input type="text" class="form-control" v-model="newRecipe.new_product_details.buying_price">-->
<!--                            </div>-->

                            <div class="form-group col-md-2" v-if="newRecipe.new_product_details.can_sell">
                                <label> Selling Price </label>
                                <input type="text" class="form-control" v-model="newRecipe.new_product_details.selling_price">
                            </div>

                            <div class="form-group col-md-2" v-if="businessUsesVariants && hasVariant('type')">
                                <label> Type </label>
                                <input type="text" class="form-control" v-model="newRecipe.new_product_details.type_variant">
                            </div>

                            <div class="form-group col-md-2" v-if="businessUsesVariants && hasVariant('size')">
                                <label> Size </label>
                                <input type="text" class="form-control" v-model="newRecipe.new_product_details.size_variant">
                            </div>

                            <div class="form-group col-md-2" v-if="businessUsesVariants && hasVariant('color')">
                                <label> Color </label>
                                <input type="text" class="form-control" v-model="newRecipe.new_product_details.color_variant">
                            </div>

                            <div class="form-group col-md-2" v-if="businessUsesVariants && hasVariant('mass')">
                                <label> Mass / Weight </label>
                                <input type="text" class="form-control" v-model="newRecipe.new_product_details.mass_variant">
                            </div>

                            <div class="form-group col-md-2" v-if="businessUsesVariants && hasVariant('capacity')">
                                <label> Capacity </label>
                                <input type="text" class="form-control" v-model="newRecipe.new_product_details.capacity_variant">
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row" v-else>
                    <div class="form-group col-md-4 mb-3 mt-3">
                        <label> Select a product </label>
                        <el-select
                            v-model="newRecipe.stock_item_id"
                            default-first-option
                            filterable
                            size="large"
                        >
                            <el-option
                                v-for="item in metadata.stock_levels"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id"
                            />
                        </el-select>
                    </div>
                </div>

                <!-- Raw Materials -->
                <div id="raw-materials">
                    <h5 class="section-header"> Raw Materials </h5>
                    <el-table :data="newRecipe.raw_materials" style="width: 100%">
                        <el-table-column label="Raw Material" width="350">
                            <template #default="scope">
                                <el-select
                                    v-model="scope.row.stock_item_id"
                                    default-first-option
                                    filterable
                                    size="large"
                                    @change="fillRawMaterialDetails($event, scope.$index)"
                                >
                                    <el-option
                                        v-for="item in metadata.stock_levels"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.id"
                                    />
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column label="Quantity" width="300">
                            <template #default="scope">
                                <input type="text" class="form-control mr-12" v-model="scope.row.quantity" style="display: inline-block; width: 100px;">
                                <span v-if="scope.row.stock_item_id">{{ scope.row.units }}s</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="" v-if="newRecipe.raw_materials.length > 1" width="40">
                            <template #default="scope">
                                <i class="far fa-trash-alt action-icon text-danger" @click="removeEntry(scope.$index)"></i>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="d-flex justify-content-end mt-2">
                        <button class="btn btn-sm btn-outline-primary new-item-btn" @click="addEntry">
                            <i class="fas fa-plus-circle"></i>Add Entry
                        </button>
                    </div>
                </div>
            </div>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showNewRecipeDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addRecipe"> Add Recipe</button>
                </div>
            </template>
        </el-dialog>

        <el-dialog
            v-model="showProductionDialog"
            :title="'Produce ' + production.item_name"
            width="30%"
            v-if="production.item_name"
        >
            <form @submit.prevent="produce"
                  v-loading="producing"
                  element-loading-text="In progress, Please wait"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="qty"> How many {{ production.units }}s of {{ production.item_name }} are you producing? </label>
                    <input type="text" id="qty" class="form-control" v-model="production.quantity">
                    <div class="invalid-feedback"> Please provide a valid quantity </div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showProductionDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="produce"> Confirm Production </button>
                </div>
            </template>
        </el-dialog>

    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import $ from 'jquery'

export default {
    name: "ProduceStock",

    data() {
        return {
            baseUrl: '/api/stock/production',
            filters: {},
            gettingRecipes: false,
            recipes: [],
            showNewRecipeDialog: false,
            newRecipe: {},
            addingRecipe: false,
            showProductionDialog: false,
            production: {},
            producing: false,
            pagination: {
                current_page: 1,
                total_items: 0
            },
            metadata: {
                stock_levels: [],
            },
        }
    },

    computed: {
        ...mapState('system', ['currentUser', 'settings']),
        ...mapState('stock', ['productCategories', 'productSubCategories', 'uoms']),

        ...mapGetters('system', ['getBasicPayload']),
        ...mapGetters('stock', ['getStockLevelsBaseUrl']),

        businessUsesVariants() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'USE_PRODUCT_VARIATIONS')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        businessTracksExpiryDates() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'TRACK_EXPIRY_DATES')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },
    },

    created() {
        this.getRecipes()
        if (!this.settings || this.empty(this.settings)) {
            this.$store.dispatch('system/getSettings')
        }

        if (!this.productCategories || this.empty(this.productCategories)) {
            this.$store.dispatch('stock/getProductCategories')
        }

        if (!this.productSubCategories || this.empty(this.productSubCategories)) {
            this.$store.dispatch('stock/getProductSubCategories')
        }

        if (!this.uoms || this.empty(this.uoms)) {
            this.$store.dispatch('stock/getProductUoms')
        }
    },

    methods: {
        getRecipes() {
            let payload = {
                user: this.currentUser,
                filters: this.filters,
                page: this.pagination.current_page - 1
            }
            this.gettingRecipes = true
            axios.get(`${this.baseUrl}/recipes`, {params: {data: JSON.stringify(payload)}}).then(res => {
                this.gettingRecipes = false
                this.recipes = res.data.data.content
                this.pagination.total_items = res.data.data.total_count

                this.getStockLevels()
            }).catch(error => {
                this.$message.error(this.serverErrorMessage(true))
                this.gettingRecipes = false
            })
        },

        getStockLevels() {
            axios.get('/api/stock/levels', {params: {...this.getBasicPayload, ...{can_sell_only: 0}}}).then(response => {
                this.metadata.stock_levels = response.data.data
            }).catch(error => {
            })
        },

        promptNewRecipe() {
            this.newRecipe = {
                new_product: false,
                new_product_details: {
                    can_sell: true,
                    buying_price: 0,
                    selling_price: 0,
                    quantity: 0
                },
                raw_materials: [{
                    quantity: 1
                }]
            }
            this.showNewRecipeDialog = true
        },

        addRecipe() {
            // Format data
            if (this.newRecipe.new_product) {
                this.newRecipe.new_product_details.can_sell = this.newRecipe.new_product_details.can_sell ? 1 : 0
                this.newRecipe.new_product_details.buying_price = 0
                this.newRecipe.raw_materials.forEach(material => {
                    this.newRecipe.new_product_details.buying_price += (parseFloat(material.cost) * parseFloat(material.quantity))
                })
            }
            this.newRecipe.new_product = this.newRecipe.new_product ? 1 : 0

            let payload = {
                user: this.currentUser,
                recipe: this.newRecipe
            }
            this.addingRecipe = true
            axios.post(`${this.baseUrl}/recipes/add`, {data: JSON.stringify(payload)}).then(res => {
                this.addingRecipe = false
                this.getRecipes()

                this.$message.success('Recipe added successfully')
                this.showNewRecipeDialog = false
            }).catch(error => {
                this.addingRecipe = false
                this.$message.error(this.serverErrorMessage())
            })
        },

        resetRecipeFilters() {
            this.filters = {}
            this.getRecipes()
        },

        handlePaginationChange(newPage) {
            this.currentPage = newPage
            this.getRecipes()
        },

        displayableSubcategories() {
            return this.productSubCategories.filter(subCat => subCat.category_id === this.newRecipe.new_product_details.category_id)
        },

        hasVariant(variant) {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'APPLICABLE_PRODUCT_VARIATIONS')
            if (setting && (setting.value !== "")) {
                return (setting.value.split(",")).includes(variant)
            }

            return false
        },

        addEntry() {
            this.newRecipe.raw_materials.push({
                quantity: 1
            })
        },

        removeEntry(index) {
            this.newRecipe.raw_materials.splice(index, 1)
        },

        fillRawMaterialDetails(newValue, index) {
            let rawMaterial = this.newRecipe.raw_materials[index]
            let item = this.metadata.stock_levels.find(_item => _item.id === rawMaterial.stock_item_id)
            if (item) {
                rawMaterial.units = item.units
                rawMaterial.cost = item.buying_price
            }
        },

        displayableStockItems() {
            /**
             * Return only items that are not finished product, and are not selected
             */
            let items = this.metadata.stock_levels
            // items.filter(item => {
            //     let itemIsNotSelectedFinishedProduct = true
            //     // let itemIsNotSelectedFinishedProduct = item.id !== this.newRecipe.stock_item_id
            //
            //     // let itemIsNotAlreadyARawMaterial = true
            //     // this.newRecipe.raw_materials.forEach(rawMaterial => {
            //     //     if (item.id === rawMaterial.stock_item_id) {
            //     //         itemIsNotAlreadyARawMaterial = false
            //     //     }
            //     // })
            //
            //     return itemIsNotSelectedFinishedProduct
            // })

            return items
        },

        promptRemoveRecipe(index) {
            this.$confirm(
                'This action is permanent. Continue?',
                'Remove Recipe',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(() => {
                let payload = {id: this.recipes[index].id, user: this.currentUser}
                axios.post(`${this.baseUrl}/recipes/remove`, {data: JSON.stringify(payload)})
                    .then(response => {
                        this.recipes.splice(index, 1)
                        this.$message.success('Recipe removed successfully')
                    }).catch(error => {
                    this.$message.error(this.serverErrorMessage())
                })
            })
                .catch(() => {
                    //
                })
        },

        promptNewProduction(index) {
            this.production = {
                id: this.recipes[index].item.id,
                item_name: this.recipes[index].item.label,
                units: this.recipes[index].item.units,
                quantity: 1
            }

            this.showProductionDialog = true
        },

        produce(e) {
            e.preventDefault()

            let dataIsValid = true
            if (!this.production.quantity || isNaN(this.production.quantity) || (parseFloat(this.production.quantity) < 1)) {
                dataIsValid = false
                $("#qty").addClass('is-invalid')
            } else {
                $("#qty").removeClass('is-invalid')
            }

            if (dataIsValid) {
                let payload = {
                    item: this.production,
                    user: this.currentUser
                }

                this.producing = true
                axios.post(`${this.baseUrl}/produce`, {data: JSON.stringify(payload)}).then(res => {
                    this.producing = false
                    this.$message.success(`${this.production.quantity} ${this.production.units}s of ${this.production.item_name} produced successfully`)

                    this.showProductionDialog = false
                }).catch(error => {
                    this.producing = false
                    this.$message.error(this.serverErrorMessage())
                })
            }

        },
    },
}
</script>

<style scoped>

</style>
