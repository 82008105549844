<template>
    <div id="system-users">
        <div class="master-subtitle d-flex justify-content-between align-items-center">
            <h5 class="mb-0"> System Roles </h5>

            <div class="actions">
                <button class="btn btn-primary new-item-btn" @click="getRoles">
                    <i class="fas fa-sync"></i>Refresh List
                </button>
                <button class="btn btn-primary new-item-btn" @click="promptNewRole">
                    <i class="fas fa-plus-circle"></i>New Role
                </button>
            </div>
        </div>

        <div class="master-content">
            <div class="data-table mt-3">
                <el-skeleton :loading="gettingRoles" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" v-for="row in 9" :key="row" style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                    </template>
                    <template #default>
                        <!-- Filters -->
                        <div class="card shadow-sm rounded bg-white mb-4">
                            <h5 class="card-title"> Filters </h5>
                            <div class="data-table-filters d-flex justify-content-between align-items-center">
                                <div class="filters row flex-grow-1 d-flex flex-wrap justify-content-between align-items-center">
                                    <div class="form-group col-md-5">
                                        <label> Search roles by name </label>
                                        <input type="text" class="form-control" placeholder="Search roles by name"
                                               v-model="filters.name"
                                               @input="filterRoles">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End of Filters -->

                        <!-- List -->
                        <el-table :data="displayableRoles" style="width: 100%; margin-top: 10px;" empty-text="No roles found. Please try different filters.">
                            <el-table-column prop="name" label="Name"/>
                            <el-table-column prop="created_at" label="Date Created"/>
                            <el-table-column label="Created By">
                                <template #default="scope">
                                    <span v-if="scope.row.created_by">{{ scope.row.created_by }}</span>
                                    <span v-else>-</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="updated_at" label="Last Updated"/>
                            <el-table-column label="Updated By">
                                <template #default="scope">
                                    <span v-if="scope.row.updated_by">{{ scope.row.updated_by }}</span>
                                    <span v-else>-</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Actions">
                                <template #default="scope">
                                    <el-dropdown @command="handleRoleActions">
                                        <el-button type="primary">
                                            Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item :command="'update-' + scope.$index">Update Role</el-dropdown-item>
                                                <el-dropdown-item :command="'remove-' + scope.$index">Remove Role</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- End of List -->
                    </template>
                </el-skeleton>
            </div>
        </div>

        <!-- New Role Dialog -->
        <el-dialog
            v-model="showingNewRoleDialog"
            title="New Role"
            width="30%"
        >
            <form @submit.prevent="addRole"
                  v-loading="addingRole"
                  element-loading-text="Adding Role"
                  id="add-role-form"
                  novalidate
            >
                <div class="form-group">
                    <label for="role-name" class="required"> Role Name </label>
                    <input type="text" id="role-name" class="form-control" v-model="newRole.name">
                    <div class="invalid-feedback">{{ validationErrors.name }}</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showingNewRoleDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addRole"> Add Role </button>
                </div>
            </template>
        </el-dialog>
        <!-- End of New Role Dialog -->

        <!-- Update Role Dialog -->
        <el-dialog
            v-model="showingUpdateRoleDialog"
            title="Update Role"
            width="30%"
        >
            <form @submit.prevent="updateRole"
                  v-loading="updatingRole"
                  element-loading-text="Updating Role"
                  id="update-role-form"
                  novalidate
            >
                <div class="form-group">
                    <label for="update-role-name" class="required"> Role Name </label>
                    <input type="text" id="update-role-name" class="form-control" v-model="actionRole.name">
                    <div class="invalid-feedback">{{ validationErrors.name }}</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showingUpdateRoleDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="updateRole"> Save Changes </button>
                </div>
            </template>
        </el-dialog>
        <!-- End of New Role Dialog -->

    </div>
</template>

<script>
import {mapState} from "vuex";
import $ from 'jquery'

export default {
    name: "SystemUsers",

    data() {
        return {
            gettingRoles: false,
            filters: {
                name: null
            },
            displayableRoles: [],
            showingNewRoleDialog: false,
            showingUpdateRoleDialog: false,
            addingRole: false,
            updatingRole: false,
            newRole: {},
            actionRole: {},

            validationErrors: {
                name: 'Please enter a valid role name'
            }
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
        ...mapState('users', ['baseUrl', 'roles', 'systemUsers']),

        rolesBaseUrl() {
            return `${this.baseUrl}/roles`
        }
    },

    created() {
        this.getRoles()
    },

    methods: {
        getRoles() {
            this.gettingRoles = true
            let params = {
                user_id: this.currentUser.id,
                business_id: this.currentUser.business_id
            }
            axios.get(`${this.rolesBaseUrl}/list`, {params: params}).then(response => {
                let roles = response.data.data
                this.displayableRoles = roles
                this.gettingRoles = false

                // Update Store
                this.$store.commit('users/setSystemRoles', roles)
            }).catch(error => {
                this.gettingRoles = false
                this.$message.error('An error was encountered. Please refresh the page')
            })
        },

        promptNewRole() {
            this.newRole = {}
            this.newRole.user_id = this.currentUser.id
            this.newRole.business_id = this.currentUser.business_id

            this.showingNewRoleDialog = true
        },

        filterRoles() {
            if (this.filters.name || !(this.hasSpacesOnly(this.filters.name))) {
                this.displayableRoles = this.roles.filter(role => (role.name.toLowerCase()).includes(this.filters.name.toLowerCase()))
            } else {
                this.displayableRoles = this.roles
            }
        },

        addRole(e) {
            e.preventDefault()

            // Validate
            let dataIsValid = true
            this.resetValidationErrors()
            if (!this.newRole.name || this.hasSpacesOnly(this.newRole.name)) {
                dataIsValid = false
                $("#role-name").addClass('is-invalid')
            } else {
                $("#role-name").removeClass('is-invalid')
            }

            if (!dataIsValid) {
                return this.$message.warning('Please fill all required fields')
            }

            this.addingRole = true
            axios.post(`${this.rolesBaseUrl}/add`, this.newRole).then(response => {
                this.$store.commit('users/addNewRole', response.data.data)
                this.displayableRoles = this.roles
                this.filters = {}

                this.addingRole = false
                this.showingNewRoleDialog = false
            }).catch(error => {
                this.addingRole = false

                // Handle Bad Request exceptions
                if (error.response) {
                    if (error.response.status === 422) {
                        let errors = error.response.data.errors

                        // Check for role name
                        if (errors.hasOwnProperty('name')) {
                            this.validationErrors.name = errors.name
                            this.$message.error(this.validationErrors.name)
                            $("#role-name").addClass('is-invalid')
                        }
                    } else {
                        this.$message.error('An error was encountered. Please try again')
                    }
                } else {
                    this.$message.error('An error was encountered. Please try again')
                }
            })
        },

        handleRoleActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            if (commandName === 'update') {
                this.promptUpdateRole(this.displayableRoles[commandIndex])
            }

            if (commandName === 'remove') {
                this.removeRole(this.displayableRoles[commandIndex])
            }
        },

        promptUpdateRole(role) {
            this.actionRole = JSON.parse(JSON.stringify(role))
            this.actionRole.user_id = this.currentUser.id
            this.actionRole.business_id = this.currentUser.business_id
            this.showingUpdateRoleDialog = true
        },

        updateRole(e) {
            e.preventDefault()

            // Validate
            let dataIsValid = true
            this.resetValidationErrors()
            if (!this.actionRole.name || this.hasSpacesOnly(this.actionRole.name)) {
                dataIsValid = false
                $("#update-role-name").addClass('is-invalid')
            } else {
                $("#update-role-name").removeClass('is-invalid')
            }

            if (!dataIsValid) {
                return this.$message.warning('Please fill all required fields')
            }

            this.updatingRole = true
            axios.post(`${this.rolesBaseUrl}/update`, this.actionRole).then(response => {
                this.updatingRole = false

                let updatedRolePosition = this.roles.findIndex(role => role.id === response.data.data.id)
                if (updatedRolePosition !== -1) {
                    let payload = {
                        index: updatedRolePosition,
                        role: response.data.data
                    }
                    this.$store.commit('users/updateRole', payload)
                    this.$message.success('Role updated successfully')
                }

                this.displayableRoles = this.roles
                this.filters = {}
                this.showingUpdateRoleDialog = false
            }).catch(error => {
                this.updatingRole = false

                // Handle Bad Request exceptions
                if (error.response) {
                    if (error.response.status === 422) {
                        let errors = error.response.data.errors

                        // Check for role name
                        if (errors.hasOwnProperty('name')) {
                            this.validationErrors.name = errors.name
                            this.$message.error(this.validationErrors.name)
                            $("#update-role-name").addClass('is-invalid')
                        }
                    } else {
                        this.$message.error('An error was encountered. Please try again')
                    }
                } else {
                    this.$message.error('An error was encountered. Please try again')
                }
            })
        },

        removeRole(role) {
            this.$confirm(
                'This action is permanent and will remove all permissions from users assigned to this role. Make sure you first assign different roles to this users. Continue?',
                'Confirm Role Removal',
                {
                    confirmButtonText: 'Yes, remove role',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.gettingRoles = true
                    axios.post(`${this.rolesBaseUrl}/remove`, {id: role.id, business_id: this.currentUser.business.id}).then(response => {
                        this.gettingRoles = false

                        let removedRolePosition = this.roles.findIndex(_role => _role.id === role.id)
                        if (removedRolePosition !== -1) {
                            this.$store.commit('users/removeRole', removedRolePosition)
                            this.$message.success('Role removed successfully')
                        }

                        this.displayableRoles = this.roles

                        // Update any potential business users
                        let users = response.data.data
                        if (!this.empty(users)) {
                            users.forEach(user => {
                                // Find the user
                                let existingUserPosition = this.systemUsers.findIndex(_user => _user.id === user.id)
                                if (existingUserPosition === -1) {
                                    // User not found: add
                                    this.$store.commit('users/addNewUser', user)
                                } else {
                                    // Update user
                                    this.$store.commit('users/updateSystemUser', {
                                        index: existingUserPosition,
                                        user: user
                                    })
                                }
                            })
                        }
                    }).catch(error => {
                        this.gettingRoles = false
                        this.$message.error('An error was encountered. Please try again')
                    })
                })
                .catch(() => {
                    //
                })
        },

        resetValidationErrors() {
            this.validationErrors = {
                name: 'Please enter a valid role name'
            }
        },
    },
}
</script>

<style scoped>

</style>
