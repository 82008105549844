<script>
import {mapGetters, mapState} from "vuex";

export default {
    name: "PaymentAccountsList",

    data() {
        return {
            baseUrl: '/api/transactions/payment-accounts',
            gettingAccounts: false,
            accounts: [],
            activeAccount: null,
            selectedStoreId: null,

            newAccountModalIsActive: false,
            newAccount: {},
            addingAccount: false,

            updateAccountModalIsActive: false,
            updatingAccount: false
        }
    },

    computed: {
        ...mapState('system', ['currentUser', 'stores']),
        ...mapGetters('system', ['getBasicPayload', 'businessHasMultipleStores'])
    },

    created() {
        this.getAccounts()

        if (this.stores.length === 0) {
            this.$store.dispatch('system/fetchStores')
        }
    },

    mounted() {
        this.selectedStoreId = this.currentUser.store_id
    },

    methods: {
        getAccounts() {
            let payload = {
                store_id: this.selectedStoreId ?? this.currentUser.store_id
            }

            this.gettingAccounts = true
            axios.get(this.baseUrl, {params: payload}).then(res => {
                this.gettingAccounts = false
                this.accounts = res.data.data
            }).catch(err => {
                this.gettingAccounts = false
                this.$message.error("Error getting payment accounts. Please try again")
            })
        },

        promptAddAccount() {
            this.newAccount = {
                store_id: this.currentUser.store_id,
                name: null,
                initial_balance: 0,
                initial_balance_date: this.getFormattedNow(),
                description: null
            }

            this.newAccountModalIsActive = true
        },

        handleAccountActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            if (commandName === 'update') {
                this.activeAccount = JSON.parse(JSON.stringify(this.accounts[commandIndex]))
                this.newBalance = this.activeAccount.balance
                this.updateAccountModalIsActive = true
            }

            if (commandName === 'deposit') {
                this.activeAccount = this.accounts[commandIndex]
                this.depositAmount = 0
                this.depositModalIsActive = true
            }

            if (commandName === 'withdraw') {
                this.activeAccount = this.accounts[commandIndex]
                this.withdrawalAmount = 0
                this.withdrawDialogIsActive = true
            }

            if (commandName === 'transfer') {
                this.activeAccount = this.accounts[commandIndex]
                this.transferDetails.source_account_id = this.activeAccount.id
                this.transferDetails.transfer_amount = 0
                this.transferModalIsActive = true
            }
        },

        addAccount(e) {
            e.preventDefault()

            this.addingAccount = true
            axios.post(`${this.baseUrl}/create`, {payload: JSON.stringify(this.newAccount)}).then(() => {
                this.addingAccount = false
                this.getAccounts()
                this.$message.success('Account added successfully.')

                this.newAccountModalIsActive = false
            }).catch(() => {
                this.addingAccount = false
                this.$message.error('Error adding account. Please try again.')
            })
        },

        updateAccount(e) {
            e.preventDefault()

            this.updatingAccount = true
            axios.post(`${this.baseUrl}/update`, {payload: JSON.stringify(this.activeAccount)}).then(() => {
                this.updatingAccount = false
                this.getAccounts()
                this.$message.success('Account updated successfully.')

                this.updateAccountModalIsActive = false
            }).catch(() => {
                this.updatingAccount = false
                this.$message.error('Error updating account. Please try again.')
            })
        },
    },
}
</script>

<template>
    <div class="master-template">
        <div class="master-title d-flex justify-content-between align-items-center">
            <h5> Payment Accounts </h5>
            <div class="actions d-flex">
                <el-select v-model="selectedStoreId" filterable size="large"
                           placeholder="Select store" v-if="businessHasMultipleStores && can('manage_multiple_stores', currentUser)"
                           @change="getAccounts">
                    <el-option v-for="store in stores" :key="store.id" :label="store.store_name"
                               :value="store.id"></el-option>
                </el-select>

                <button class="btn btn-primary new-item-btn ml-12" @click="promptAddAccount">
                    <i class="fas fa-plus-circle"></i>Add Payment Account
                </button>
            </div>
        </div>

        <div class="master-content">
            <div class="data-table">
                <el-skeleton :loading="gettingAccounts" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" v-for="row in 7" :key="row" style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                    </template>

                    <template #default>
                        <el-table :data="accounts" style="width: 100%">
                            <el-table-column label="Account Name" prop="name"></el-table-column>
                            <el-table-column label="Account Description">
                                <template #default="scope">
                                    {{ scope.row.description ?? '-' }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Account Balance">
                                <template #default="scope">
                                    {{ formatAmount(scope.row.balance) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Actions">
                                <template #default="scope">
                                    <el-dropdown @command="handleAccountActions">
                                        <el-button type="primary">
                                            Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item :command="'update-' + scope.$index">Update Account Balance</el-dropdown-item>
                                                <!--                                                <el-dropdown-item :command="'deposit-' + scope.$index">Deposit Into Account</el-dropdown-item>-->
                                                <!--                                                <el-dropdown-item :command="'withdraw-' + scope.$index"-->
                                                <!--                                                                  :disabled="accountTransferIsDisabled(scope.row)">Withdraw From Account-->
                                                <!--                                                </el-dropdown-item>-->
                                                <!--                                                <el-dropdown-item :command="'transfer-' + scope.$index"-->
                                                <!--                                                                  :disabled="accountTransferIsDisabled(scope.row)">Transfer to Another Account-->
                                                <!--                                                </el-dropdown-item>-->
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-skeleton>
            </div>
        </div>

        <!-- New Account -->
        <el-dialog v-model="newAccountModalIsActive" width="30%" title="Add Payment Account"
                   :close-on-click-modal="false" :close-on-press-escape="false">

            <form method="post" @submit.prevent="addAccount" novalidate v-loading="addingAccount">
                <div class="form-group">
                    <label for="account-name"> Account Name </label>
                    <input type="text" class="form-control" v-model="newAccount.name" id="new-balance">
                    <div class="invalid-feedback"> Please provide a valid name</div>
                </div>

                <div class="form-group">
                    <label for="account-desc"> Account Description </label>
                    <textarea id="account-desc" class="form-control" v-model="newAccount.description"></textarea>
                </div>

                <div class="form-group">
                    <label for="initial-balance"> Initial Balance </label>
                    <input type="number" class="form-control" v-model="newAccount.initial_balance" id="initial-balance">
                    <div class="invalid-feedback"> Please provide a valid amount</div>
                </div>

                <div class="form-group">
                    <label for="initial-balance"> Initial Balance Date </label>
                    <el-date-picker
                        v-model="newAccount.initial_balance_date"
                        type="date"
                        placeholder="Pick a day"
                        format="MMM D, YYYY"
                        value-format="YYYY-MM-DD"
                        size="large"
                    >
                    </el-date-picker>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="newAccountModalIsActive = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addAccount"> Add Account</button>
                </div>
            </template>
        </el-dialog>

        <!-- Update Account -->
        <el-dialog v-model="updateAccountModalIsActive" width="30%" :title="`Update Payment Account ${activeAccount?.name}`"
                   :close-on-click-modal="false" :close-on-press-escape="false">

            <form method="post" @submit.prevent="updateAccount" novalidate v-loading="updatingAccount">
                <div class="form-group">
                    <label for="initial-balance"> Initial Balance </label>
                    <input type="number" class="form-control" v-model="activeAccount.initial_balance" id="initial-balance">
                    <div class="invalid-feedback"> Please provide a valid amount</div>
                </div>

                <div class="form-group">
                    <label for="initial-balance"> Initial Balance Date </label>
                    <el-date-picker
                        v-model="activeAccount.initial_balance_date"
                        type="date"
                        placeholder="Pick a day"
                        format="MMM D, YYYY"
                        value-format="YYYY-MM-DD"
                        size="large"
                    >
                    </el-date-picker>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="updateAccountModalIsActive = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="updateAccount"> Save Changes</button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<style scoped>

</style>
