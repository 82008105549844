<template>
    <div class="master-template">
        <div class="master-title-div d-flex justify-content-between">
            <h5> Sale Reports </h5>
        </div>

        <div class="master-content">
            <div class="reports-container d-flex flex-wrap">
                <el-skeleton :throttle="200" animated :loading="gettingSaleReports">
                    <template #template>
                        <el-skeleton-item variant="rect" v-for="row in 12" :key="row"
                                          style="width: 300px; height: 270px; margin-right: 20px; margin-bottom: 20px;"/>
                    </template>

                    <template #default>
                        <a :href="report.destination" v-for="(report, index) in saleReports" :key="index">
                            <div class="report card bg-white shadow">
                                <h5> {{ report.name }} </h5>
                                <hr>
                                <p> {{ report.description }} </p>
                            </div>
                        </a>

                        <p v-if="empty(saleReports)"> No reports found. </p>
                    </template>
                </el-skeleton>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SaleReports",

    data() {
        return {
            gettingSaleReports: false,
            saleReports: [],
        }
    },

    created() {
        this.getSaleReports()
    },

    methods: {
        getSaleReports() {
            this.gettingSaleReports = true
            axios.get('/api/reports/modular', {params: {module: 'sales'}}).then(res => {
                this.gettingSaleReports = false
                this.saleReports = res.data.data
            }).catch(error => {
                this.gettingSaleReports = false
            })
        },
    },
}
</script>

<style scoped>

</style>
