<template>
    <div id="side-bar" class="bg-white shadow-sm">
        <div id="side-navigation">
            <el-row class="tac">
                <el-col :span="12">
                    <el-menu
                        :default-active="navKey.toString()"
                        class="el-menu-vertical-demo"
                        @open="handleOpenMenuItem"
                        @close="handleCloseMenuItem"
                        v-if="currentUser"
                    >

                        <a href="/dashboard" v-if="can('access_dashboard', currentUser)">
                            <el-menu-item index="1">
                                <i class="fas fa-th-large mr-3"></i>
                                <span>Dashboard</span>
                            </el-menu-item>
                        </a>

                        <el-sub-menu index="2" v-if="canEitherOr(['see_stock_levels', 'see_purchases'], currentUser)">
                            <template #title>
                                <i class="fas fa-boxes mr-3"></i>
                                <span>Inventory</span>
                            </template>
                            <el-menu-item-group>
                                <a href="/stock/levels" id="2-1" v-if="can('see_stock_levels', currentUser)">
                                    <el-menu-item index="2-1" @click="updateSubNavKey">
                                        <i class="fas fa-dot-circle sub-menu-chevron"></i>Inventory List
                                    </el-menu-item>
                                </a>

                                <a href="/stock/classification" id="2-2" v-if="can('see_purchases', currentUser)">
                                    <el-menu-item index="2-2" @click="updateSubNavKey">
                                        <i class="fas fa-dot-circle sub-menu-chevron"></i>Classification
                                    </el-menu-item>
                                </a>

                                <a href="/procurement/purchases" id="2-3" v-if="can('see_purchases', currentUser)">
                                    <el-menu-item index="2-3" @click="updateSubNavKey">
                                        <i class="fas fa-dot-circle sub-menu-chevron"></i>Purchases
                                    </el-menu-item>
                                </a>

                                <a href="/stock/production" v-if="can('produce_stock', currentUser)" id="2-4">
                                    <el-menu-item index="2-4" @click="updateSubNavKey">
                                        <i class="fas fa-dot-circle sub-menu-chevron"></i>Production
                                    </el-menu-item>
                                </a>
                            </el-menu-item-group>
                        </el-sub-menu>

                        <el-sub-menu index="3" v-if="canEitherOr(['see_sales_list', 'view_orders_list'], currentUser)">
                            <template #title>
                                <i class="fas fa-cash-register mr-3"></i>
                                <span>Sales & Orders</span>
                            </template>
                            <el-menu-item-group>
                                <a href="/orders" id="3-1" v-if="businessIsRestaurant">
                                    <el-menu-item index="3-1" @click="updateSubNavKey">
                                        <i class="fas fa-dot-circle sub-menu-chevron"></i>Orders
                                    </el-menu-item>
                                </a>

                                <a href="/sales" id="3-1" v-else>
                                    <el-menu-item index="3-1" @click="updateSubNavKey">
                                        <i class="fas fa-dot-circle sub-menu-chevron"></i>All Sales
                                    </el-menu-item>
                                </a>

                                <a href="/sales/credit-sales" id="3-2" v-if="!businessIsRestaurant">
                                    <el-menu-item index="3-2" @click="updateSubNavKey">
                                        <i class="fas fa-dot-circle sub-menu-chevron"></i>Credit Sales
                                    </el-menu-item>
                                </a>
                            </el-menu-item-group>
                        </el-sub-menu>

                        <el-sub-menu index="4" v-if="canEitherOr(['manage_pay_ins', 'make_expenses', 'manage_payment_accounts'], currentUser)">
                            <template #title>
                                <i class="fas fa-wallet mr-3"></i>
                                <span> Transactions </span>
                            </template>

                            <el-menu-item-group>
                                <a href="/transactions/expenses" id="4-1" v-if="can('make_expenses', currentUser)">
                                    <el-menu-item index="4-1" @click="updateSubNavKey">
                                        <i class="fas fa-dot-circle sub-menu-chevron"></i>Expenses
                                    </el-menu-item>
                                </a>

                                <a href="/transactions/pay-ins" id="4-2" v-if="can('manage_pay_ins', currentUser)">
                                    <el-menu-item index="4-2" @click="updateSubNavKey">
                                        <i class="fas fa-dot-circle sub-menu-chevron"></i>Pay Ins
                                    </el-menu-item>
                                </a>

                                <a href="/transactions/payment-accounts" id="4-3" v-if="can('manage_payment_accounts', currentUser)">
                                    <el-menu-item index="4-3" @click="updateSubNavKey">
                                        <i class="fas fa-dot-circle sub-menu-chevron"></i>Payment Accounts
                                    </el-menu-item>
                                </a>
                            </el-menu-item-group>
                        </el-sub-menu>

                        <el-sub-menu index="5"
                                     v-if="canEitherOr(['view_accounts_receivable'], currentUser)">
                            <template #title>
                                <i class="fas fa-file-invoice-dollar mr-3"></i>
                                <span> Accounting </span>
                            </template>
                            <el-menu-item-group>
                                <a href="/accounting/accounts-receivable" id="5-1" v-if="can('view_accounts_receivable', currentUser)">
                                    <el-menu-item index="5-1" @click="updateSubNavKey">
                                        <i class="fas fa-dot-circle sub-menu-chevron"></i> Accounts Receivable
                                    </el-menu-item>
                                </a>
                            </el-menu-item-group>
                        </el-sub-menu>

                        <!-- Users & Access -->
                        <a href="/users" v-if="can('manage_users', currentUser)">
                            <el-menu-item index="6">
                                <i class="fas fa-users mr-3"></i>
                                <span>Users & Access</span>
                            </el-menu-item>
                        </a>

                        <a href="/reports" v-if="can('view_reports', currentUser)">
                            <el-menu-item index="7">
                                <i class="fas fa-chart-line mr-3"></i>
                                <span>Reports</span>
                            </el-menu-item>
                        </a>

                        <a href="/my-business" v-if="can('manage_business_details', currentUser)">
                            <el-menu-item index="8">
                                <i class="fas fa-store mr-3"></i>
                                <span> My Business </span>
                            </el-menu-item>
                        </a>

                        <el-sub-menu index="9" v-if="can('edit_system_settings', currentUser)">
                            <template #title>
                                <i class="fas fa-sliders-h mr-3"></i>
                                <span>Settings</span>
                            </template>
                            <el-menu-item-group>
                                <a href="/settings/inventory" id="9-1">
                                    <el-menu-item index="9-1" @click="updateSubNavKey">
                                        <i class="fas fa-dot-circle sub-menu-chevron"></i>Inventory Settings
                                    </el-menu-item>
                                </a>

                                <a href="/settings/sales" id="9-2">
                                    <el-menu-item index="9-2" @click="updateSubNavKey">
                                        <i class="fas fa-dot-circle sub-menu-chevron"></i>POS Settings
                                    </el-menu-item>
                                </a>

                            </el-menu-item-group>
                        </el-sub-menu>

                        <el-sub-menu index="10" v-if="can('edit_system_settings', currentUser)">
                            <template #title>
                                <i class="fas fa-comments mr-3"></i>
                                <span>Communications</span>
                            </template>

                            <el-menu-item-group>
                                <a href="/communications/notifications" id="10-1">
                                    <el-menu-item index="10-1" @click="updateSubNavKey">
                                        <i class="fas fa-dot-circle sub-menu-chevron"></i>Notifications
                                    </el-menu-item>
                                </a>
                            </el-menu-item-group>
                        </el-sub-menu>

                    </el-menu>
                </el-col>
            </el-row>
        </div>

        <form method="POST" action="/logout" style="display: none;" id="logout-form">
            <!-- CSRF -->
            <input type="hidden" name="_token" :value="csrf">
        </form>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import $ from 'jquery'

export default {
    name: "SideBarComponent",

    computed: {
        ...mapState('system', ['navKey', 'subNavKey', 'currentUser', 'settings']),

        csrf() {
            return this.getCsrf()
        },

        businessIsRestaurant() {
            return this.currentUser.business_type === 'Bar/Restaurant'
        },
    },

    created() {
        if (!this.settings || this.empty(this.settings)) {
            this.$store.dispatch('system/getSettings')
        }
    },

    mounted() {
        // Set active side bar links
        $(`li.el-sub-menu:nth-child(${this.navKey})`).addClass('is-active is-opened')
        $(`.el-menu.el-menu-vertical-demo li:nth-child(${this.navKey}) > ul`).css('display', 'block')

        if (this.subNavKey) {
            $(`a#${this.subNavKey} > li`).addClass('is-active')
        }
    },

    methods: {
        goToLogout() {
            this.$store.commit('system/resetNavKeys')
            $("#logout-form").submit();
        },

        handleOpenMenuItem(key, keyPath) {
            /**
             * If the incoming key doesnt match with the existing key, update
             */
            if (key !== this.navKey) {
                this.$store.dispatch('system/setActiveNavKey', parseInt(key))
            }
        },

        handleCloseMenuItem(key, keyPath) {
        },

        updateSubNavKey(i) {
            this.$store.dispatch('system/setActiveSubNavKey', i.index)
        }
    },
}
</script>

<style>
#side-bar {
    width: 250px;
    position: fixed;
    left: 0;
    top: 80px;
    z-index: 1;
    height: 100%;
    overflow: auto;
    padding: 20px;
}

#side-bar a {
    text-decoration: none;
}

.el-col-12 {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
}

.el-menu {
    border-right: none !important;
}

.el-sub-menu__title, .el-menu-item {
    padding: 0 0 0 12px !important;
    height: 50px !important;
    margin-bottom: 10px !important;
    font-size: 16px !important;
}

.el-menu-item-group .el-menu-item {
    height: 30px !important;
    font-weight: 600;
}

.el-sub-menu__title i, .el-menu-item i {
    display: block;
    margin-right: 12px;
}

.el-sub-menu__title span, .el-menu-item span {
    font-weight: 600;
}

.el-menu-item-group__title {
    display: none;
}

.el-menu-item-group > ul {
    padding: 0 20px !important;
}

.el-menu-item.is-active {
    color: #01358D;
    background-color: #EAEDFA;
    border-radius: 10px;
    min-width: 170px;
}

.el-sub-menu__title.is-active span, .el-menu-item.is-active span {
    font-weight: 700 !important;
}

.sub-menu-chevron {
    display: inline-block;
    margin-right: 6px;
    font-weight: 700;
    margin-top: 3px;
    font-size: 10px;
}

#side-navigation {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 100px);
}
</style>
