require('./bootstrap');

import {createApp} from 'vue'
const app = createApp({})

// Store
import {store} from './store/store'

/**
 * Third Party Libraries
 */
// Element UI
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// DayJS
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

// Vue Tel Input
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

// Mitt (Event Bus)
import mitt from 'mitt'
const emitter = mitt()
app.config.globalProperties.emitter = emitter

/**
 * Components
 */
// Auth
import RegisterUserComponent from "./components/auth/RegisterUserComponent.vue";
import LoginComponent from "./components/auth/LoginComponent";

// On-boarding
import SetupBusinessComponent from "./components/onboarding/SetupBusinessComponent";
import RequestDemo from "./components/onboarding/RequestDemo";

// Navigation
import TopBarComponent from "./components/navigation/TopBar";
import SideBarComponent from "./components/navigation/SideBarComponent";

// Home
import DashboardComponent from "./components/DashboardComponent";
import AccessDenied from "./components/AccessDenied";
import Dashboard from "./components/home/Dashboard.vue";

// POS
import PosMain from "./components/pos/PosMain.vue";

// Products
import ProductsListComponent from "./components/products/list/ProductsListComponent";
import ProductsClassificationComponent from "./components/stocks/classification/ProductsClassificationComponent";

// Stocks
import StockLevels from "./components/stocks/levels/StockLevels";
import AddStock from "./components/stocks/levels/AddStock";
import ProduceStock from "./components/stocks/production/ProduceStock";

// Sales
import SalesList from "./components/sales/list/SalesList";

// Procurement
import Purchases from "./components/procurement/purchases/Purchases.vue";
import NewPurchase from "./components/procurement/purchases/NewPurchase.vue";

// Users
import UsersMain from "./components/users/users/UsersMain";

// Audit
import Activity from "./components/audit/Activity";

// Reports
import ReportModules from "./components/reports/ReportModules";

import AuditReportsMain from "./components/reports/audit/AuditReportsMain";
import ActivityReport from "./components/reports/audit/ActivityReport";

import SaleReports from "./components/reports/sales/SaleReports";
import SalesOverviewReport from "./components/reports/sales/SalesOverviewReport";
import SalesByItemReport from "./components/reports/sales/SalesByItemReport";

import StockReports from "./components/reports/stocks/StockReports";
import OpeningClosingStocksReport from "./components/reports/stocks/OpeningClosingStocksReport";
import StockLevelsReport from "./components/reports/stocks/StockLevelsReport";

// Settings
import InventorySettings from "./components/settings/InventorySettings";
import BusinessSettings from "./components/settings/business/BusinessSettings";
import SalesSettings from "./components/settings/SalesSettings";

// Accounts
import Expenses from "./components/accounts/expenses/Expenses";
import Receivables from "./components/accounts/receivables/Receivables";
import Injections from "./components/accounts/Injections.vue";
import Wallets from "./components/accounts/wallets/Wallets.vue";

// Transactions
import PayInsView from "./views/transactions/PayInsView.vue";
import CashFlowAccountsView from "./views/transactions/CashFlowAccountsView.vue";

import BusinessOverviewReport from "./views/reports/business/BusinessOverviewReport.vue";
app.component('business-overview-report', BusinessOverviewReport)

import EndOfDayReport from "./views/reports/business/EndOfDayReport.vue";
app.component('end-of-day-report', EndOfDayReport)

import POSView from "./views/pos/POSView.vue";
app.component('pos-view', POSView)

import CreditSalesView from "./views/sales/CreditSalesView.vue";
app.component('credit-sales-view', CreditSalesView)

import AccountsReceivableView from "./views/accounting/AccountsReceivableView.vue";
app.component('accounts-receivable-view', AccountsReceivableView)

import StockItemHistoryReport from "./views/reports/stock/StockItemHistoryReport.vue";
app.component('stock-item-history-report', StockItemHistoryReport)

import MyBusinessView from "./views/business/MyBusinessView.vue";
app.component('my-business-view', MyBusinessView)

import StaffCommissionsReport from "./views/reports/sales/StaffCommisionsReport.vue";
app.component('staff-commissions-report', StaffCommissionsReport)

import StockProductionList from "./views/stock/production/StockProductionList.vue";
import NewStockProduction from "./views/stock/production/NewStockProduction.vue";
app.component('stock-production-list', StockProductionList)
app.component('new-stock-production', NewStockProduction)

import NotificationsView from "./views/communications/NotificationsView.vue";
app.component('notifications-view', NotificationsView)

import RestaurantScreenSaver from "./views/pos/restaurants/RestaurantScreenSaver.vue";
app.component('restaurant-screen-saver', RestaurantScreenSaver)

import NewOrder from "./views/pos/restaurants/NewOrder.vue";
app.component('new-order', NewOrder)

import OrdersList from "./views/orders/OrdersList.vue";
app.component('orders-list', OrdersList)

import PaymentAccountsList from "./views/transactions/payment_accounts/PaymentAccountsList.vue";
app.component('payment-accounts-list', PaymentAccountsList)

import PayOrder from "./views/orders/PayOrder.vue";
app.component('pay-order', PayOrder)

// Register Components
// Auth
app.component('register-user', RegisterUserComponent)
app.component('login', LoginComponent)

// On-boarding
app.component('setup-business', SetupBusinessComponent)
app.component('request-demo', RequestDemo)

// Navigation
app.component('top-bar', TopBarComponent)
app.component('side-bar', SideBarComponent)

// Home
app.component('dashboard', Dashboard)
app.component('access-denied', AccessDenied)

// POS
app.component('pos-main', PosMain)

// Products
app.component('product-list', ProductsListComponent)
app.component('products-classification', ProductsClassificationComponent)

// Stocks
app.component('stock-levels', StockLevels)
app.component('add-stock', AddStock)
app.component('produce-stock', ProduceStock)

// Sales
app.component('sales-list', SalesList)

// Procurement
app.component('purchases', Purchases)
app.component('new-purchase', NewPurchase)

// Users
app.component('users-main', UsersMain)

// Audit
app.component('activity', Activity)

// Reports
app.component('report-modules', ReportModules)

app.component('audit-reports', AuditReportsMain)
app.component('activity-report', ActivityReport)

app.component('sale-reports', SaleReports)
app.component('sales-overview-report', SalesOverviewReport)
app.component('sales-by-item-report', SalesByItemReport)

app.component('stock-reports', StockReports)
app.component('opening-closing-stocks-report', OpeningClosingStocksReport)
app.component('stock-levels-report', StockLevelsReport)

// Settings
app.component('inventory-settings', InventorySettings)
app.component('business-settings', BusinessSettings)
app.component('sales-settings', SalesSettings)

// Accounts
app.component('expenses', Expenses)
app.component('receivables', Receivables)
app.component('injections', Injections)
app.component('wallets', Wallets)

// Transactions
app.component('pay-ins-view', PayInsView)
app.component('cash-flow-accounts-view', CashFlowAccountsView)

// Use plugins and libraries
app.use(ElementPlus)
app.use(VueTelInput)

// Global Mixins
app.mixin({
    methods: {
        /**
         * Retrieves Laravel session csrf token from the csrf meta on app.blade.php
         *
         * @returns {string}
         */
        getCsrf() {
            return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },

        /**
         * Formats a date string to the passed format
         *
         * @param dateString
         * @param format
         * @returns {string}
         */
        formatDateString(dateString, format = 'YYYY-MM-DD') {
            dayjs.extend(customParseFormat)
            return dayjs(dateString).format(format)
        },

        /**
         * Returns the current time in the passed format
         *
         * @param format
         * @returns {string}
         */
        getFormattedNow(format = 'YYYY-MM-DD') {
            dayjs.extend(customParseFormat)
            return dayjs().format(format)
        },

        /**
         * Formats an amount with currency
         *
         * @param amt
         * @param currency
         * @returns {string|number}
         */
        formatAmount(amt, currency = 'KES') {
            if (!amt) return `${currency}. ${Math.round(0).toFixed(2)}`

            return `${currency}. ${Math.round(amt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
        },

        /**
         * Capitalizes each word of the passed sentence
         *
         * @param words
         * @param separator
         * @param finalSeparator
         * @returns {string}
         */
        capitalizeEachWord(words, separator = '_', finalSeparator = ' ') {
            let newWords = words.replaceAll(separator, ' ')
            let separateWord = newWords.toLowerCase().split(' ');
            for (let i = 0; i < separateWord.length; i++) {
                separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
                    separateWord[i].substring(1);
            }
            return separateWord.join(finalSeparator);
        },

        capitalizeFirstWord(words, separator = '_', finalSeparator = ' ') {
            let newWords = words.replaceAll(separator, ' ')
            let separateWord = newWords.toLowerCase().split(' ');
            separateWord[0] = separateWord[0].charAt(0).toUpperCase() +
                separateWord[0].substring(1);
            return separateWord.join(finalSeparator);
        },

        /**
         * Checks whether an array is empty or not
         *
         * @param array
         * @returns {boolean}
         */
        empty(array) {
            return array.length === 0
        },

        hasSpacesOnly(str) {
            return str.trim().length === 0;
        },

        isAdmin(user) {
            return user?.is_admin === 1 ?? false
        },

        /**
         * Policy Checker. Returns true if a role has the specified permission
         *
         * @param ability
         * @param user
         * @returns {boolean}
         */
        can(ability, user) {
            if (this.isAdmin(user)) {
                return true
            }

            if (!user?.role) {
                return false
            }

            if (!user?.role?.permissions) {
                return false
            }

            let policy = user.role.permissions.find(permission => permission.action === ability)
            if (policy) {
                return policy.status === 1;
            }

            return false
        },

        /**
         * Policy Checker. Returns true if a role has either of the specified permissions
         *
         * @param abilities
         * @param user
         * @returns {boolean}
         */
        canEitherOr(abilities, user) {
            if (this.isAdmin(user)) {
                return true
            }

            if (!user?.role) {
                return false
            }

            if (!user?.role?.permissions) {
                return false
            }

            let hasEither = false
            abilities.every(ability => {
                let policy = user.role.permissions.find(permission => permission.action === ability)
                if (policy && policy.status === 1) {
                    hasEither = true
                    return false;
                }

                return true
            })


            return hasEither
        },

        serverErrorMessage(shouldReload = false) {
            return `An error was encountered. Please ${shouldReload ? 'reload the page' : 'try again'}.`
        },

        amountIsNotValid(amount) {
            if (!amount) return true

            try {
                return isNaN(parseFloat(amount))
            } catch (_) {
                return true
            }
        },

        numberIsValid(number) {
            if (!number) return false

            try {
                return !(isNaN(parseFloat(number)))
            } catch (_) {
                return false
            }
        },

        numberIsNotNegative(number) {
            if (!number) return false

            try {
                return (isNaN(parseFloat(number))) >= 0
            } catch (_) {
                return false
            }
        },

        numberIsGreaterThanZero(number) {
            if (!number) return false

            try {
                return (isNaN(parseFloat(number))) >= 0
            } catch (_) {
                return false
            }
        },

        variableIsNotNull(variable) {
            return variable;
        }
    },
})

// Mount
app.use(store)
app.mount('#app')
