<script>
import {defineComponent} from 'vue'
import {mapGetters, mapState} from "vuex";
import SvgSymbols from "../../../components/shared/SvgSymbols.vue";
import Tip from "../../../components/shared/Tip.vue";

export default defineComponent({
    name: "StaffCommissionsReport",
    components: {Tip, SvgSymbols},

    data() {
        return {
            fetchingReport: false,
            report: [],
            errorFetchingReport: false,
            filters: {
                store_id: null,
                user_id: null,
                start_date: null,
                end_date: null,
            },

            payCommissionModalIsActive: false,
            activeAttendant: null,
            commissionPayment: 0,
            paymentMethod: 'Cash',
            paymentDate: null,
            payingCommission: false,
            errorPayingCommission: false,
        }
    },

    computed: {
        ...mapState('system', ['currentUser', 'settings', 'stores']),
        ...mapState('users', ['systemUsers']),

        ...mapGetters('system', ['businessHasMultipleStores']),

        businessHasEnabledAttendantCommissions() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'ENABLE_ATTENDANT_COMMISSIONS')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },
    },

    created() {
        if (this.settings.length === 0) {
            this.$store.dispatch('system/getSettings')
        }

        if (this.stores.length === 0) {
            this.$store.dispatch('system/fetchStores')
        }

        if (this.systemUsers.length === 0) {
            this.$store.dispatch('users/getUsers')
        }

        this.fetchReport()
    },

    mounted() {
        this.filters.store_id = this.currentUser.store_id
    },

    methods: {
        fetchReport() {
            let payload = {
                store_id: this.filters.store_id ?? this.currentUser.store_id,
                user_id: this.filters.user_id,
                start_date: this.filters.start_date,
                end_date: this.filters.end_date,
            }

            this.fetchingReport = true
            axios.get('/api/reports/sales/staff-commissions-report', {params: payload}).then(res => {
                this.fetchingReport = false
                this.report = res.data.data
                this.errorFetchingReport = false
            }).catch(_ => {
                this.fetchingReport = false
                this.errorFetchingReport = true
            })
        },

        resetReportFilters() {
            this.filters = {store_id: this.currentUser.store_id}
            this.fetchReport()
        },

        handleAttendantActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            if (commandName === 'pay') {
                this.activeAttendant = JSON.parse(JSON.stringify(this.report[commandIndex]))
                this.commissionPayment = this.activeAttendant.commission_owed
                this.errorPayingCommission = false
                this.paymentMethod = 'Cash'
                this.paymentDate = this.getFormattedNow()

                this.payCommissionModalIsActive = true
            }
        },

        payCommission(e) {
            e?.preventDefault()

            if (!this.commissionPayment || (parseFloat(this.commissionPayment) < 1)) {
                return this.$message.error('Please provide a valid payment amount')
            }

            let payload = {
                store_id: this.currentUser.store_id,
                current_user_id: this.currentUser.id,
                amount: this.commissionPayment,
                payment_method: this.paymentMethod,
                attendant_id: this.activeAttendant.id,
                payment_date: this.paymentDate,
            }

            this.payingCommission = true
            axios.post('/api/users/users/pay-commission', {payload: JSON.stringify(payload)}).then(_ => {
                this.payingCommission = false
                this.fetchReport()
                this.errorPayingCommission = false
                this.payCommissionModalIsActive = false
            }).catch(_ => {
                this.payingCommission = false
                this.errorPayingCommission = true
            })
        },
    },
})
</script>

<template>
    <div class="master-template">
        <div class="master-title-div d-flex justify-content-between align-items-center">
            <h5> Staff Commissions Report </h5>

            <div class="actions">
                <a href="/reports" class="btn btn-outline-primary new-item-btn">
                    <i class="fas fa-backward"></i>Back to Reports
                </a>
            </div>
        </div>

        <div class="master-content" v-if="!businessHasEnabledAttendantCommissions">
            <p> You have not enabled staff commissions. You can enable staff commissions under
                <a href="/settings/inventory"> Inventory Settings</a>. </p>
        </div>

        <div class="master-content" v-else>
            <div class="master-tip">
                <svg-symbols></svg-symbols>
                <tip type="primary">
                    Report period defaults to this month.
                </tip>
            </div>

            <div class="master-tip" v-if="errorFetchingReport">
                <svg-symbols></svg-symbols>
                <tip type="danger">
                    An error was encountered while fetching report. Please reload the page. If the issue persists, please contact System
                    Administration.
                </tip>
            </div>

            <div class="data-table-filters card bg-white">
                <div class="row">
                    <div class="col-12 col-md-3 form-group">
                        <label class="block"> Select user </label>
                        <el-select
                            v-model="filters.user_id"
                            default-first-option
                            filterable
                            size="large">
                            <el-option
                                v-for="user in systemUsers"
                                :key="user.id"
                                :label="user.full_name"
                                :value="user.id"
                            />
                        </el-select>
                    </div>

                    <div class="form-group col-12 col-md-3"
                         v-if="businessHasMultipleStores && can('manage_multiple_stores', currentUser)">
                        <label> Select Store </label>
                        <el-select v-model="filters.store_id" filterable size="large"
                                   placeholder="Select store">
                            <el-option v-for="store in stores" :key="store.id" :label="store.store_name"
                                       :value="store.id"></el-option>
                        </el-select>
                    </div>

                    <div class="col-md-3 col-12 form-group">
                        <label> Start Date </label>
                        <el-date-picker
                            v-model="filters.start_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>

                    <div class="col-md-3 col-12 form-group">
                        <label> End Date </label>
                        <el-date-picker
                            v-model="filters.end_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>
                </div>

                <div class="mt-3 d-flex">
                    <button class="btn btn-secondary mr-12" @click="fetchReport"> Filter Report</button>
                    <el-button size="large" @click="resetReportFilters">Reset Filters</el-button>
                </div>
            </div>

            <div class="data-table table-responsive card bg-white">
                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col"> #</th>
                        <th scope="col"> Staff Name</th>
                        <th scope="col"> Items/Services Sold</th>
                        <th scope="col"> Total Sales</th>
                        <th scope="col"> Total Commission</th>
                        <th scope="col"> Commission Paid</th>
                        <th scope="col"> Commission Owed</th>
                        <th scope="col"> Actions</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="(user, index) in report" :key="index">
                        <th scope="row"> {{ index + 1 }}</th>
                        <td> {{ user.name }}</td>
                        <td> {{ user.total_items }}</td>
                        <td> {{ formatAmount(user.total_sales) }}</td>
                        <td> {{ formatAmount(user.total_commission) }}</td>
                        <td> {{ formatAmount(user.commission_paid) }}</td>
                        <td> {{ formatAmount(user.commission_owed) }}</td>
                        <td>
                            <el-dropdown @command="handleAttendantActions">
                                <el-button type="primary">
                                    Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item :command="'breakdown-' + index"> View Breakdown</el-dropdown-item>
                                        <el-dropdown-item :command="'pay-' + index" v-if="user.commission_owed > 0"> Pay Commission</el-dropdown-item>
                                        <el-dropdown-item :command="'history-' + index"> View Payment History</el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Pay Commission -->
        <el-dialog
            v-model="payCommissionModalIsActive"
            :title="`Commission Payment to ${activeAttendant.name}`"
            width="30%"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            v-if="activeAttendant"
        >
            <div class="master-tip" v-if="errorPayingCommission">
                <svg-symbols></svg-symbols>
                <tip type="danger">
                    An error was encountered while making payment. Please try again. If the issue persists, please contact System Administration.
                </tip>
            </div>

            <form @submit.prevent="payCommission" v-loading="payingCommission" novalidate>
                <div class="form-group">
                    <label class="required" for="amount"> Payment Amount </label>
                    <input type="number" id="amount" class="form-control" v-model="commissionPayment">
                </div>

                <div class="form-group">
                    <label> Payment Date </label>
                    <el-date-picker
                        v-model="paymentDate"
                        type="date"
                        placeholder="Pick a day"
                        format="MMM D, YYYY"
                        value-format="YYYY-MM-DD"
                        size="large"
                    >
                    </el-date-picker>
                </div>

                <div class="form-group">
                    <label class="display-block"> Payment Method </label>
                    <el-radio-group v-model="paymentMethod" size="large">
                        <el-radio :label="'Cash'" border>Cash</el-radio>
                        <el-radio :label="'MPESA'" border>Bank/MPESA Till</el-radio>
                    </el-radio-group>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="payCommissionModalIsActive = false" size="large" type="info" plain>Cancel</el-button>
                    <button class="btn btn-primary" @click="payCommission"> Make Payment</button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<style scoped>

</style>
