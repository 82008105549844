<template>
    <div id="request-demo-container">
        <div class="row" style="position:relative; height: 100%;">
            <div id="right-side" class="col-12 col-lg-6 d-flex flex-column text-white">
                <div id="logo-section">
                    <img src="/assets/images/logo-white.png" alt="fuatilia POS">
                </div>

                <div id="caption-section" class="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
                    <h5 id="main-caption"> WELCOME </h5>

                    <div class="text-center" id="caption-info" style="word-break: break-word;">
                        You can also reach out to us directly by sending us an email at
                        <strong><a href="mailto:sales@fuatilia.com">sales@fuatilia.com</a></strong>, or
                        giving us a call through <strong><a href="tel:0795677343">0790544563</a></strong>
                    </div>
                </div>
            </div>

            <div id="left-side" class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
                <div style="position:relative; width: 80%;" class="d-flex justify-content-center flex-column" id="left-side-container">
                    <div class="w-100 text-center">
                        <h5> Request <span class="title-orange"> Demo </span> </h5>
                    </div>

                    <div class="text-center w-100" v-if="demoRequested">
                        <p> Request sent successfully. </p>
                    </div>

                    <form method="post" @submit.prevent="requestDemo" novalidate v-else v-loading="sendingRequest">
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="first_name" class="required"> First Name </label>
                                <input type="text" id="first_name" class="form-control" v-model="clientDetails.first_name">
                                <div class="invalid-feedback"> Please provide your first name </div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="last_name"> Last Name </label>
                                <input type="text" id="last_name" class="form-control" v-model="clientDetails.last_name">
                                <div class="invalid-feedback"> Please provide your last name </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="email_address" class="required"> Email Address </label>
                                <input type="text" id="email_address" class="form-control" v-model="clientDetails.email_address">
                                <div class="invalid-feedback"> Please provide your email address </div>
                            </div>

                            <div class="form-group col-md-6">
                                <label class="required"> Phone Number </label>
                                <vue-tel-input :value="clientDetails.phone_number" @input="onInput" mode="international"></vue-tel-input>
                                <input type="hidden" :value="clientDetails.phone_number" name="phone">
                                <div class="invalid-feedback" id="client-phone-number"> Please provide your phone number </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="business_name"> Business Name </label>
                                <input type="text" id="business_name" class="form-control" v-model="clientDetails.business_name">
                                <div class="invalid-feedback"> Please provide your business name </div>
                            </div>

                            <div class="form-group col-md-6">
                                <label> Where is your business located? </label>
                                <input type="text" v-model="clientDetails.business_location" class="form-control">
                                <div class="invalid-feedback"> Please provide your business location </div>
                            </div>
                        </div>

                        <div class="form-group" style="margin-right: 15px; margin-left: 15px">
                            <label for="details"> What challenges are you facing (What are you looking for in a system) </label>
                            <textarea name="" id="details" cols="20" class="form-control" v-model="clientDetails.details"></textarea>
                        </div>

                        <div class="form-group mt-4" style="margin-right: 15px; margin-left: 15px">
                            <button class="btn btn-primary btn-block" @click="requestDemo"> Request Demo </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RequestDemo",

    data() {
        return {
            clientDetails: {},
            demoRequested: false,
            sendingRequest: false,
        }
    },

    methods: {
        requestDemo(e) {
            e.preventDefault()

            if (!this.clientDetails.email_address || !this.clientDetails.phone_number) {
                return this.$message.error('Please provide the required details')
            }

            this.sendingRequest = true
            axios.post('/api/onboarding/request-demo', this.clientDetails).then(res => {
                this.sendingRequest = false
                this.demoRequested = true
                this.$message.success('Request sent successfully')
            }).catch(e => {
                this.sendingRequest = false
                this.$message.error('Request not sent. Try again.')
            })
        },

        onInput(phone, phoneObject, input) {
            if (phoneObject?.formatted) {
                this.clientDetails.phone_number = phoneObject.formatted
            }
        },
    },
}
</script>

<style scoped>
#request-demo-container {
    height: 100vh;
}

#request-demo-container #right-side {
    background-image: url('/assets/images/request-demo-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#right-side, #left-side {
    position: relative;
    height: 100%;
}

#logo-section img {
    width: 385px;
}

#caption-section {
    padding: 20px;
    margin-top: -250px;
}

#main-caption {
    font-weight: 900;
    font-size: 50px;
    margin-bottom: 25px;
    position: relative;
}

#main-caption::before {
    content: "";
    position: absolute;
    width: 20%;
    height: 1px;
    bottom: 0;
    left: 43%;
    border-bottom: 5px solid white;
    border-radius: 6px;
}

#caption-info {
    font-weight: 500;
    font-size: 20px;
    padding: 0 50px;
}

#caption-info a, #caption-info a:hover {
    color: white;
}

#left-side h5 {
    font-weight: 800;
    font-size: 45px;
    margin-bottom: 40px;
}

.title-orange {
    color: #F06D26;
}

@media only screen and (max-width: 991px) {
    #right-side {
        height: 42%;
    }

    #logo-section img {
        width: 270px;
    }

    #caption-section {
        margin-top: -120px;
    }

    #main-caption {
        font-size: 50px;
        margin-bottom: 25px;
    }

    #caption-info {
        font-weight: 500;
        font-size: 20px;
        padding: 0;
    }

    #left-side {
        height: auto;
        margin-bottom: 30px;
    }

    #left-side h5 {
        font-weight: 800;
        font-size: 45px;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    #left-side-container {
        width: 100% !important;
    }
}

@media only screen and (max-width: 767px) {

    #logo-section img {
        width: 130px;
    }

    #caption-section {
        margin-top: -20px;
    }

    #main-caption {
        font-size: 30px;
        margin-bottom: 12px;
    }

    #left-side h5 {
        font-weight: 800;
        font-size: 30px;
        margin-bottom: 30px;
        margin-top: 20px;
    }
}
</style>
