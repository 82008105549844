<script>
import {mapState} from "vuex";
import AppBarNotifications from "../../../components/feedback/AppBarNotifications.vue";
import $ from "jquery";

export default {
    name: "RestaurantScreenSaver",
    components: {AppBarNotifications},

    computed: {
        ...mapState('system', ['currentUser', 'settings']),

        businessUsesOwnLogo() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'USE_OWN_LOGO')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        csrf() {
            return this.getCsrf()
        },
    },

    created() {
        if (!this.settings || this.empty(this.settings)) {
            this.$store.dispatch('system/getSettings')
        }
    },

    methods: {
        goToLogout() {
            // Clear state
            this.$store.commit('system/resetNavKeys')
            this.$store.dispatch('system/resetAll')
            this.$store.dispatch('pos/resetAll')
            this.$store.dispatch('stock/resetAll')
            this.$store.dispatch('users/resetAll')
            this.$store.dispatch('procurement/resetAll')

            $("#logout-form").submit();
        },
    },
}
</script>

<template>
    <div class="bg-white" id="screen-saver-div">
        <div id="overlay">
            <div id="screen-saver-top-bar" class="d-flex justify-content-end align-items-center">
                <app-bar-notifications v-if="currentUser && can('see_notifications', currentUser)"></app-bar-notifications>

                <div id="profile">
                    <el-dropdown>
                                <span class="el-dropdown-link d-flex align-items-center">
                                  <div class="avatar d-flex align-items-center">
                                      <el-avatar src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" size="small"></el-avatar>
                                      <span
                                          style="font-weight: 600; margin-right: 8px; margin-left: 8px; font-size: 18px; color: #fff"
                                          v-if="currentUser">{{ currentUser.name }}</span>
                                  </div>

                                  <i class="fas fa-chevron-down text-white"></i>
                                </span>

                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="goToLogout" style="font-size: 16px;">Logout</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>

                <form method="POST" action="/logout" style="display: none;" id="logout-form">
                    <!-- CSRF -->
                    <input type="hidden" name="_token" :value="csrf">
                </form>
            </div>

            <div id="scree-saver-image-div" class="d-flex w-100 justify-content-center align-items-center flex-column">
                <img src="/assets/images/pos-bgs/cozy_lounge.png" alt="fuatilia POS" v-if="businessUsesOwnLogo">
                <img src="/assets/images/logo-white.png" alt="fuatilia POS" v-else>

                <p class="text-white fw-700" style="font-size: 30px; margin-bottom: 30px;">
                    {{ currentUser.business_name }}, {{ currentUser.store_name }}
                </p>
            </div>

            <div id="pos-action-links" class="d-flex w-100 flex-wrap justify-content-center align-items-center">
                <a href="/orders/create" role="button" class="btn pos-white-btn">
                    <i class="fas fa-plus-circle"></i> New Order
                </a>

                <a href="/orders" role="button" class="btn pos-white-btn" v-if="can('view_orders_list', currentUser)">
                    <i class="fas fa-list-ul"></i> Orders List
                </a>

                <a href="/reports/business/end-of-day-report" role="button" class="btn pos-white-btn">
                    <i class="fas fa-user-clock"></i> Current Shift
                </a>

                <a href="/dashboard" role="button" class="btn pos-white-btn" v-if="can('access_backoffice', currentUser)">
                    <i class="fas fa-user-tie"></i> Back Office
                </a>
            </div>
        </div>
    </div>
</template>

<style scoped>
#screen-saver-div {
    width: 100vw;
    height: 100vh;
    background-image: url('/assets/images/restaurant-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#overlay {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 30px;
}

#scree-saver-image-div img {
    height: 60vh;
}

#pos-action-links a {
    margin-right: 45px;
    margin-bottom: 20px;
}

.pos-white-btn {
    background-color: #fff !important;
    border-color: #fff !important;
    color: #01358D !important;
    font-size: 25px !important;
    font-weight: bold !important;
}

.pos-white-btn:hover {
    background-color: #01358D !important;
    border-color: #01358D !important;
    color: #fff !important;
}

.pos-white-btn i {
    margin-right: 5px;
}

#screen-saver-top-bar {
    padding: 0 20px;
}

#profile {
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 15px;
}
</style>
