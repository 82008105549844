<script>
import {defineComponent} from 'vue'
import {mapState} from "vuex";
import Tip from "../../components/shared/Tip.vue";
import SvgSymbols from "../../components/shared/SvgSymbols.vue";
import $ from "jquery";

export default defineComponent({
    name: "PayInsView",
    components: {SvgSymbols, Tip},

    data() {
        return {
            baseUrl: '/api/transactions/pay-ins',
            loadingPayIns: false,
            payIns: [],
            currentPage: 1,
            payInCount: 0,
            filters: {
                date: null,
            },

            newPayInModalIsActive: false,
            newPayIn: {
                amount: 0,
                date: null,
                description: null,
                payment_method: 'Cash',
            },
            addingPayIn: false
        }
    },

    computed: {
        ...mapState('system', ['currentUser'])
    },

    created() {
        this.getPayIns()
    },

    methods: {
        getPayIns() {
            let payload = {
                store_id: this.currentUser.store_id,
                date_filter: this.filters.date,
                page: this.currentPage - 1
            }

            this.loadingPayIns = true
            axios.get(`${this.baseUrl}/paginated`, {params: payload}).then(res => {
                this.loadingPayIns = false
                this.payIns = res.data.data.content
                this.payInCount = res.data.data.count
            }).catch(error => {
                this.loadingPayIns = false
                this.$message.error('Error loading transactions. Please reload the page')
            })
        },

        promptNewPayIn() {
            this.newPayIn = {}
            this.newPayIn.date = this.getFormattedNow()
            this.newPayIn.payment_method = 'Cash'
            this.newPayInModalIsActive = true
        },

        resetPayInFilters() {
            this.filters = {}
            this.currentPage = 1
            this.getPayIns()
        },

        addTransaction(e) {
            e.preventDefault()

            if (!this.newPayIn.amount || this.amountIsNotValid(this.newPayIn.amount)) {
                return $("#transaction-amount").addClass('is-invalid')
            } else {
                $("#transaction-amount").removeClass('is-invalid')
            }

            let payload = {
                store_id: this.currentUser.store_id,
                current_user_id: this.currentUser.id,
                amount: this.newPayIn.amount,
                description: this.newPayIn.description,
                pay_in_date: this.newPayIn.date,
                payment_method: this.newPayIn.payment_method,
            }

            this.addingPayIn = true
            axios.post(`${this.baseUrl}/create`, {payload: JSON.stringify(payload)}).then(_ => {
                this.addingPayIn = false
                this.getPayIns()
                this.newPayInModalIsActive = false

                this.$message.success('Transaction posted successfully')
            }).catch(error => {
                this.addingPayIn = false
                this.$message.error('Error saving transaction. Please try again')
            })
        },

        handlePayInActions(command) {
        },
    },
})
</script>

<template>
    <div class="master-template">
        <div class="master-title d-flex justify-content-between align-items-center">
            <h5> Pay In Transactions </h5>

            <div class="actions d-flex">
                <button class="btn btn-primary new-item-btn" @click="promptNewPayIn">
                    <i class="fas fa-plus-circle"></i>New Transaction
                </button>
            </div>
        </div>

        <div class="master-content">
            <div class="master-tip">
                <svg-symbols></svg-symbols>
                <tip type="primary">
                    Please note that pay ins are different from capital injections. For more details, please give us a call.
                </tip>
            </div>

            <div class="data-table-filters card shadow bg-white">
                <h5> Transaction Filters </h5>

                <div class="row m-0 p-0">
                    <div class="form-group col-md-4">
                        <label> Pay In Date </label>
                        <el-date-picker
                            v-model="filters.date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>
                </div>

                <div class="mt-3 d-flex">
                    <button class="btn btn-secondary mr-12" @click="getPayIns"> Filter Pay Ins</button>
                    <el-button size="large" @click="resetPayInFilters">Reset Filters</el-button>
                </div>
            </div>

            <div class="data-table-summary d-flex justify-content-end">
                <span class="summary"> Showing {{ payIns.length }} out of {{ payInCount }} transactions </span>
            </div>

            <div class="data-table">
                <el-skeleton :loading="loadingPayIns" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" v-for="row in 7" :key="row" style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                    </template>

                    <template #default>
                        <el-table :data="payIns" style="width: 100%">
                            <el-table-column label="Pay In Amount" prop="display_amount"></el-table-column>
                            <el-table-column label="Pay In Date" prop="display_pay_in_date"></el-table-column>
                            <el-table-column label="Description" prop="description"></el-table-column>
                            <el-table-column label="Actions">
                                <template #default="scope">
                                    <el-dropdown @command="handlePayInActions">
                                        <el-button type="primary">
                                            Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item :command="'update-' + scope.$index">Update Transaction</el-dropdown-item>
                                                <el-dropdown-item :command="'void-' + scope.$index">Void Transaction</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-skeleton>
            </div>

            <div class="data-table-pagination d-flex justify-content-end mt-3">
                <el-pagination background layout="prev, pager, next" :total="payInCount"
                               :page-size="10" v-model:current-page="currentPage" @current-change="getPayIns" hide-on-single-page/>
            </div>

        </div>

        <!-- New Pay In -->
        <el-dialog
            v-model="newPayInModalIsActive"
            title="New Pay In Transaction"
            width="30%"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
        >
            <form @submit.prevent="addTransaction"
                  v-loading="addingPayIn"
                  novalidate>
                <div class="form-group">
                    <label class="required" for="expense-amount"> Transaction Amount </label>
                    <input type="text" id="transaction-amount" class="form-control" v-model="newPayIn.amount" placeholder="Expense amount">
                    <div class="invalid-feedback"> Transaction amount is a required field</div>
                </div>

                <div class="form-group">
                    <label for="transaction-description"> Transaction Description </label>
                    <textarea id="transaction-description" class="form-control" v-model="newPayIn.description"
                              placeholder="Transaction description"></textarea>
                </div>

                <div class="form-group">
                    <label> Transaction Date </label>
                    <el-date-picker
                        v-model="newPayIn.date"
                        type="date"
                        placeholder="Pick a day"
                        format="MMM D, YYYY"
                        value-format="YYYY-MM-DD"
                        size="large"
                    >
                    </el-date-picker>
                </div>

                <div class="form-group">
                    <label class="display-block"> Payment Method </label>
                    <el-radio-group v-model="newPayIn.payment_method" size="large">
                        <el-radio :label="'Cash'" border>Cash</el-radio>
                        <el-radio :label="'MPESA'" border>Bank/MPESA Till</el-radio>
                    </el-radio-group>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="newPayInModalIsActive = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addTransaction"> Save Transaction</button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<style scoped>

</style>
