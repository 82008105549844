export const stockStore = {
    namespaced: true,
    state: () => ({
        baseUrl: '/api/stock',
        productCategories: [],
        gettingProductCategories: false,
        productSubCategories: [],
        gettingProductSubCategories: false,
        uoms: [],
    }),

    getters: {
        getClassificationBaseUrl(state) {
            return `${state.baseUrl}/classification`
        },

        getStockLevelsBaseUrl(state) {
            return `${state.baseUrl}/levels`
        },
    },

    mutations: {
        setProductCategories(state, categories) {
            state.productCategories = categories
        },

        addCategory(state, category) {
            state.productCategories.push(category)
        },

        updateCategory(state, payload) {
            state.productCategories[payload.index] = payload.category
        },

        removeCategory(state, index) {
            state.productCategories.splice(index, 1)
        },

        toggleGettingProductCategories(state, status) {
            state.gettingProductCategories = status
        },

        setProductSubCategories(state, subCategories) {
            state.productSubCategories = subCategories
        },

        addSubCategory(state, subCategory) {
            state.productSubCategories.push(subCategory)
        },

        updateSubCategory(state, payload) {
            state.productSubCategories[payload.index] = payload.subcategory
        },

        removeSubCategory(state, index) {
            state.productSubCategories.splice(index, 1)
        },

        toggleGettingProductSubCategories(state, status) {
            state.gettingProductSubCategories = status
        },

        setUoms(state, uoms) {
            state.uoms = uoms
        }
    },

    actions: {
        getProductCategories({commit, getters, rootGetters}) {
            commit('toggleGettingProductCategories', true)
            axios.get(`${getters.getClassificationBaseUrl}/categories`, {params: rootGetters['system/getBasicPayload']})
                .then(res => {
                commit('toggleGettingProductCategories', false)
                commit('setProductCategories', res.data.data)
            }).catch(error => {
                commit('toggleGettingProductCategories', false)
            })
        },

        getProductSubCategories({commit, getters, rootGetters}) {
            commit('toggleGettingProductSubCategories', true)
            axios.get(`${getters.getClassificationBaseUrl}/subcategories`, {params: rootGetters['system/getBasicPayload']}).then(res => {
                commit('toggleGettingProductSubCategories', false)
                commit('setProductSubCategories', res.data.data)
            }).catch(error => {
                commit('toggleGettingProductSubCategories', false)
            })
        },

        getProductUoms({commit, getters}) {
            axios.get(`${getters.getClassificationBaseUrl}/uoms`).then(res => {
                commit('setUoms', res.data.data)
            }).catch(error => {
            })
        },

        resetAll({commit}) {
            commit('setProductCategories', [])
            commit('setUoms', [])
            commit('setProductSubCategories', [])
        },
    },
}
