<template>
    <div class="master-template">
        <div class="master-title d-flex justify-content-between">
            <h5> Expenses </h5>

            <div class="actions d-flex">
                <div class="master-title-actions-search mr-12">
                    <div class="form-group mb-0 has-prefix">
                        <input type="text" class="form-control has-prefix" placeholder="Search expenses"
                               v-model="filters.name"
                               @input="filterExpenses">
                        <i class="fas fa-search prefix"></i>
                    </div>
                </div>

                <button class="btn btn-primary new-item-btn" @click="promptNewExpense" v-if="can('make_expenses', this.currentUser)">
                    <i class="fas fa-plus-circle"></i>New Expense
                </button>
            </div>
        </div>

        <div class="master-content">
            <div class="data-table-filters card shadow bg-white">
                <h5> Expense Filters </h5>

                <div class="row">
                    <div class="form-group col-md-4">
                        <label> Start Date </label>
                        <el-date-picker
                            v-model="filters.begin"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>

                    <div class="form-group col-md-4 has-padding-left">
                        <label>End Date </label>
                        <el-date-picker
                            v-model="filters.end"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>
                </div>

                <div class="mt-3 d-flex">
                    <button class="btn btn-secondary mr-12" @click="getExpenses"> Filter Expenses </button>
                    <el-button size="large" @click="resetExpenseFilters">Reset Filters</el-button>
                </div>
            </div>

            <div class="data-table mt-3">
                <div class="data-table-summary d-flex justify-content-end">
                    <span class="summary"> Showing {{ expenses.length }} out of {{ totalExpenses }} expenses </span>
                </div>

                <div class="data-table">
                    <el-skeleton :loading="gettingExpenses" :throttle="200" animated>
                        <template #template>
                            <el-skeleton-item variant="h3" v-for="row in 7" :key="row" style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                        </template>

                        <template #default>
                            <el-table :data="expenses" style="width: 100%">
                                <el-table-column label="Expense Number" prop="display_expense_number"></el-table-column>
                                <el-table-column label="Expense Amount" prop="display_expense_amount"></el-table-column>
                                <el-table-column label="Description" prop="description"></el-table-column>
                                <el-table-column label="Expense Date" prop="expense_date"></el-table-column>
                                <el-table-column label="Actions">
                                    <template #default="scope">
                                        <el-dropdown @command="handleExpenseActions">
                                            <el-button type="primary">
                                                Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                            </el-button>
                                            <template #dropdown>
                                                <el-dropdown-menu>
                                                    <el-dropdown-item :command="'update-' + scope.$index">Update Expense</el-dropdown-item>
                                                    <el-dropdown-item :command="'void-' + scope.$index">Void Expense</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </el-skeleton>
                </div>
            </div>

            <!-- Pagination -->
            <div class="data-table-pagination d-flex justify-content-end mt-3">
                <el-pagination background layout="prev, pager, next" :total="totalExpenses"
                               :page-size="10" v-model:current-page="currentPage" @current-change="getExpenses" hide-on-single-page/>
            </div>
        </div>

        <!-- START OF ADD NEW EXPENSE MODAL -->
        <el-dialog
            v-model="showNewExpenseDialog"
            title="New Expense"
            width="30%"
        >
            <form @submit.prevent="addNewExpense"
                  v-loading="addingExpense"
                  element-loading-text="Saving Expense"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="expense-amount"> Expense Amount </label>
                    <input type="text" id="expense-amount" class="form-control" v-model="newExpense.amount" placeholder="Expense amount">
                    <div class="invalid-feedback"> Expense amount is a required field</div>
                </div>

                <div class="form-group">
                    <label class="required" for="expense-description"> Expense Description </label>
                    <textarea id="expense-description" class="form-control" v-model="newExpense.description" placeholder="Expense description"></textarea>
                    <div class="invalid-feedback"> Expense description is a required field</div>
                </div>

                <div class="form-group" v-if="systemUsers.length > 1">
                    <label class="required"> User </label>
                    <el-select filterable v-model="newExpense.user_id" size="large">
                        <el-option
                            v-for="user in systemUsers"
                            :key="user.id"
                            :label="user.full_name"
                            :value="user.id"
                        />
                    </el-select>
                </div>

                <div class="form-group">
                    <el-checkbox v-model="newExpense.backdated" label="Backdate Expense" border size="large"></el-checkbox>
                    <div class="backdate mt-2" v-if="newExpense.backdated">
                        <el-date-picker
                            v-model="newExpense.backdate"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>
                </div>

                <div class="form-group">
                    <label class="display-block"> Payment Method </label>
                    <el-radio-group v-model="newExpense.payment_method" size="large">
                        <el-radio :label="'Cash'" border>Cash</el-radio>
                        <el-radio :label="'MPESA'" border>Bank/MPESA Till</el-radio>
                    </el-radio-group>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showNewExpenseDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addNewExpense"> Save Expense</button>
                </div>
            </template>
        </el-dialog>
        <!-- END OF ADD NEW CATEGORY MODAL -->

        <!-- Update Expense Modal -->
        <el-dialog
            v-model="showUpdateExpenseDialog"
            :title="'Update Expense ' + activeExpense.display_expense_number"
            width="30%"
            v-if="activeExpense"
        >
            <form @submit.prevent="updateExpense"
                  v-loading="updatingExpense"
                  element-loading-text="Saving changes"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="new-expense-amount"> New Expense Amount </label>
                    <input type="text" id="new-expense-amount" class="form-control" v-model="activeExpense.expense_amount" placeholder="Expense amount">
                    <div class="invalid-feedback"> Expense amount is a required field</div>
                </div>

                <div class="form-group">
                    <label class="required" for="new-expense-description"> New Expense Description </label>
                    <textarea id="new-expense-description" class="form-control" v-model="activeExpense.description" placeholder="Expense description"></textarea>
                    <div class="invalid-feedback"> Expense description is a required field</div>
                </div>

                <div class="form-group">
                    <div class="backdate mt-2">
                        <el-date-picker
                            v-model="activeExpense.editing_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showUpdateExpenseDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="updateExpense"> Save Changes</button>
                </div>
            </template>
        </el-dialog>

        <!-- Void Expense Modal -->
        <el-dialog
            v-model="showVoidExpenseDialog"
            :title="'Void Expense ' + activeExpense.display_expense_number"
            width="30%"
            v-if="activeExpense"
        >
            <form @submit.prevent="voidExpense"
                  v-loading="voidingExpense"
                  element-loading-text="Voiding expense"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="voiding-reason"> Why are you voiding this expense? </label>
                    <input type="text" id="voiding-reason" class="form-control" v-model="activeExpense.voiding_reason">
                    <div class="invalid-feedback"> Please provide a reason for voiding expense </div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showVoidExpenseDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="voidExpense"> Void Expense</button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {Delete, Edit} from '@element-plus/icons-vue'
import $ from 'jquery'

export default {
    name: "Expenses",

    data() {
        return {
            baseUrl: '/api/accounting/expenses',
            filters: {},
            gettingExpenses: false,
            expenses: [],
            currentPage: 1,
            totalExpenses: 0,
            showNewExpenseDialog: false,
            newExpense: {},
            addingExpense: false,
            showUpdateExpenseDialog: false,
            showVoidExpenseDialog: false,
            activeExpense: {},
            updatingExpense: false,
            voidingExpense: false,
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
        ...mapState('users', ['systemUsers']),

        ...mapGetters('system', ['getBasicPayload']),

        Edit() {
            return Edit
        },

        Delete() {
            return Delete
        },
    },

    created() {
        this.getExpenses()

        if (!this.systemUsers || this.systemUsers?.length === 0) {
            this.$store.dispatch('users/getUsers')
        }
    },

    methods: {
        getExpenses() {
            let payload = {...this.getBasicPayload, ...this.filters, page: this.currentPage - 1}
            this.gettingExpenses = true
            axios.get(`${this.baseUrl}`, {params: payload}).then(res => {
                this.gettingExpenses = false
                this.expenses = res.data.data.expenses
                this.totalExpenses = res.data.data.count
            }).catch(error => {
                this.gettingExpenses = false
                this.$message.error('Error loading expenses. Please reload the page')
            })
        },

        promptNewExpense() {
            this.newExpense = {...{backdated: false, payment_method: 'Cash'}, ...this.getBasicPayload}
            this.showNewExpenseDialog = true
        },

        addNewExpense(e) {
            e.preventDefault()

            // Validate
            let dataIsValid = true
            if (!this.newExpense.amount || this.amountIsNotValid(this.newExpense.amount)) {
                dataIsValid = false
                $("#expense-amount").addClass('is-invalid')
            } else {
                $("#expense-amount").removeClass('is-invalid')
            }

            if (!this.newExpense.description) {
                dataIsValid = false
                $("#expense-description").addClass('is-invalid')
            } else {
                $("#expense-description").removeClass('is-invalid')
            }

            if (!this.newExpense.user_id) {
                dataIsValid = false
                this.$message.error('Please select a user')
            }

            if (dataIsValid) {
                this.newExpense.backdated = this.newExpense.backdated ? 1 : 0
                this.addingExpense = true
                axios.post(`${this.baseUrl}/add`, this.newExpense).then(res => {
                    this.addingExpense = false
                    this.getExpenses()
                    this.showNewExpenseDialog = false

                    this.$message.success('Expense added successfully')
                }).catch(error => {
                    this.addingExpense = false
                    this.$message.error('Error saving expense. Please try again')
                })
            } else {
                // this.$message.error('Please fill all required fields')
            }
        },

        handleExpenseActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            if (commandName === 'update') {
                this.activeExpense = JSON.parse(JSON.stringify(this.expenses[commandIndex]))
                this.showUpdateExpenseDialog = true
            }

            if (commandName === 'void') {
                this.activeExpense = JSON.parse(JSON.stringify(this.expenses[commandIndex]))
                this.showVoidExpenseDialog = true
            }
        },

        resetExpenseFilters() {
            this.filters = {}
            this.getExpenses()
        },

        updateExpense(e) {
            e.preventDefault()

            let dataIsValid = true
            if (!this.activeExpense.expense_amount || this.amountIsNotValid(this.activeExpense.expense_amount)) {
                dataIsValid = false
                $("#new-expense-amount").addClass('is-invalid')
            } else {
                $("#new-expense-amount").removeClass('is-invalid')
            }

            if (!this.activeExpense.description) {
                dataIsValid = false
                $("#new-expense-description").addClass('is-invalid')
            } else {
                $("#new-expense-description").removeClass('is-invalid')
            }

            if (dataIsValid) {
                let payload = {
                    expense_id: this.activeExpense.id,
                    amount: this.activeExpense.expense_amount,
                    description: this.activeExpense.description,
                    date: this.activeExpense.editing_date,
                    user_id: this.currentUser.id
                }

                this.updatingExpense = true
                axios.post(`${this.baseUrl}/update`, payload).then(res => {
                    this.updatingExpense = false
                    this.showUpdateExpenseDialog = false
                    this.getExpenses()

                    this.$message.success('Expense updated successfully')
                }).catch(error => {
                    this.updatingExpense = false
                    this.$message.error('Error saving changes. Please try again')
                })
            }
        },

        voidExpense(e) {
            e.preventDefault()

            let dataIsValid = true
            if (!this.activeExpense.voiding_reason) {
                dataIsValid = false
                $("#voiding-reason").addClass('is-invalid')
            } else {
                $("#voiding-reason").removeClass('is-invalid')
            }

            if (dataIsValid) {
                let payload = {
                    expense_id: this.activeExpense.id,
                    reason: this.activeExpense.voiding_reason,
                    user_id: this.currentUser.id
                }

                this.voidingExpense = true
                axios.post(`${this.baseUrl}/void`, payload).then(res => {
                    this.voidingExpense = false
                    this.showVoidExpenseDialog = false
                    this.getExpenses()

                    this.$message.success('Expense voided successfully')
                }).catch(error => {
                    this.updatingExpense = false
                    this.$message.error('Error voiding expense. Please try again')
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
