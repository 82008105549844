<template>
    <div id="role-permissions" class="card shadow-sm">
        <div class="master-subtitle d-flex justify-content-between align-items-center">
            <h5 class="mb-0"> Role Permissions </h5>
        </div>

        <div class="master-content">
            <!-- Role Selection -->
            <div id="role-selection" v-loading="gettingRolePermissions" element-loading-text="Fetching role permissions">
                <p> Please select a role first to list its permissions </p>

                <div class="d-flex flex-wrap" id="role-selector">
                    <p v-if="empty(roles) || !roles"> No roles found. Click on the <strong class="text-primary"> System Roles </strong> to add a role. </p>
                    <el-radio-group v-model="selectedRole" size="large" v-else>
                        <el-radio v-for="role in roles" :key="role.id" :label="role.id" border> {{ role.name }}</el-radio>
                    </el-radio-group>
                </div>

                <div class="d-flex justify-content-end mt-2">
                    <button class="btn btn-primary" @click="fetchRolePermissions" :disabled="!selectedRole"> Fetch Role Permissions</button>
                </div>
            </div>
            <!-- End Of Role Selection -->

            <el-divider/>

            <!-- Permissions -->
            <div id="permissions" v-if="showRolePermissions" v-loading="updatingRolePermissions" element-loading-text="Updating permissions">
                <div class="d-flex justify-content-between">
                    <h5 class="master-subtitle"> Permissions </h5>

                    <div>
                        <button class="btn btn-outline-primary btn-sm" @click="checkAllPermissions"> Assign All Permissions</button>
                        <button class="btn btn-outline-warning btn-sm" style="margin-left: 10px;" @click="checkAllPermissions"> Revoke All Permissions</button>
                    </div>
                </div>

                <!-- Modules -->
                <div id="modules" class="mt-3">
                    <div class="d-flex flex-wrap justify-content-between w-100 mt-3">
                        <div class="module card bg-white" v-for="(module, index) in permissions" :key="index">
                            <el-checkbox
                                v-model="module.checked_for_all"
                                :indeterminate="module.indeterminate"
                                @change="handleCheckAllModulePermissionsChange($event, index)"
                            >{{ capitalizeEachWord((module.name).replace('_', ' ')) }}
                            </el-checkbox>
                            <el-checkbox-group
                                v-model="module.checked_actions"
                                @change="handleCheckedModuleActionsChange($event, index)"
                            >
                                <el-checkbox v-for="action in module.actions" :key="action.name" :label="action.name">
                                    {{ capitalizeEachWord((action.name).replace('_', ' ')) }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>

                    <div class="d-flex justify-content-end mt-2">
                        <button class="btn btn-primary" @click="updateRolePermissions"> Update Permissions</button>
                    </div>
                </div>
                <!-- End Of Modules -->
            </div>
            <!-- End Of Permissions -->
        </div>

    </div>
</template>

<script>
import {mapState} from "vuex";
import {Refresh} from "@element-plus/icons-vue";

export default {
    name: "RolePermissions",

    data() {
        return {
            selectedRole: null,
            showRolePermissions: false,
            gettingRolePermissions: false,
            updatingRolePermissions: false,
            permissions: [],
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
        ...mapState('users', ['roles', 'baseUrl']),

        Refresh() {
            return Refresh
        },

        permissionsBaseUrl() {
            return `${this.baseUrl}/permissions`
        },
    },

    methods: {
        fetchRolePermissions() {
            this.showRolePermissions = false
            this.gettingRolePermissions = true

            axios.get(`${this.permissionsBaseUrl}/role-permissions`, {params: {role_id: this.selectedRole}}).then(res => {
                this.permissions = res.data.data

                this.gettingRolePermissions = false
                this.showRolePermissions = true
            }).catch(error => {
                this.gettingRolePermissions = false
                this.$message.error('An error was encountered. Please try again.')
            })
        },

        checkAllPermissions() {
        },

        revokeAllPermissions() {
        },

        handleCheckAllModulePermissionsChange(value, moduleIndex) {
            let module = this.permissions[moduleIndex]
            module.checked_actions = []
            if (value) {
                module.actions.forEach(action => {
                    action.value = true
                    module.checked_actions.push(action.name)
                })
            } else {
                module.actions.forEach(action => {
                    action.value = false
                })
            }
            module.indeterminate = false
        },

        handleCheckedModuleActionsChange(value, moduleIndex) {
            const checkedCount = value.length
            let module = this.permissions[moduleIndex]
            module.checked_for_all = checkedCount === module.checked_actions.length
            module.indeterminate = checkedCount > 0 && checkedCount < module.checked_actions.length
        },

        updateRolePermissions() {
            this.permissions.forEach(module => {
                module.actions.forEach(action => {
                    let checkedAction = module.checked_actions.find(_action => action.name === _action)
                    action.value = !!checkedAction;
                })
            })
            let payload = {
                business_id: this.currentUser.business_id,
                role_id: this.selectedRole,
                permissions: this.permissions
            }

            this.updatingRolePermissions = true
            axios.post(`${this.permissionsBaseUrl}/update-role-permissions`, payload).then(res => {
                this.updatingRolePermissions = false
                this.$message.success('Permissions updated successfully')
            }).catch(error => {
                this.updatingRolePermissions = false
                this.$message.error('An error was encountered. Please try again.')
            })
        },
    },
}
</script>

<style>
.module {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, .3);
    width: 30%;
    position: relative;
    margin-bottom: 20px;
}

.module .el-checkbox-group {
    padding-left: 25px !important;
}

.module .el-checkbox-group .el-checkbox {
    display: block !important;
}
</style>
