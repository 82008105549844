<template>
    <div class="w-100">
        <form id="signup-form" method="POST" class="card bg-white shadow mb-2" @submit.prevent="submitSignupForm" v-loading="signingUp">

            <div class="row" style="margin-right: -15px !important; margin-left: -15px!important;">
                <div class="form-group col-md-6">
                    <label for="first-name" class="required"> First Name </label>
                    <input type="text" name="firstname" id="first-name" class="form-control" v-model="firstName">
                    <div class="invalid-feedback">
                        Please provide your first name.
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="last-name" class="required"> Last Name </label>
                    <input type="text" name="lastname" id="last-name" class="form-control" v-model="lastName">
                    <div class="invalid-feedback">
                        Please provide your last name.
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="email" class="required"> Email Address </label>
                <input type="email" name="email" id="email" class="form-control" v-model="email">
                <div class="invalid-feedback">
                    {{ emailError }}
                </div>
            </div>

            <div class="form-group">
                <label class="required"> Phone Number </label>
                <vue-tel-input :value="phone" @input="onInput" mode="international"></vue-tel-input>
                <input type="hidden" :value="phone" name="phone">
                <div class="invalid-feedback" id="phone-invalid-feedback">
                    {{ phoneError }}
                </div>
            </div>

            <div class="form-group">
                <label for="password" class="required"> Password </label>
                <div class="input-group" id="signup-pwd-input-grp">
                    <input :type="passwordIsVisible ? 'text' : 'password'" name="password" id="password" class="form-control" v-model="password">
                    <span class="input-group-text toggle-password" @click="togglePasswordVisibility">
                        <i class="far fa-eye-slash" v-if="passwordIsVisible"></i>
                        <i class="far fa-eye" v-else></i>
                    </span>
                </div>
                <div id="passwordHelp" class="form-text">Please create a strong and secure password.</div>
                <div class="invalid-feedback">
                    Please create a password.
                </div>
            </div>

            <div>
                <button @click="submitSignupForm" class="btn btn-radius btn-red mb-4 btn-block" id="sign-up-btn"> Let's Go</button>
            </div>

<!--            <div class="d-flex justify-content-end">-->
<!--                <span><span>Already registered?</span> &nbsp;<a href="/login" class="text-primary fw-600"> Sign in instead. </a></span>-->
<!--            </div>-->
        </form>
    </div>
</template>

<script>
import $ from 'jquery'

export default {
    name: "RegisterUserComponent",
    data() {
        return {
            agreeTerms: false,
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            password: null,
            signingUp: false,
            emailError: '',
            phoneError: '',
            passwordIsVisible: false,
        }
    },

    mounted() {
        // Set styles for focus input texts
        $("#password").focus(() => {
            console.log('focus')
            $("#signup-pwd-input-grp .toggle-password").addClass('focused')
        })

        $("#password").focusout(() => {
            $("#signup-pwd-input-grp .toggle-password").removeClass('focused')
        })
    },

    methods: {
        onInput(phone, phoneObject, input) {
            if (phoneObject?.formatted) {
                this.phone = phoneObject.formatted
            }
        },

        submitSignupForm(e) {
            e.preventDefault()

            let formIsValid = true

            // Validate
            // First name
            if (!this.firstName || this.firstName === '') {
                formIsValid = false
                $("#first-name").addClass('is-invalid')
            } else {
                $("#first-name").removeClass('is-invalid')
            }

            // Last name
            if (!this.lastName || this.lastName === '') {
                formIsValid = false
                $("#last-name").addClass('is-invalid')
            } else {
                $("#last-name").removeClass('is-invalid')
            }

            // Email
            if (!this.email || this.email === '') {
                formIsValid = false
                this.emailError = 'Email address is required'
                $("#email").addClass('is-invalid')
            } else {
                $("#email").removeClass('is-invalid')
            }

            // Phone number
            if (!this.phone || this.phone === '') {
                formIsValid = false
                this.phoneError = 'Phone number is required'
                $("#phone-invalid-feedback").css('display', 'block')
                $(".vue-tel-input").css('border', '1px solid red')
            } else {
                $("#phone-invalid-feedback").css('display', 'none')
                $(".vue-tel-input").css('border', '1px solid #bbb')
            }

            // Password
            if (!this.password || this.password === '') {
                formIsValid = false
                $("#password").addClass('is-invalid')
            } else {
                $("#password").removeClass('is-invalid')
            }

            // Submit form
            if (formIsValid) {
                let payload = {
                    firstname: this.firstName,
                    lastname: this.lastName,
                    phone: this.phone,
                    email: this.email,
                    password: this.password
                }
                this.signingUp  = true
                axios.post('/api/auth/register', payload).then(res => {
                    // Login user
                    axios.post('/api/auth/login', {email: this.email, password: this.password, is_onboarding: 1}).then(res => {
                        this.signingUp  = false
                        return window.location.assign('/business/setup')
                    }).catch(error => {
                        this.signingUp  = false
                        return window.location.assign('/business/setup')
                    })
                }).catch(error => {
                    this.signingUp  = false
                    this.password = null

                    if (error.response) {
                        if (error.response.status === 422) {
                            let data = error.response.data
                            if (data.hasOwnProperty('email')) {
                                this.emailError = data.email
                                $("#email").addClass('is-invalid')
                            }

                            if (data.hasOwnProperty('phone')) {
                                this.phoneError = data.phone
                                $("#phone-invalid-feedback").css('display', 'block')
                                $(".vue-tel-input").css('border', '1px solid red')
                            }
                        } else {
                            this.$message.error('Please try again')
                        }
                    } else {
                        this.$message.error('Please try again')
                    }
                })
            }
        },

        togglePasswordVisibility(e) {
            e.preventDefault()

            this.passwordIsVisible = !this.passwordIsVisible
        },
    },
}
</script>

<style scoped>

</style>
