<template>
    <div class="master-template">
        <div class="master-title d-flex justify-content-between">
            <h5> Receivables </h5>
        </div>

        <div class="master-content" v-loading="payingReceivable">
            <div class="data-table mt-3">
                <div class="data-table-summary d-flex justify-content-end">
                    <span class="summary"> Showing {{ receivables.length }} out of {{ totalReceivables }} receivables </span>
                </div>

                <div class="data-table">
                    <el-skeleton :loading="gettingReceivables" :throttle="200" animated>
                        <template #template>
                            <el-skeleton-item variant="h3" v-for="row in 7" :key="row" style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                        </template>

                        <template #default>
                            <el-table :data="receivables" style="width: 100%">
                                <el-table-column label="Receivable Source" prop="source"></el-table-column>
                                <el-table-column label="Receivable Amount" prop="display_amount"></el-table-column>
                                <el-table-column label="Status">
                                    <template #default="scope">
                                        <el-button :type="scope.row.status_type" size="small">{{ scope.row.display_payment_status }}</el-button>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Amount Paid" prop="display_amount_paid"></el-table-column>
                                <el-table-column label="Amount Due" prop="display_amount_due"></el-table-column>
                                <el-table-column label="Due Date" prop="display_due_date"></el-table-column>
                                <el-table-column label="Actions">
                                    <template #default="scope">
                                        <el-dropdown @command="handleReceivableActions">
                                            <el-button type="primary" size="small">
                                                Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                            </el-button>
                                            <template #dropdown>
                                                <el-dropdown-menu>
                                                    <el-dropdown-item :command="'pay-' + scope.$index" v-if="scope.row.can_pay"> Make Payment</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </el-skeleton>
                </div>

                <!-- Pagination -->
                <div class="data-table-pagination d-flex justify-content-end mt-3">
                    <el-pagination background layout="prev, pager, next" :total="totalReceivables"
                                   :page-size="10" v-model:current-page="currentPage" @current-change="handlePageChange" hide-on-single-page/>
                </div>
            </div>
        </div>

        <!-- Receivable payment amount dialog -->
        <el-dialog
            v-model="showReceivableAmountDialog"
            title="Pay Receivable"
            width="30%"
        >
            <form @submit.prevent="payReceivable"
                  v-loading="payingReceivable"
                  element-loading-text="Processing..."
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="amount"> Payment Amount </label>
                    <input type="text" id="amount" class="form-control" v-model="paymentAmount" placeholder="Amount">
                    <div class="invalid-feedback"> Input a valid amount</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showReceivableAmountDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="payReceivable"> Make Payment</button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import $ from 'jquery'

export default {
    name: "Receivables",

    data() {
        return {
            baseUrl: '/api/accounting/receivables',
            gettingReceivables: false,
            receivables: [],
            filters: {},
            currentPage: 1,
            totalReceivables: 0,
            payingReceivable: false,
            showReceivableAmountDialog: false,
            paymentAmount: null,
            activeReceivableId: null,
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),

        ...mapGetters('system', ['getBasicPayload']),
    },

    created() {
        this.getReceivables()
    },

    methods: {
        getReceivables() {
            let payload = {...this.getBasicPayload, ...{page: (this.currentPage - 1)}}
            this.gettingReceivables = true
            axios.get(`${this.baseUrl}`, {params: payload}).then(res => {
                this.gettingReceivables = false
                this.receivables = res.data.data.receivables
                this.totalReceivables = res.data.data.count
            }).catch(error => {
                this.gettingReceivables = false
                this.$message.error('Error loading receivables. Please reload the page')
            })
        },

        handlePageChange(val) {
            let payload = {...this.getBasicPayload, ...{page: (this.currentPage - 1)}}
            this.gettingReceivables = false
            axios.get(`${this.baseUrl}`, {params: payload}).then(res => {
                this.gettingReceivables = false
                this.receivables = res.data.data.receivables
                // this.totalReceivables = res.data.data.count
            }).catch(error => {
                this.gettingReceivables = false
                this.$message.error('Error loading receivables. Please reload the page')
            })
            // this.getReceivables()
        },

        filterReceivables() {
        },

        handleReceivableActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            if (commandName === 'pay') {
                this.promptPayReceivable(this.receivables[commandIndex])
            }
        },

        promptPayReceivable(receivable) {
            this.paymentAmount = receivable.amount_due
            this.activeReceivableId = receivable.id
            this.showReceivableAmountDialog = true
        },

        payReceivable(e) {
            e.preventDefault()

            if (!this.paymentAmount || isNaN(parseFloat(this.paymentAmount))) {
                $("#amount").addClass('is-invalid')
                return this.$message.error('Input a valid amount')
            } else {
                $("#amount").removeClass('is-invalid')
            }

            let payload = {
                id: this.activeReceivableId,
                user_id: this.currentUser.id,
                amount: this.paymentAmount
            }
            this.payingReceivable = true
            axios.post(`${this.baseUrl}/pay`, payload).then(res => {
                this.payingReceivable = false
                this.getReceivables()

                this.$message.success('Success!')
                this.showReceivableAmountDialog = false
            }).catch(error => {
                this.payingReceivable = false
                this.$message.error('Error paying receivable. Please try again')
            })
        },
    }
}
</script>

<style scoped>

</style>
