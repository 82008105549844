<template>
<div class="master-template">
    <div class="master-title-div d-flex justify-content-between align-items-center">
        <h5> Purchases </h5>

        <div class="actions">
            <a href="/procurement/purchases/create" class="btn btn-primary new-item-btn" @click="promptNewPurchase">
                <i class="fas fa-plus-circle"></i>New Purchase
            </a>
        </div>
    </div>

    <div class="master-content card bg-white shadow-sm">
        <div class="data-table-filters">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="purchase-number"> Search purchase number </label>
                        <input type="text" class="form-control" id="purchase-number" v-model="filters.purchase_number">
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="purchase-date" class="display-block">Search by purchase date</label>
                        <el-date-picker
                            v-model="filters.purchase_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                            style="width: 100%"
                        >
                        </el-date-picker>
                    </div>
                </div>
            </div>

            <div class="mt-3 d-flex">
                <el-button type="info" size="large" @click="resetFilters"> Reset filters </el-button>
                <el-button type="primary" size="large" @click="searchPurchases"> Search purchases </el-button>
            </div>
        </div>

        <div class="data-table-summary d-flex justify-content-end">
            Showing {{ purchases.length }} out of {{ purchasesCount }} purchases.
        </div>

        <div class="data-table">
            <el-skeleton :loading="gettingPurchases" :throttle="200" animated>
                <template #template>
                    <el-skeleton-item variant="h3" v-for="row in 9" :key="row" style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                </template>
                <template #default>
                    <el-table
                        :data="purchases"
                        empty-text="No purchases found"
                        style="width: 100%; height: 100%;"
                    >

                        <el-table-column prop="purchase_number" label="Purchase Number"/>
                        <el-table-column prop="purchase_date" label="Purchase Date"/>
                        <el-table-column label="Supplier">
                            <template #default="scope">
                                <span>{{ scope.row.supplier.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="display_purchase_total" label="Total Cost"/>
                        <el-table-column label="Actions">
                            <template #default="scope">
                                <el-dropdown @command="handlePurchaseActions">
                                    <el-button type="primary">
                                        Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                    </el-button>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item :command="'view-' + scope.$index">View Purchase Details</el-dropdown-item>
<!--                                            <el-dropdown-item :command="'update-' + scope.$index">Update Purchase </el-dropdown-item>
                                            <el-dropdown-item :command="'void-' + scope.$index">Void Purchase</el-dropdown-item>-->
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                            </template>
                        </el-table-column>

                    </el-table>

                    <div class="data-table-pagination d-flex justify-content-end mt-3">
                        <el-pagination background layout="prev, pager, next" :total="purchasesCount"
                                       :page-size="10" v-model:current-page="currentPage" @current-change="getPurchases"
                                       hide-on-single-page/>
                    </div>
                </template>
            </el-skeleton>
        </div>

    </div>

    <!-- Sale Items Dialog -->
    <el-dialog :title="'Purchase Items for ' + activePurchase.purchase_number" width="50%" v-model="showPurchaseItemsDialog">
        <el-table :data="activePurchase.items" style="width: 100%">
            <el-table-column prop="item_label" label="Item"/>
            <el-table-column label="Quantity">
                <template #default="scope">
                    {{ formatAmount(scope.row.quantity) }}
                </template>
            </el-table-column>
            <el-table-column label="Purchase Price" prop="price"/>
            <el-table-column label="Item Total" prop="cost"/>
        </el-table>

        <template #footer>
            <div class="dialog-footer d-flex justify-content-end">
                <button class="btn btn-primary" @click="showPurchaseItemsDialog = false"> Close</button>
            </div>
        </template>
    </el-dialog>
</div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
    name: "Purchases",

    data() {
        return {
            purchases: [],
            filters: {
                purchase_number: null,
                purchase_date: null
            },
            currentPage: 1,
            purchasesCount: 0,
            gettingPurchases: false,
            activePurchase: {},
            showPurchaseItemsDialog: false,
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
        ...mapState('procurement', ['suppliers']),

        ...mapGetters('system', ['getBasicPayload']),
    },

    created() {
        this.getPurchases()
        this.getSuppliers()
    },

    methods: {
        getPurchases() {
            this.gettingPurchases = true
            axios.get(`/api/procurement/purchases/list`, {params: this.getBasicPayload}).then(response => {
                this.purchases = response.data.data
                this.gettingPurchases = false
            }).catch(error => {
                this.gettingPurchases = false
            })
        },

        getSuppliers() {
            this.gettingSuppliers = true
            axios.get('/api/procurement/suppliers', {params: this.getBasicPayload}).then(response => {
                this.$store.commit('procurement/setSuppliersList', response.data.data)
                this.gettingSuppliers = false
            }).catch(error => {})
        },

        promptNewPurchase() {
            //
        },

        searchPurchases() {},

        resetFilters() {
            this.filters.purchase_number = null
            this.filters.purchase_date = null

            this.getPurchases()
        },

        handlePurchaseActions(command) {
            console.log(command)
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            switch (commandName) {
                case 'view':
                    this.activePurchase = {}
                    this.activePurchase = this.purchases[commandIndex]
                    console.log(this.activePurchase)
                    this.showPurchaseItemsDialog = true
                    break
                default:
                    break
            }
        }
    }
}
</script>

<style scoped>

</style>
