<template>
    <div id="business-details">
        <div class="master-content">
            <form method="post" @submit.prevent="updateBusinessDetails" id="update-business-details" v-loading="updatingDetails">
                <div class="form-group">
                    <div class="setting-style d-flex justify-content-between align-items-center">
                        <div class="setting-desc-div flex-grow-1">
                            <h5> Business Name </h5>
                            <p>  </p>
                        </div>

                        <div class="setting-options">
                            <input type="text" v-model="businessDetails.business_name" class="form-control" id="update-business-name">
                            <div class="invalid-feedback"> Business name is required </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="setting-style d-flex justify-content-between align-items-center">
                        <div class="setting-desc-div flex-grow-1">
                            <h5> Business Email </h5>
                            <p> This email will be used for receiving reports and communications. </p>
                        </div>

                        <div class="setting-options">
                            <input type="text" v-model="businessDetails.business_email" class="form-control" id="update-business-email">
                            <div class="invalid-feedback"> Business email is required </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="setting-style d-flex justify-content-between align-items-center">
                        <div class="setting-desc-div flex-grow-1">
                            <h5> Business Number </h5>
                            <p> This phone number will be used for receiving alerts and communications. </p>
                        </div>

                        <div class="setting-options">
                            <vue-tel-input :value="businessDetails.business_number" @input="onInput" mode="international" defaultCountry="KE"></vue-tel-input>
                            <input type="hidden" :value="businessDetails.business_number" name="phone">
                            <div class="invalid-feedback" id="phone-invalid-feedback"> Business number is required </div>
                        </div>
                    </div>
                </div>
            </form>

            <div class="d-flex justify-content-end fw-700 mt-4">
                <button class="btn btn-primary action-btn" @click="updateBusinessDetails"> Save Changes </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import $ from 'jquery'

export default {
    name: "BusinessDetails",

    data() {
        return {
            updatingDetails: false,
            businessDetails: {},
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
        ...mapGetters('system', ['getBasicPayload']),
    },

    mounted() {
        this.businessDetails = JSON.parse(JSON.stringify(this.currentUser.business))
    },

    methods: {
        onInput(phone, phoneObject, input) {
            if (phoneObject?.formatted) {
                this.businessDetails.business_number = phoneObject.formatted
            }
        },

        updateBusinessDetails(e) {
            e.preventDefault()

            let dataIsValid = true
            if (!this.businessDetails.business_name) {
                dataIsValid = false
                $("#update-business-name").addClass('is-invalid')
            } else {
                $("#update-business-name").removeClass('is-invalid')
            }

            if (!this.businessDetails.business_email) {
                dataIsValid = false
                $("#update-business-email").addClass('is-invalid')
            } else {
                $("#update-business-email").removeClass('is-invalid')
            }

            if (!this.businessDetails.business_number) {
                dataIsValid = false
                $("#phone-invalid-feedback").css('display', 'block')
            } else {
                $("#phone-invalid-feedback").css('display', 'none')
            }

            if (dataIsValid) {
                this.updatingDetails = true
                axios.post('/api/business/update', {...this.businessDetails, ...{user_id: this.currentUser.id}}).then(res => {
                    this.updatingDetails = false
                    this.$store.commit('system/updateCurrentUser', res.data.data)
                    this.$message.success('Success!')
                }).catch(error => {
                    this.updatingDetails = false
                    this.$message.error('An error occurred. Please try again.')
                })
            }
        },
    },
}
</script>

<style scoped>

</style>
