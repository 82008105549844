<template>
    <div class="master-template">
        <div class="master-title-div d-flex justify-content-between align-items-center">
            <h5> New Purchase </h5>

            <div class="actions">
                <a href="/procurement/purchases" class="btn btn-outline-primary new-item-btn mr-12">
                    <i class="fas fa-backward"></i>Back to Purchases List
                </a>
            </div>
        </div>

        <div class="master-content card bg-white">
            <div class="row">
                <div class="form-group col-md-4">
                    <label for="item-code"> Scan item code </label>
                    <input type="text" class="form-control" v-model="scannedItemCode" @keyup.enter="addItemByCode" placeholder="Scan item code"
                           id="item-code">
                </div>

                <div class="form-group col-md-4">
                    <label for="item-code" style="display: block;"> Search item name </label>
                    <el-select v-model="selectedItemId" @change="addItemById" filterable size="large"
                               placeholder="Search item name"
                               :loading="loadingStockItems"
                               style="width: 100% !important;">
                        <el-option v-for="item in stockItems"
                                   :key="item.id"
                                   :label="`${item.label} (${item.display_units} available)`"
                                   :value="item.id" style="font-size: 18px !important;"></el-option>
                    </el-select>
                </div>

                <div class="col-md-4">
                    <label class="display-block"> Actions </label>
                    <div class="d-flex">
                        <el-button type="primary" style="margin-right: 12px;" size="large" @click="promptAddNewItem"> New item</el-button>
                        <el-button type="primary" plain size="large" @click="reloadStockItems"> Reload items</el-button>
                    </div>
                </div>
            </div>

            <div class="mt-3">
                <el-table :data="purchase.items" empty-text="No items added to purchase">
                    <el-table-column prop="code" label="Item code" width="150"></el-table-column>

                    <el-table-column prop="label" label="Item name" width="200"></el-table-column>

                    <el-table-column label="Quantity">
                        <template #default="scope">
                            <input type="text" v-model="scope.row.quantity" class="form-control" style="width: 120px;">
                        </template>
                    </el-table-column>

                    <el-table-column label="Buying price" width="250">
                        <template #default="scope">
                            <div class="d-flex align-items-center">
                                <span class="display-block mr-12"> {{ scope.row.display_buying_price }} </span>
                                <el-button plain type="primary" @click="promptNewBuyingPrice(scope.row)"> Update</el-button>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Selling price" width="250">
                        <template #default="scope">
                            <div class="d-flex align-items-center" v-if="scope.row.can_sell">
                                <span class="display-block mr-12"> {{ scope.row.display_selling_price }} </span>
                                <el-button plain type="primary" @click="promptNewSellingPrice(scope.row)"> Update</el-button>
                            </div>

                            <span v-else> N/A </span>
                        </template>
                    </el-table-column>

                    <el-table-column label="Item Total" width="150">
                        <template #default="scope">
                            {{ formatAmount(this.purchaseItemTotal(scope.row)) }}
                        </template>
                    </el-table-column>

                    <el-table-column label="New expiry date" v-if="businessTracksExpiryDates">
                        <template #default="scope">
                            <el-date-picker
                                v-model="scope.row.expiry_date"
                                type="date"
                                placeholder="Pick a day"
                                format="MMM D, YYYY"
                                value-format="YYYY-MM-DD"
                                size="large"
                                style="width: 100%"
                            >
                            </el-date-picker>
                        </template>
                    </el-table-column>

                    <el-table-column label="Actions" width="100">
                        <template #default="scope">
                            <div class="d-flex justify-content-center align-items-center w-100">
                                <i class="fas fa-trash"
                                   style="color: darkred; cursor:pointer;" @click="removePurchaseItem(scope.$index)"></i>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="mt-5 d-flex justify-content-end">
                <button class="btn btn-primary new-item-btn" @click="promptPurchaseCheckout" :disabled="purchase.items.length === 0"> Checkout
                    Purchase
                </button>
            </div>

        </div>

        <!-- Checkout Purchase -->
        <el-dialog
            v-model="checkoutPurchaseModalIsActive"
            width="30%"
            title="Checkout Purchase"
            v-loading="makingPurchase"
        >
            <div class="d-flex justify-content-center align-items-center checkout-total">
                Purchase total: {{ formatAmount(purchaseItemsTotal) }}
            </div>

            <div class="form-group">
                <label class="display-block"> Purchase Date </label>
                <el-date-picker
                    v-model="purchase.purchase_date"
                    type="date"
                    placeholder="Pick a day"
                    format="MMM D, YYYY"
                    value-format="YYYY-MM-DD"
                    size="large"
                    style="width: 100%"
                >
                </el-date-picker>
            </div>

            <div class="form-group">
                <label class="display-block"> Supplier </label>
                <div class="d-flex">
                    <el-select
                        v-model="purchase.supplier_id"
                        default-first-option
                        filterable
                        placeholder="Select a supplier"
                        size="large"
                        style="margin-right: 12px;"
                    >
                        <el-option
                            v-for="supplier in suppliers"
                            :key="supplier.id"
                            :label="supplier.name"
                            :value="supplier.id"
                        />
                    </el-select>
                    <el-button type="primary" plain @click="promptAddSupplier" size="large"> New Supplier</el-button>
                </div>
            </div>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="checkoutPurchaseModalIsActive = false" size="large" type="info">Cancel</el-button>
                    <button class="btn btn-primary" @click="makePurchase"> Make Purchase</button>
                </div>
            </template>
        </el-dialog>

        <!-- New Supplier Dialog -->
        <el-dialog title="New Supplier" width="30%" v-model="addSupplierModalIsActive">
            <form method="post" v-loading="addingSupplier" element-loading-text="Saving supplier" @submit.prevent="addSupplier">
                <div class="form-group">
                    <label for="new-customer-name" class="required"> Supplier Name </label>
                    <input type="text" class="form-control" id="new-customer-name" v-model="newSupplier.name">
                    <div class="invalid-feedback"> Customer name is required</div>
                </div>
            </form>
            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="addSupplierModalIsActive = false" size="large" type="info">Cancel</el-button>
                    <button class="btn btn-primary" @click="addSupplier"> Save Supplier</button>
                </div>
            </template>
        </el-dialog>

        <!-- New Item Dialog -->
        <el-dialog title="New Stock Item" width="60%" v-model="newItemModalIsActive"
                   :close-on-click-modal="false" :close-on-press-escape="false" v-if="newItem">

            <div class="entry" v-loading="addingNewItem">
                <div class="d-flex flex-wrap">
                    <div class="form-group">
                        <label> Item Code </label>
                        <input type="text" v-model="newItem.item_code" class="form-control">
                    </div>

                    <div class="form-group">
                        <label> Item Name </label>
                        <input type="text" v-model="newItem.label" class="form-control">
                    </div>

                    <div class="form-group">
                        <label> Category </label>
                        <div class="d-flex">
                            <el-select v-model="newItem.category_id" filterable size="large" style="margin-right: 5px;">
                                <el-option v-for="category in productCategories" :key="category.id" :label="category.name"
                                           :value="category.id"></el-option>
                            </el-select>
                            <el-button plain type="primary" size="large" style="font-weight: bold;" @click="promptNewCategory"> Add New</el-button>
                        </div>
                    </div>

                    <div class="form-group">
                        <label> Sub Category </label>
                        <div class="d-flex">
                            <el-select v-model="newItem.sub_category_id" filterable size="large" style="margin-right: 5px;">
                                <el-option v-for="subCategory in displayableSubcategories()" :key="subCategory.id" :label="subCategory.name"
                                           :value="subCategory.id"></el-option>
                            </el-select>
                            <el-button plain type="primary" size="large" style="font-weight: bold;" @click="promptNewSubCategory"> Add New</el-button>
                        </div>
                    </div>

                    <div class="form-group">
                        <label> Primary Stocking Unit </label>
                        <div class="d-flex">
                            <el-select
                                v-model="newItem.unit_id"
                                default-first-option
                                filterable
                                size="large"
                            >
                                <el-option
                                    v-for="unit in uoms"
                                    :key="unit.id"
                                    :label="unit.unit_name"
                                    :value="unit.id"
                                />
                            </el-select>
                        </div>
                    </div>

<!--                    <div class="form-group">-->
<!--                        <label for="quantity"> Quantity </label>-->
<!--                        <input type="text" class="form-control" v-model="newItem.quantity" id="quantity">-->
<!--                    </div>-->

                    <div class="form-group">
                        <label for="bp"> Buying Price </label>
                        <input type="text" class="form-control" v-model="newItem.buying_price" id="bp">
                    </div>

                    <div class="form-group">
                        <label for="sp"> Selling Price </label>
                        <div class="d-flex">
                            <input type="text" class="form-control" v-model="newItem.selling_price" id="sp" style="margin-right: 5px;">
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="rl"> Reorder Level </label>
                        <input type="text" class="form-control" v-model="newItem.reorder_level" id="rl">
                    </div>

                    <div class="form-group" v-if="businessTracksExpiryDates">
                        <label class="display-block"> Nearest Expiry Date </label>
                        <el-date-picker
                            v-model="newItem.expiry_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                            style="width: 100%"
                        >
                        </el-date-picker>
                    </div>

                    <div class="form-group" v-if="businessHasEnabledManualSellOuts">
                        <label style="display: block;"> Item requires manual sell out </label>
                        <el-radio-group v-model="newItem.sells_out_manually">
                            <el-radio :label="true" size="large" border>Yes</el-radio>
                            <el-radio :label="false" size="large" border>No</el-radio>
                        </el-radio-group>
                    </div>

                </div>
            </div>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="newItemModalIsActive = false" size="large" type="info">Discard Item</el-button>
                    <button class="btn btn-primary" @click="addNewItem"> Save Item</button>
                </div>
            </template>
        </el-dialog>

        <!-- START OF ADD NEW Category MODAL -->
        <el-dialog
            v-model="showAddCategoryDialog"
            title="Add Category"
            width="30%"
        >
            <form @submit.prevent="addNewCategory"
                  v-loading="addingNewCategory"
                  element-loading-text="Adding Category"
                  id="new-category-form"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="category-name"> Category Name </label>
                    <input type="text" id="category-name" class="form-control" v-model="newCategory.name"
                           placeholder="Category name">
                    <div class="invalid-feedback"> {{ categoryNameErrorMessage }}</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showAddCategoryDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addNewCategory"> Add Category</button>
                </div>
            </template>
        </el-dialog>
        <!-- END OF ADD NEW CATEGORY MODAL -->

        <!-- START OF ADD NEW SubCategory MODAL -->
        <el-dialog
            v-model="showAddSubCategoryDialog"
            title="Add Sub Category"
            width="30%"
        >
            <form @submit.prevent="addNewSubCategory"
                  v-loading="addingNewSubCategory"
                  element-loading-text="Adding Sub Category"
                  id="new-subcategory-form"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="subcategory-name"> Sub Category Name </label>
                    <input type="text" id="subcategory-name" class="form-control" v-model="newSubCategory.name"
                           placeholder="Sub category name">
                    <div class="invalid-feedback"> {{ subCategoryNameErrorMessage }}</div>
                </div>

                <div class="form-group">
                    <label class="required"> Category </label>
                    <el-select v-model="newSubCategory.category_id" filterable size="large" disabled>
                        <el-option v-for="category in productCategories" :key="category.id" :label="category.name"
                                   :value="category.id"></el-option>
                    </el-select>
                    <div class="invalid-feedback" id="subcategory-category-id"> Please select a category</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showAddSubCategoryDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addNewSubCategory"> Add Sub Category</button>
                </div>
            </template>
        </el-dialog>
        <!-- END OF ADD NEW Sub CATEGORY MODAL -->

        <!-- New Buying Price -->
        <el-dialog title="Update buying price" width="30%" v-model="newBuyingPriceModalIsActive" v-if="activePurchaseItem">
            <div class="form-group">
                <label for="new-buying-price" class="required"> New Buying Price </label>
                <input type="text" class="form-control" id="new-buying-price" v-model="newBuyingPrice">
            </div>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="newBuyingPriceModalIsActive = false" size="large" type="info">Discard </el-button>
                    <button class="btn btn-primary" @click="updateBuyingPrice"> Update </button>
                </div>
            </template>
        </el-dialog>

        <!-- New Selling Price -->
        <el-dialog title="Update buying price" width="30%" v-model="newSellingPriceModalIsActive" v-if="activePurchaseItem">
            <div class="form-group">
                <label for="new-selling-price" class="required"> New Selling Price </label>
                <input type="text" class="form-control" id="new-selling-price" v-model="newSellingPrice">
            </div>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="newSellingPriceModalIsActive = false" size="large" type="info">Discard </el-button>
                    <button class="btn btn-primary" @click="updateSellingPrice"> Update </button>
                </div>
            </template>
        </el-dialog>

    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import $ from "jquery";

export default {
    name: "NewPurchase",

    data() {
        return {
            stockItems: [],
            loadingStockItems: false,
            purchase: {
                supplier_id: null,
                purchase_date: null,
                items: []
            },
            selectedItemId: null,
            scannedItemCode: null,
            newItemModalIsActive: false,
            newItem: null,
            addingNewItem: false,
            checkoutPurchaseModalIsActive: false,
            makingPurchase: false,
            newBuyingPriceModalIsActive: false,
            newSellingPriceModalIsActive: false,
            newSupplier: {},
            addSupplierModalIsActive: false,
            addingSupplier: false,
            newCategory: {},
            showAddCategoryDialog: false,
            addingNewCategory: false,
            categoryNameErrorMessage: 'Category name is required',
            newSubCategory: {},
            showAddSubCategoryDialog: false,
            addingNewSubCategory: false,
            subCategoryNameErrorMessage: 'Sub category name is required',
            newBuyingPrice: 0,
            newSellingPrice: 0,
            activePurchaseItem: null
        }
    },

    computed: {
        ...mapState('system', ['currentUser', 'settings']),
        ...mapState('procurement', ['suppliers']),
        ...mapGetters('system', ['getBasicPayload']),

        ...mapState('stock', ['productCategories', 'productSubCategories', 'uoms']),
        ...mapGetters('stock', ['getStockLevelsBaseUrl', 'getClassificationBaseUrl']),

        businessTracksExpiryDates() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'TRACK_EXPIRY_DATES')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        businessHasEnabledSellingVariants() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'ENABLE_SELLING_VARIANTS')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        businessHasEnabledManualSellOuts() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'ENABLE_MANUAL_SELL_OUTS')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        purchaseItemsTotal() {
            let total = 0
            this.purchase.items.forEach(item => {
                total += this.purchaseItemTotal(item)
            })

            return total
        },

        getCategoriesBaseUrl() {
            return `${this.getClassificationBaseUrl}/categories`
        },

        getSubCategoriesBaseUrl() {
            return `${this.getClassificationBaseUrl}/subcategories`
        },
    },

    created() {
        this.getStockLevels()

        if (!this.settings || this.empty(this.settings)) {
            this.$store.dispatch('system/getSettings')
        }

        if (!this.productCategories || this.empty(this.productCategories)) {
            this.$store.dispatch('stock/getProductCategories')
        }

        if (!this.productSubCategories || this.empty(this.productSubCategories)) {
            this.$store.dispatch('stock/getProductSubCategories')
        }

        this.$store.dispatch('stock/getProductUoms')
    },

    mounted() {
        this.purchase.purchase_date = this.getFormattedNow()
    },

    methods: {
        getStockLevels() {
            this.loadingStockItems = true
            axios.get(`/api/stock/levels/pos`, {params: this.getBasicPayload}).then(response => {
                this.loadingStockItems = false
                this.stockItems = response.data.data
                this.stockItems = this.stockItems.filter(stockItem => stockItem.restocking_method === 'purchasing')
                this.$message.success('Stock items loaded successfully')
            }).catch(error => {
                this.loadingStockItems = false
                // pass
            })
        },

        displayableSubcategories() {
            return this.productSubCategories.filter(subCat => subCat.category_id === this.newItem.category_id)
        },

        addItemByCode() {
            let item = this.stockItems.find(_item => _item.item_code === this.scannedItemCode)
            if (item) {
                this.addItemToPurchases(item)
            }

            this.scannedItemCode = null
            $("#item-code").focus()
        },

        addItemById() {
            let item = this.stockItems.find(_item => _item.id === this.selectedItemId)
            if (item) {
                this.addItemToPurchases(item)
            }

            this.selectedItemId = null
            $("#item-code").focus()
        },

        addItemToPurchases(item) {
            let existingItemIndex = this.purchase.items.findIndex(_item => _item.stock_item_id === item.id)
            if (existingItemIndex !== -1) {
                return this.purchase.items[existingItemIndex].quantity += 1
            }

            this.purchase.items.push({
                stock_item_id: item.id,
                code: item.item_code,
                label: item.label,
                buying_price: item.buying_price,
                display_buying_price: this.formatAmount(item.buying_price),
                selling_price: item.selling_price, // For the purposes of editing
                display_selling_price: this.formatAmount(item.selling_price), // For the purposes of editing
                quantity: 1
            })
        },

        purchaseItemTotal(item) {
            try {
                return parseFloat(item.quantity) * parseFloat(item.buying_price)
            } catch (e) {
                return 0
            }
        },

        promptAddNewItem() {
            this.newItem = {
                quantity: 0,
                buying_price: 0,
                selling_price: 0
            }

            this.newItemModalIsActive = true
        },

        addNewItem() {
            let payload = {
                user: this.currentUser,
                entries: [this.newItem]
            }

            this.addingNewItem = true
            axios.post(`${this.getStockLevelsBaseUrl}/add`, {data: JSON.stringify(payload)}).then(res => {
                this.addingNewItem = false
                this.getStockLevels()
                this.$message.success('Stock item added successfully')
                this.newItemModalIsActive = false

                this.newItem = {}
            }).catch(error => {
                this.addingNewItem = false
                this.$message.error('An error was encountered. Please try again')
            })
        },

        reloadStockItems() {
            this.getStockLevels()
        },

        removePurchaseItem(itemIndex) {
            return this.purchase.items.splice(itemIndex, 1)
        },

        promptNewBuyingPrice(item) {
            this.activePurchaseItem = item
            this.newBuyingPrice = item.buying_price
            this.newBuyingPriceModalIsActive = true
        },

        updateBuyingPrice() {
            this.activePurchaseItem.buying_price = this.newBuyingPrice
            this.activePurchaseItem.display_buying_price = this.formatAmount(this.activePurchaseItem.buying_price)
            this.newBuyingPriceModalIsActive = false
        },

        promptNewSellingPrice(item) {
            this.activePurchaseItem = item
            this.newSellingPrice = item.selling_price
            this.newSellingPriceModalIsActive = true
        },

        updateSellingPrice() {
            this.activePurchaseItem.selling_price = this.newSellingPrice
            this.activePurchaseItem.display_selling_price = this.formatAmount(this.activePurchaseItem.selling_price)
            this.newSellingPriceModalIsActive = false
        },

        promptPurchaseCheckout() {
            this.checkoutPurchaseModalIsActive = true
            this.purchase.purchase_date = this.getFormattedNow()
        },

        makePurchase() {
            if (!this.purchase.supplier_id) {
                return this.$message.error('Please select a supplier')
            }

            this.purchase.items.forEach(item => {
                item.item_total = this.purchaseItemTotal(item)
            })

            let payload = {
                purchase: this.purchase,
                current_user: {
                    id: this.currentUser.id,
                    store_id: this.currentUser.store_id,
                }
            }

            this.makingPurchase = true
            axios.post('/api/procurement/purchases/store', {payload: JSON.stringify(payload)}).then(res => {
                this.makingPurchase = false
                this.$message.success('Purchase saved successfully')
                this.checkoutPurchaseModalIsActive = false
                this.purchase.items = []
                this.purchase.supplier_id = null
            }).catch(err => {
                this.makingPurchase = false
                this.$message.error("Error making purchase. Please try again")
            })
        },

        promptAddSupplier() {
            this.newSupplier = {...{}, ...this.getBasicPayload}
            this.addSupplierModalIsActive = true
        },

        addSupplier(e) {
            e.preventDefault()

            if (!this.newSupplier.name) {
                $("#new-supplier-name").addClass('is-invalid')
                return this.$message.error('Supplier name is required')
            } else {
                $("#new-supplier-name").removeClass('is-invalid')
            }

            this.addingSupplier = true
            axios.post('/api/procurement/suppliers/add', this.newSupplier).then(res => {
                this.addingSupplier = false
                this.purchase.supplier_id = res.data.data.id
                this.$store.dispatch('procurement/getSuppliers')
                this.$message.success('Supplier added successfully')
                this.addSupplierModalIsActive = false
            }).catch(error => {
                this.addingSupplier = false
                this.$message.error('Error adding supplier. Please try again')
            })
        },

        promptNewCategory() {
            this.newCategory = {}

            this.showAddCategoryDialog = true
        },

        promptNewSubCategory() {
            this.newSubCategory = {
                category_id: this.newItem.category_id
            }
            this.showAddSubCategoryDialog = true
        },

        addNewCategory(e) {
            e.preventDefault()

            // Validation
            let dataIsValid = true
            this.categoryNameErrorMessage = 'Category name is required'
            if (!this.newCategory.name || this.newCategory.name === '') {
                dataIsValid = false
                $("#category-name").addClass('is-invalid')
            } else {
                $("#category-name").removeClass('is-invalid')
            }

            if (dataIsValid) {
                let payload = {
                    store_id: this.currentUser.store_id,
                    user_id: this.currentUser.id,
                    name: this.newCategory.name,
                }

                this.addingNewCategory = true
                axios.post(`${this.getCategoriesBaseUrl}/add`, payload).then(response => {
                    this.$store.commit('stock/addCategory', response.data.data)
                    this.newItem.category_id = response.data.data.id

                    this.addingNewCategory = false
                    this.showAddCategoryDialog = false
                    this.$message.success('Category added successfully')
                }).catch(error => {
                    this.addingNewCategory = false

                    if (error.response && error.response.status === 422) {
                        this.categoryNameErrorMessage = 'A category with this name already exists'
                        $("#category-name").addClass('is-invalid')
                        this.$message.error(this.categoryNameErrorMessage)
                    } else {
                        this.$message.error('An error was encountered. Please try again')
                    }
                })
            } else {
                this.$message.warning('Please fill all fields')
            }
        },

        addNewSubCategory(e) {
            e.preventDefault()

            // Validation
            let dataIsValid = true
            this.subCategoryNameErrorMessage = 'Sub category name is required'
            if (!this.newSubCategory.name || this.newSubCategory.name === '') {
                dataIsValid = false
                $("#subcategory-name").addClass('is-invalid')
            } else {
                $("#subcategory-name").removeClass('is-invalid')
            }

            if (!this.newSubCategory.category_id) {
                dataIsValid = false
                $("#subcategory-category-id").css('display', 'block')
            } else {
                $("#subcategory-category-id").css('display', 'none')
            }

            if (dataIsValid) {
                let payload = {
                    store_id: this.currentUser.store_id,
                    user_id: this.currentUser.id,
                    name: this.newSubCategory.name,
                    category_id: this.newSubCategory.category_id
                }

                this.addingNewSubCategory = true
                axios.post(`${this.getSubCategoriesBaseUrl}/add`, payload).then(response => {
                    this.$store.commit('stock/addSubCategory', response.data.data)
                    this.newItem.sub_category_id = response.data.data.id

                    this.addingNewSubCategory = false
                    this.showAddSubCategoryDialog = false
                    this.$message.success('Sub category added successfully')
                }).catch(error => {
                    this.addingNewSubCategory = false

                    if (error.response && error.response.status === 422) {
                        this.subcategoryNameErrorMessage = 'A sub category with this name already exists'
                        $("#subcategory-name").addClass('is-invalid')
                        this.$message.error(this.subcategoryNameErrorMessage)
                    } else {
                        this.$message.error('An error was encountered. Please try again')
                    }
                })
            } else {
                this.$message.warning('Please fill all fields')
            }
        },
    },
}
</script>

<style>
.checkout-total {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 35px;
}
</style>
