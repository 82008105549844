<script>
import {mapState} from "vuex";

export default {
    name: "OrdersList",

    data() {
        return {
            orders: [],
            ordersCount: 0,
            fetchingOrders: false,
            errorFetchingOrders: false,
            filters: {
                page: 1
            },

            customers: [],
        }
    },

    computed: {
        ...mapState('system', ['currentUser', 'settings']),
        ...mapState('users', ['systemUsers']),
    },

    mounted() {
        this.filters.page = 1
    },

    created() {
        this.fetchOrders(true)

        if (!this.settings || this.empty(this.settings)) {
            this.$store.dispatch('system/getSettings')
        }

        if (!this.systemUsers || this.empty(this.systemUsers)) {
            this.$store.dispatch('users/getUsers')
        }
    },

    methods: {
        fetchOrders(resetPage = false) {
            if (resetPage) {
                this.filters.page = 1
            }

            let payload = {
                ...this.filters,
                ...{store_id: this.currentUser.store_id}
            }

            this.fetchingOrders = true
            axios.get('/api/orders/list', {params: payload}).then(res => {
                this.fetchingOrders = false
                this.orders = res.data.data.content
                this.ordersCount = res.data.data.count

                this.errorFetchingOrders = false
            }).catch(err => {
                this.fetchingOrders = false
                this.errorFetchingOrders = true
            })
        },

        fetchCustomers() {
            axios.get('/api/customers', {params: {business_id: this.currentUser.business_id}}).then(res => {
                this.customers = res.data.data
            }).catch(error => {
                //
            })
        },

        resetOrderFilters() {
            this.filters = {}
            this.filters.page = 1
            this.fetchOrders(true)
        },

        handleOrderActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            if (commandName === 'pay') {
                let order = this.orders[commandIndex]
                window.location.assign(`/orders/${order.id}/pay`)
            }

            if (commandName === 'complete') {
                let order = this.orders[commandIndex]
                this.completeOrder(order)
            }

            if (commandName === 'void') {
                let order = this.orders[commandIndex]
                this.voidOrder(order)
            }

            if (commandName === 'bill') {
                let order = this.orders[commandIndex]
                this.printOrder(order.id)
            }

            if (commandName === 'kitchen') {
                let order = this.orders[commandIndex]
                this.printKitchenOrder(order.id)
            }

            if (commandName === 'items') {
                let order = this.orders[commandIndex]
                this.$store.commit('pos/toggleAddingToExistingOrder', true)
                this.$store.commit('pos/setExistingOrderId', order.id)

                window.location.assign('/orders/create')
            }
        },

        printOrder(orderId) {
            this.$message.info("Preparing to print...")
            axios.get('/api/orders/printer-payload', {params: {order_id: orderId}}).then(res => {
                axios.post('http://printer.test/api/sales/sales-receipt', {sale: JSON.stringify(res.data.data)}).then(res => {
                    this.$message.success("Success!")
                }).catch(error => {
                    this.$message.error('Receipt was not printed')
                    console.log(error)
                })
            }).catch(err => {
                this.$message.error("Error printing customer bill. Please try again.")
            })
        },

        printKitchenOrder(orderId) {
            this.$message.info("Preparing to print...")
            axios.get('/api/orders/printer-payload', {params: {order_id: orderId}}).then(res => {
                axios.post('http://printer.test/api/sales/kitchen-order', {sale: JSON.stringify(res.data.data)}).then(res => {
                    this.$message.success("Success!")
                }).catch(error => {
                    this.$message.error('Receipt was not printed')
                    console.log(error)
                })
            }).catch(err => {
                this.$message.error("Error printing customer bill. Please try again.")
            })
        },

        canCompleteOrder(order) {
            return (order.status !== 'complete') && (order.payment_status === 'fully paid')
        },

        canReceivePayment(order) {
            return (order.status !== 'complete') && (order.payment_status !== 'fully paid')
        },

        completeOrder(order) {
            this.$confirm(
                'This order will be marked as complete. Continue?',
                `Complete Order ${order.display_order_number}`,
                {
                    confirmButtonText: 'Yes, Complete Order',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(() => {
                axios.post('/api/orders/complete', {order_id: order.id})
                    .then(response => {
                        this.fetchOrders()
                        this.$message.success('Order completed successfully')
                    }).catch(error => {
                    this.$message.error('An error was encountered. Please try again')
                })
            }).catch(() => {
                //
            })
        },

        voidOrder(order) {
            this.$confirm(
                'This order will be voided and its payments will be reversed. Continue?',
                `Void Order ${order.display_order_number}`,
                {
                    confirmButtonText: 'Yes, Void Order',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(() => {
                axios.post('/api/orders/void', {order_id: order.id, user_id: this.currentUser.id})
                    .then(response => {
                        this.fetchOrders()
                        this.$message.success('Order voided successfully')
                    }).catch(error => {
                    this.$message.error('An error was encountered. Please try again')
                })
            }).catch(() => {
                //
            })
        },
    },
}
</script>

<template>
    <div class="master-template">
        <div class="master-title-div">
            <h5> Current Orders </h5>
        </div>

        <div class="master-content">
            <div class="data-table-filters card shadow-sm fuatilia-card bg-white">
                <div class="row">
                    <div class="form-group col-md-2 mr-12">
                        <label> Search by order number </label>
                        <input type="text" class="form-control" v-model="filters.order_number" placeholder="Search by order number"
                               @keyup.enter="fetchOrders(true)">
                    </div>

                    <div class="form-group col-md-2 mr-12">
                        <label> Start Date </label>
                        <el-date-picker
                            v-model="filters.start_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                            clearable
                        >
                        </el-date-picker>
                    </div>

                    <div class="form-group col-md-2 mr-12">
                        <label> End Date </label>
                        <el-date-picker
                            v-model="filters.end_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                            clearable
                        >
                        </el-date-picker>
                    </div>

                    <div class="form-group col-md-2 mr-12">
                        <label> Search by customer </label>
                        <el-select size="large" filterable v-model="filters.customer_id" clearable>
                            <el-option v-for="customer in customers" :key="customer.id" :value="customer.id"
                                       :label="customer.name"></el-option>
                        </el-select>
                    </div>

                    <div class="form-group col-md-2">
                        <label> Search by attendant </label>
                        <el-select size="large" filterable v-model="filters.attendant_id" clearable>
                            <el-option v-for="attendant in systemUsers" :key="attendant.id" :value="attendant.id"
                                       :label="attendant.full_name"></el-option>
                        </el-select>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="form-group col-md-2">
                        <label> Search by order status </label>
                        <el-select size="large" filterable v-model="filters.order_status" clearable>
                            <el-option v-for="(status, index) in ['All', 'pending', 'complete']" :key="index" :value="status"
                                       :label="capitalizeEachWord(status)"></el-option>
                        </el-select>
                    </div>

                    <div class="form-group col-md-2">
                        <label> Search by payment status </label>
                        <el-select size="large" filterable v-model="filters.payment_status" clearable>
                            <el-option v-for="(status, index) in ['All', 'fully paid', 'partially paid', 'not paid']" :key="index" :value="status"
                                       :label="capitalizeEachWord(status)"></el-option>
                        </el-select>
                    </div>
                </div>

                <div class="mt-3 d-flex">
                    <el-button style="margin-right: 12px;" type="primary" size="large" @click="fetchOrders(true)"> Search Orders</el-button>
                    <el-button size="large" @click="resetOrderFilters">Reset</el-button>
                </div>
            </div>

            <div class="data-table-summary d-flex justify-content-end">
                <span class="summary"> Showing {{ orders.length }} out of {{ ordersCount }} orders </span>
            </div>

            <div class="data-table">
                <el-skeleton :loading="fetchingOrders" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" v-for="row in 9" :key="row" style="width: 100%; height: 20px; margin-bottom: 20px;"/>
                    </template>

                    <template #default>
                        <el-alert title="An error was encountered while fetching orders. Please reload this page."
                                  type="error" effect="dark" style="margin-bottom: 10px;" v-if="errorFetchingOrders"/>

                        <p v-if="orders.length === 0"> No orders found. </p>

                        <el-table :data="orders" style="width: 100%" v-else>
                            <el-table-column prop="display_order_number" label="Order Number"/>
                            <el-table-column prop="timestamp" label="Order Date"/>
                            <el-table-column label="Order Total">
                                <template #default="scope">
                                    {{ formatAmount(scope.row.order_total) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Customer">
                                <template #default="scope">
                                    {{ scope.row.customer?.name }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="display_items" label="Item(s)"/>
                            <el-table-column label="Order Status">
                                <template #default="scope">
                                    <el-button :type="scope.row.order_status_type" size="small">{{ scope.row.status }}</el-button>
                                </template>
                            </el-table-column>

                            <el-table-column label="Payment Status">
                                <template #default="scope">
                                    <el-button :type="scope.row.payment_status_type" size="small">{{ scope.row.payment_status }}</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column label="Actions">
                                <template #default="scope">
                                    <el-dropdown @command="handleOrderActions">
                                        <el-button type="primary" plain>
                                            Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item :command="'pay-' + scope.$index" v-if="canReceivePayment(scope.row)">
                                                    Receive Payment
                                                </el-dropdown-item>

                                                <el-dropdown-item :command="'complete-' + scope.$index" v-if="canCompleteOrder(scope.row)">
                                                    Complete Order
                                                </el-dropdown-item>

                                                <el-dropdown-item :command="'view-' + scope.$index"> View Order Details</el-dropdown-item>
                                                <el-dropdown-item :command="'bill-' + scope.$index"> Print Customer Bill</el-dropdown-item>
                                                <el-dropdown-item :command="'kitchen-' + scope.$index"> Print Kitchen Order</el-dropdown-item>
                                                <el-dropdown-item :command="'items-' + scope.$index"> Add Order Items</el-dropdown-item>
                                                <el-dropdown-item :command="'void-' + scope.$index"> Void Order</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-skeleton>

                <!-- Pagination -->
                <div class="data-table-pagination d-flex justify-content-end mt-3">
                    <el-pagination background layout="prev, pager, next" :total="ordersCount"
                                   :page-size="10" v-model:current-page="filters.page" @current-change="fetchOrders()" hide-on-single-page/>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
