<template>
    <div id="login" class="w-100">
        <form id="login-form" class="card bg-white shadow mb-2" method="POST" @submit.prevent="submitLoginForm" v-loading="signingIn">

            <div class="form-group">
                <label for="email" class="required"> Email Address </label>
                <input type="email" name="email" id="email" class="form-control" v-model="email">
                <div class="invalid-feedback" id="email-invalid-feedback">{{ loginError }}</div>
            </div>

            <div class="form-group">
                <label for="password" class="required"> Password </label>
                <div class="input-group" id="login-pwd-input-grp">
                    <input :type="passwordIsVisible ? 'text' : 'password'" name="password" id="password" class="form-control" v-model="password">
                    <span class="input-group-text toggle-password" @click="togglePasswordVisibility">
                                            <i class="far fa-eye-slash" v-if="passwordIsVisible"></i>
                                            <i class="far fa-eye" v-else></i>
                                        </span>
                </div>
                <div class="invalid-feedback">
                    Please input your password.
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <a href="#" class="text-primary"> Forgot Password? </a>
            </div>

            <div style="margin-top: 30px;">
                <button @click="submitLoginForm" class="btn btn-radius btn-red mb-4 btn-block" id="login-btn"> Sign in</button>
            </div>

<!--            <div class="d-flex justify-content-end">-->
<!--                <span><span>New here?</span> &nbsp;<a href="/register" class="text-primary fw-600"> Start your free trial today! </a></span>-->
<!--            </div>-->
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
import { mapState } from "vuex";

export default {
    name: "LoginComponent",

    data() {
        return {
            email: null,
            password: null,
            loginError: 'Email address cannot be blank',
            passwordIsVisible: false,
            signingIn: false
        }
    },

    computed: {
        ...mapState('system', ['currentUser'])
    },

    mounted() {
        // Set styles for focus input texts
        $("#password").focus(() => {
            $("#login-pwd-input-grp .toggle-password").addClass('focused')
        })

        $("#password").focusout(() => {
            $("#login-pwd-input-grp .toggle-password").removeClass('focused')
        })

        // Clear state
        this.$store.commit('system/resetNavKeys')
        this.$store.dispatch('system/resetAll')
        this.$store.dispatch('pos/resetAll')
        this.$store.dispatch('stock/resetAll')
        this.$store.dispatch('users/resetAll')
        this.$store.dispatch('procurement/resetAll')
    },

    methods: {
        submitLoginForm(e) {
            e.preventDefault()

            let formIsValid = true

            // Validate

            // Email
            if (!this.email || this.email === '') {
                formIsValid = false

                // Reset email invalid feedback
                this.loginError = 'Email address is required'

                $("#email").addClass('is-invalid')
            } else {
                $("#email").removeClass('is-invalid')
            }

            // Password
            if (!this.password || this.password === '') {
                formIsValid = false
                $("#password").addClass('is-invalid')
            } else {
                $("#password").removeClass('is-invalid')
            }

            // Submit form
            if (formIsValid) {
                this.signingIn  = true
                axios.post('/api/auth/login', {email: this.email, password: this.password}).then(res => {
                    this.$store.commit('system/updateCurrentUser', res.data.data)

                    /**
                     * Reset any saved navigation keys.
                     */
                    this.$store.commit('system/resetNavKeys')

                    this.signingIn  = false

                    return window.location.assign('/pos')
                }).catch(error => {
                    this.signingIn  = false

                    if (error.response) {
                        if (error.response.status === 422) {
                            this.loginError = 'The credentials do not match our records'
                            $("#email").addClass('is-invalid')
                            this.password = null
                        } else {
                            this.$message.error('Please try again')
                        }
                    } else {
                        this.$message.error('Please try again')
                    }
                })
            } else {
                $("#login-btn").css('margin-top', '0')
            }
        },

        togglePasswordVisibility(e) {
            e.preventDefault()

            this.passwordIsVisible = !this.passwordIsVisible
        },
    },
}
</script>

<style>
#login-pwd-input-grp .input-group-text, #signup-pwd-input-grp .input-group-text {
    background-color: white !important;
    border-right: 1px solid #ced4da !important;
    border-top: 1px solid #ced4da !important;
    border-bottom: 1px solid #ced4da !important;
    border-left: none !important;
}

#login-pwd-input-grp .input-group-text.focused, #signup-pwd-input-grp .input-group-text.focused {
    border-right: 1px solid #01358D !important;
    border-top: 1px solid #01358D !important;
    border-bottom: 1px solid #01358D !important;
}

#login-pwd-input-grp .input-group-text.is-invalid, #signup-pwd-input-grp .input-group-text.is-invalid {
    border-right: 1px solid #dc3545 !important;
    border-top: 1px solid #dc3545 !important;
    border-bottom: 1px solid #dc3545 !important;
}

#password {
    border-right: none !important;
}

.toggle-password i {
    color: #ced4da;
    cursor: pointer;
}

.toggle-password.focused i {
    color: #01358D;
}
</style>
