<template>
    <div class="master-template">
        <div class="master-title-div d-flex justify-content-between align-items-center">
            <h5> Recent Sales </h5>

            <div class="actions">
                <el-button plain type="primary" size="large" @click="getRecentSales" class="f-700 f-18"> Reload Recent Sales </el-button>
                <a href="/sales" class="btn btn-primary new-item-btn f-18 fw-700"> View Complete Sale List </a>
            </div>
        </div>

        <div class="master-content">
            <div class="data-table">
                <el-skeleton :loading="gettingSales" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" v-for="row in 9" :key="row"
                                          style="width: 100%; height: 20px; margin-bottom: 20px;"/>
                    </template>
                    <template #default>
                        <el-table :data="sales" style="width: 100%">
                            <el-table-column prop="display_sale_number" label="Sale Number"/>
                            <el-table-column prop="timestamp" label="Sale Date"/>
                            <el-table-column prop="display_sale_total" label="Sale Total"/>
                            <el-table-column prop="display_amount_paid" label="Amount Paid"/>
                            <el-table-column prop="customer" label="Customer"></el-table-column>
                            <el-table-column label="Sold By" prop="seller"></el-table-column>
                            <el-table-column label="Actions">
                                <template #default="scope">
                                    <el-dropdown @command="handleSaleActions">
                                        <el-button type="primary" plain>
                                            Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item :command="'view-' + scope.$index">View Sale Details
                                                </el-dropdown-item>
                                                <el-dropdown-item :command="'reprint-' + scope.$index"> Reprint Sale
                                                    Receipt
                                                </el-dropdown-item>
                                                <el-dropdown-item :command="'void-' + scope.$index">Void Sale
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-skeleton>
            </div>
        </div>

        <!-- Sale Details -->
        <el-dialog
            :title="'Sale Details for Sale ' + activeSale.display_sale_number"
            width="52%"
            v-model="saleDetailsDialogIsActive"
            v-if="activeSale"
        >
            <div>
                <h5 class="f-20 fw-700 mb-2"> Sale Items </h5>
                <el-table :data="activeSale.items" style="width: 100%">
                    <el-table-column prop="item_label" label="Sale Item"/>
                    <el-table-column label="Item Price">
                        <template #default="scope">
                            {{ formatAmount(scope.row.selling_price) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="display_quantity" label="Quantity Sold"/>
                    <el-table-column label="Discount">
                        <template #default="scope">
                            {{ formatAmount(scope.row.discount) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Item Total">
                        <template #default="scope">
                            {{ formatAmount(scope.row.item_total) }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="mt-5">
                <h5 class="f-20 fw-700 mb-2"> Sale Payments </h5>
                <el-table :data="activeSale.payments" style="width: 100%">
                    <el-table-column prop="method" label="Payment method"/>
                    <el-table-column prop="display_amount" label="Payment Amount"/>
                </el-table>
            </div>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-end">
                    <button class="btn btn-primary f-18 fw-700" @click="saleDetailsDialogIsActive = false"> Close </button>
                </div>
            </template>
        </el-dialog>

        <!-- Void Sale Dialog -->
        <el-dialog title="Void Sale" width="30%" v-model="showVoidSaleDialog" v-if="toVoidSale">
            <form method="post" @submit.prevent="voidSale" v-loading="voidingSale" element-loading-text="Voiding sale. Please wait.">
                <div class="form-group">
                    <label for="reason" class="required"> Why are you voiding this sale? </label>
                    <textarea v-model="toVoidSale.reason" id="reason" class="form-control"></textarea>
                    <div class="invalid-feedback"> Please provide a reason </div>
                </div>
            </form>
            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showVoidSaleDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="voidSale"> Void Sale </button>
                </div>
            </template>
        </el-dialog>
        <!-- End Of Void Sale Dialog -->

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import $ from "jquery";

export default {
    name: "RecentSales",

    data() {
        return {
            baseUrl: '/api/sales',
            sales: [],
            gettingSales: false,
            saleDetailsDialogIsActive: false,
            activeSale: null,
            showVoidSaleDialog: false,
            toVoidSale: {},
            voidingSale: false,
        }
    },

    computed: {
        ...mapGetters('system', ['getBasicPayload'])
    },

    created() {
        this.getRecentSales()
    },

    methods: {
        getRecentSales() {
            this.gettingSales = true
            axios.get('/api/sales/recent', {params: this.getBasicPayload}).then(res => {
                this.gettingSales = false
                this.sales = res.data.data
            }).catch(err => {
                this.gettingSales = false
                this.$message.error("An error was encountered. Please try again")
            })
        },

        handleSaleActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            switch (commandName) {
                case 'void':
                    this.toVoidSale = {}
                    this.toVoidSale.id = this.sales[commandIndex].id
                    this.toVoidSale.index = commandIndex
                    this.showVoidSaleDialog = true
                    break

                case 'view':
                    this.activeSale = {}
                    this.activeSale = this.sales[commandIndex]
                    this.saleDetailsDialogIsActive = true
                    break

                case 'reprint':
                    this.activeSale = this.sales[commandIndex]
                    axios.post('http://printer.test/api/sales/sales-receipt', {sale: JSON.stringify(this.activeSale)})
                        .then(res => {
                            //
                        }).catch(error => {
                        this.$message.error('Receipt could not be printed printed')
                    })

                    break
                default:
                    break
            }
        },

        voidSale(e) {
            e.preventDefault()

            if (!this.toVoidSale.reason) {
                $("#reason").addClass('is-invalid')
                return this.$message.error('Please provide a reason')
            } else {
                $("#reason").removeClass('is-invalid')

                this.toVoidSale = {...this.toVoidSale, ...this.getBasicPayload}
                this.voidingSale = true
                axios.post(`${this.baseUrl}/void`, this.toVoidSale).then(res => {
                    this.voidingSale = false
                    this.sales.splice(this.toVoidSale.index, 1)
                    this.$message.success('Sale voided successfully')
                    this.showVoidSaleDialog = false
                }).catch(error => {
                    this.voidingSale = false
                    this.$message.error('An error was encountered. Please try again.')
                })
            }
        },

    },
}
</script>

<style>

</style>
