<template>
    <div class="master-template">
        <div class="master-title-div d-flex justify-content-between align-items-center">
            <h5 class="master-title"> POS Terminal Settings </h5>

            <div class="actions d-flex" v-if="businessHasMultipleStores && can('manage_multiple_stores', currentUser)">
                <el-select size="large" filterable v-model="selectedStoreId" @change="getSettings">
                    <el-option v-for="store in stores" :key="store.id" :value="store.id" :label="store.store_name"></el-option>
                </el-select>
            </div>
        </div>

        <!-- Master Content -->
        <div class="master-content">
            <!-- Data Table -->
            <div class="data-table">
                <el-skeleton :loading="loading" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" style="width: 100%; height: 70vh;"/>
                    </template>
                    <template #default>
                        <div class="settings-main card shadow-sm" v-loading="updating"
                             element-loading-text="Updating Settings">
                            <div class="setting d-flex justify-content-between align-items-center"
                                 v-for="setting in settings" :key="setting.key">
                                <div class="setting-desc-div flex-grow-1">
                                    <h5> {{ setting.label }} </h5>
                                    <p> {{ setting.description }} </p>
                                </div>
                                <div class="setting-options">
                                    <el-radio-group v-model="setting.value" size="large"
                                                    v-if="(setting.selector === 'radio') && (setting.type === 'boolean')">
                                        <el-radio-button :label="true"> Yes</el-radio-button>
                                        <el-radio-button :label="false"> No</el-radio-button>
                                    </el-radio-group>

                                    <el-select
                                        v-model="setting.value"
                                        multiple
                                        default-first-option
                                        filterable
                                        placeholder="Select a variant"
                                        size="large"
                                        v-if="setting.selector === 'multi-list'"
                                    >
                                        <el-option
                                            v-for="variant in setting.options"
                                            :key="variant"
                                            :label="capitalizeEachWord(variant)"
                                            :value="variant"
                                        />
                                    </el-select>

                                    <el-select
                                        v-model="setting.value"
                                        default-first-option
                                        filterable
                                        placeholder="Select an option"
                                        size="large"
                                        v-if="setting.selector === 'select'"
                                    >
                                        <el-option
                                            v-for="option in setting.options"
                                            :key="option"
                                            :label="capitalizeEachWord(option)"
                                            :value="option"
                                        />
                                    </el-select>

                                    <input type="text" class="form-control" v-if="setting.selector === 'input'"
                                           v-model="setting.value">

                                </div>
                            </div>

                            <!-- Button -->
                            <div class="d-flex justify-content-end fw-700 mt-4"
                                 v-if="businessHasMultipleStores && can('manage_multiple_stores', currentUser)">
                                <button class="btn btn-primary action-btn" @click="updateSettings()">
                                    Update For Current Branch ({{ getCurrentStore }})
                                </button>

                                <button class="btn btn-outline-primary action-btn ml-12" @click="updateSettings(true)">
                                    Update For All Stores
                                </button>
                            </div>

                            <div class="d-flex justify-content-end fw-700 mt-4" v-else>
                                <button class="btn btn-primary action-btn" @click="updateSettings()">Update Settings</button>
                            </div>

                        </div>
                    </template>
                </el-skeleton>
            </div>
        </div>

    </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";

export default {
    name: "SalesSettings",

    data() {
        return {
            baseUrl: '/api/settings',
            loading: false,
            updating: false,
            settings: [],
            module: 'sales',
            selectedStoreId: null
        }
    },

    computed: {
        ...mapState('system', ['currentUser', 'stores']),
        ...mapGetters('system', ['getBasicPayload', 'businessHasMultipleStores']),

        getCurrentStore() {
            let store = this.stores.find(_store => _store.id === this.selectedStoreId)
            return store?.store_name
        },
    },

    created() {
        this.getSettings()

        if (this.stores.length === 0) {
            this.$store.dispatch('system/fetchStores')
        }
    },

    mounted() {
        this.selectedStoreId = this.currentUser.store_id
    },

    methods: {
        /**
         * Get system settings first, then saved settings, then match
         */
        getSettings() {
            this.loading = true
            let payload = {module: this.module}
            axios.get(`${this.baseUrl}/system`, {params: payload}).then(async res => {
                this.settings = res.data.data

                // Saved settings
                let savedSettingsPayload = {
                    store_id: this.selectedStoreId ?? this.currentUser.store_id,
                    module: this.module
                }
                let savedSettings = await axios.get(`${this.baseUrl}/saved`, {params: savedSettingsPayload})
                    .then(res => {
                        return res.data.data
                    }).catch(error => {
                        return null
                    })

                // Fill settings
                if (savedSettings) {
                    this.settings.forEach(_setting => {
                        let value = _setting.default_value
                        let matchingSetting = savedSettings.find(savedSetting => savedSetting.setting === _setting.key)
                        if (matchingSetting) {
                            value = matchingSetting.value
                        }

                        _setting.value = this.applySetting(value, _setting.type)
                    })
                }

                this.loading = false
            }).catch(error => {
                this.loading = false
                this.$message.error('An error occurred while loading settings. Please reload the page.')
                console.log(error)
            })
        },

        applySetting(value, type) {
            switch (type) {
                case 'boolean':
                    return parseInt(value) === 1;

                case 'multi-list':
                    return value === "" ? [] : value.split(",")
                default:
                    return value
            }
        },

        updateSettings(updateForAllStores = false) {
            let payload = JSON.parse(JSON.stringify(this.getBasicPayload))
            payload.store_id = this.selectedStoreId ?? this.currentUser.store_id
            payload.settings = this.settings
            payload.module = this.module
            payload.update_for_all = updateForAllStores ? 1 : 0

            payload.settings.forEach(setting => {
                if (setting.type === 'boolean') {
                    setting.db_value = setting.value ? 1 : 0
                } else if (setting.type === 'multi-list') {
                    setting.db_value = (setting.value).join()
                } else {
                    setting.db_value = setting.value
                }
            })

            this.updating = true
            axios.post(`${this.baseUrl}/update`, payload).then(res => {
                this.updating = false
                this.$message.success('Settings updated successfully.')

                this.$store.dispatch('system/getSettings')
            }).catch(error => {
                this.updating = false
                this.$message.error('An error occurred while updating settings. Please try again.')
            })
        },
    },
}
</script>

<style scoped>
#inventory-settings input.has-prefix {
    width: 300px;
}
</style>
