<template>
    <div id="report-modules" class="master-template">
        <div class="master-title-div d-flex justify-content-between">
            <h5> Reports </h5>
        </div>

        <div class="master-content">
            <div class="popular-reports reports-container">
                <h5 class="master-title fw-700"> Popular Reports </h5>

                <div class="d-flex flex-wrap">
                    <el-skeleton :throttle="200" animated :loading="gettingPopularReports">
                        <template #template>
                            <el-skeleton-item variant="rect" v-for="row in 4" :key="row"
                                              style="width: 300px; height: 270px; margin-right: 20px;"/>
                        </template>

                        <template #default>
                            <a :href="report.destination" v-for="(report, index) in popularReports" :key="index">
                                <div class="report card bg-white shadow">
                                    <h5> {{ report.name }} </h5>
                                    <hr>
                                    <p> {{ report.description }} </p>
                                </div>
                            </a>

                            <p v-if="empty(popularReports)"> No reports found. </p>
                        </template>
                    </el-skeleton>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "ReportModules",

    data() {
        return {
            popularReports: [],
            gettingPopularReports: false,
        }
    },

    created() {
        this.getPopularReports()
    },

    mounted() {
        this.modules.forEach(module => {
            module.color = this.getRandomModuleColor()
        })
    },

    methods: {
        getRandomModuleColor() {
            return this.colors[Math.floor(Math.random() * this.colors.length)];
        },

        getPopularReports() {
            this.gettingPopularReports = true
            axios.get('/api/reports/popular').then(res => {
                this.gettingPopularReports = false
                this.popularReports = res.data.data
            }).catch(error => {
                this.gettingPopularReports = false
            })
        },
    },
}
</script>

<style>
#report-modules a, #report-modules a:hover, .reports-container a, .reports-container a:hover {
    text-decoration: none;
}

.report-module {
    margin-bottom: 30px;
    margin-right: 30px;
}

.report-module-icon {
    width: 130px;
    height: 130px;
}

.report-module-icon i {
    font-size: 40px;
}

.report-module-name {
    color: #212529;
}

.report {
    margin: 0 30px 30px 0;
    width: 300px;
    height: 270px;
}

.reports-container a {
    color: #212529;
}

.report h5 {
    font-weight: 700;
}

.report hr {
    height: 5px;
}
</style>
