<script>
import {mapState} from "vuex";
import SvgSymbols from "../../components/shared/SvgSymbols.vue";
import Tip from "../../components/shared/Tip.vue";

export default {
    name: "NotificationsView",
    components: {Tip, SvgSymbols},

    data() {
        return {
            baseUrl: '/api/notifications',
            notifications: [],
            notificationCount: 0,
            fetchingNotifications: false,
            errorFetchingNotifications: false,
            currentPage: 1,
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
    },

    created() {
        this.fetchNotifications()
    },

    methods: {
        fetchNotifications() {
            let payload = {
                store_id: this.currentUser.store_id,
                page: this.currentPage - 1
            }

            this.fetchingNotifications = true
            axios.get(this.baseUrl, {params: payload}).then(response => {
                this.fetchingNotifications = false
                this.errorFetchingNotifications = false
                this.notifications = response.data.data.content
                this.notificationCount = response.data.data.count
            }).catch(() => {
                this.fetchingNotifications = false
                this.errorFetchingNotifications = true
            })
        },

        markNotificationAsRead(notification) {
            this.processingNotifications = true
            axios.post('/api/notifications/read', {id: notification.id}).then(() => {
                this.processingNotifications = false
                this.fetchNotifications()
                this.$message.success('Success!')
            }).catch(() => {
                this.processingNotifications = false
                this.$message.error('An error was encountered. Please try again.')
            })
        },

        markAllNotificationsAsRead() {
            this.processingNotifications = true
            axios.post('/api/notifications/read-all', {store_id: this.currentUser.store_id}).then(() => {
                this.processingNotifications = false
                this.fetchNotifications()
                this.$message.success('Success!')

                this.notificationsAreVisible = false
                this.emitter.emit('all-notifications-read')
            }).catch(() => {
                this.processingNotifications = false
                this.$message.error('An error was encountered. Please try again.')
            })
        },
    },
}
</script>

<template>
    <div class="master-template">
        <div class="master-title-div d-flex justify-content-between align-items-center">
            <h5> Notifications </h5>

            <button class="btn btn-primary new-item-btn" @click="markAllNotificationsAsRead">
                <i class="fas fa-check-double"></i>
                <span> Mark All As Read </span>
            </button>
        </div>

        <div class="master-content">
            <div class="data-table-summary">
                Showing {{ notifications.length }} out of {{ notificationCount }} notifications.
            </div>

            <div class="data-table card shadow-sm bg-white">
                <el-skeleton :loading="fetchingNotifications" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="rect" v-for="row in 7" :key="row"
                                          style="width: 100%; height: 100px; margin-bottom: 20px;"/>
                    </template>

                    <template #default>
                        <div class="master-tip" v-if="errorFetchingNotifications">
                            <svg-symbols></svg-symbols>
                            <tip type="danger">
                                An error was encountered while fetching notifications. Please reload the page, and if the issue persists, contact
                                System
                                Administrator.
                            </tip>
                        </div>

                        <div v-else>
                            <p v-if="notifications.length === 0"> No entries found. </p>

                            <ul v-else style="margin: 0; padding: 0; list-style-type: none;">
                                <li class="d-flex flex-column notification" v-for="notification in notifications" :key="notification.id"
                                    :class="{read: notification.read}">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span class="notification-title"> {{ notification.title }} </span>
                                        <span class="notification-timestamp"> {{ notification.timestamp }} </span>
                                    </div>

                                    <p class="notification-message"> {{ notification.message }} </p>
                                </li>
                            </ul>

                            <div class="data-table-pagination d-flex justify-content-end mt-3">
                                <el-pagination background layout="prev, pager, next" :total="notificationCount"
                                               :page-size="5" v-model:current-page="currentPage" @current-change="fetchNotifications"
                                               hide-on-single-page/>
                            </div>
                        </div>
                    </template>
                </el-skeleton>
            </div>
        </div>
    </div>
</template>

<style scoped>
.notification.read .notification-title {
    font-weight: 600;
}

.notification.read .notification-message {
    font-weight: 400;
}
</style>
