<template>
<div class="master-template">
    <div class="master-title d-flex justify-content-between">
        <h5> Sales By Item Report </h5>

        <div class="actions">
            <a href="/reports" class="btn btn-outline-primary new-item-btn">
                <i class="fas fa-backward"></i>Back to Reports
            </a>
        </div>
    </div>

    <div class="master-content">
        <div class="data-table-filters card shadow bg-white">
            <h5> Report Filters </h5>

            <div class="row">
                <div class="form-group col-md-4 has-padding-right">
                    <label> Filter by item </label>
                    <el-select
                        v-model="filters.stock_item_id"
                        default-first-option
                        filterable
                        allow-create
                        size="large"
                        clearable
                    >
                        <el-option
                            v-for="user in stockLevels"
                            :key="user.id"
                            :label="user.label"
                            :value="user.id"
                        />
                    </el-select>
                </div>

                <div class="form-group col-md-4 has-padding-left has-padding-right">
                    <label style="display: block;"> Start Date </label>
                    <el-date-picker size="large"
                                    v-model="filters.start_date"
                                    type="date"
                                    placeholder="Pick a day"
                                    format="MMM D, YYYY"
                                    value-format="YYYY-MM-DD" style="width: 100%;" clearable/>
                </div>

                <div class="form-group col-md-4 has-padding-left">
                    <label style="display: block;"> End Date </label>
                    <el-date-picker size="large"
                                    v-model="filters.end_date"
                                    type="date"
                                    placeholder="Pick a day"
                                    format="MMM D, YYYY"
                                    value-format="YYYY-MM-DD" style="width: 100%;" clearable/>
                </div>
            </div>

            <div class="mt-3 d-flex">
                <button class="btn btn-secondary mr-12" @click="getReport"> Apply Filters</button>
                <el-button size="large" @click="clearFilters">Reset Filters</el-button>
            </div>
        </div>

        <div class="data-table-summary d-flex justify-content-between mt-4 px-1">
            <span class="summary"></span>
            <span class="summary"> Showing {{ report.length }} out of {{ itemCount }} items. </span>
        </div>

        <div class="data-table is-report mt-3">
            <el-table :data="report" style="width: 100%; margin-top: 20px;" v-loading="gettingReport" element-loading-text="Generating report">
                <el-table-column prop="item" label="Item"/>
                <el-table-column prop="buying_price" label="Buying Price"/>
                <el-table-column prop="selling_price" label="Selling Price"/>
                <el-table-column prop="total_stocked_quantity" label="Total Stocked Quantity"/>
                <el-table-column prop="quantity_sold" label="Quantity Sold"/>
                <el-table-column prop="quantity_remaining" label="Quantity Remaining"/>
                <el-table-column prop="total_sales" label="Total Sales"/>
                <el-table-column prop="total_profit" label="Total Profits"/>
            </el-table>
        </div>

        <!-- Pagination -->
        <div class="data-table-pagination d-flex justify-content-end mt-3">
            <el-pagination background layout="prev, pager, next" :total="itemCount"
                           :page-size="10" v-model:current-page="currentPage" @current-change="getReport" hide-on-single-page/>
        </div>
    </div>
</div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
    name: "SalesByItemReport",

    data() {
        return {
            gettingReport: false,
            report: [],
            stockLevels: [],
            itemCount: 0,
            filters: [],
            currentPage: 1
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
        ...mapGetters('system', ['getBasicPayload']),
    },

    created() {
        this.getReport()
        this.getStockLevels()
    },

    mounted() {
        this.filters.start_date = this.getFormattedNow()
        this.filters.end_date = this.getFormattedNow()
    },

    methods: {
        getReport() {
            let payload = {
                store_id: this.currentUser.store_id,
                // business_id: this.currentUser.business.id,
                stock_item_id: this.filters.stock_item_id,
                page: (this.currentPage - 1),
                start_date: this.filters.start_date ?? this.getFormattedNow(),
                end_date: this.filters.end_date ?? this.getFormattedNow(),
                business_type: this.currentUser.business_type
            }

            this.gettingReport = true
            axios.get('/api/reports/sales/sales-by-item-report', {params: payload}).then(res => {
                this.gettingReport = false
                this.report = res.data.data.content
                this.itemCount = res.data.data.count
            }).catch(error => {
                this.gettingReport = false
                this.$message.error('Error generating report. Please reload the page')
            })
        },

        getStockLevels() {
            let payload = {...this.getBasicPayload, ...{can_sell: 0}}
            axios.get(`/api/stock/levels`, {params: payload}).then(response => {
                this.stockLevels = response.data.data
            }).catch(error => {
                // pass
            })
        },

        clearFilters() {
            this.filters = {}
            this.filters.start_date = this.getFormattedNow()
            this.filters.end_date = this.getFormattedNow()
            this.currentPage = 1
            this.getReport()
        },
    },
}
</script>

<style scoped>

</style>
