<script>
import {defineComponent} from 'vue'
import {mapGetters, mapState} from "vuex";
import {CirclePlus} from "@element-plus/icons-vue";
import $ from "jquery";

export default defineComponent({
    name: "POSView",

    data() {
        return {
            updatingStockItems: false,
            scannedCode: null,
            selectedItemId: null,
            stockItems: [],
            finalizingCheckout: false,
            selectedPaymentMethod: 'Cash',
            paymentMethods: ['Cash', 'MPESA', 'Credit', 'Split'],
            draftPayments: [],
            splitPayments: [],
            saleOptions: {
                print_receipt: false,
                backdate_sale: false,
                backdate: null,
                credit_due_date: null
            },

            postingSale: false,
            discountingItem: false,
            discountItem: {},
            showNewCustomerDialog: false,
            newCustomer: {},
            addingCustomer: false,
            customers: [],
            selectedCustomerId: null,
            activeCartItem: null,
            selectCustomerPaymentModalIsActive: false,
            gettingCustomerPayments: false,
            customerPayments: [],
            customerPaymentVerified: false,
            cashTendered: 0,
        }
    },

    computed: {
        ...mapState('system', ['currentUser', 'settings']),
        ...mapState('pos', ['cart']),

        ...mapGetters('system', ['getBasicPayload']),

        CirclePlus() {
            return CirclePlus
        },

        businessAutomaticallyPrintsReceipts() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'PRINT_RECEIPT_BY_DEFAULT')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        businessCanAddCustomers() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'CAN_SELECT_POS_CUSTOMER')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        businessDisplaysStockQuantities() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'SHOW_ITEM_QUANTITIES_IN_POS')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        defaultPaymentMethodIsCash() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'MAKE_CASH_DEFAULT_PAYMENT')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        businessHasEnabledMPESAIntegration() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'ENABLE_MPESA_INTEGRATION')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        cartTotal() {
            let total = 0

            this.cart.forEach(item => {
                total += this.cartItemTotal(item)
            })

            return total
        },

        checkoutChange() {
            let change = 0

            try {
                if (this.selectedPaymentMethod === 'Cash') {
                    change = parseFloat(this.cashTendered) - this.cartTotal
                }

                if (this.selectedPaymentMethod === 'Split') {
                    let cashMethod = this.draftPayments.find(method => method.method === 'Cash')
                    if (cashMethod) {
                        change = cashMethod.amount - this.cartTotal
                    }
                }
            } catch (e) {
                // pass. change remains 0
            }

            if (change < 0) {
                return 0
            }

            return change
        },

        discountTotal() {
            let total = 0

            this.cart.forEach(item => {
                total += parseFloat(item.discount)
            })

            return total
        },

        cartSubTotal() {
            return this.cartTotal + this.discountTotal
        },

        draftPaymentsTotal() {
            let total = 0

            this.draftPayments.forEach(payment => {
                try {
                    total += parseFloat(payment.amount)
                } catch (_) {
                    total += 0
                }
            })

            return total
        },

        getAmountPaid() {
            let amount = 0

            this.draftPayments.forEach(payment => {
                try {
                    if (payment.method !== 'Credit') {
                        amount += parseFloat(payment.amount)
                    }
                } catch (_) {
                    amount += 0
                }
            })

            return amount
        },
    },

    created() {
        this.getPosMetaData()
    },

    mounted() {
        $("#item-code").focus()
    },

    methods: {
        getPosMetaData() {
            this.getStockLevels()

            if (!this.settings || this.empty(this.settings)) {
                this.$store.dispatch('system/getSettings')
            }

            if (!this.customers || this.empty(this.customers)) {
                this.getCustomers()
            } else {
                this.selectedCustomerId = this.customers[0].id
            }
        },

        getStockLevels() {
            this.updatingStockItems = true
            axios.get('/api/stock/levels', {params: {store_id: this.currentUser.store_id}}).then(response => {
                this.updatingStockItems = false
                this.stockItems = response.data.data
            }).catch(error => {
                this.updatingStockItems = false
                this.$message.error('An error was encountered. Please reload the page')
            })
        },

        getCustomers() {
            axios.get('/api/customers', {params: this.getBasicPayload}).then(res => {
                this.customers = res.data.data
            }).catch(error => {
            })
        },

        determineItemLabel(item) {
            if (this.businessDisplaysStockQuantities) {
                return `${item.label} (${item.display_units} available)`
            }

            return item.label
        },

        addItemByCode(_) {
            let item = this.stockItems.find(_item => _item.item_code === this.scannedCode)
            if (item) {
                this.addItemToCart(item)
            }
            this.scannedCode = null
            $("#item-code").focus()
        },

        addItemById(_) {
            let item = this.stockItems.find(_item => _item.id === this.selectedItemId)
            if (item) {
                if (item.has_selling_variants) {
                    this.activeCartItem = item
                    return this.showingVariantsSelectionModal = true
                }

                this.addItemToCart(item)
            }

            this.selectedItemId = null
            $("#item-code").focus()
        },

        addItemToCart(item) {
            // Check if item is already cart
            let existingCartItemIndex = this.cart.findIndex(_item => _item.stock_item_id === item.id)
            if (existingCartItemIndex !== -1) {
                return this.$store.commit('pos/updateItemQuantity', {
                    index: existingCartItemIndex,
                    quantity: (this.cart[existingCartItemIndex].quantity) + 1
                })
            }

            this.$store.commit('pos/addCartItem', {
                stock_item_id: item.id,
                variant_id: null,
                item_code: item.item_code,
                label: item.label,
                buying_price: item.buying_price,
                initial_price: item.selling_price, // For the purposes of editing
                price: item.selling_price,
                quantity: 1,
                discount: 0,
                active_identifier: item.active_identifier
            })
        },

        updateItemQuantity(value, itemIndex) {
            if (this.numberIsValid(value) && this.numberIsNotNegative(value)) {
                this.$store.commit('pos/updateItemQuantity', {
                    index: itemIndex,
                    quantity: value
                })
            }
        },

        fillDraftPayments() {
            this.draftPayments = []

            if (this.selectedPaymentMethod === 'Split') {
                this.paymentMethods.forEach(method => {
                    if (method !== 'Split') {
                        this.draftPayments.push({
                            method: method,
                            amount: 0
                        })
                    }
                })
            } else {
                this.draftPayments.push({
                    method: this.selectedPaymentMethod,
                    amount: this.cartTotal
                })
            }
        },

        cartItemTotal(item) {
            try {
                let priceToUse = parseFloat(item.initial_price)
                if (parseFloat(item.price) > priceToUse) {
                    priceToUse = parseFloat(item.price)
                }

                let subTotal = priceToUse * parseFloat(item.quantity)
                return subTotal - parseFloat(item.discount)
            } catch (e) {
                return 0
            }
        },

        removeCartItem(index) {
            this.$store.commit('pos/removeCartItem', index)
        },

        promptCheckout() {
            this.selectedPaymentMethod = 'MPESA'
            if (this.defaultPaymentMethodIsCash) {
                this.selectedPaymentMethod = 'Cash'
            }

            this.draftPayments = []
            this.draftPayments.push({
                method: this.selectedPaymentMethod,
                amount: this.cartTotal
            })

            this.selectedCustomerId = this.customers[0]?.id
            if (this.businessAutomaticallyPrintsReceipts) {
                this.saleOptions.print_receipt = true
            }

            $("#post-sale-btn").attr("disabled", false)
            this.finalizingCheckout = true
        },

        postSale() {
            // An attempt to prevent double clicks which lead to double sale posting
            $("#post-sale-btn").attr("disabled", true)

            // Check for customer
            if (!this.selectedCustomerId) {
                this.selectedCustomerId = this.customers[0]?.id
            }

            this.cart.forEach(item => {
                item.item_total = this.cartItemTotal(item)
            })

            /**
             * Alert if there's an underpayment, or overpayment
             */
            this.draftPayments.forEach(_payment => {
                try {
                    _payment.amount = parseFloat(_payment.amount)
                } catch (_) {
                    _payment.amount = 0
                }
            })

            if (this.draftPaymentsTotal < this.cartTotal) {
                $("#post-sale-btn").attr("disabled", false)
                return this.$message.error('You have an underpayment. Please fill all payments')
            }

            if (this.draftPaymentsTotal > this.cartTotal) {
                let draftPayment = this.draftPayments.find(_payment => _payment.method === 'Cash')
                if (draftPayment) {
                    draftPayment.amount -= this.checkoutChange
                }
            }

            // Build payload
            let payload = {
                store_id: this.currentUser.store_id,
                user_id: this.currentUser.id,
                customer_id: this.selectedCustomerId,
                sale_total: this.cartTotal,
                items: this.cart,
                payments: this.draftPayments,
                print_receipt: this.saleOptions.print_receipt ? 1 : 0,
                backdated: this.saleOptions.backdate_sale ? 1 : 0,
                backdate: this.saleOptions.backdate,
                due_date: this.saleOptions.due_date,
            }

            this.postingSale = true
            axios.post('/api/pos/sales/new', payload).then(response => {
                this.postingSale = false
                this.finalizingCheckout = false
                $("#item-code").focus()
                this.$message.success('Sale posted successfully')

                // Reset
                this.$store.commit('pos/clearCart')

                // Print receipt
                if (this.saleOptions.print_receipt) {
                    this.$message('Waiting for receipt to print')
                    axios.post('http://printer.test/api/sales/sales-receipt', {sale: JSON.stringify(response.data.data)}).then(res => {
                        //
                    }).catch(error => {
                        this.$message.error('Receipt was not printed')
                    })
                }

                setTimeout(() => {
                    this.draftPayments = []
                    this.saleOptions.backdate_sale = false
                    this.selectedCustomerId = this.customers[0].id
                    this.saleOptions.print_receipt = false
                    this.saleOptions.backdate = null
                    this.saleOptions.due_date = null
                    this.customerPaymentVerified = false
                    this.customerPayments = []
                }, 1500)
            }).catch(error => {
                this.postingSale = false
                $("#post-sale-btn").attr("disabled", false)
                if (error && error.response && error.response.status === 422) {
                    return
                }

                this.$message.error('An error was encountered. Please try again')
            })
        },

        goToDashboard() {
            window.location.assign('/dashboard')
        },

        promptCartItemOptions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]
            let cartItem = this.cart[commandIndex]
            this.discountItem = {
                item_index: commandIndex,
                item_id: cartItem.stock_item_id,
                label: cartItem.label,
                discount: cartItem.discount,
                price: cartItem.price,
                initial_price: cartItem.initial_price,
            }
            this.discountingItem = true
        },

        applyDiscount(e) {
            e.preventDefault()

            let discount = this.discountItem.discount
            let newPrice = this.discountItem.price
            let cartItem = this.cart[this.discountItem.item_index]
            if (this.variableIsNotNull(discount) && this.numberIsValid(discount) && (parseFloat(discount) > 0)) {
                this.discountingItem = false
                return this.$store.commit('pos/updateItemDiscount', {
                    index: this.discountItem.item_index,
                    discount: discount
                })
            }

            if (this.variableIsNotNull(newPrice) && this.numberIsValid(newPrice)) {
                this.$store.commit('pos/updateItemPrice', {
                    index: this.discountItem.item_index,
                    price: newPrice
                })

                let shouldBeItemTotal = parseFloat(cartItem.quantity) * parseFloat(cartItem.initial_price)
                let actualItemTotal = parseFloat(cartItem.quantity) * parseFloat(newPrice)
                let newDiscount = shouldBeItemTotal - actualItemTotal
                if (newDiscount > 0) {
                    this.$store.commit('pos/updateItemDiscount', {
                        index: this.discountItem.item_index,
                        discount: newDiscount
                    })
                } else {
                    this.$store.commit('pos/updateItemDiscount', {
                        index: this.discountItem.item_index,
                        discount: 0
                    })
                }
            } else {
                this.$store.commit('pos/updateItemPrice', {
                    index: this.discountItem.item_index,
                    price: this.discountItem.initial_price
                })
            }

            this.discountingItem = false
        },

        promptAddCustomer() {
            this.newCustomer = {}
            this.showNewCustomerDialog = true
        },

        onNewCustomerPhoneInput(phone, phoneObject, input) {
            if (phoneObject?.formatted) {
                this.newCustomer.customer_number = phoneObject.formatted
            }
        },

        addCustomer() {
            if (!this.newCustomer.customer_name) {
                $("#new-customer-name").addClass('is-invalid')
                return this.$message.error('Customer name is required')
            } else {
                $("#new-customer-name").removeClass('is-invalid')
            }

            this.addingCustomer = true
            axios.post('/api/customers/new', {...this.newCustomer, ...this.getBasicPayload}).then(res => {
                this.addingCustomer = false
                this.getCustomers()
                this.selectedCustomerId = res.data.data.id

                this.$message.success('Customer added successfully')
                this.showNewCustomerDialog = false
            }).catch(error => {
                this.addingCustomer = false
                this.$message.error('Error adding customer. Please try again')
            })
        },

        promptSelectCustomerPayment() {
            this.getCustomerPayments()
            this.selectCustomerPaymentModalIsActive = true
        },

        getCustomerPayments() {
            this.gettingCustomerPayments = true
            axios.get('/api/payments/latest', {params: {business_id: this.getBasicPayload.business_id}})
                .then(res => {
                    this.gettingCustomerPayments = false
                    this.customerPayments = res.data.data
                }).catch(err => {
                this.gettingCustomerPayments = false
                this.$message.error("Error getting customer payments")
            })
        },

        verifyCustomerPayment(payment) {
            let mpesaPayment = this.draftPayments.find(_payment => _payment.method === 'MPESA')
            if (mpesaPayment) {
                let paymentIsLess = payment.amount < parseFloat(mpesaPayment.amount)
                if (paymentIsLess) {
                    return this.$message.error("The selected payment is less than the expected MPESA payment")
                }
            }

            this.selectCustomerPaymentModalIsActive = false
            this.customerPaymentVerified = true
        },

        showCurrentShiftModal() {},
    }
})
</script>

<template>
    <div class="master-template d-flex flex-column justify-content-between" id="point-of-sale" style="">
        <div class="flex-grow-1">
            <!-- Item search -->
            <div class="d-flex w-100 justify-content-between align-items-center">
                <div class="flex-grow-1 d-flex">
                    <div class="form-group mr-12" style="width: 35%;">
                        <label for="item-code"> Scan Code </label>
                        <input type="text" class="form-control" v-model="scannedCode" @keyup.enter="addItemByCode"
                               placeholder="Scan item code" id="item-code">
                    </div>

                    <div class="form-group" style="width: 35%;">
                        <label for="item-code" style="display: block;"> Search item name </label>
                        <el-select v-model="selectedItemId" @change="addItemById" filterable size="large"
                                   placeholder="Search item name"
                                   style="width: 100% !important;" :loading="updatingStockItems"
                                   element-loading-text="Loading stock items. Please wait">
                            <el-option v-for="item in stockItems"
                                       :key="item.id"
                                       :label="determineItemLabel(item)"
                                       :value="item.id" style="font-size: 18px !important;"></el-option>
                        </el-select>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="form-group mr-12">
                        <label class="display-block">&nbsp;</label>
                        <el-button type="primary" size="large" plain @click="getStockLevels"
                                   style="font-size: 16px; font-weight: 700;">
                            <i class="fas fa-sync-alt" style="margin-right: 8px;"></i> Reload Stock Items
                        </el-button>
                    </div>

                    <div class="form-group">
                        <label class="display-block">&nbsp;</label>
                        <el-button type="primary" size="large" @click="showCurrentShiftModal" style="font-size: 16px; font-weight: 700;">
                            <i class="fas fa-user-clock" style="margin-right: 8px;"></i> Current Shift
                        </el-button>
                    </div>
                </div>
            </div>

            <!-- Cart Items -->
            <div class="mt-3 position-relative w-100" style="overflow-y: auto;" v-loading="updatingStockItems"
                 element-loading-text="Updating stock items. Please wait">
                <el-table :data="cart" empty-text="No items added to cart" style="width: 100%;">
                    <el-table-column prop="item_code" label="Item Code"></el-table-column>
                    <el-table-column prop="label" label="Item Name"></el-table-column>
                    <el-table-column label="Quantity">
                        <template #default="scope">
                            <input type="text" v-model="cart[scope.$index].quantity"
                                   @input="updateItemQuantity($event, scope.$index)"
                                   class="form-control">
                        </template>
                    </el-table-column>
                    <el-table-column label="Price">
                        <template #default="scope">
                            {{ formatAmount(scope.row.price) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Discount">
                        <template #default="scope">
                            {{ formatAmount(scope.row.discount) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Item Total">
                        <template #default="scope">
                            {{ formatAmount(cartItemTotal(scope.row)) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Actions">
                        <template #default="scope">
                            <div class="d-flex align-items-center w-100">
                                <el-dropdown @command="promptCartItemOptions">
                                    <el-button type="primary" plain style="margin-right: 25px;">
                                        <span class="fw-700 f-16"> More </span> <i class="fas fa-caret-down ml-2"
                                                                                   style="margin-left: 10px;"></i>
                                    </el-button>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item :command="'discount-' + scope.$index">
                                                Discount/Upsell Item
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>

                                <i class="fas fa-trash"
                                   style="color: darkred; cursor:pointer;" @click="removeCartItem(scope.$index)"></i>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <!-- POS Total -->
        <div id="pos-actions" class="d-flex justify-content-between align-items-center bg-white">
            <span id="cart-total">Sale Total: {{ formatAmount(cartTotal) }}</span>

            <button class="btn btn-primary h-45 fw-700 f-20" id="checkout-btn"
                    @click="promptCheckout" :disabled="cart.length === 0"> GO TO CHECKOUT
            </button>
        </div>

        <!-- Checkout -->
        <el-dialog
            title="Checkout"
            width="35%"
            v-model="finalizingCheckout"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            top="10vh"
        >
            <div class="w-100" v-loading="postingSale">
                <div class="d-flex justify-content-center align-items-center checkout-total">
                    Sale total: {{ formatAmount(cartTotal) }}
                </div>

                <div id="payment-method">
                    <h5 class="pos-title"> Payment Method </h5>

                    <el-radio-group v-model="selectedPaymentMethod" @change="fillDraftPayments">
                        <el-radio v-for="method in paymentMethods" :key="method" :label="method" size="large" border>
                            {{ method }}
                        </el-radio>
                    </el-radio-group>

                    <div class="form-group mt-2" v-if="selectedPaymentMethod === 'Cash'">
                        <label class="f-16"> Cash tendered </label>
                        <input type="text" style="width: 60%;" class="form-control" v-model="cashTendered">
                    </div>

                    <div class="row mt-2" v-if="selectedPaymentMethod === 'Split'">
                        <div class="form-group col-4" v-for="draftPayment in draftPayments">
                            <label class="f-16"> {{ draftPayment.method }} </label>
                            <input type="text" class="form-control" v-model="draftPayment.amount">
                        </div>
                    </div>
                </div>

                <!--                <div class="row align-items-center"-->
                <!--                     v-for="draftPayment in draftPayments"-->
                <!--                     :key="draftPayment.method" style="margin-bottom: 20px !important;">-->
                <!--                    <div class="col-3">-->
                <!--                        <h5 class="draft-payment-method fw-600"> {{ draftPayment.method }} </h5>-->
                <!--                    </div>-->

                <!--                    <div class="col-9 d-flex"-->
                <!--                         v-if="(draftPayment.method === 'MPESA') && businessHasEnabledMPESAIntegration">-->
                <!--                        <input type="text" class="form-control" v-model="draftPayment.amount"-->
                <!--                               style="margin-right: 12px;">-->
                <!--                        <el-button type="success" plain size="large" v-if="customerPaymentVerified"-->
                <!--                                   style="height: 42px;">-->
                <!--                            <i class="fas fa-check-double" style="margin-right: 5px;"></i> Verified-->
                <!--                        </el-button>-->
                <!--                        <el-button type="primary" plain size="large" @click="promptSelectCustomerPayment" v-else-->
                <!--                                   style="height: 42px;">-->
                <!--                            Select Customer Payment-->
                <!--                        </el-button>-->
                <!--                    </div>-->

                <!--                    <div class="col-9" v-else>-->
                <!--                        <input type="text" class="form-control" v-model="draftPayment.amount">-->
                <!--                    </div>-->

                <!--                    <div class="row col-12 d-flex justify-content-end" style="margin-top: 12px !important;"-->
                <!--                         v-if="(draftPayment.method === 'Credit') && (parseFloat(draftPayment.amount ) > 0)">-->
                <!--                        <div class="col-6">-->
                <!--                            <el-date-picker-->
                <!--                                v-model="draftPayment.due_date"-->
                <!--                                type="date"-->
                <!--                                placeholder="Pick a due date"-->
                <!--                                format="MMM D, YYYY"-->
                <!--                                value-format="YYYY-MM-DD"-->
                <!--                                size="large"-->
                <!--                            >-->
                <!--                            </el-date-picker>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->

                <div class="mt-4 w-100" v-if="businessCanAddCustomers">
                    <div class="d-flex justify-content-between align-items-center w-100 mb-2">
                        <h5 class="pos-title"> Customer </h5>
                        <div>
                            <el-button plain type="primary" size="large" :icon="CirclePlus" @click="promptAddCustomer">
                                New Customer
                            </el-button>
                        </div>
                    </div>

                    <el-select
                        v-model="selectedCustomerId"
                        default-first-option
                        filterable
                        placeholder="Select a customer"
                        size="large"
                    >
                        <el-option
                            v-for="customer in customers"
                            :key="customer.id"
                            :label="customer.name"
                            :value="customer.id"
                        />
                    </el-select>
                </div>

                <div class="d-flex flex-column w-100 mt-4">
                    <h5 class="pos-title"> Sale Options </h5>

                    <el-checkbox v-model="saleOptions.print_receipt" label="Print receipt" size="large"
                                 style="width: 100%; margin-bottom: 15px;"></el-checkbox>
                    <el-checkbox v-model="saleOptions.backdate_sale" label="Backdate this sale" size="large"
                                 style="width: 100%;"></el-checkbox>
                    <div class="backdate mt-2" v-if="saleOptions.backdate_sale">
                        <el-date-picker
                            v-model="saleOptions.backdate"
                            type="date"
                            size="large"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                        >
                        </el-date-picker>
                    </div>
                </div>

                <div class="w-100 mt-2" id="checkout-summary">
                    <div class="d-flex w-100 justify-content-between">
                        <span class="label"> Sale Total </span>
                        <span class="total"> {{ formatAmount(cartTotal) }} </span>
                    </div>

                    <div class="d-flex w-100 justify-content-between">
                        <span class="label"> Amount Paid </span>
                        <span class="total"> {{ formatAmount(getAmountPaid) }} </span>
                    </div>

                    <div class="d-flex w-100 justify-content-between">
                        <span class="label"> Change </span>
                        <span class="total"> {{ formatAmount(checkoutChange) }} </span>
                    </div>
                </div>
            </div>
            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button type="info" plain @click="finalizingCheckout = false" size="large"> Cancel</el-button>
                    <button class="btn btn-primary f-20 fw-700" @click="postSale" id="post-sale-btn"> Post Sale</button>
                </div>
            </template>
        </el-dialog>

        <!-- Discounting -->
        <el-dialog :title="'Discount ' + discountItem.label" width="30%" v-model="discountingItem" v-if="discountItem">
            <p class="f-18"> Please choose one of the following options </p>
            <form method="post" @submit.prevent="applyDiscount">
                <div class="form-group">
                    <label for="discount"> Discount Amount </label>
                    <input type="text" class="form-control" v-model="discountItem.discount">
                </div>

                <div class="form-group">
                    <label for="new-price"> New Price </label>
                    <input type="text" class="form-control" v-model="discountItem.price">
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="discountingItem = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary fw-700 f-18" @click="applyDiscount"> Apply Discount</button>
                </div>
            </template>
        </el-dialog>

        <!-- New Customer -->
        <el-dialog title="New Customer" width="30%" v-model="showNewCustomerDialog">
            <form method="post" v-loading="addingCustomer" element-loading-text="Saving customer"
                  @submit.prevent="addCustomer">
                <div class="form-group">
                    <label for="new-customer-name" class="required"> Customer Name </label>
                    <input type="text" class="form-control" id="new-customer-name" v-model="newCustomer.customer_name">
                    <div class="invalid-feedback"> Customer name is required</div>
                </div>

                <div class="form-group">
                    <label for="new-customer-email"> Customer Email </label>
                    <input type="email" class="form-control" id="new-customer-email"
                           v-model="newCustomer.customer_email">
                </div>

                <div class="form-group">
                    <label for="new-customer-email"> Customer Number </label>
                    <vue-tel-input :value="newCustomer.customer_number" @input="onNewCustomerPhoneInput"
                                   mode="international"></vue-tel-input>
                    <input type="hidden" :value="newCustomer.customer_number" name="phone">
                </div>
            </form>
            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showNewCustomerDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addCustomer"> Save Customer</button>
                </div>
            </template>
        </el-dialog>

        <!-- Customer Payments -->
        <el-dialog
            title="Customer Payment"
            width="50%"
            v-model="selectCustomerPaymentModalIsActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            top="8vh"
        >
            <div class="mb-3 d-flex justify-content-between align-items-center">
                <p class="f-18"> Please select a transaction </p>
                <el-button type="primary" plain @click="getCustomerPayments" size="large"> Refresh</el-button>
            </div>

            <div class="data-table" v-loading="gettingCustomerPayments" element-loading-text="Refreshing...">
                <el-table :data="customerPayments" style="width: 100%;" empty-text="No transactions recorded"
                          highlight-current-row>
                    <el-table-column prop="timestamp" label="Timestamp"></el-table-column>
                    <el-table-column prop="display_amount" label="Amount"></el-table-column>
                    <el-table-column prop="sender_full_name" label="Sender"></el-table-column>
                    <el-table-column prop="sender_phone_number" label="Phone number"></el-table-column>
                    <el-table-column prop="payment_reference" label="Reference"></el-table-column>
                    <el-table-column label="">
                        <template #default="scope">
                            <el-button type="primary" plain @click="verifyCustomerPayment(scope.row)"> Select
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-end">
                    <el-button type="info" plain @click="selectCustomerPaymentModalIsActive = false" size="large">
                        Cancel
                    </el-button>
                </div>
            </template>
        </el-dialog>

    </div>
</template>

<style>
#point-of-sale {
    height: calc(100vh - 120px);
    position: relative;
    margin-bottom: -40px;
}

#point-of-sale input.form-control {
    line-height: 1.8 !important;
}

#point-of-sale .el-select-dropdown__item {
    font-size: 18px !important;
}

.el-table th.el-table__cell > .cell {
    font-size: 17px !important;
}

.el-table td.el-table__cell div {
    font-size: 16px !important;
}

.el-checkbox.el-checkbox--large .el-checkbox__label {
    font-size: 18px !important;
}

.el-radio .el-radio__label {
    font-size: 18px !important;
}

#checkout-summary {
    border-top: 1px solid rgba(0, 0, 0, .125);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    padding: 12px 0;
}

#checkout-summary span {
    font-weight: 700;
    font-size: 22px;
}

.pos-title {
    font-weight: 700;
    font-size: 18px;
}

#pos-actions {
    padding: 20px 25px;
    margin-left: -42px;
    margin-right: -40px;
}

#pos-actions #cart-total {
    font-weight: 800;
    font-size: 45px;
}

.checkout-total {
    font-weight: 900;
    font-size: 35px;
    margin-bottom: 10px;
}
</style>
