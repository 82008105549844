<script>
import {mapState} from "vuex";

export default {
    name: "PayOrder",

    data() {
        return {
            paymentAccounts: [],
            fetchingPaymentAccounts: false,
            errorFetchingAccounts: false,
            processing: false,

            selectedPaymentMethodId: null,
            draftPayments: [],
            splitPayments: [],

            cashTendered: 0,
            paymentDate: null
        }
    },

    props: ['order'],

    computed: {
        ...mapState('system', ['currentUser']),

        currentOrder() {
            return JSON.parse(this.order)
        },

        getAmountPaid() {
            let amount = 0

            this.draftPayments.forEach(payment => {
                try {
                    if (payment.method !== 'Credit') {
                        amount += parseFloat(payment.amount)
                    }
                } catch (_) {
                    amount += 0
                }
            })

            return amount
        },

        checkoutChange() {
            let change = 0

            try {
                let paymentAccount = this.paymentAccounts.find(acc => acc.id === this.selectedPaymentMethodId)
                if (paymentAccount.name === 'Cash') {
                    change = parseFloat(this.cashTendered) - this.currentOrder.amount_due
                }

                if (paymentAccount.name === 'Split') {
                    let cashMethod = this.draftPayments.find(method => method.method === 'Cash')
                    if (cashMethod) {
                        change = cashMethod.amount - this.currentOrder.amount_due
                    }
                }
            } catch (e) {
                // pass. change remains 0
            }

            if (change < 0) {
                return 0
            }

            return change
        },

        selectedPaymentAccount() {
            return this.paymentAccounts.find(acc =>acc.id === this.selectedPaymentMethodId)
        },
    },

    created() {
        this.fetchPaymentAccounts()
    },

    mounted() {
        this.paymentDate = this.getFormattedNow()
    },

    methods: {
        fetchPaymentAccounts() {
            this.fetchingPaymentAccounts = true
            axios.get('/api/transactions/payment-accounts', {params: {store_id: this.currentUser.store_id}}).then(res => {
                this.fetchingPaymentAccounts = false
                this.paymentAccounts = res.data.data
                this.paymentAccounts.push({
                    id: 0,
                    name: 'Split'
                })
                this.fillInitialMetaData()

                this.errorFetchingAccounts = false
            }).catch(err => {
                this.fetchingPaymentAccounts = false
                this.errorFetchingAccounts = true
            })
        },

        fillInitialMetaData() {
            this.selectedPaymentMethodId = this.paymentAccounts[0].id
            let paymentAccount = this.paymentAccounts.find(acc => acc.id === this.selectedPaymentMethodId)

            this.draftPayments = []
            this.cashTendered = this.currentOrder.amount_due
            this.draftPayments.push({
                id: paymentAccount.id,
                method: paymentAccount.name,
                amount: this.cashTendered,
            })
        },

        fillDraftPayments() {
            this.draftPayments = []

            if (this.selectedPaymentMethodId === 0) {
                this.paymentAccounts.forEach(acc => {
                    if (acc.name !== 'Split') {
                        this.draftPayments.push({
                            id: acc.id,
                            method: acc.name,
                            amount: 0
                        })
                    }
                })
            } else {
                let paymentAccount = this.paymentAccounts.find(acc => acc.id === this.selectedPaymentMethodId)
                this.draftPayments.push({
                    id: paymentAccount.id,
                    method: paymentAccount.name,
                    amount: this.cashTendered,
                })
            }
        },

        addPayment() {
            let payload = {
                order_id: this.currentOrder.id,
                user_id: this.currentUser.id,
                payments: this.draftPayments,
                payment_date: this.paymentDate,
            }
            this.processing = true
            axios.post(`/api/orders/pay`, {payload: JSON.stringify(payload)}).then(() => {
                this.processing = false
                this.$message.success("Payment received successfully")

                return window.location.assign('/orders')
            }).catch(error => {
                this.processing = false
                this.$message.error("An error was encountered while processing payment. Please try again.")
            })
        },
    },
}
</script>

<template>
    <div class="master-template d-flex justify-content-center align-items-center">
        <div class="card" id="order-payment-div" v-loading="processing">
            <h5 class="fw-700 f-22"> Payment For Order {{ currentOrder.display_order_number }} </h5>

            <div class="w-100">
                <div class="d-flex justify-content-center align-items-center checkout-total">
                    Order total: {{ formatAmount(currentOrder.amount_due) }}
                </div>

                <div class="mt-2 mb-3">
                    <div class="form-group">
                        <h5 class="pos-title"> Payment Date </h5>
                        <el-date-picker
                            v-model="paymentDate"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>
                </div>

                <div id="payment-method">
                    <h5 class="pos-title"> Payment Method </h5>

                    <el-radio-group v-model="selectedPaymentMethodId" @change="fillDraftPayments">
                        <el-radio v-for="account in paymentAccounts" :key="account.name" :label="account.id" size="large" border>
                            {{ account.name }}
                        </el-radio>
                    </el-radio-group>

                    <div class="form-group mt-2" v-if="selectedPaymentMethodId !== 0">
                        <label class="f-16"> Payment Amount </label>
                        <input type="text" style="width: 60%;" class="form-control" v-model="cashTendered" @input="fillDraftPayments">
                    </div>

                    <div class="row mt-2" v-if="selectedPaymentMethodId === 0">
                        <div class="form-group col-4" v-for="draftPayment in draftPayments">
                            <label class="f-16"> {{ draftPayment.method }} </label>
                            <input type="text" class="form-control" v-model="draftPayment.amount">
                        </div>
                    </div>
                </div>

                <div class="w-100 mt-2" id="checkout-summary">
                    <div class="d-flex w-100 justify-content-between">
                        <span class="label"> Order Total </span>
                        <span class="total"> {{ formatAmount(currentOrder.amount_due) }} </span>
                    </div>

                    <div class="d-flex w-100 justify-content-between">
                        <span class="label"> Amount Paid </span>
                        <span class="total"> {{ formatAmount(getAmountPaid) }} </span>
                    </div>

                    <div class="d-flex w-100 justify-content-between">
                        <span class="label"> Change </span>
                        <span class="total"> {{ formatAmount(checkoutChange) }} </span>
                    </div>
                </div>
            </div>

            <div class="mt-3 d-flex justify-content-between">
                <el-button type="info" plain @click="window.location.assign('/orders')" size="large"> Back to Orders</el-button>
                <button class="btn btn-primary f-20 fw-700" @click="addPayment" id="post-sale-btn"> Add Payment</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
#order-payment-div {
    position: relative;
    width: 40%;
}
</style>
