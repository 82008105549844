<template>
    <div id="activity" class="master-template">
        <h5 class="master-title"> Audit Trail </h5>

        <div class="master-content">
            <el-skeleton :loading="gettingActivity" :throttle="200" animated>
                <template #template>
                    <el-skeleton-item variant="h3" style="width: 100%; height: 120px; margin-bottom: 20px;"/>
                </template>
                <template #default>
                    <div class="data-table-filters card shadow-sm rounded bg-white">
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 class="card-title"> Filters </h5>
                        </div>

                        <div class="row justify-content-between">
                            <div class="form-group col-md-5">
                                <label> Filter by user </label>
                                <el-select
                                    v-model="filters.user"
                                    filterable
                                    placeholder="Search or select a user"
                                    size="large">
                                    <el-option
                                        v-for="user in users"
                                        :key="user.id"
                                        :label="user.name"
                                        :value="user.id"
                                    />
                                </el-select>
                            </div>

                            <div class="form-group col-md-5">
                                <label style="display: block;"> Filter by date </label>
                                <el-date-picker size="large"
                                                v-model="filters.date"
                                                type="datetimerange"
                                                unlink-panels
                                                range-separator="To"
                                                start-placeholder="Start date"
                                                end-placeholder="End date"
                                                format="MMM D, YYYY h:mm A"
                                                value-format="YYYY-MM-DD HH:mm"
                                                :shortcuts="shortcuts" style="width: 100%"/>
                            </div>
                        </div>

                        <div class="d-flex justify-content-end mt-3">
                            <button class="btn btn-outline-danger" style="margin-right: 12px;"> Reset</button>
                            <button class="btn btn-primary"> Filter Activity</button>
                        </div>
                    </div>
                </template>
            </el-skeleton>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "Activity",

    data() {
        return {
            gettingActivity: false,
            activity: [],
            filters: {},
            users: [],

            shortcuts: [
                {
                    text: 'Today',
                    value: new Date(),
                },
                {
                    text: 'Yesterday',
                    value: () => {
                        const date = new Date()
                        date.setTime(date.getTime() - (3600 * 1000 * 24))
                        return date
                    },
                },
            ],
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
    },
}
</script>

<style scoped>

</style>
