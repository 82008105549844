<template>
    <div id="system-users">
        <div class="master-subtitle d-flex justify-content-between align-items-center">
            <h5 class="mb-0"> System Users </h5>

            <div class="actions">
                <button class="btn btn-primary new-item-btn" @click="getSystemUsers">
                    <i class="fas fa-sync"></i>Refresh List
                </button>
                <button class="btn btn-primary new-item-btn" @click="promptNewUser">
                    <i class="fas fa-plus-circle"></i>New User
                </button>
            </div>
        </div>

        <div class="master-content">
            <div class="data-table mt-3">
                <el-skeleton :loading="gettingUsers" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" v-for="row in 9" :key="row"
                                          style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                    </template>
                    <template #default>
                        <div class="card bg-white rounded">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col"> Name </th>
                                        <th scope="col"> Email Address </th>
                                        <th scope="col"> Phone Number </th>
                                        <th scope="col"> Assigned Store </th>
                                        <th scope="col"> Role </th>
                                        <th scope="col"> Actions </th>
                                    </tr>
                                </thead>

                                <tbody>
                                <tr v-for="(user, index) in users" :key="user.id">
                                    <td> {{ user.full_name }} </td>
                                    <td> {{ user.email }} </td>
                                    <td> {{ user.phone ?? '-' }} </td>
                                    <td> {{ user.store?.store_name ?? 'Not Assigned' }} </td>
                                    <td> {{ user.role?.name ?? 'Not Assigned' }} </td>
                                    <td> <el-dropdown @command="handleUserActions">
                                        <el-button type="primary">
                                            Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item :command="'update-' + index">Update User
                                                    Details
                                                </el-dropdown-item>
                                                <el-dropdown-item :command="'password-' + index">Change
                                                    Password
                                                </el-dropdown-item>
                                                <el-dropdown-item :command="'remove-' + index">Remove User
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown> </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

<!--                        <el-table :data="users" style="width: 100%; margin-top: 10px;"-->
<!--                                  empty-text="No users found. Try different filters.">-->
<!--                            <el-table-column prop="full_name" label="Name"/>-->
<!--                            <el-table-column prop="email" label="Email Address"/>-->
<!--                            <el-table-column prop="phone" label="Phone Number"/>-->
<!--                            <el-table-column label="Assigned Store">-->
<!--                                <template #default="scope">-->
<!--                                    <span>{{ scope.row.store?.store_name }}</span>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column label="Role">-->
<!--                                <template #default="scope">-->
<!--                                    <span>{{ scope.row.role?.name ?? 'Unassigned' }}</span>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column label="Actions">-->
<!--                                <template #default="scope">-->
<!--                                    <el-dropdown @command="handleUserActions">-->
<!--                                        <el-button type="primary">-->
<!--                                            Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>-->
<!--                                        </el-button>-->
<!--                                        <template #dropdown>-->
<!--                                            <el-dropdown-menu>-->
<!--                                                <el-dropdown-item :command="'update-' + scope.$index">Update User-->
<!--                                                    Details-->
<!--                                                </el-dropdown-item>-->
<!--                                                <el-dropdown-item :command="'password-' + scope.$index">Change-->
<!--                                                    Password-->
<!--                                                </el-dropdown-item>-->
<!--                                                <el-dropdown-item :command="'remove-' + scope.$index">Remove User-->
<!--                                                </el-dropdown-item>-->
<!--                                            </el-dropdown-menu>-->
<!--                                        </template>-->
<!--                                    </el-dropdown>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                        </el-table>-->
                    </template>
                </el-skeleton>
            </div>
        </div>

        <el-dialog
            v-model="showingNewUserDialog"
            title="New User"
            width="30%"
        >
            <form @submit.prevent="addUser"
                  v-loading="addingUser"
                  element-loading-text="Adding Users"
                  id="add-user-form"
                  novalidate
            >
                <div class="row">
                    <div class="form-group col-md-6" style="padding-left: 0!important;">
                        <label for="first-name" class="required"> First Name </label>
                        <input type="text" name="firstname" id="first-name" class="form-control"
                               v-model="newUser.firstname">
                        <div class="invalid-feedback">
                            Please enter user's first name.
                        </div>
                    </div>
                    <div class="form-group col-md-6" style="padding-right: 0!important;">
                        <label for="last-name" class="required"> Last Name </label>
                        <input type="text" name="lastname" id="last-name" class="form-control"
                               v-model="newUser.lastname">
                        <div class="invalid-feedback">
                            Please enter user's last name.
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="email" class="required"> Email Address </label>
                    <input type="email" name="email" id="email" class="form-control" v-model="newUser.email">
                    <div class="invalid-feedback">{{ validationErrors.email }}</div>
                </div>

                <div class="form-group">
                    <label> Phone Number </label>
                    <vue-tel-input :value="newUser.phone" @input="onInput" mode="international"></vue-tel-input>
                    <input type="hidden" :value="newUser.phone" name="phone">
                    <div class="invalid-feedback" id="new-user-phone-feedback">{{ validationErrors.phone }}</div>
                </div>

                <div class="form-group">
                    <label for="password" class="required"> Password </label>
                    <input :type="passwordIsVisible ? 'text' : 'password'" id="password" class="form-control"
                           v-model="newUser.password">
                    <div class="form-text d-flex justify-content-end">
                        <a href="javascript:void(0);" v-if="passwordIsVisible" @click="togglePasswordVisibility"> Hide
                            password </a>
                        <a href="javascript:void(0);" v-else @click="togglePasswordVisibility"> Show password </a>
                    </div>
                    <div class="invalid-feedback">Please create user's password.</div>
                </div>

                <div class="form-group">
                    <label> Assigned Store </label>
                    <el-select
                        v-model="newUser.store_id"
                        filterable
                        placeholder="Search or select a store"
                        size="large"
                        no-data-text="No stores found.">
                        <el-option
                            v-for="store in stores"
                            :key="store.id"
                            :label="store.store_name"
                            :value="store.id"
                        />
                    </el-select>
                </div>

                <div class="form-group">
                    <label> Role </label>
                    <el-select
                        v-model="newUser.role_id"
                        filterable
                        allow-create
                        placeholder="Search or select a role"
                        size="large"
                        no-data-text="No roles found.">
                        <el-option
                            v-for="role in roles"
                            :key="role.id"
                            :label="role.name"
                            :value="role.id"
                        />
                    </el-select>
                    <div class="form-text"> To add a new role, type the role's name and select it.</div>
                </div>

            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showingNewUserDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addUser"> Add User</button>
                </div>
            </template>
        </el-dialog>

        <!-- Update User Dialog -->
        <el-dialog
            v-model="showingUpdateUserDialog"
            title="Update User"
            width="35%"
        >
            <form @submit.prevent="updateUser"
                  v-loading="updatingUser"
                  element-loading-text="Updating User"
                  id="update-user-form"
                  novalidate
            >
                <div class="row">
                    <div class="form-group col-md-6" style="padding-left: 0!important;">
                        <label for="update-first-name" class="required"> First Name </label>
                        <input type="text" name="firstname" id="update-first-name" class="form-control"
                               v-model="actionUser.firstname">
                        <div class="invalid-feedback">Please enter user's first name.</div>
                    </div>
                    <div class="form-group col-md-6" style="padding-right: 0!important;">
                        <label for="update-last-name" class="required"> Last Name </label>
                        <input type="text" name="lastname" id="update-last-name" class="form-control"
                               v-model="actionUser.lastname">
                        <div class="invalid-feedback">Please enter user's last name.</div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6" style="padding-left: 0!important;">
                        <label for="update-email" class="required"> Email Address </label>
                        <input type="email" name="email" id="update-email" class="form-control"
                               v-model="actionUser.email">
                        <div class="invalid-feedback">{{ validationErrors.email }}</div>
                    </div>

                    <div class="form-group col-md-6" style="padding-right: 0!important;">
                        <label> Phone Number </label>
                        <vue-tel-input :value="actionUser.phone" @input="onUpdateUserPhoneInput"
                                       mode="international"></vue-tel-input>
                        <input type="hidden" :value="actionUser.phone" name="phone">
                        <div class="invalid-feedback" id="update-user-phone-feedback">{{ validationErrors.phone }}</div>
                    </div>
                </div>

                <div class="form-group">
                    <label> Assigned Store </label>
                    <el-select
                        v-model="actionUser.store_id"
                        filterable
                        placeholder="Search or select a store"
                        size="large"
                        no-data-text="No store found.">
                        <el-option
                            v-for="store in stores"
                            :key="store.id"
                            :label="store.store_name"
                            :value="store.id"
                        />
                    </el-select>
                </div>

                <div class="form-group">
                    <label> Role </label>
                    <el-select
                        v-model="actionUser.role_id"
                        filterable
                        allow-create
                        placeholder="Search or select a role"
                        size="large"
                        no-data-text="No roles found.">
                        <el-option
                            v-for="role in roles"
                            :key="role.id"
                            :label="role.name"
                            :value="role.id"
                        />
                    </el-select>
                    <div class="form-text"> To add a new role, type the role's name and select it.</div>
                </div>

            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showingUpdateUserDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="updateUser"> Save Changes</button>
                </div>
            </template>
        </el-dialog>
        <!-- End of Update User Dialog -->

        <!-- Change User Password Dialog -->
        <el-dialog
            v-model="showingUpdateUserPasswordDialog"
            title="Change User Password"
            width="25%"
            v-if="newPassword"
        >
            <form @submit.prevent="changeUserPassword"
                  v-loading="updatingUser"
                  element-loading-text="Updating"
                  novalidate
            >

                <div class="form-group">
                    <p> User: {{ newPassword.user }} </p>
                </div>

                <div class="form-group">
                    <label for="change-password" class="required"> Password </label>
                    <input :type="passwordIsVisible ? 'text' : 'password'" id="change-password" class="form-control"
                           v-model="newPassword.password">
                    <div class="form-text d-flex justify-content-end">
                        <a href="javascript:void(0);" v-if="passwordIsVisible" @click="togglePasswordVisibility"> Hide
                            password </a>
                        <a href="javascript:void(0);" v-else @click="togglePasswordVisibility"> Show password </a>
                    </div>
                    <div class="invalid-feedback"> Password cannot be empty.</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showingUpdateUserPasswordDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="changeUserPassword"> Save Changes</button>
                </div>
            </template>
        </el-dialog>
        <!-- End of Change User Password Dialog -->

    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import $ from "jquery";
import {Refresh} from "@element-plus/icons-vue";

export default {
    name: "SystemUsers",

    data() {
        return {
            gettingUsers: false,
            filters: {
                name: null,
                email: null,
                phone: null,
                roleId: null,
            },

            users: [],
            stores: [],
            newUser: {},
            actionUser: {},
            newPassword: {},
            showingNewUserDialog: false,
            showingUpdateUserDialog: false,
            showingUpdateUserPasswordDialog: false,
            addingUser: false,
            updatingUser: false,
            passwordIsVisible: false,

            validationErrors: {
                email: 'Please provide the user\'s email address',
                phone: ''
            },
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
        ...mapState('users', ['baseUrl', 'roles']),

        ...mapGetters('system', ['getBasicPayload']),

        Refresh() {
            return Refresh
        },
    },

    created() {
        this.getSystemUsers()
        this.getStores()
    },

    methods: {
        getSystemUsers() {
            this.gettingUsers = true
            axios.get(`${this.baseUrl}/users`, {params: {store_id: this.currentUser.store_id}}).then(response => {
                this.gettingUsers = false
                this.users = response.data.data
                this.$store.commit('users/setSystemUsers', this.users)
            }).catch(error => {
                this.gettingUsers = false
                this.$message.error("Error fetching users. Please reload page.")
            })
        },

        getStores() {
            axios.get('/api/business/stores', {params: {business_id: this.currentUser.business_id}})
                .then(res => {
                    this.stores = res.data.data
                }).catch(error => {
            })
        },

        promptNewUser() {
            this.newUser = {...this.getBasicPayload, ...{}}
            this.showingNewUserDialog = true
        },

        onInput(phone, phoneObject, input) {
            if (phoneObject?.formatted) {
                this.newUser.phone = phoneObject.formatted
            }
        },

        onUpdateUserPhoneInput(phone, phoneObject, input) {
            if (phoneObject?.formatted) {
                this.actionUser.phone = phoneObject.formatted
            }
        },

        togglePasswordVisibility(e) {
            e.preventDefault()

            this.passwordIsVisible = !this.passwordIsVisible
        },

        addUser(e) {
            e.preventDefault()

            // Validate user details
            this.resetValidationErrors()
            let dataIsValid = true

            // First name
            if (!this.newUser.firstname || this.hasSpacesOnly(this.newUser.firstname)) {
                dataIsValid = false
                $("#first-name").addClass('is-invalid')
            } else {
                $("#first-name").removeClass('is-invalid')
            }

            // Last name
            if (!this.newUser.lastname || this.hasSpacesOnly(this.newUser.lastname)) {
                dataIsValid = false
                $("#last-name").addClass('is-invalid')
            } else {
                $("#last-name").removeClass('is-invalid')
            }

            // Email
            if (!this.newUser.email || this.hasSpacesOnly(this.newUser.email)) {
                dataIsValid = false
                $("#email").addClass('is-invalid')
            } else {
                $("#email").removeClass('is-invalid')
            }

            // Password
            if (!this.newUser.password || this.hasSpacesOnly(this.newUser.password)) {
                dataIsValid = false
                $("#password").addClass('is-invalid')
            } else {
                $("#password").removeClass('is-invalid')
            }

            if (dataIsValid) {
                // Check for a new role
                if ((typeof this.newUser.role_id) === 'string') {
                    this.newUser.has_new_role = 1
                }

                this.addingUser = true
                axios.post(`${this.baseUrl}/users/add`, this.newUser).then(response => {
                    this.addingUser = false
                    this.getSystemUsers()
                    this.$message.success('User added successfully')
                    this.showingNewUserDialog = false

                    // Check for new role and add
                    if (response.data.data.role) {
                        this.$store.commit('users/addNewRole', response.data.data.role)
                    }
                }).catch(error => {
                    this.addingUser = false

                    // Handle Bad Request exceptions
                    if (error.response) {
                        if (error.response.status === 422) {
                            let errors = error.response.data.errors

                            // Check for email error
                            if (errors.hasOwnProperty('email')) {
                                this.validationErrors.email = errors.email
                                this.$message.error(this.validationErrors.email)
                                $("#email").addClass('is-invalid')
                            }

                            // Check for phone error
                            if (errors.hasOwnProperty('phone')) {
                                this.validationErrors.phone = errors.phone
                                this.$message.error(this.validationErrors.phone)
                                $("#new-user-phone-feedback").css('display', 'block')
                            } else {
                                $("#new-user-phone-feedback").css('display', 'none')
                            }
                        } else {
                            this.$message.error('An error was encountered. Please try again')
                        }
                    } else {
                        this.$message.error('An error was encountered. Please try again')
                    }
                })
            } else {
                this.$message.warning('Please fill all required fields')
            }
        },

        resetValidationErrors() {
            this.validationErrors = {
                email: 'Please provide the user\'s email address',
                phone: ''
            }
        },

        handleUserActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            if (commandName === 'update') {
                this.promptUpdateUser(this.users[commandIndex])
            }

            if (commandName === 'password') {
                this.promptChangeUserPassword(this.users[commandIndex])
            }

            if (commandName === 'remove') {
                this.removeUser(this.users[commandIndex])
            }
        },

        promptUpdateUser(user) {
            this.actionUser = JSON.parse(JSON.stringify(user))
            this.actionUser.user_id = this.currentUser.id
            this.actionUser.store_id = this.currentUser.store_id
            this.actionUser.role_id = this.actionUser.role?.id
            this.showingUpdateUserDialog = true
        },

        updateUser(e) {
            e.preventDefault()

            // Validate user details
            this.resetValidationErrors()
            let dataIsValid = true

            // First name
            if (!this.actionUser.firstname || this.hasSpacesOnly(this.actionUser.firstname)) {
                dataIsValid = false
                $("#update-first-name").addClass('is-invalid')
            } else {
                $("#update-first-name").removeClass('is-invalid')
            }

            // Last name
            if (!this.actionUser.lastname || this.hasSpacesOnly(this.actionUser.lastname)) {
                dataIsValid = false
                $("#update-last-name").addClass('is-invalid')
            } else {
                $("#update-last-name").removeClass('is-invalid')
            }

            // Email
            if (!this.actionUser.email || this.hasSpacesOnly(this.actionUser.email)) {
                dataIsValid = false
                $("#update-email").addClass('is-invalid')
            } else {
                $("#update-email").removeClass('is-invalid')
            }

            if (dataIsValid) {
                // Check for a new role
                if ((typeof this.actionUser.role_id) === 'string') {
                    this.actionUser.has_new_role = 1
                }

                this.updatingUser = true
                axios.post(`${this.baseUrl}/users/update`, this.actionUser).then(response => {
                    this.updatingUser = false
                    this.getSystemUsers()
                    this.$message.success('User updated successfully')
                    this.showingUpdateUserDialog = false

                    // Check for new role and add
                    if (response.data.data.role) {
                        this.$store.commit('users/addNewRole', response.data.data.role)
                    }
                }).catch(error => {
                    this.updatingUser = false

                    // Handle Bad Request exceptions
                    if (error.response) {
                        if (error.response.status === 422) {
                            let errors = error.response.data.errors

                            // Check for email error
                            if (errors.hasOwnProperty('email')) {
                                this.validationErrors.email = errors.email
                                this.$message.error(this.validationErrors.email)
                                $("#update-email").addClass('is-invalid')
                            }

                            // Check for phone error
                            if (errors.hasOwnProperty('phone')) {
                                this.validationErrors.phone = errors.phone
                                this.$message.error(this.validationErrors.phone)
                                $("#update-user-phone-feedback").css('display', 'block')
                            } else {
                                $("#update-user-phone-feedback").css('display', 'none')
                            }
                        } else {
                            this.$message.error('An error was encountered. Please try again')
                        }
                    } else {
                        this.$message.error('An error was encountered. Please try again')
                    }
                })
            } else {
                this.$message.warning('Please fill all required fields')
            }
        },

        removeUser(user) {
            this.$confirm(
                'This action is permanent. Continue?',
                'Confirm User Removal',
                {
                    confirmButtonText: 'Yes, remove user',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.gettingUsers = true
                    axios.post(`${this.baseUrl}/users/remove`, {id: user.id}).then(response => {
                        this.gettingUsers = false
                        this.getSystemUsers()
                        this.$message.success('User removed successfully')
                    }).catch(error => {
                        this.gettingUsers = false
                        this.$message.error('An error was encountered. Please try again')
                    })
                })
                .catch(() => {
                    //
                })
        },

        promptChangeUserPassword(user) {
            this.newPassword.id = user.id
            this.newPassword.password = null
            this.newPassword.user = user.name

            this.showingUpdateUserPasswordDialog = true
        },

        changeUserPassword(e) {
            e.preventDefault()

            // Validate
            let dataIsValid = true
            if (!this.newPassword.password || this.hasSpacesOnly(this.newPassword.password)) {
                dataIsValid = false
                $("#change-password").addClass('is-invalid')
            } else {
                $("#change-password").removeClass('is-invalid')
            }

            if (dataIsValid) {
                this.updatingUser = true
                axios.post(`${this.baseUrl}/users/change-password`, this.newPassword).then(response => {
                    this.updatingUser = false
                    this.$message.success('User updated successfully')

                    this.showingUpdateUserPasswordDialog = false
                }).catch(error => {
                    this.updatingUser = false
                    this.$message.error('An error was encountered. Please try again')
                })
            }
        }
    },
}
</script>

<style scoped>

</style>
