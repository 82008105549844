export const posStore = {
    namespaced: true,
    state: () => ({
        baseUrl: '/api/pos',
        hasPosUser: false,
        cart: [],
        addingToExistingOrder: false,
        existingOrderId: null
    }),

    getters: {},

    mutations: {
        toggleHasPosUser(state, status) {
            state.hasPosUser = status
        },

        addCartItem(state, item) {
            state.cart.push(item)
        },

        removeCartItem(state, index) {
            state.cart.splice(index, 1)
        },

        clearCart(state) {
            state.cart = []
        },

        updateItemQuantity(state, payload) {
            state.cart[payload.index].quantity = payload.quantity
        },

        updateItemDiscount(state, payload) {
            state.cart[payload.index].discount = payload.discount
        },

        updateItemPrice(state, payload) {
            state.cart[payload.index].price = payload.price
        },

        toggleAddingToExistingOrder(state, status) {
            state.addingToExistingOrder = status
        },

        setExistingOrderId(state, id) {
            state.existingOrderId = id
        }
    },

    actions: {
        resetAll({commit}) {
            commit('clearCart')
            commit('toggleHasPosUser', false)
        }
    },
}
