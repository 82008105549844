<template>
    <div class="tip">
        <div class="alert d-flex align-items-center alert-dismissible fade show" role="alert" :class="'alert-' + type">
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:">
                <use xlink:href="#info-fill" v-if="type === 'primary'"/>
                <use xlink:href="#check-circle-fill" v-else-if="type === 'success'"/>
                <use xlink:href="#exclamation-triangle-fill" v-else/>
            </svg>

            <div class="tip-message">
               <slot></slot>
            </div>

            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tip",

    props: ['type'],
}
</script>

<style scoped>

</style>
