<template>
    <div id="users" class="master-template">
        <h5 class="master-title"> Users & Access </h5>

        <div class="master-content">
            <el-tabs v-model="activeTab">
                <!-- Users -->
                <el-tab-pane label="System Users" name="users">
                    <system-users></system-users>
                </el-tab-pane>

                <!-- Roles -->
                <el-tab-pane label="System Roles" name="roles">
                    <system-roles></system-roles>
                </el-tab-pane>

                <!-- Permissions -->
                <el-tab-pane label="Role Permissions" name="permissions">
                    <role-permissions></role-permissions>
                </el-tab-pane>

            </el-tabs>
        </div>
    </div>
</template>

<script>
import SystemUsers from "./SystemUsers";
import SystemRoles from "./SystemRoles";
import RolePermissions from "./RolePermissions";

export default {
    name: "UsersMain",

    components: {
        SystemUsers,
        SystemRoles,
        RolePermissions,
    },

    data() {
        return {
            activeTab: 'users',
        }
    },

    computed: {},

    methods: {},
}
</script>

<style scoped>

</style>
