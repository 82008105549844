<script>
import {CircleCheck} from '@element-plus/icons-vue'
import {mapState} from "vuex";

export default {
    name: "AppBarNotifications",

    data() {
        return {
            notifications: [],
            notificationCount: 0,
            loadingNotifications: false,
            errorFetchingNotifications: false,
            notificationsAreVisible: false,
            processingNotifications: false,
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),

        CircleCheckIcon() {
            return CircleCheck
        },
    },

    created() {
        this.fetchNotifications()
    },

    mounted() {
        this.emitter.on('all-notifications-read', (evt) => {
            this.notificationCount = 0
        })

        this.emitter.on('pos-sale-made', (evt) => {
            this.fetchNotifications()
        })

        this.emitter.on('stock-wasted', (evt) => {
            this.fetchNotifications()
        })
    },

    methods: {
        fetchNotifications() {
            let payload = {store_id: this.currentUser.store_id}
            this.loadingNotifications = true
            axios.get('/api/notifications/unread', {params: payload}).then(response => {
                this.loadingNotifications = false
                this.errorFetchingNotifications = false
                this.notifications = response.data.data.content
                this.notificationCount = response.data.data.count
            }).catch(() => {
                this.loadingNotifications = false
                this.errorFetchingNotifications = true
            })
        },

        markNotificationAsRead(notification) {
            this.processingNotifications = true
            axios.post('/api/notifications/read', {id: notification.id}).then(() => {
                this.processingNotifications = false
                this.fetchNotifications()
                this.$message.success('Success!')
            }).catch(() => {
                this.processingNotifications = false
                this.$message.error('An error was encountered. Please try again.')
            })
        },

        markAllNotificationsAsRead() {
            this.processingNotifications = true
            axios.post('/api/notifications/read-all', {store_id: this.currentUser.store_id}).then(() => {
                this.processingNotifications = false
                this.fetchNotifications()
                this.$message.success('Success!')

                this.notificationsAreVisible = false
            }).catch(() => {
                this.processingNotifications = false
                this.$message.error('An error was encountered. Please try again.')
            })
        },
    },
}
</script>

<template>
    <div class="notifications position-relative">
        <i class="far fa-bell" @click="notificationsAreVisible = !notificationsAreVisible" style="cursor:pointer;"></i>
        <div class="notifications-count" v-if="notificationCount > 0" @click="notificationsAreVisible = !notificationsAreVisible"
             style="cursor:pointer;">{{ notificationCount }}
        </div>

        <div class="card bg-white shadow-lg" id="notification-content" v-if="notificationsAreVisible">
            <div class="d-flex justify-content-between" style="padding: 15px;">
                <p v-if="notifications.length === 0"> You have no unread notifications </p>
                <a href="/communications/notifications" class="fw-700" style="text-decoration: none;"> View All </a>
            </div>

            <ul v-if="notifications.length > 0">
                <li class="d-flex flex-column notification" v-for="notification in notifications" :key="notification.id">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="notification-title"> {{ notification.title }} </span>
                        <span class="notification-timestamp"> {{ notification.timestamp }} </span>
                    </div>

                    <p class="notification-message"> {{ notification.message }} </p>

                    <div class="notification-actions d-flex justify-content-end">
                        <el-button type="success" size="large" plain @click="markNotificationAsRead(notification)">
                            Mark As Read
                        </el-button>
                    </div>
                </li>
            </ul>

            <div class="d-flex justify-content-between" style="padding: 15px;">
                <el-button size="large" @click="notificationsAreVisible = false">
                    Close
                </el-button>

                <el-button type="success" size="large" :icon="CircleCheckIcon" @click="markAllNotificationsAsRead"
                           v-if="notifications.length > 0"> Mark All As Read
                </el-button>
            </div>
        </div>
    </div>
</template>

<style>
.notifications {
    margin-right: 15px;
    padding: 5px;
    position: relative;
    margin-top: 6px;
}

.notifications i {
    font-size: 20px;
}

.notifications-count {
    position: absolute;
    top: 0;
    right: 0;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    text-align: center;
    font-weight: 700;
    background-color: red;
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#notification-content {
    position: absolute;
    right: 0;
    width: 30vw;
    padding: 0 !important;
    margin: 0 !important;
}

#notification-content ul {
    list-style-type: none;
    padding: 0 !important;
    margin: 0 !important;
}

.notification {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 15px;
}

.notification-title {
    font-weight: 700;
    font-size: 18px;
}

.notification-timestamp {
    font-weight: 600;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.5);
}

.notification-message {
    font-size: 16px;
    word-break: break-word;
    font-weight: 500;
}
</style>
