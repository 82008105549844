<template>
    <div id="master-nav" class="bg-white shadow-sm d-flex justify-content-between align-items-center py-1">

        <div id="master-brand">
            <a href="/dashboard">
                <img src="/logo.png" alt="fuatilia POS">
            </a>
        </div>

        <div class="d-flex flex-grow-1 justify-content-between align-items-center master-nav-container">
            <div id="business-branding" class="d-flex justify-content-center align-items-center" v-if="currentUser">
                <img src="/assets/images/store.png" alt="fuatilia POS" style="height: 25px;" class="mr-12">
                <span>{{ currentUser.business_name }}, {{ currentUser.store_name }}</span>
            </div>

            <div class="master-actions">
                <div class="profile fw-600">
                    <!-- Actions -->
                    <div class="actions" v-if="can('access_backoffice', currentUser)">
                        <a href="/dashboard" class="btn btn-light">BACK OFFICE </a>
                    </div>

                    <!-- Notifications -->
                    <app-bar-notifications v-if="currentUser && can('see_notifications', currentUser)"></app-bar-notifications>

                    <!-- Account -->
                    <div class="current-user-profile">
                        <el-dropdown>
                                <span class="el-dropdown-link d-flex align-items-center">
                                  <div class="avatar d-flex align-items-center">
                                      <el-avatar src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" size="small"></el-avatar>
                                      <span style="font-weight: 600; margin-right: 8px; margin-left: 8px; font-size: 18px;"
                                            v-if="currentUser">{{ currentUser.name }}</span>
                                  </div>
                                  <i class="fas fa-chevron-down"></i>
                                </span>

                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item @click="goToLogout" style="font-size: 16px;">Logout</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>


                    <form method="POST" action="/logout" style="display: none;" id="logout-form">
                        <!-- CSRF -->
                        <input type="hidden" name="_token" :value="csrf">
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import $ from "jquery";
import AppBarNotifications from "../feedback/AppBarNotifications.vue";

export default {
    name: "PosTopBar",
    components: {AppBarNotifications},

    computed: {
        ...mapState('system', ['currentUser']),

        csrf() {
            return this.getCsrf()
        },
    },

    methods: {
        goToLogout() {
            // Clear state
            this.$store.commit('system/resetNavKeys')
            this.$store.dispatch('system/resetAll')
            this.$store.dispatch('pos/resetAll')
            this.$store.dispatch('stock/resetAll')
            this.$store.dispatch('users/resetAll')
            this.$store.dispatch('procurement/resetAll')

            $("#logout-form").submit();
        },
    },
}
</script>

<style scoped>

</style>
