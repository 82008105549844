<script>
import {defineComponent} from 'vue'
import {mapState} from "vuex";
import SvgSymbols from "../../../components/shared/SvgSymbols.vue";
import Tip from "../../../components/shared/Tip.vue";
import {Edit, Delete, CirclePlus} from "@element-plus/icons-vue"

export default defineComponent({
    name: "StockProductionList",
    components: {Tip, SvgSymbols},

    data() {
        return {
            baseUrl: '/api/stock/production',
            productions: [],
            totalProductions: [],
            fetchingProductions: false,
            errorFetchingProductions: false,
            filters: {
                store_id: null,
                stock_item_id: null,
            },
            currentPage: 1,

            stockItems: [],
            loadingStockItems: false,

            newProductionModalIsActive: false,
            newProduction: {
                production_date: null,
                stock_item_id: null,
                quantity: null,
                new_recipe: false,
                production_cost: null,
                item_selling_price: null,
                item_buying_price: null,
                items: [],
            },
            addingProduction: false,
            errorAddingProduction: false
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),

        producibleItems() {
            return this.stockItems.filter(stockItem => stockItem.restocking_method === 'producing')
        },

        EditIcon() {
            return Edit
        },

        DeleteIcon() {
            return Delete
        },

        CirclePlusIcon() {
            return CirclePlus
        },

        estimatedProductionCost() {
            let cost = 0

            this.newProduction.items.forEach(item => {
                cost += this.getRawMaterialItemTotal(item)
            })

            return cost
        },
    },

    created() {
        this.fetchProductions()
        this.fetchStockItems()
    },

    methods: {
        fetchProductions() {
            this.errorFetchingProductions = false

            let payload = {
                store_id: this.filters.store_id ?? this.currentUser.store_id,
                page: this.currentPage - 1
            }
            this.fetchingProductions = true
            axios.get(`${this.baseUrl}/paginated`, {params: payload}).then(res => {
                this.fetchingProductions = false
                this.errorFetchingProductions = false
                this.productions = res.data.data.content
                this.totalProductions = res.data.data.count
            }).catch(_ => {
                this.fetchingProductions = false
                this.errorFetchingProductions = true
            })
        },

        fetchStockItems() {
            this.loadingStockItems = true
            axios.get(`/api/stock/levels`, {params: {store_id: this.currentUser.store_id}}).then(response => {
                this.loadingStockItems = false
                this.stockItems = response.data.data
            }).catch(_ => {
                this.loadingStockItems = false
            })
        },

        promptNewProduction() {
            this.resetNewProductionObject()
            this.newProduction.production_date = this.getFormattedNow()
            this.newProduction.quantity = 1

            this.errorAddingProduction = false
            this.newProductionModalIsActive = true
        },

        resetNewProductionObject() {
            this.newProduction = {
                production_date: null,
                stock_item_id: null,
                quantity: null,
                new_recipe: false,
                production_cost: null,
                item_selling_price: null,
                item_buying_price: null,
                items: [],
            }
        },

        fillRawMaterials() {
            let stockItem = this.stockItems.find(_item => _item.id === this.newProduction.stock_item_id)
            if (stockItem.recipe) {
                this.newProduction.item_selling_price = stockItem.selling_price
                this.newProduction.item_buying_price = stockItem.buying_price
                stockItem.recipe.items.forEach(item => {
                    this.addRawMaterialEntry(item)
                })
            }
        },

        fillRawMaterialItemDetails(value, entryIndex) {
            let entry = this.newProduction.items[entryIndex]
            let stockItem = this.stockItems.find(item => item.id === value)
            entry.stock_item_id = stockItem.id
            entry.cost = stockItem.buying_price
        },

        createProductBom() {
            this.newProduction.new_recipe = true

            let stockItem = this.stockItems.find(_item => _item.id === this.newProduction.stock_item_id)
            this.newProduction.item_selling_price = stockItem.selling_price
            this.newProduction.item_buying_price = stockItem.buying_price

            this.addRawMaterialEntry()
        },

        addRawMaterialEntry(item) {
            if (!item) {
                this.newProduction.items.push({
                    stock_item_id: null,
                    quantity: 1,
                    new_item: true,
                    new_quantity: true
                })
            } else {
                this.newProduction.items.push({
                    id: item.id,
                    stock_item_id: item.stock_item_id,
                    stock_item_label: item.stock_item_label,
                    quantity: item.quantity,
                    display_quantity: item.display_quantity,
                    new_item: false,
                    cost: item.stock_item_cost,
                    new_quantity: false
                })
            }
        },

        getRawMaterialItemTotal(entry) {
            try {
                return entry.cost * entry.quantity
            } catch (e) {
                return 0
            }
        },

        promptAddRawItem() {
            this.addRawMaterialEntry()
        },

        promptRemoveRawMaterial(index) {
            let entry = this.newProduction.items[index]
            if (entry.new_item) {
                return this.newProduction.items.splice(index, 1)
            }

            this.$confirm(
                `Are you sure you want to remove ${entry.stock_item_label} from saved raw materials?`,
                `Remove Raw Material`,
                {
                    confirmButtonText: 'Yes, remove item',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(() => {
                axios.post(`${this.baseUrl}/remove-raw-material`, {id: entry.id}).then(_ => {
                    this.newProduction.items.splice(index, 1)
                    this.fetchStockItems()
                    this.$message.success('Raw material removed successfully')
                }).catch(_ => {
                    this.$message.error('An error was encountered. Please try again')
                })
            }).catch(() => {
                //
            })
        },

        promptEditRawItemQuantity(index) {
            let entry = this.newProduction.items[index]
            entry.new_quantity = true
        },

        produceStock() {
            let pauseOnZeroRawMaterial = false
            this.newProduction.items.forEach(item => {
                let stockItem = this.stockItems.find(_item => _item.id === item.stock_item_id)
                if (stockItem.quantity < item.quantity) {
                    pauseOnZeroRawMaterial = true
                }
            })

            if (pauseOnZeroRawMaterial) {
                return this.$message.error('There\'s no enough quantity for one or more of your raw materials.' )
            }

            this.newProduction.store_id = this.currentUser.store_id
            this.newProduction.user_id = this.currentUser.id
            this.newProduction.production_cost = this.estimatedProductionCost * this.newProduction.quantity

            this.addingProduction = true
            axios.post(`${this.baseUrl}/create`, {payload: JSON.stringify(this.newProduction)}).then(_ => {
                this.addingProduction = false
                this.errorAddingProduction = false
                this.fetchProductions()
                this.fetchStockItems()

                this.$message.success("Item produced successfully")
                this.newProductionModalIsActive = false
            }).catch(_ => {
                this.addingProduction = false
                this.errorAddingProduction = true
            })
        },

        promptVoidProduction(production) {
            this.$confirm(
                'This action is permanent. Continue?',
                `Void Production ${production.display_production_number}`,
                {
                    confirmButtonText: 'Yes, void production',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(() => {
                axios.post(`${this.baseUrl}/void`, {id: production.id}).then(_ => {
                    this.fetchProductions()
                    this.$message.success('Production voided successfully')
                }).catch(_ => {
                    this.$message.error('An error was encountered. Please try again')
                })
            }).catch(() => {
                //
            })
        },
    },
})
</script>

<template>
    <div class="master-template">
        <div class="master-title-div d-flex justify-content-between align-items-center">
            <h5> Stock Production List </h5>

            <button class="btn btn-primary new-item-btn" @click="promptNewProduction">
                <i class="fas fa-plus-circle"></i>New Production
            </button>
        </div>

        <div class="master-content">
            <div class="data-table table-responsive card bg-white">
                <p v-if="productions.length === 0"> No entries were found </p>

                <table class="table" v-else>
                    <thead>
                    <tr>
                        <th scope="col"> #</th>
                        <th scope="col"> Production Number</th>
                        <th scope="col"> Production Date</th>
                        <th scope="col"> Stock Item</th>
                        <th scope="col"> Quantity Produced</th>
                        <th scope="col"> Total Cost</th>
                        <th scope="col"> Actions</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="(production, index) in productions" :key="production.id">
                        <th scope="row"> {{ index + 1 }}</th>
                        <td> {{ production.display_production_number }}</td>
                        <td> {{ production.display_production_date }}</td>
                        <td> {{ production.stock_item_label }}</td>
                        <td> {{ production.display_quantity }}</td>
                        <td> {{ production.display_production_cost }}</td>
                        <td> <el-button size="large" type="danger" :icon="DeleteIcon" @click="promptVoidProduction(production)"></el-button> </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- New Production -->
        <el-dialog
            v-model="newProductionModalIsActive"
            title="New Production"
            width="60%"
            top="5vh"
        >
            <div v-loading="addingProduction">
                <div class="master-tip" v-if="errorAddingProduction">
                    <svg-symbols></svg-symbols>
                    <tip type="danger">
                        An error was encountered while adding production
                    </tip>
                </div>

                <div class="row">
                    <div class="col-md-3 col-12 form-group">
                        <label class="required"> Production Item </label>
                        <el-select v-model="newProduction.stock_item_id" size="large" filterable no-data-text="No producible items were found"
                                   @change="fillRawMaterials" :loading="loadingStockItems">
                            <el-option v-for="item in producibleItems" :key="item.id" :value="item.id" :label="item.label"></el-option>
                        </el-select>
                    </div>

                    <div class="col-md-3 col-12 form-group">
                        <label class="required"> Production Date </label>
                        <el-date-picker
                            v-model="newProduction.production_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                            style="width: 100%"
                        >
                        </el-date-picker>
                    </div>

                    <div class="col-md-3 col-12 form-group">
                        <label class="required"> Production Quantity </label>
                        <input type="number" v-model="newProduction.quantity" class="form-control">
                    </div>
                </div>

                <div class="mt-3">
                    <h5 class="f-18 fw-700"> Confirm Raw Materials </h5>

                    <p class="f-16" v-if="!newProduction.stock_item_id"> Select a production item to view raw materials. </p>

                    <div v-else>
                        <div v-if="newProduction.items.length === 0">
                            <p class="f-16"> The selected production item does not have a defined bill of materials. Click on the button below to
                                create one.
                            </p>
                            <button class="btn btn-outline-primary" @click="createProductBom"> Create Bill of Materials</button>
                        </div>

                        <div class="table-responsive" v-else>
                            <table class="table">
                                <thead>
                                <tr>
                                    <th scope="col"> #</th>
                                    <th scope="col"> Raw Material</th>
                                    <th scope="col"> Quantity</th>
                                    <th scope="col"> Item Cost</th>
                                    <th scope="col"> Item Total</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr v-for="(entry, index) in newProduction.items">
                                    <th scope="row"> {{ index + 1 }}</th>
                                    <td>
                                        <el-select v-model="entry.stock_item_id" size="large" filterable
                                                   @change="fillRawMaterialItemDetails($event, index)" v-if="entry.new_item">
                                            <el-option v-for="item in stockItems" :key="item.id" :value="item.id" :label="item.label"></el-option>
                                        </el-select>
                                        <span v-else>  {{ entry.stock_item_label }}</span>
                                    </td>

                                    <td>
                                        <input type="number" class="form-control" v-model="entry.quantity" v-if="entry.new_quantity"
                                               style="width: 100px;">
                                        <div class="d-flex" v-else>
                                            <span class="mr-12"> {{ entry.display_quantity }} </span>
                                            <el-button size="small" plain type="primary" :icon="EditIcon"
                                                       @click="promptEditRawItemQuantity(index)">Update
                                            </el-button>
                                        </div>
                                    </td>

                                    <td> {{ formatAmount(entry.cost) }}</td>
                                    <td> {{ formatAmount(getRawMaterialItemTotal(entry)) }}</td>
                                    <td>
                                        <el-button type="danger" plain :icon="DeleteIcon" @click="promptRemoveRawMaterial(index)"></el-button>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="4"><span class="fw-700"> Production Cost </span></td>
                                    <td colspan="2"><span class="fw-700"> {{ formatAmount(estimatedProductionCost) }} </span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="mt-2 d-flex justify-content-end">
                            <el-button size="large" type="primary" :icon="CirclePlusIcon" @click="promptAddRawItem">Add Item</el-button>
                        </div>
                    </div>
                </div>

                <div class="mt-3" v-if="newProduction.items.length !== 0">
                    <h5 class="f-18 fw-700"> Cost Details </h5>
                    <div class="row">
                        <div class="col-md-4 col-12 form-group">
                            <label class="required"> Set Actual Production Cost </label>
                            <input type="number" v-model="newProduction.item_buying_price" class="form-control">
                            <div class="form-text"> This will act as the actual buying price of the production item</div>
                        </div>

                        <div class="col-md-4 col-12 form-group">
                            <label class="required"> Item Selling Price </label>
                            <input type="number" v-model="newProduction.item_selling_price" class="form-control">
                            <div class="form-text"> This will update the selling price of the production item</div>
                        </div>
                    </div>
                </div>
            </div>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="newProductionModalIsActive = false" size="large">Discard</el-button>
                    <button class="btn btn-primary" @click="produceStock"> Produce</button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<style scoped>

</style>
