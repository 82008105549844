<template>
    <div class="master-template">
        <div class="master-title">
            <h5> Sales Overview Report </h5>
        </div>

        <div class="master-content">
            <div class="data-table-filters mt-3 card bg-white shadow-sm">
                <div class="d-flex justify-content-between">
                    <h5> Report Filters </h5>
                    <el-button type="danger" :icon="Close" plain @click="clearFilters" style="margin-top: -5px;">Clear Filters</el-button>
                </div>

                <div class="row mt-3">
                    <div class="form-group col-md-6">
                        <label style="display: block;"> Period </label>
                        <el-date-picker size="large"
                                        v-model="filters.period"
                                        type="daterange"
                                        unlink-panels
                                        range-separator="To"
                                        start-placeholder="Start date"
                                        end-placeholder="End date"
                                        format="MMM D, YYYY"
                                        value-format="YYYY-MM-DD" style="width: 100%;"/>
                    </div>
                </div>

                <div class="mt-3">
                    <button class="btn btn-secondary" @click="getReport"> Generate Report</button>
                </div>
            </div>

            <div class="mt-4">
                <el-skeleton :loading="generatingReport" :throttle="200" animated>
                    <template #template>
                        <div class="analytic-cards d-flex justify-content-between">
                            <el-skeleton-item variant="rect" v-for="row in 4" :key="row" style="width: 22%; height: 95px;"/>
                        </div>
                    </template>
                    <template #default>
                        <div class="analytic-cards d-flex justify-content-between flex-wrap">
                            <!-- Collections -->
                            <div class="analytic-card card shadow flex-column" style="background-color: #DFECE0;">
                                <span class="analytic-card-title"> Total Collections </span>

                                <div class="analytic-card-icon d-flex justify-content-center align-items-center">
                                    <div class="flex-grow-1">
                                        <span class="analytic-card-value"> {{ formatAmount(report.top_cards.collections) }} </span>
                                    </div>
                                    <i class="fas fa-hand-holding-usd"></i>
                                </div>
                            </div>

                            <!-- Money Out -->
                            <div class="analytic-card card shadow flex-column" style="background-color: #f2d1d9;">
                                <span class="analytic-card-title"> Total Money Out </span>

                                <div class="analytic-card-icon d-flex justify-content-center align-items-center">
                                    <div class="flex-grow-1">
                                        <span class="analytic-card-value"> {{ formatAmount(report.top_cards.money_out) }} </span>
                                    </div>
                                    <i class="fas fa-sign-out-alt"></i>
                                </div>
                            </div>

                            <!-- Sales -->
                            <div class="analytic-card card shadow flex-column" style="background-color: rgba(230, 226, 249, 1);">
                                <!--                    <div class="analytic-card card shadow d-flex flex-row" style="background-color: #E2EDF9;">-->
                                <span class="analytic-card-title"> Total Sales </span>

                                <div class="analytic-card-icon d-flex justify-content-center align-items-center">
                                    <div class="flex-grow-1">
                                        <span class="analytic-card-value"> {{ formatAmount(report.top_cards.sales) }} </span>
                                    </div>
                                    <i class="fas fa-shopping-bag"></i>
                                </div>
                            </div>

                            <!-- Profit -->
                            <div class="analytic-card card shadow flex-column" style="background-color: #98ed9e;">
                                <span class="analytic-card-title"> Total Profit </span>

                                <div class="analytic-card-icon d-flex justify-content-center align-items-center">
                                    <div class="flex-grow-1">
                                        <span class="analytic-card-value"> {{ formatAmount(report.top_cards.profit) }} </span>
                                    </div>
                                    <i class="fas fa-chart-line"></i>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-skeleton>

                <el-skeleton :loading="generatingReport" :throttle="200" animated style="margin-top: 40px;">
                    <template #template>
                        <div class="d-flex justify-content-between">
                            <el-skeleton-item variant="rect" style="height: 300px; width: 60%;"/>
                            <el-skeleton-item variant="rect" style="height: 300px; width: 35%;"/>
                        </div>
                    </template>
                    <template #default>
                        <div class="d-flex justify-content-between flex-wrap dashboard-summaries" style="margin-top: 40px;">
<!--                            &lt;!&ndash; Sales Summary &ndash;&gt;-->
<!--                            <div class="card shadow-sm" style="width: 50%;">-->
<!--                                <h5 class="card-title"> Sales Summary This Week </h5>-->

<!--                                <div class="card-content" :id="'report-sales-summary-'+chartNumber" style="min-height: 360px;"></div>-->
<!--                            </div>-->

                            <!-- Top Grossing Products -->
                            <div class="card shadow-sm top-selling-products" style="width: 45%;">
                                <h5 class="card-title"> Top Selling Products </h5>

                                <div class="card-content">
                                    <el-table :data="report.products" style="width: 100%" empty-text="No sales made today.">
                                        <el-table-column prop="product_name" label="Product"/>
                                        <el-table-column prop="count" label="Qty Sold"/>
                                        <el-table-column label="Total">
                                            <template #default="scope">
                                                <span>{{ formatAmount(scope.row.sale_total) }}</span>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>

                            <!-- Top Selling Products -->
                            <div class="card shadow-sm top-selling-products" style="width: 45%;">
                                <h5 class="card-title"> Top Grossing Products </h5>

                                <div class="card-content">
                                    <el-table :data="report.top_grossing" style="width: 100%" empty-text="No sales made today.">
                                        <el-table-column prop="product_name" label="Product"/>
                                        <el-table-column prop="count" label="Qty Sold"/>
                                        <el-table-column label="Total">
                                            <template #default="scope">
                                                <span>{{ formatAmount(scope.row.sale_total) }}</span>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-skeleton>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import {Close} from '@element-plus/icons-vue'

export default {
    name: "SalesOverviewReport",
    data() {
        return {
            generatingReport: false,
            chartNumber: 0,
            root: null,
            report: {
                top_cards: {
                    collections: 0,
                    money_out: 0,
                    sales: 0,
                    profit: 0,
                },
                products: [],
                top_grossing: [],
                sales: [],
                summary: [],
            },
            filters: {},
        }
    },

    computed: {
        ...mapGetters('system', ['getBasicPayload']),

        Close() {
            return Close
        },
    },

    created() {
        this.getReport()
    },

    beforeUnmount() {
        if (this.root) {
            this.root.dispose()
        }
    },

    methods: {
        getReport() {
            // Add filters
            let params = this.getBasicPayload
            if (this.filters.period) {
                params = {
                    ...params, ...{
                        start_date: this.filters.period[0],
                        end_date: this.filters.period[1]
                    }
                }
            }

            this.chartNumber += 1
            this.generatingReport = true
            axios.get('/api/reports/sales/sales-overview-report', {params: params}).then(res => {
                this.generatingReport = false
                this.report = res.data.data.analytics

                // if (this.root) {
                //     this.root.dispose()
                // }
                //
                // setTimeout(() => {
                //     this.renderSummaryChart()
                // }, 1500)
            }).catch(error => {
                this.generatingReport = false
                this.$message.error('An error was encountered. Please reload the page.')
            })
        },

        renderSummaryChart() {
            // Instantiate chart
            let root = am5.Root.new(`report-sales-summary-${this.chartNumber}`);
            root.setThemes([am5themes_Animated.new(root)]);

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: true,
                    panY: true,
                    wheelX: "panX",
                    wheelY: "zoomX",
                    pinchZoomX: true
                })
            );

            // Add cursor
            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

            // Create Y-axis
            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {}),
                    maxDeviation: 0.3
                })
            );

            // Create X-Axis
            let xRenderer = am5xy.AxisRendererX.new(root, {minGridDistance: 30});
            xRenderer.grid.template.setAll({
                location: 0.5
            });
            let xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(root, {
                    renderer: xRenderer,
                    // renderer: am5xy.AxisRendererX.new(root, {}),
                    categoryField: "day",
                    tooltip: am5.Tooltip.new(root, {}),
                    maxDeviation: 0.3
                })
            );

            // Create series
            let series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: "Total Sales",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "sales",
                    categoryXField: "day",
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "{valueY}"
                    }),
                    sequencedInterpolation: true
                })
            );

            series.columns.template.setAll({cornerRadiusTL: 5, cornerRadiusTR: 5});
            series.columns.template.adapters.add("fill", function (fill, target) {
                return chart.get("colors").getIndex(series.columns.indexOf(target));
            });

            series.columns.template.adapters.add("stroke", function (stroke, target) {
                return chart.get("colors").getIndex(series.columns.indexOf(target));
            });

            // Set data
            xAxis.data.setAll(this.report.summary);
            series.data.setAll(this.report.summary);

            series.appear(1000);
            chart.appear(1000, 100);

            this.root = root
        },

        clearFilters() {
            this.filters = {}
            this.getReport()
        },
    },
}
</script>

<style scoped>

</style>
