<template>
    <div id="stock-levels">
        <div class="master-title-div d-flex justify-content-between align-items-center">
            <h5> Stock Levels </h5>

            <div class="actions">
                <a href="/stock/levels/add" class="btn btn-primary new-item-btn" v-if="can('add_stock', currentUser)">
                    <i class="fas fa-plus-circle"></i>Add Stock Entries
                </a>

                <button class="btn btn-outline-primary new-item-btn" @click="promptBulkStockUpdate">
                    <i class="fas fa-cloud-download-alt"></i>Bulk Update
                </button>

            </div>
        </div>

        <div class="master-content">
            <div class="data-table-filters card shadow bg-white">
                <h5> Product Filters </h5>

                <div class="row">
                    <div class="form-group col-md-3">
                        <label> Search by name </label>
                        <input type="text" class="form-control" v-model="productFilters.product_name"
                               placeholder="Filter by product name">
                    </div>

                    <div class="form-group col-md-3 has-padding-left">
                        <label> Filter by category </label>
                        <el-select v-model="productFilters.category_id" filterable size="large"
                                   placeholder="Filter by category">
                            <el-option v-for="category in productCategories" :key="category.id" :label="category.name"
                                       :value="category.id"></el-option>
                        </el-select>
                    </div>

                    <div class="form-group col-md-3 has-padding-left">
                        <label> Filter by sub category </label>
                        <el-select v-model="productFilters.sub_category_id" filterable size="large"
                                   placeholder="Filter by sub category">
                            <el-option v-for="subCategory in filterSubCategories" :key="subCategory.id"
                                       :label="subCategory.name" :value="subCategory.id"></el-option>
                        </el-select>
                    </div>

                    <div class="form-group col-md-3 has-padding-left"
                         v-if="businessHasMultipleStores && can('manage_multiple_stores', currentUser)">
                        <label> Store </label>
                        <el-select v-model="productFilters.store_id" filterable size="large"
                                   placeholder="Select store">
                            <el-option v-for="store in stores" :key="store.id" :label="store.store_name"
                                       :value="store.id"></el-option>
                        </el-select>
                    </div>
                </div>

                <div class="mt-3 d-flex">
                    <button class="btn btn-secondary mr-12" @click="getStockLevels"> Filter Products</button>
                    <el-button size="large" @click="resetProductFilters">Reset Filters</el-button>
                </div>
            </div>

            <div class="data-table-summary d-flex justify-content-between mt-4 px-1">
                <span class="summary" v-if="can('see_stock_value', currentUser)">
                    Total stock value: {{ formatAmount(totalStockValue) }} </span>
                <span class="summary"> Showing {{ stockLevels.length }} out of {{ totalItems }} items. </span>
            </div>


            <div class="data-table">
                <el-skeleton :loading="gettingStockLevels" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" v-for="row in 7" :key="row"
                                          style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                    </template>
                    <template #default>
                        <el-table
                            :data="stockLevels"
                            :default-sort="{ prop: sortAttribute, order: sortOrder }"
                            @sort-change="handleSortChange"
                            style="width: 100%"
                            empty-text="No items found.">
                            <el-table-column label="Item Code">
                                <template #default="scope">
                                    <span>{{ scope.row.item_code ?? '-' }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Item Name">
                                <template #default="scope">
                                    <span>{{ scope.row.label }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Available Quantity" sortable prop="quantity">
                                <template #default="scope">
                                    <span v-if="scope.row.item_type === 'stock'">{{ scope.row.display_units }}</span>
                                    <span v-else> - </span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Buying Price" v-if="can('see_buying_price', currentUser)">
                                <template #default="scope">
                                    <span v-if="scope.row.item_type === 'stock'">{{
                                            scope.row.display_buying_price
                                        }}</span>
                                    <span v-else> - </span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Selling Price">
                                <template #default="scope">
                                    <span>{{ scope.row.display_selling_price }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Stock Value" v-if="can('see_stock_value', currentUser)">
                                <template #default="scope">
                                    <span
                                        v-if="(scope.row.item_type === 'stock') && (scope.row.restocking_method === 'purchasing')">
                                        {{ formatAmount(scope.row.stock_value) }}</span>
                                    <span v-else> - </span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Actions"
                                             v-if="canEitherOr(['update_stock', 'remove_stock_items', 'waste_stock'], currentUser)">
                                <template #default="scope">
                                    <el-dropdown @command="handleStockItemActions">
                                        <el-button type="primary" plain>
                                            Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item :command="'update-' + scope.$index"
                                                                  v-if="can('update_stock', currentUser)"> Update Item
                                                </el-dropdown-item>

                                                <el-dropdown-item :command="'level-' + scope.$index"
                                                                  v-if="can('update_stock', currentUser) && (scope.row.item_type === 'stock')">
                                                    Update Stock Level
                                                </el-dropdown-item>

                                                <el-dropdown-item :command="'remove-' + scope.$index"
                                                                  v-if="can('remove_stock_items', currentUser)">Remove
                                                    Item
                                                </el-dropdown-item>

                                                <el-dropdown-item :command="'waste-' + scope.$index"
                                                                  v-if="can('waste_stock', currentUser) && (scope.row.item_type === 'stock')">
                                                    Waste Stock
                                                </el-dropdown-item>

                                                <el-dropdown-item :command="'sellout-' + scope.$index"
                                                                  v-if="scope.row.sells_out_manually">Sell Out
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-skeleton>
            </div>

            <!-- Pagination -->
            <div class="data-table-pagination d-flex justify-content-end mt-3">
                <el-pagination background layout="prev, pager, next" :total="totalItems"
                               :page-size="10" v-model:current-page="currentPage" @current-change="getStockLevelsByPage"
                               hide-on-single-page/>
            </div>
        </div>

        <!-- Waste Stock Dialog -->
        <el-dialog
            v-model="showingWasteStockDialog"
            :title="'Waste ' + wasteItem.label + ' Stock'"
            width="30%"
        >
            <form @submit.prevent="wasteStock" novalidate v-loading="wastingItem">
                <div class="form-group">
                    <label class="required"> Quantity to waste </label>
                    <input type="text" class="form-control" v-model="wasteItem.quantity" @input="validateWasteQuantity"
                           id="waste-quantity">
                    <div class="invalid-feedback"> Provide a valid quantity</div>
                </div>

                <div class="form-group">
                    <label> Reason </label>
                    <textarea class="form-control" v-model="wasteItem.reason"></textarea>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showingWasteStockDialog = false" size="large">Discard</el-button>
                    <button class="btn btn-primary" @click="wasteStock"> Save</button>
                </div>
            </template>
        </el-dialog>

        <!-- Update item -->
        <el-dialog v-model="showUpdateStockItemDialog" width="50%" title="Update Stock Item">
            <form method="post" @submit.prevent="updateStockItem" v-loading="updatingItem">
                <div class="row">
                    <div class="form-group col-md-4" v-if="activeItem.item_type === 'stock'">
                        <label for="item_code" class="required"> Item Code </label>
                        <input type="text" id="item_code" v-model="activeItem.item_code" class="form-control">
                    </div>

                    <div class="form-group col-md-4">
                        <label for="product_name" class="required"> Item Name </label>
                        <input type="text" id="product-name" v-model="activeItem.label" class="form-control">
                        <div class="invalid-feedback"> Item name is required</div>
                    </div>

                    <div class="form-group col-md-4">
                        <label class="required"> Item Type </label>
                        <el-select v-model="activeItem.item_type" filterable size="large">
                            <el-option v-for="type in ['stock', 'service']" :key="type"
                                       :label="capitalizeFirstWord(type)"
                                       :value="type"></el-option>
                        </el-select>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-4">
                        <label class="required"> Category </label>
                        <el-select
                            v-model="activeItem.category_id"
                            default-first-option
                            filterable
                            size="large"
                            @change="resetActiveItemSubcategory"
                        >
                            <el-option
                                v-for="category in productCategories"
                                :key="category.id"
                                :label="category.name"
                                :value="category.id"
                            />
                        </el-select>
                        <div class="invalid-feedback" id="category"> Product category is required</div>
                    </div>

                    <div class="form-group col-md-4">
                        <label class="required"> Sub Category </label>
                        <el-select
                            v-model="activeItem.sub_category_id"
                            default-first-option
                            filterable
                            size="large"
                            no-data-text="Select a category first"
                        >
                            <el-option
                                v-for="subCategory in updateItemSubcategories"
                                :key="subCategory.id"
                                :label="subCategory.name"
                                :value="subCategory.id"
                            />
                        </el-select>
                        <div class="invalid-feedback" id="sub-category"> Product sub category is required</div>
                    </div>

                    <div class="form-group col-md-4" v-if="activeItem.item_type === 'stock'">
                        <label for="product_name" class="required"> Stocking Unit </label>
                        <el-select
                            v-model="activeItem.unit_id"
                            default-first-option
                            filterable
                            size="large"
                        >
                            <el-option
                                v-for="uom in uoms"
                                :key="uom.id"
                                :label="uom.unit_name"
                                :value="uom.id"
                            />
                        </el-select>
                        <div class="invalid-feedback" id="units"> Product units are required</div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-3"
                         v-if="(activeItem.item_type === 'stock') && (activeItem.can_stock)">
                        <label for="buying_price" class="required"> Buying Price </label>
                        <input type="text" id="buying-price" v-model="activeItem.buying_price" class="form-control">
                        <div class="invalid-feedback"> Please put a valid amount</div>
                    </div>

                    <div class="form-group col-md-3" v-if="activeItem.can_sell">
                        <label for="selling_price" class="required" v-if="activeItem.item_type === 'stock'"> Selling
                            Price </label>
                        <label for="selling_price" class="required" v-else> Service Cost </label>
                        <input type="text" id="selling-price" v-model="activeItem.selling_price" class="form-control">
                        <div class="invalid-feedback"> Please put a valid quantity</div>
                    </div>

                    <div class="form-group col-md-3"
                         v-if="(activeItem.item_type === 'service') && (businessHasEnabledAttendantCommissions)">
                        <label for="commission"> Attendant Commission (%) </label>
                        <input type="text" class="form-control" v-model="activeItem.attendant_commission"
                               id="commission">
                    </div>

                    <div class="form-group col-md-3"
                         v-if="(activeItem.item_type === 'stock') && (activeItem.can_stock)">
                        <label for="reorder_level" class="required"> Reorder level </label>
                        <input type="text" id="reorder_level" v-model="activeItem.reorder_level" class="form-control">
                    </div>

                    <div class="form-group col-md-3"
                         v-if="businessTracksExpiryDates && (activeItem.item_type === 'stock')">
                        <label class="display-block"> Expiry Date </label>
                        <el-date-picker
                            v-model="activeItem.expiry_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                            style="width: 100%"
                        >
                        </el-date-picker>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-12 col-md-4" v-if="activeItem.item_type === 'stock'">
                        <label> Restocking Method </label>
                        <div class="d-flex">
                            <el-select v-model="activeItem.restocking_method" filterable size="large">
                                <el-option v-for="method in ['purchasing', 'producing', 'preparation']" :key="method"
                                           :label="capitalizeFirstWord(method)"
                                           :value="method"></el-option>
                            </el-select>
                        </div>
                    </div>

                    <div class="form-group col-12 col-md-4" v-if="activeItem.item_type === 'stock'">
                        <label style="display: block;"> Item can be sold </label>
                        <el-radio-group v-model="activeItem.can_sell">
                            <el-radio :label="true" size="large" border>Yes</el-radio>
                            <el-radio :label="false" size="large" border>No</el-radio>
                        </el-radio-group>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-12 col-md-4" v-if="activeItem.item_type === 'stock'">
                        <label style="display: block;"> Item can be stocked </label>
                        <el-radio-group v-model="activeItem.can_stock">
                            <el-radio :label="true" size="large" border>Yes</el-radio>
                            <el-radio :label="false" size="large" border>No</el-radio>
                        </el-radio-group>
                    </div>
                </div>

            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showUpdateStockItemDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="updateStockItem"> Save Changes</button>
                </div>
            </template>
        </el-dialog>

        <!-- Update stock level Dialog -->
        <el-dialog
            v-model="updateStockLevelDialogIsActive"
            :title="'Update ' + activeItem.label + ' stock level'"
            width="30%"
            v-if="activeItem"
        >
            <form @submit.prevent="updateStockLevel" novalidate v-loading="updatingStockLevel">
                <div class="form-group">
                    <label for="new-quantity" class="required"> New Quantity </label>
                    <input type="text" class="form-control" v-model="newQuantity" id="new-quantity">
                    <div class="invalid-feedback"> Provide a valid quantity</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="updateStockLevelDialogIsActive = false" size="large">Discard</el-button>
                    <button class="btn btn-primary" @click="updateStockLevel"> Update Quantity</button>
                </div>
            </template>
        </el-dialog>

        <!-- Bulk update dialog -->
        <el-dialog v-model="showingBulkUpdateDialog" width="30%" title="Bulk Stock Update">
            <h5 class="has-primary-color fw-700 f-18"> Update Guide </h5>

            <ul class="f-18" id="bulk-update-guide-list">
                <li>
                    <span
                        class="display-block"> Click the button below to export your stock list to an excel sheet. </span>
                    <a :href="'/stock/levels/export?store_id=' + currentUser.store_id" target="_blank"
                       class="btn btn-outline-primary fw-700 f-16 mt-2"> Download Stock List </a>
                </li>

                <li>
                    Edit the necessary columns as required. Please don't edit the <strong> Item ID </strong> column.
                </li>

                <li>
                    <span class="display-block"> Once satisfied with your updates, upload the updated excel sheet below and save changes. </span>
                    <div class="form-group mt-2">
                        <input type="file" id="file" class="form-control"
                               @change="handleBulkUpdateFileSelection($event)">
                        <div class="form-text"> Only excel (.xlsx) files are accepted.</div>
                        <div class="invalid-feedback"> Please select a file</div>
                    </div>
                </li>
            </ul>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showingBulkUpdateDialog = false" size="large">Discard</el-button>
                    <button class="btn btn-primary fw-700 f-18" @click="bulkUpdateStock"> Save Changes</button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import $ from 'jquery'
import SvgSymbols from "../../shared/SvgSymbols";

export default {
    name: "StockLevels",
    components: {SvgSymbols},


    data() {
        return {
            gettingStockLevels: false,
            stockLevels: [],
            totalStockValue: 0,
            totalItems: 0,
            productFilters: {},
            currentPage: 1,
            showUpdateStockItemDialog: false,
            activeItem: {},
            updatingItem: false,
            wastingItem: false,
            wasteItem: {},
            showingWasteStockDialog: false,
            showingBulkUpdateDialog: false,
            uploadedBulkUpdateFile: null,
            bulkUpdatingStock: false,
            updateStockLevelDialogIsActive: false,
            updatingStockLevel: false,
            newQuantity: 0,
            sortAttribute: null,
            sortOrder: null,
            sortOrderRemote: null,
        }
    },

    computed: {
        ...mapState('system', ['currentUser', 'settings', 'stores']),
        ...mapState('stock', ['productCategories', 'productSubCategories', 'uoms']),

        ...mapGetters('system', ['getBasicPayload', 'businessHasMultipleStores']),
        ...mapGetters('stock', ['getStockLevelsBaseUrl']),

        businessTracksExpiryDates() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'TRACK_EXPIRY_DATES')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        businessUsesVariants() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'USE_PRODUCT_VARIATIONS')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        businessHasEnabledAttendantCommissions() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'ENABLE_ATTENDANT_COMMISSIONS')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        filterSubCategories() {
            if (!this.productFilters.category_id) {
                return this.productSubCategories
            }

            return this.productSubCategories.filter(subCat => subCat.category_id === this.productFilters.category_id)
        },

        updateItemSubcategories() {
            if (!this.activeItem.category_id) {
                return []
            }

            return this.productSubCategories.filter(subCat => subCat.category_id === this.activeItem.category_id)
        },
    },

    created() {
        this.getStockLevels()

        this.$store.dispatch('stock/getProductCategories')
        this.$store.dispatch('stock/getProductSubCategories')
        this.$store.dispatch('stock/getProductUoms')

        if (!this.settings || this.empty(this.settings)) {
            this.$store.dispatch('system/getSettings')
        }

        // if (!this.stores || this.empty(this.stores)) {
        this.$store.dispatch('system/fetchStores')
        // }
    },

    mounted() {
        this.productFilters.store_id = this.currentUser.store_id
    },

    methods: {
        getStockLevels() {
            let payload = {
                store_id: this.productFilters.store_id ?? this.currentUser.store_id,
                page: (this.currentPage - 1),
                name: this.productFilters.product_name,
                category_id: this.productFilters.category_id,
                sub_category_id: this.productFilters.sub_category_id,
                sortOrder: this.sortOrderRemote,
                sortAttribute: this.sortAttribute,
            }

            this.gettingStockLevels = true
            axios.get(`${this.getStockLevelsBaseUrl}/paginated`, {params: payload}).then(response => {
                this.gettingStockLevels = false

                this.stockLevels = response.data.data.items
                this.totalItems = response.data.data.count
                this.totalStockValue = response.data.data.stock_value
            }).catch(error => {
                this.gettingStockLevels = false
                this.$message.error('An error was encountered. Please reload the page')
            })
        },
        handleSortChange(sortData) {
            this.sortOrder = sortData.order;
            this.sortOrderRemote = sortData.order == null ? null : sortData.order === 'ascending' ? 'asc' : 'desc';
            this.sortAttribute = this.sortOrder == null ? null : sortData.prop;
            this.getStockLevels();
        },

        resetProductFilters() {
            this.productFilters = {
                store_id: this.currentUser.store_id
            }

            this.getStockLevels()
        },

        getStockLevelsByPage(newPage) {
            this.currentPage = newPage
            this.getStockLevels()
        },

        handleStockItemActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            if (commandName === 'update') {
                this.promptUpdateStockItem(this.stockLevels[commandIndex])
            }

            if (commandName === 'level') {
                this.activeItem = this.stockLevels[commandIndex]
                this.newQuantity = this.activeItem.quantity
                this.updateStockLevelDialogIsActive = true
            }

            if (commandName === 'remove') {
                this.promptRemoveItem(this.stockLevels[commandIndex])
            }

            if (commandName === 'waste') {
                this.promptWasteStock(this.stockLevels[commandIndex])
            }

            if (commandName === 'sellout') {
                this.promptSelloutItem(this.stockLevels[commandIndex])
            }
        },

        promptWasteStock(item) {
            this.wasteItem = {}
            this.wasteItem.id = item.id
            this.wasteItem.label = item.label
            this.wasteItem.initial_quantity = item.quantity
            this.wasteItem.quantity = 0

            this.showingWasteStockDialog = true
        },

        validateWasteQuantity(_) {
            if ((!this.wasteItem.quantity) || (isNaN(parseFloat(this.wasteItem.quantity))) || (parseFloat(this.wasteItem.quantity) > parseFloat(this.wasteItem.initial_quantity))) {
                $("#waste-quantity").addClass('is-invalid')
            } else {
                $("#waste-quantity").removeClass('is-invalid')
            }
        },

        wasteStock(e) {
            e.preventDefault()

            this.wastingItem = true
            axios.post(`${this.getStockLevelsBaseUrl}/waste`, {
                id: this.wasteItem.id,
                quantity: this.wasteItem.quantity,
                reason: this.wasteItem.reason,
                user_id: this.currentUser.id
            }).then(response => {
                this.wastingItem = false
                this.showingWasteStockDialog = false
                this.getStockLevels()
                this.$message.success('Stock wasted successfully')

                this.emitter.emit('stock-wasted')
            }).catch(error => {
                this.wastingItem = false
                this.$message.error('An error was encountered. Please try again')
            })
        },

        promptRemoveItem(item) {
            this.$confirm(
                'This action is will permanently remove this item from the system. Continue?',
                `Remove ${item.label}`,
                {
                    confirmButtonText: 'Yes, Remove',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            )
                .then(() => {
                    axios.post(`${this.getStockLevelsBaseUrl}/remove`, {id: item.id, user_id: this.currentUser.id})
                        .then(response => {
                            this.getStockLevels()
                            this.$message.success('Item removed successfully')
                        }).catch(error => {
                        this.$message.error('An error was encountered. Please try again')
                    })
                })
                .catch(() => {
                    //
                })
        },

        promptSelloutItem(item) {
            this.$confirm(
                `This action assumes you\'ve sold out 1 ${item.units} of ${item.label}. Continue?`,
                'Confirm Sellout',
                {
                    confirmButtonText: 'Yes, Confirmed',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            )
                .then(() => {
                    axios.post(`${this.getStockLevelsBaseUrl}/sellout`, {
                        item_id: item.id,
                        current_user_id: this.currentUser.id
                    })
                        .then(response => {
                            this.getStockLevels()
                            this.$message.success('Item sold out successfully')
                        }).catch(error => {
                        this.$message.error('An error was encountered. Please try again')
                    })
                })
                .catch(() => {
                    //
                })
        },

        promptUpdateStockItem(item) {
            this.activeItem = JSON.parse(JSON.stringify(item))
            this.activeItem.can_sell = this.activeItem.can_sell === 1
            this.activeItem.can_stock = this.activeItem.can_stock === 1
            this.showUpdateStockItemDialog = true
        },

        resetActiveItemSubcategory() {
            this.activeItem.sub_category_id = null
        },

        updateStockItem() {
            let dataIsValid = true
            if (!this.activeItem.label) {
                dataIsValid = false
                $("#product-name").addClass('is-invalid')
            } else {
                $("#product-name").removeClass('is-invalid')
            }

            if (!this.activeItem.category_id) {
                dataIsValid = false
                $("#category").css('display', 'block')
            } else {
                $("#category").css('display', 'none')
            }

            if (!this.activeItem.sub_category_id) {
                dataIsValid = false
                $("#sub-category").css('display', 'block')
            } else {
                $("#sub-category").css('display', 'none')
            }

            if (!this.activeItem.unit_id) {
                dataIsValid = false
                $("#units").css('display', 'block')
            } else {
                $("#units").css('display', 'none')
            }

            if (dataIsValid) {
                this.activeItem.user_id = this.currentUser.id
                this.activeItem.can_sell = this.activeItem.can_sell ? 1 : 0
                this.activeItem.can_stock = this.activeItem.can_stock ? 1 : 0

                this.updatingItem = true
                axios.post(`${this.getStockLevelsBaseUrl}/update`, {entry: JSON.stringify(this.activeItem)}).then(res => {
                    this.updatingItem = false
                    this.showUpdateStockItemDialog = false
                    this.getStockLevels()

                    this.$message.success('Item updated successfully')
                }).catch(error => {
                    this.updatingItem = false
                    this.$message.error('An error occurred. Please try again')
                })
            }
        },

        promptBulkStockUpdate() {
            this.uploadedBulkUpdateFile = null
            this.showingBulkUpdateDialog = true
        },

        handleBulkUpdateFileSelection(event) {
            this.uploadedBulkUpdateFile = event.target.files[0]
        },

        bulkUpdateStock() {
            if (!this.uploadedBulkUpdateFile) {
                $("#file").addClass('is-invalid')
                return this.$message.error('Please select a file')
            } else {
                $("#file").addClass('is-invalid')
            }

            let headers = {
                'Content-Type': 'multipart/form-data'
            }

            let formData = new FormData()
            formData.append('file', this.uploadedBulkUpdateFile)
            formData.append('store_id', this.currentUser.store_id)
            formData.append('user_id', this.currentUser.id)

            this.bulkUpdatingStock = true
            axios.post(`${this.getStockLevelsBaseUrl}/import`, formData, {headers: headers}).then(res => {
                this.bulkUpdatingStock = false
                this.showingBulkUpdateDialog = false
                this.$message.success('Stock updated successfully')
                this.getStockLevels()
            }).catch(error => {
                this.bulkUpdatingStock = false
                this.$message.error('An error was encountered. Please try again')
            })
        },

        updateStockLevel(e) {
            e.preventDefault()

            let payload = {
                item_id: this.activeItem.id,
                new_quantity: this.newQuantity,
                current_user_id: this.currentUser.id
            }
            this.updatingStockLevel = true
            axios.post(`${this.getStockLevelsBaseUrl}/update-stock-level`, payload).then(res => {
                this.updatingStockLevel = false
                this.getStockLevels()
                this.updateStockLevelDialogIsActive = false
                this.newQuantity = 0
                this.$message.success("Stock level updated successfully")
            }).catch(err => {
                this.updatingStockLevel = false
                this.$message.error("An error occurred while updating. Please try again")
            })
        },
    },
}
</script>

<style>
.update-stock-item-level-input {
    margin-right: 10px;
    width: 80px;
    display: inline-block;
    height: 25px;
    font-size: 14px;
    font-weight: 600;
}

.dialog-body .el-radio-group {
    margin-top: 10px;
}

.dialog-body .el-radio {
    font-weight: 700;
    position: relative;
    width: 100%;
}

.dialog-body .el-radio:not(:last-child) {
    margin-bottom: 15px;
}

#bulk-update-guide-list li {
    margin-bottom: 12px;
    word-break: break-word;
}
</style>
