<template>
  <div id="products-classification" class="master-template">
      <h5 class="master-title"> Products Classification </h5>

      <div class="master-content">
          <el-tabs v-model="activeTab" class="classification-tabs" @tab-click="handleClick">
              <el-tab-pane label="Categories" name="categories">
                  <product-categories></product-categories>
              </el-tab-pane>
              <el-tab-pane label="Sub Categories" name="sub-categories">
                  <product-sub-categories></product-sub-categories>
              </el-tab-pane>
          </el-tabs>
      </div>
  </div>
</template>

<script>
import ProductCategories from './ProductCategoriesComponent'
import ProductSubCategories from './ProductsSubCategories'

export default {
    name: "ProductsClassificationComponent",

    components: {
        ProductCategories,
        ProductSubCategories,
    },

    data() {
        return {
            activeTab: 'categories',
        }
    },

    methods: {
        handleClick(tab, event) {},
    },
}
</script>

<style scoped>

</style>
