<template>
    <div id="master-nav" class="bg-white shadow-sm d-flex justify-content-between align-items-center py-1">

        <div id="master-brand">
            <a href="/dashboard">
                <img src="/logo.png" alt="fuatilia POS">
            </a>
        </div>

        <div class="d-flex flex-grow-1 justify-content-between align-items-center master-nav-container">
            <div id="business-branding" class="d-flex justify-content-center align-items-center" v-if="currentUser">
                <img src="/assets/images/store.png" alt="fuatilia POS" style="height: 25px;" class="mr-12">
                <span>{{ currentUser.business_name }}, {{ currentUser.store_name }}</span>
            </div>

            <!-- Actions -->
            <div class="master-actions">
                <div class="profile fw-600">
                    <!-- Actions -->
                    <div class="actions hide-sm">
                        <a href="/pos" class="btn btn-light" v-if="currentUser && can('access_pos', currentUser)">
                            GO TO POS </a>
                    </div>

                    <!-- Notifications -->
                    <app-bar-notifications v-if="currentUser && can('see_notifications', currentUser)"></app-bar-notifications>

                    <!-- Account -->
                    <div class="current-user-profile">
                        <el-dropdown>
                                <span class="el-dropdown-link d-flex align-items-center">
                                  <div class="avatar d-flex align-items-center">
                                      <el-avatar src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" size="small"></el-avatar>
                                      <span style="font-weight: 600; margin-right: 8px; margin-left: 8px; font-size: 18px;"
                                            v-if="currentUser">{{ currentUser.name }}</span>
                                  </div>
                                  <i class="fas fa-chevron-down"></i>
                                </span>

                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item @click="goToLogout" style="font-size: 16px;">Logout</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>


                    <form method="POST" action="/logout" style="display: none;" id="logout-form">
                        <!-- CSRF -->
                        <input type="hidden" name="_token" :value="csrf">
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import $ from 'jquery'
import AppBarNotifications from "../feedback/AppBarNotifications.vue";

export default {
    name: "TopBar",
    components: {AppBarNotifications},

    data() {
        return {
            now: null,
            menuIsOpen: false,
            shouldLogLogin: false,
            notifications: [],
            loadingNotifications: false,
            errorFetchingNotifications: false,

            notificationsAreVisible: false,

            processingNotifications: false,
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),

        csrf() {
            return this.getCsrf()
        },

        notificationCount() {
            return this.notifications.length
        },

        CircleCheckIcon() {
            return CircleCheck
        },
    },

    created() {
        this.fetchNotifications()
    },

    methods: {
        goToLogout() {
            // Clear state
            this.$store.commit('system/resetNavKeys')
            this.$store.dispatch('system/resetAll')
            this.$store.dispatch('pos/resetAll')
            this.$store.dispatch('stock/resetAll')
            this.$store.dispatch('users/resetAll')
            this.$store.dispatch('procurement/resetAll')

            $("#logout-form").submit();
        },

        fetchNotifications() {
            let payload = {store_id: this.currentUser.store_id}
            this.loadingNotifications = true
            axios.get('/api/notifications/unread', {params: payload}).then(response => {
                this.loadingNotifications = false
                this.errorFetchingNotifications = false
                this.notifications = response.data.data
            }).catch(() => {
                this.loadingNotifications = false
                this.errorFetchingNotifications = true
            })
        },

        markNotificationAsRead(notification) {
            this.processingNotifications = true
            axios.post('/api/notifications/read', {id: notification.id}).then(() => {
                this.processingNotifications = false
                this.fetchNotifications()
                this.$message.success('Success!')
            }).catch(() => {
                this.processingNotifications = false
                this.$message.error('An error was encountered. Please try again.')
            })
        },

        markAllNotificationsAsRead() {
            this.processingNotifications = true
            axios.post('/api/notifications/read-all', {store_id: this.currentUser.store_id}).then(() => {
                this.processingNotifications = false
                this.fetchNotifications()
                this.$message.success('Success!')

                this.notificationsAreVisible = false
            }).catch(() => {
                this.processingNotifications = false
                this.$message.error('An error was encountered. Please try again.')
            })
        },
    },
}
</script>

<style>
#master-nav {
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

#master-brand {
    width: 250px;
}

#master-brand img {
    width: 150px;
}

#business-branding {
    padding-left: 40px;
    font-weight: 600;
    font-size: 20px;
}

.profile {
    display: flex;
    align-items: center;
    padding-right: 40px;
}

#master-nav .actions {
    margin-right: 30px;
}

#master-nav .actions button, #master-nav .actions a {
    font-weight: 800;
    border: 1px solid #01358D;
    margin-left: 15px;
    background-color: #01358D;
    color: white;
}

#master-nav .actions button:hover, #master-nav .actions a:hover {
    color: #01358D;
    background-color: #f8f9fa;
}

#master-nav .actions button:hover {
    border-color: #01358D;
}

#current-time i {
    margin-right: 12px;
}

#notification-content {
    position: absolute;
    right: 0;
    width: 30vw;
    padding: 0 !important;
    margin: 0 !important;
}

#notification-content ul {
    list-style-type: none;
    padding: 0 !important;
    margin: 0 !important;
}

.notification {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 15px;
}

.notification-title {
    font-weight: 700;
    font-size: 18px;
}

.notification-timestamp {
    font-weight: 600;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.5);
}

.notification-message {
    font-size: 16px;
    word-break: break-word;
    font-weight: 500;
}
</style>
