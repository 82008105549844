<template>
    <div id="product-categories">
        <div class="master-subtitle-div d-flex justify-content-between align-items-center">
            <h5> Product Categories </h5>

            <div>
                <button class="btn btn-sm btn-outline-primary new-item-btn" @click="refreshList">
                    <i class="fas fa-sync"></i>Refresh List
                </button>

                <button class="btn btn-primary btn-sm new-item-btn" @click="promptAddNewCategory">
                    <i class="fas fa-plus-circle"></i>Add New Category
                </button>
            </div>
        </div>

        <div class="data-table mt-3">
            <div class="data-table-summary d-flex justify-content-end">
                <span> Showing {{ productCategories.length }} out of {{ productCategories.length }} categories </span>
            </div>

            <el-skeleton :loading="gettingProductCategories" :throttle="200" animated>
                <template #template>
                    <el-skeleton-item variant="h3" v-for="row in 7" :key="row"
                                      style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                </template>

                <template #default>
                    <el-table :data="productCategories" style="width: 100%"
                              :empty-text="'No categories found. ' + emptyText">
                        <el-table-column prop="name" label="Category Name"/>
                        <el-table-column prop="created_at" label="Date Created"/>
                        <el-table-column label="Actions">
                            <template #default="scope">
                                <el-button size="small" type="primary" :icon="Edit"
                                           @click="promptUpdateCategory(scope.$index, scope.row)"
                                >Update
                                </el-button
                                >
                                <el-button
                                    size="small"
                                    type="danger"
                                    :icon="Delete"
                                    @click="promptRemoveCategory(scope.$index, scope.row)">
                                    Remove
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </el-skeleton>
        </div>

        <!-- START OF ADD NEW Category MODAL -->
        <el-dialog
            v-model="showAddCategoryDialog"
            title="Add Category"
            width="30%"
        >
            <form @submit.prevent="addNewCategory"
                  v-loading="addingNewCategory"
                  element-loading-text="Adding Category"
                  id="new-category-form"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="category-name"> Category Name </label>
                    <input type="text" id="category-name" class="form-control" v-model="newCategoryName"
                           placeholder="Category name">
                    <div class="invalid-feedback"> {{ categoryNameErrorMessage }}</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showAddCategoryDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addNewCategory"> Add Category</button>
                </div>
            </template>
        </el-dialog>
        <!-- END OF ADD NEW CATEGORY MODAL -->

        <!-- START OF UPDATE Category MODAL -->
        <el-dialog
            v-model="showUpdateCategoryDialog"
            title="Update Category Details"
            width="30%"
        >
            <form @submit.prevent="updateCategory"
                  v-loading="updatingNewCategory"
                  element-loading-text="Updating Category"
                  id="update-category-form"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="update-category-name"> Category Name </label>
                    <input type="text" id="update-category-name" class="form-control" v-model="activeCategory.name"
                           placeholder="Category name">
                    <div class="invalid-feedback"> Category name is required</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showUpdateCategoryDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="updateCategory"> Update Category</button>
                </div>
            </template>
        </el-dialog>
        <!-- END OF ADD UPDATE CATEGORY MODAL -->

    </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import $ from 'jquery'

import {Delete, Edit} from '@element-plus/icons-vue'

export default {
    name: "ProductCategories",

    data() {
        return {
            nameFilter: null,
            newCategoryName: null,
            showAddCategoryDialog: false,
            addingNewCategory: false,
            activeCategory: {},
            showUpdateCategoryDialog: false,
            updatingNewCategory: false,
            categoryNameErrorMessage: 'Category name is required'
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
        ...mapState('stock', ['productCategories', 'gettingProductCategories']),

        ...mapGetters('stock', ['getClassificationBaseUrl']),

        Edit() {
            return Edit
        },

        Delete() {
            return Delete
        },

        emptyText() {
            return this.nameFilter ? 'Try a different filter.' : 'Click on the Add New Category button to add a new category.'
        },

        getCategoriesBaseUrl() {
            return `${this.getClassificationBaseUrl}/categories`
        },
    },

    created() {
        // Only fetch if store is empty or null
        if (this.empty(this.productCategories)) {
            this.$store.dispatch('stock/getProductCategories')
        }
    },

    methods: {
        getCategoryIndex(queryId) {
            return this.productCategories.findIndex(category => category.id === queryId)
        },

        promptAddNewCategory() {
            this.newCategoryName = null
            this.showAddCategoryDialog = true
        },

        addNewCategory(e) {
            e.preventDefault()

            // Validation
            let dataIsValid = true
            this.categoryNameErrorMessage = 'Category name is required'
            if (!this.newCategoryName || this.newCategoryName === '') {
                dataIsValid = false
                $("#category-name").addClass('is-invalid')
            } else {
                $("#category-name").removeClass('is-invalid')
            }

            if (dataIsValid) {
                let payload = {
                    store_id: this.currentUser.store_id,
                    user_id: this.currentUser.id,
                    name: this.newCategoryName,
                }

                this.addingNewCategory = true
                axios.post(`${this.getCategoriesBaseUrl}/add`, payload).then(response => {
                    this.$store.commit('stock/addCategory', response.data.data)

                    this.addingNewCategory = false
                    this.showAddCategoryDialog = false
                    this.$message.success('Category added successfully')
                }).catch(error => {
                    this.addingNewCategory = false

                    if (error.response && error.response.status === 422) {
                        this.categoryNameErrorMessage = 'A category with this name already exists'
                        $("#category-name").addClass('is-invalid')
                        this.$message.error(this.categoryNameErrorMessage)
                    } else {
                        this.$message.error('An error was encountered. Please try again')
                    }
                })
            } else {
                this.$message.warning('Please fill all fields')
            }
        },

        promptUpdateCategory(index, category) {
            this.activeCategory = category
            this.showUpdateCategoryDialog = true
        },

        updateCategory(e) {
            e.preventDefault()

            // Validation
            let dataIsValid = true
            if (!this.activeCategory.name || this.activeCategory.name === '') {
                dataIsValid = false
                $("#update-category-name").addClass('is-invalid')
            } else {
                $("#update-category-name").removeClass('is-invalid')
            }

            if (dataIsValid) {
                let payload = {
                    id: this.activeCategory.id,
                    user_id: this.currentUser.id,
                    name: this.activeCategory.name
                }

                this.updatingNewCategory = true
                axios.post(`${this.getCategoriesBaseUrl}/update`, payload).then(response => {
                    this.updatingNewCategory = false

                    let existingCategoryIndex = this.getCategoryIndex(response.data.data.id)
                    if (existingCategoryIndex !== -1) {
                        this.$store.commit('stock/updateCategory', {
                            index: existingCategoryIndex,
                            category: response.data.data
                        })
                    }

                    this.showUpdateCategoryDialog = false
                    this.$message.success('Category updated successfully')
                }).catch(error => {
                    this.updatingNewCategory = false
                    this.$message.error('An error was encountered. Please try again')
                })
            } else {
                this.$message.warning('Please fill all fields')
            }
        },

        promptRemoveCategory(index, category) {
            this.$confirm(
                'This action is permanent and will remove all sub categories and products under this category. Continue?',
                'Remove Category',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            )
                .then(() => {
                    axios.post(`${this.getCategoriesBaseUrl}/remove`, {id: category.id, user_id: this.currentUser.id})
                        .then(response => {
                            let existingCategoryIndex = this.getCategoryIndex(category.id)
                            if (existingCategoryIndex !== -1) {
                                this.$store.commit('stock/removeCategory', existingCategoryIndex)
                                this.$store.dispatch('stock/getProductSubCategories')
                            }

                            this.$message.success('Category removed successfully')
                        }).catch(error => {
                        this.$message.error('An error was encountered. Please try again')
                    })
                })
                .catch(() => {
                    //
                })
        },

        refreshList() {
            this.$store.dispatch('stock/getProductCategories')
        }
    },
}
</script>

<style scoped>

</style>
