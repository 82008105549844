<template>
    <div id="dashboard" class="master-template" v-if="can('access_dashboard', currentUser)">
        <div class="d-flex justify-content-between align-items-center master-title-div">
            <h5 class="master-title" style="margin-bottom: 0;"> Today's Summary </h5>
            <button class="btn btn-outline-primary new-item-btn" @click="refreshDashboard">
                <i class="fas fa-sync"></i>Refresh Data
            </button>
        </div>

        <!-- Top Card -->
        <el-skeleton :loading="gettingAnalytics" :throttle="200" animated>
            <template #template>
                <div class="analytic-cards d-flex justify-content-between">
                    <el-skeleton-item variant="rect" v-for="row in 4" :key="row" style="width: 22%; height: 95px;"/>
                </div>
            </template>
            <template #default>
                <div class="analytic-cards d-flex justify-content-between flex-wrap">
                    <!-- Collections -->
                    <div class="analytic-card card shadow flex-column" style="background-color: #DFECE0;">
                        <span class="analytic-card-title"> Collections </span>

                        <div class="analytic-card-icon d-flex justify-content-center align-items-center">
                            <div class="flex-grow-1">
                                <span class="analytic-card-value"> {{ formatAmount(analytics.top_cards.collections) }} </span>
                            </div>
                            <i class="fas fa-hand-holding-usd"></i>
                        </div>
                    </div>

                    <!-- Money Out -->
                    <div class="analytic-card card shadow flex-column" style="background-color: #f2d1d9;">
                        <span class="analytic-card-title"> Expenses </span>

                        <div class="analytic-card-icon d-flex justify-content-center align-items-center">
                            <div class="flex-grow-1">
                                <span class="analytic-card-value"> {{ formatAmount(analytics.top_cards.money_out) }} </span>
                            </div>
                            <i class="fas fa-sign-out-alt"></i>
                        </div>
                    </div>

                    <!-- Sales -->
                    <div class="analytic-card card shadow flex-column" style="background-color: rgba(230, 226, 249, 1);">
                        <!--                    <div class="analytic-card card shadow d-flex flex-row" style="background-color: #E2EDF9;">-->
                        <span class="analytic-card-title"> Total Sales </span>

                        <div class="analytic-card-icon d-flex justify-content-center align-items-center">
                            <div class="flex-grow-1">
                                <span class="analytic-card-value"> {{ formatAmount(analytics.top_cards.sales) }} </span>
                            </div>
                            <i class="fas fa-shopping-bag"></i>
                        </div>
                    </div>

                    <!-- Profit -->
                    <div class="analytic-card card shadow flex-column" style="background-color: #98ed9e;">
                        <span class="analytic-card-title"> Gross Profit </span>

                        <div class="analytic-card-icon d-flex justify-content-center align-items-center">
                            <div class="flex-grow-1">
                                <span class="analytic-card-value"> {{ formatAmount(analytics.top_cards.profit) }} </span>
                            </div>
                            <i class="fas fa-chart-line"></i>
                        </div>
                    </div>
                </div>
            </template>
        </el-skeleton>

        <el-skeleton :loading="gettingAnalytics" :throttle="200" animated style="margin-top: 40px;">
            <template #template>
                <div class="d-flex justify-content-between">
                    <el-skeleton-item variant="rect" style="height: 300px; width: 60%;"/>
                    <el-skeleton-item variant="rect" style="height: 300px; width: 35%;"/>
                </div>
            </template>
            <template #default>
                <div class="d-flex justify-content-between flex-wrap dashboard-summaries" style="margin-top: 40px;">
                    <!-- Sales Summary -->
                    <div class="card shadow-sm" style="width: 100%;">
                        <h5 class="card-title"> Sales Summary This Week </h5>

                        <div class="card-content" id="dashboard-sales-summary" style="min-height: 500px;"></div>
                    </div>

                    <!-- Top Products -->
<!--                    <div class="card shadow-sm top-selling-products" style="width: 45%;">-->
<!--                        <h5 class="card-title"> Top Selling Products Today </h5>-->

<!--                        <div class="card-content">-->
<!--                            <el-table :data="analytics.products" style="width: 100%" empty-text="No sales made today.">-->
<!--                                <el-table-column prop="product_name" label="Product"/>-->
<!--                                <el-table-column prop="count" label="Qty Sold"/>-->
<!--                                <el-table-column label="Total">-->
<!--                                    <template #default="scope">-->
<!--                                        <span>{{ formatAmount(scope.row.sale_total) }}</span>-->
<!--                                    </template>-->
<!--                                </el-table-column>-->
<!--                            </el-table>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </template>
        </el-skeleton>
    </div>

    <div v-else>
        <access-denied></access-denied>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

export default {
    name: "DashboardComponent",

    data() {
        return {
            root: null,
            analytics: {
                top_cards: {
                    collections: 0,
                    money_out: 0,
                    sales: 0,
                    profit: 0,
                },
                products: [],
                sales: [],
                summary: [],
            },
            gettingAnalytics: false,
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
        ...mapGetters('system', ['getBasicPayload']),
    },

    created() {
       // if (!this.currentUser) {
       //     this.getCurrentUser()
       // } else {
       //     if (this.can('access_dashboard', this.currentUser)) {
       //         this.getDashboardAnalytics()
       //     }
       // }
    },

    beforeUnmount() {
        if (this.root) {
            this.root.dispose()
        }
    },

    methods: {
        getCurrentUser() {
            axios.get('/api/users/users/current').then(res => {
                this.$store.commit('system/updateCurrentUser', res.data.data)
                if (this.can('access_dashboard', this.currentUser)) {
                    this.getDashboardAnalytics()
                }
            }).catch(error => {
                this.$message.error('An error was encountered. Please reload the page')
            })
        },

        getDashboardAnalytics() {
            if (!this.gettingAnalytics) {
                this.gettingAnalytics = true
            }

            axios.get('/api/business/analytics', {params: this.getBasicPayload}).then(response => {
                this.analytics = response.data.data.analytics
                this.gettingAnalytics = false

                if (this.root) {
                    this.root.dispose()
                }


                setTimeout(() => {
                    this.renderSummaryChart()
                }, 1500)
            }).catch(error => {
                this.gettingAnalytics = false
                this.$message.error('Error loading analytics. Please reload the page')
            })
        },

        refreshDashboard() {
            this.getDashboardAnalytics()
        },

        renderSummaryChart() {
            // Instantiate chart
            let root = am5.Root.new("dashboard-sales-summary");
            root.setThemes([am5themes_Animated.new(root)]);

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: true,
                    panY: true,
                    wheelX: "panX",
                    wheelY: "zoomX",
                    pinchZoomX: true
                })
            );

            // Add cursor
            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

            // Create Y-axis
            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {}),
                    maxDeviation: 0.3
                })
            );

            // Create X-Axis
            let xRenderer = am5xy.AxisRendererX.new(root, {minGridDistance: 30});
            xRenderer.grid.template.setAll({
                location: 0.5
            });
            let xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(root, {
                    renderer: xRenderer,
                    // renderer: am5xy.AxisRendererX.new(root, {}),
                    categoryField: "day",
                    tooltip: am5.Tooltip.new(root, {}),
                    maxDeviation: 0.3
                })
            );

            // Create series
            let series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: "Total Sales",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "sales",
                    categoryXField: "day",
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "{valueY}"
                    }),
                    sequencedInterpolation: true
                })
            );

            series.columns.template.setAll({cornerRadiusTL: 5, cornerRadiusTR: 5});
            series.columns.template.adapters.add("fill", function (fill, target) {
                return chart.get("colors").getIndex(series.columns.indexOf(target));
            });

            series.columns.template.adapters.add("stroke", function (stroke, target) {
                return chart.get("colors").getIndex(series.columns.indexOf(target));
            });

            // Set data
            xAxis.data.setAll(this.analytics.summary);
            series.data.setAll(this.analytics.summary);

            series.appear(1000);
            chart.appear(1000, 100);

            this.root = root
        },
    },
}
</script>

<style>
.analytic-card {
    outline: none;
    border: none;
    border-radius: 10px;
    padding: 12px 15px;
    width: 22%;
    flex-direction: row;
    position: relative;
}

.analytic-card span {
    display: block;
}

.analytic-card-title {
    font-weight: 700;
    font-size: 18px;
}

.analytic-card-value {
    font-weight: 800;
    font-size: 25px;
    margin-top: 12px;
}

.analytic-card-icon i {
    font-size: 30px;
}
</style>
