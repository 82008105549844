<script>
import {defineComponent} from 'vue'
import {mapGetters, mapState} from "vuex";
import $ from 'jquery'
import Tip from "../../components/shared/Tip.vue";
import SvgSymbols from "../../components/shared/SvgSymbols.vue";

export default defineComponent({
    name: "CreditSalesView",
    components: {SvgSymbols, Tip},

    data() {
        return {
            baseUrl: '/api/sales/credit-sales',
            loadingCreditSales: false,
            creditSales: [],
            totalCreditSales: 0,
            filters: {
                sale_number: null,
                customer_id: null,
                sale_date: null,
            },
            currentPage: 1,

            paymentModalIsActive: false,
            payment: {
                method: null,
                amount: 0,
                date: null
            },
            activeSale: null,
            makingPayment: false,
            tipMessage: null,
            showTipMessage: false,
            customers: [],
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
        ...mapGetters('system', ['getBasicPayload']),
    },

    created() {
        this.getCreditSales()
        this.getCustomers()
    },

    methods: {
        getCreditSales() {
            let payload = {
                store_id: this.currentUser.store_id,
                page: (this.currentPage - 1),
                sale_number_filter: this.filters.sale_number,
                sale_date_filter: this.filters.sale_date,
                customer_id_filter: this.filters.customer_id,
            }

            this.loadingCreditSales = true
            axios.get(`${this.baseUrl}/paginated`, {params: payload}).then(res => {
                this.loadingCreditSales = false
                this.creditSales = res.data.data.content
                this.totalCreditSales = res.data.data.count
            }).catch(error => {
                this.loadingCreditSales = false
                this.$message.error('Error loading credit sales. Please reload the page')
            })
        },

        getCustomers() {
            axios.get('/api/customers', {params: this.getBasicPayload}).then(res => {
                this.customers = res.data.data
            }).catch(error => {
            })
        },

        handleCreditSaleActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            if (commandName === 'pay') {
                this.activeSale = this.creditSales[commandIndex]
                this.payment.amount = this.activeSale.amount_due
                this.payment.method = 'Cash'
                this.payment.date = this.getFormattedNow()

                this.resetTipMessage()
                this.paymentModalIsActive = true
            }
        },

        makePayment(e) {
            e.preventDefault()
            this.resetTipMessage()
            $("#payment-amount").removeClass('is-invalid')

            if (!this.payment.amount) {
                return $("#payment-amount").addClass('is-invalid')
            }

            if (isNaN(parseFloat(this.payment.amount))) {
                return $("#payment-amount").addClass('is-invalid')
            }

            if (parseFloat(this.payment.amount) > parseFloat(this.activeSale.amount_due)) {
                this.tipMessage = 'Payment amount is more than the due credit amount'
                this.showTipMessage = true
                return
            }

            if (!this.payment.method) {
                this.tipMessage = 'Please select a payment method'
                this.showTipMessage = true
                return
            }

            let payload = {
                credit_sale_id: this.activeSale.id,
                current_user_id: this.currentUser.id,
                payment_amount: this.payment.amount,
                payment_method: this.payment.method,
                payment_date: this.payment.date
            }

            this.makingPayment = true
            axios.post(`${this.baseUrl}/pay`, {payload: JSON.stringify(payload)}).then(_ => {
                this.makingPayment = false
                this.getCreditSales()
                this.paymentModalIsActive = false
                this.$message.success("Payment made successfully")
            }).catch(_ => {
                this.makingPayment = false
                this.$message.error("An error was encountered. Please try again")
            })
        },

        resetTipMessage() {
            this.tipMessage = null
            this.showTipMessage = false
        },

        resetFilters() {
            this.filters = {}
            this.getCreditSales()
        }
    },
})
</script>

<template>
    <div class="master-template">
        <div class="master-title-div d-flex justify-content-between align-items-center">
            <h5> Credit Sales </h5>
        </div>

        <div class="master-content">
            <div class="data-table-filters card shadow-sm">
                <h5> Filter Credit Sales </h5>

                <div class="row has-form-groups">
                    <div class="form-group col-md-4">
                        <label> Search by sale number </label>
                        <input type="text" class="form-control" v-model="filters.sale_number" placeholder="Search by sale number"
                               @keyup.enter="getCreditSales">
                    </div>

                    <div class="form-group col-md-4">
                        <label> Search by sale date </label>
                        <el-date-picker
                            v-model="filters.sale_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>

                    <div class="form-group col-md-4">
                        <label> Search by customer </label>
                        <el-select size="large" filterable v-model="filters.customer_id">
                            <el-option v-for="customer in customers" :key="customer.id" :value="customer.id" :label="customer.name"></el-option>
                        </el-select>
                    </div>
                </div>

                <div class="mt-3 d-flex">
                    <button class="btn btn-secondary mr-12" @click="getCreditSales"> Filter Credit Sales</button>
                    <el-button size="large" @click="resetFilters">Reset Filters</el-button>
                </div>
            </div>

            <div class="data-table-summary">
                <span class="summary"> Showing {{ creditSales.length }} out of {{ totalCreditSales }} credit sales. </span>
            </div>

            <div class="data-table">
                <el-skeleton :loading="loadingCreditSales" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" v-for="row in 7" :key="row" style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                    </template>

                    <template #default>
                        <el-table :data="creditSales" style="width: 100%">
                            <el-table-column label="Credit Amount" prop="display_amount"></el-table-column>
                            <el-table-column label="Parent Sale">
                                <template #default="scope">
                                    {{ scope.row.parent_sale?.display_sale_number }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Date of Sale" prop="display_sale_date"></el-table-column>
                            <el-table-column label="Customer">
                                <template #default="scope">
                                    {{ scope.row.customer?.name }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Status">
                                <template #default="scope">
                                    <el-button :type="scope.row.status_type" size="small">{{ scope.row.display_payment_status }}</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column label="Amount Paid" prop="display_amount_paid"></el-table-column>
                            <el-table-column label="Amount Due" prop="display_amount_due"></el-table-column>
                            <el-table-column label="Due Date" prop="display_due_date"></el-table-column>
                            <el-table-column label="Actions">
                                <template #default="scope">
                                    <el-dropdown @command="handleCreditSaleActions" v-if="scope.row.can_pay">
                                        <el-button type="primary" plain>
                                            Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item :command="'pay-' + scope.$index"> Settle
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-skeleton>

                <div class="data-table-pagination d-flex justify-content-end mt-3">
                    <el-pagination background layout="prev, pager, next" :total="totalCreditSales"
                                   :page-size="10" v-model:current-page="currentPage" @current-change="getCreditSales" hide-on-single-page/>
                </div>
            </div>
        </div>

        <!-- Make Payment -->
        <el-dialog
            v-model="paymentModalIsActive"
            title="Make payment"
            width="30%"
        >

            <div class="master-tip" v-if="showTipMessage">
                <svg-symbols></svg-symbols>
                <tip type="danger">
                    {{ tipMessage }}
                </tip>
            </div>

            <form @submit.prevent="makePayment"
                  v-loading="makingPayment"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="expense-amount"> Payment Amount </label>
                    <input type="text" id="payment-amount" class="form-control" v-model="payment.amount" placeholder="Payment amount">
                    <div class="invalid-feedback"> Please provide a valid amount</div>
                </div>

                <div class="form-group">
                    <label> Payment Date </label>
                    <el-date-picker
                        v-model="payment.date"
                        type="date"
                        placeholder="Pick a day"
                        format="MMM D, YYYY"
                        value-format="YYYY-MM-DD"
                        size="large"
                    >
                    </el-date-picker>
                </div>

                <div class="form-group">
                    <label class="display-block"> Payment Method </label>
                    <el-radio-group v-model="payment.method" size="large">
                        <el-radio label="Cash" border>Cash</el-radio>
                        <el-radio label="MPESA" border>Bank/MPESA Till</el-radio>
                    </el-radio-group>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="paymentModalIsActive = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="makePayment"> Make Payment</button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<style scoped>

</style>
