<template>
    <div class="master-template">
        <div class="master-title">
            <h5> Stock Levels Report </h5>
        </div>

        <div class="master-content">
            <div class="data-table-filters card shadow bg-white">
                <h5> Report Filters </h5>

                <div class="row">
                    <div class="form-group col-md-5">
                        <label> Filter by item name </label>
                        <input type="text" class="form-control" v-model="filters.item_name" placeholder="Filter by item name">
                    </div>
                </div>

                <div class="mt-3 d-flex">
                    <button class="btn btn-secondary mr-12" @click="getReport"> Apply Filters </button>
                    <el-button size="large" @click="resetFilters">Reset Filters</el-button>
                </div>
            </div>

            <div class="data-table-summary d-flex justify-content-between mt-4 px-1">
                <span class="summary"></span>
                <span class="summary"> Showing {{ report.length }} out of {{ totalItems }} items. </span>
            </div>

            <div class="data-table">
                <el-skeleton :loading="gettingReport" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" v-for="row in 7" :key="row" style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                    </template>

                    <template #default>
                        <el-table :data="report" style="width: 100%" empty-text="No items found.">
                            <el-table-column prop="item_name" label="Item"/>
                            <el-table-column prop="stock_level" label="Stock Level"/>
                            <el-table-column label="Stock Value">
                                <template #default="scope">
                                    <span>{{ formatAmount(scope.row.stock_value) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Stock Cost">
                                <template #default="scope">
                                    <span>{{ formatAmount(scope.row.stock_cost) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="can_sell" label="Can Sell"/>
                            <el-table-column prop="can_stock" label="Can Stock"/>
                        </el-table>
                    </template>
                </el-skeleton>
            </div>

            <!-- Pagination -->
            <div class="data-table-pagination d-flex justify-content-end mt-3">
                <el-pagination background layout="prev, pager, next" :total="totalItems"
                               :page-size="10" v-model:current-page="currentPage" @current-change="getReport" hide-on-single-page/>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Close} from "@element-plus/icons-vue";

export default {
    name: "StockLevelsReport",

    data() {
        return {
            gettingReport: false,
            report: [],
            currentPage: 1,
            totalItems: 0,
            filters: {}
        }
    },

    computed: {
        ...mapGetters('system', ['getBasicPayload']),

        Close() {
            return Close
        },
    },

    created() {
        this.getReport()
    },

    methods: {
        getReport() {
            let payload = {...this.getBasicPayload, ...{page: (this.currentPage - 1), item_name: this.filters.item_name}}

            this.gettingReport = true
            axios.get('/api/reports/stocks/stock-levels-report', {params: payload}).then(res => {
                this.gettingReport = false
                this.report = res.data.data.content
                this.totalItems = res.data.data.count
            }).catch(error => {
                this.gettingReport = false
                this.$message.error('Error generating report. Please reload the page')
            })
        },

        resetFilters() {
            this.filters = {}
            this.getReport()
        },
    },
}
</script>

<style scoped>

</style>
