<script>
import {mapState} from "vuex";
import $ from "jquery";

export default {
    name: "NewOrder",

    data() {
        return {
            newOrder: {
                order_date: null,
                customer_id: null,
                items: [],
            },
            savingOrder: false,

            loadingStockItems: false,
            stockItems: [],
            itemSearchQuery: null,
            errorFetchingStockItems: false,

            loadingCategories: false,
            categories: [],
            selectedCategoryId: 0,

            loadingSubCategories: false,
            subCategories: [],
            selectedSubCategoryId: 0,

            loadingCustomers: false,
            customers: [],
            showNewCustomerDialog: false,
            newCustomer: {},
            addingCustomer: false,

            printKitchenOrder: false,
        }
    },

    computed: {
        ...mapState('system', ['currentUser', 'settings']),
        ...mapState('users', ['systemUsers']),
        ...mapState('pos', ['addingToExistingOrder', 'existingOrderId']),

        displayableItems() {
            let displayableItems = this.stockItems
            if (this.selectedCategoryId && this.selectedCategoryId !== 0) {
                displayableItems = displayableItems.filter(item => item.category_id === this.selectedCategoryId)
            }

            if (this.selectedSubCategoryId && this.selectedSubCategoryId !== 0) {
                displayableItems = displayableItems.filter(item => item.sub_category_id === this.selectedSubCategoryId)
            }

            if (this.itemSearchQuery) {
                displayableItems = displayableItems.filter(item => (item.label.toLowerCase()).includes(this.itemSearchQuery.toLowerCase()))
            }

            return displayableItems
        },

        displayableSubCategories() {
            let displayableSubCategories = this.subCategories
            if (this.selectedCategoryId && this.selectedCategoryId !== 0) {
                displayableSubCategories = displayableSubCategories.filter(subCat => subCat.category_id === this.selectedCategoryId)
                displayableSubCategories.unshift({id: 0, name: 'All'})
            }

            return displayableSubCategories
        },

        getOrderTotal() {
            let total = 0
            this.newOrder.items.forEach(item => {
                total += this.getOrderItemTotal(item)
            })

            return total
        },

        businessCanAddCustomers() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'CAN_SELECT_POS_CUSTOMER')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },

        businessHasEnabledAttendantSelection() {
            if (!this.settings) {
                return false
            }

            let setting = this.settings.find(setting => setting.setting === 'CAN_SELECT_SELLING_ATTENDANT')
            if (setting) {
                return parseInt(setting.value) === 1
            }

            return false
        },
    },

    created() {
        this.fetchStockItems(true)
    },

    mounted() {
        this.newOrder.order_date = this.getFormattedNow()
    },

    methods: {
        fetchStockItems(fetchMetaData = false) {
            this.loadingStockItems = true
            axios.get('/api/stock/levels/pos', {params: {store_id: this.currentUser.store_id}}).then(response => {
                this.loadingStockItems = false
                this.stockItems = response.data.data

                this.errorFetchingStockItems = false

                if (fetchMetaData) {
                    this.fetchOtherMetaData()
                }
            }).catch(error => {
                this.loadingStockItems = false
                this.errorFetchingStockItems = true
            })
        },

        fetchOtherMetaData() {
            if (!this.settings || this.empty(this.settings)) {
                this.$store.dispatch('system/getSettings')
            }

            if (!this.systemUsers || this.empty(this.systemUsers)) {
                this.$store.dispatch('users/getUsers')
            }

            this.fetchCategories()
            this.fetchSubCategories()
            this.fetchCustomers()
        },

        fetchCategories() {
            this.loadingCategories = true
            axios.get('/api/stock/classification/categories', {params: {store_id: this.currentUser.store_id}}).then(res => {
                this.loadingCategories = false
                this.categories = res.data.data
                this.categories.unshift({id: 0, name: 'All'})
            }).catch(error => {
                this.loadingCategories = false
            })
        },

        fetchSubCategories() {
            this.loadingSubCategories = true
            axios.get('/api/stock/classification/subcategories', {params: {store_id: this.currentUser.store_id}}).then(res => {
                this.loadingSubCategories = false
                this.subCategories = res.data.data
                this.displayableSubcategories = this.subCategories
                this.displayableSubcategories.unshift({id: 0, name: 'All'})
            }).catch(error => {
                this.loadingSubCategories = false
            })
        },

        fetchCustomers() {
            this.loadingCustomers = true
            axios.get('/api/customers', {params: {business_id: this.currentUser.business_id}}).then(res => {
                this.loadingCustomers = false
                this.customers = res.data.data

                this.newOrder.customer_id = this.customers[0].id
            }).catch(error => {
                this.loadingCustomers = false
            })
        },

        setSelectedCategoryId(categoryId) {
            this.selectedCategoryId = categoryId
        },

        setSelectedSubCategoryId(subCategoryId) {
            this.selectedSubCategoryId = subCategoryId
        },

        discardOrder() {
            return window.location.assign('/pos')
        },

        saveOrder(receivePayment = false) {
            this.newOrder.store_id = this.currentUser.store_id
            this.newOrder.adding_to_existing_order = this.addingToExistingOrder ? 1 : 0
            this.newOrder.existing_order_id = this.existingOrderId

            this.savingOrder = true
            axios.post('/api/orders/store', {order_details: JSON.stringify(this.newOrder)}).then(async res => {
                this.savingOrder = false
                this.$message.success("Order saved successfully.")

                if (this.newOrder.adding_to_existing_order === 1) {
                    this.$store.commit('pos/toggleAddingToExistingOrder', false)
                    this.$store.commit('pos/setExistingOrderId', null)                }

                await axios.post('http://printer.test/api/sales/kitchen-order', {sale: JSON.stringify(res.data.data)}).then(res => {
                    //
                }).catch(error => {
                    this.$message.error('Receipt was not printed')
                    console.log(error)
                })

                return window.location.assign('/pos')
            }).catch(error => {
                this.savingOrder = false
                this.$message.error("An error was encountered while saving this order. Please try again.")
            })
        },

        clearOrderItems() {
            this.newOrder.items = []
        },

        selectOrderItem(item) {
            let itemIsAlreadyAdded = this.newOrder.items.find(addedItem => addedItem.stock_item_id === item.id)
            if (itemIsAlreadyAdded) {
                return this.$message.warning("Item is already added to order items")
            }

            let orderItem = {
                stock_item_id: item.id,
                label: item.label,
                attendant_id: this.businessHasEnabledAttendantSelection ? null : this.currentUser.id,
                quantity: 1,
                batch_cost: item.buying_price,
                batch_selling_price: item.selling_price,
                actual_selling_price: item.selling_price,
            }

            this.newOrder.items.push(orderItem)
        },

        getOrderItemTotal(item) {
            try {
                return item.actual_selling_price * item.quantity
            } catch (e) {
                return 0
            }
        },

        removeOrderItem(index) {
            return this.newOrder.items.splice(index, 1)
        },

        promptNewCustomer() {
            this.newCustomer = {}
            this.showNewCustomerDialog = true
        },

        onNewCustomerPhoneInput(phone, phoneObject, input) {
            if (phoneObject?.formatted) {
                this.newCustomer.customer_number = phoneObject.formatted
            }
        },

        addCustomer() {
            if (!this.newCustomer.customer_name) {
                $("#new-customer-name").addClass('is-invalid')
                return this.$message.error('Customer name is required')
            } else {
                $("#new-customer-name").removeClass('is-invalid')
            }

            this.addingCustomer = true
            axios.post('/api/customers/new', {...this.newCustomer, ...{business_id: this.currentUser.business_id}}).then(res => {
                this.addingCustomer = false
                this.fetchCustomers()
                this.newOrder.customer_id = res.data.data?.id

                this.$message.success('Customer added successfully')
                this.showNewCustomerDialog = false
            }).catch(error => {
                this.addingCustomer = false
                this.$message.error('Error adding customer. Please try again')
            })
        },
    },
}
</script>

<template>
    <div id="new-order-div">
        <div id="content">
            <div class="d-flex justify-content-between align-items-center">
                <h5 class="f-22 fw-700 mb-0"> New Order </h5>

                <h5 class="mb-0 fw-800 f-22">Order Total: {{ formatAmount(getOrderTotal) }}</h5>
            </div>

            <div class="mt-3 row">
                <div class="form-group col-12 col-md-3">
                    <label class="required"> Order Date </label>
                    <el-date-picker
                        v-model="newOrder.order_date"
                        type="date"
                        size="large"
                        placeholder="Pick a day"
                        format="MMM D, YYYY"
                        value-format="YYYY-MM-DD"
                    >
                    </el-date-picker>
                </div>

                <div class="form-group col-12 col-md-3">
                    <label class="required"> Customer </label>
                    <div class="d-flex">
                        <el-select
                            v-model="newOrder.customer_id"
                            default-first-option
                            filterable
                            placeholder="Select a customer"
                            size="large">
                            <el-option
                                v-for="customer in customers"
                                :key="customer.id"
                                :label="customer.name"
                                :value="customer.id"
                            />
                        </el-select>

                        <el-button plain type="primary" size="large" @click="promptNewCustomer" v-if="businessCanAddCustomers"
                                   style="margin-left: 12px;">New Customer
                        </el-button>
                    </div>
                </div>
            </div>

            <div class="d-flex mt-3">
                <div class="card bg-white shadow-sm" style="width: 55%; margin-right: 20px; overflow-x: auto;"
                     v-loading="loadingStockItems" element-loading-text="Loading stock items. Please wait.">
                    <div id="categories-filter" class="categories-filter-div">
                        <h5 class="f-18 fw-700"> Categories </h5>

                        <div class="d-flex flex-wrap">
                            <el-button @click="setSelectedCategoryId(category.id)" v-for="category in categories" :key="category.id"
                                       :plain="selectedCategoryId !== category.id" size="large" type="primary" style="margin-bottom: 20px;">
                                {{ category.name }}
                            </el-button>
                        </div>
                    </div>

                    <div id="categories-filter" class="mt-2 categories-filter-div">
                        <h5 class="f-18 fw-700"> Sub Categories </h5>

                        <div class="d-flex flex-wrap">
                            <el-button @click="setSelectedSubCategoryId(subCategory.id)" v-for="subCategory in displayableSubCategories"
                                       :key="subCategory.id"
                                       :plain="selectedSubCategoryId !== subCategory.id" size="large" type="primary" style="margin-bottom: 20px;">
                                {{ subCategory.name }}
                            </el-button>
                        </div>
                    </div>

                    <div id="catalog" class="mt-2">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search item by name" v-model="itemSearchQuery">
                        </div>

                        <div class="d-flex flex-wrap mt-3">
                            <div class="catalog-item shadow card d-flex flex-column justify-content-between" v-for="item in displayableItems"
                                 :key="item.id" @click="selectOrderItem(item)">
                                <span class="catalog-item-label"> {{ item.label }}</span>
                                <span class="catalog-item-price"> {{ formatAmount(item.selling_price) }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card bg-white shadow-sm" style="width: 45%; height: 65vh; overflow-x: auto;" v-loading="savingOrder"
                     element-loading-text="Saving order">
                    <div class="d-flex justify-content-between align-items-center">
                        <h5 class="f-18 fw-700 mb-0"> Order Items </h5>

                        <el-button type="danger" plain size="small" @click="clearOrderItems"> Clear All Items</el-button>
                    </div>

                    <hr>

                    <p class="f-16" v-if="newOrder.items.length === 0"> No order items have been selected </p>

                    <div class="table-responsive" v-else>
                        <table class="table">
                            <thead>
                            <tr>
                                <th scope="col"> #</th>
                                <th scope="col"> Item</th>
                                <th scope="col"> Item Price</th>
                                <th scope="col"> Quantity</th>
                                <th scope="col"> Item Total</th>
                                <th v-if="businessHasEnabledAttendantSelection"> Attendant</th>
                                <th scope="col"> Actions</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr v-for="(item, index) in this.newOrder.items" :key="item.stock_item_id">
                                <th scope="row"> {{ index + 1 }}</th>
                                <td> {{ item.label }}</td>
                                <td> {{ formatAmount(item.actual_selling_price) }}</td>
                                <td>
                                    <input type="number" min="1" class="form-control" v-model="item.quantity" style="width: 100px;">
                                </td>
                                <td> {{ formatAmount(getOrderItemTotal(item)) }}</td>
                                <td v-if="businessHasEnabledAttendantSelection">
                                    <el-select
                                        v-model="item.attendant_id"
                                        default-first-option
                                        filterable
                                        placeholder="Select an attendant"
                                        size="large">
                                        <el-option
                                            v-for="attendant in systemUsers"
                                            :key="attendant.id"
                                            :label="attendant.full_name"
                                            :value="attendant.id"
                                            :disabled="attendant.role?.name === 'General Account'"
                                        />
                                    </el-select>
                                </td>
                                <td>
                                    <i class="fas fa-trash-alt text-danger" style="font-size: 16px; cursor:pointer;"
                                       @click="removeOrderItem(index)"></i>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div id="footer" class="card bg-white d-flex justify-content-between align-items-center flex-row">
            <el-button size="large" @click="discardOrder"> Discard Order</el-button>

            <div class="d-flex">
                <el-checkbox v-model="printKitchenOrder" border label="Print Kitchen Order" size="large" style="margin-right: 15px;"></el-checkbox>

                <button class="btn btn-outline-primary mr-12 f-18 fw-700" @click="saveOrder()" @dblclick="saveOrder()"> Save Order</button>
            </div>
        </div>

        <!-- New Customer -->
        <el-dialog title="New Customer" width="30%" v-model="showNewCustomerDialog">
            <form method="post" v-loading="addingCustomer" element-loading-text="Saving customer"
                  @submit.prevent="addCustomer">
                <div class="form-group">
                    <label for="new-customer-name" class="required"> Customer Name </label>
                    <input type="text" class="form-control" id="new-customer-name" v-model="newCustomer.customer_name">
                    <div class="invalid-feedback"> Customer name is required</div>
                </div>

                <div class="form-group">
                    <label for="new-customer-email"> Customer Email </label>
                    <input type="email" class="form-control" id="new-customer-email"
                           v-model="newCustomer.customer_email">
                </div>

                <div class="form-group">
                    <label for="new-customer-email"> Customer Number </label>
                    <vue-tel-input :value="newCustomer.customer_number" @input="onNewCustomerPhoneInput"
                                   mode="international"></vue-tel-input>
                    <input type="hidden" :value="newCustomer.customer_number" name="phone">
                </div>
            </form>
            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showNewCustomerDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addCustomer"> Save Customer</button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<style scoped>
#new-order-div {
    height: 100vh;
    width: 100%;
}

#footer {
    height: 80px;
    width: 100%;
    border-radius: 0 !important;
    outline: none !important;
    position: fixed;
    bottom: 0;
    left: 0;
}

#content {
    padding: 30px;
}

.catalog-item {
    outline: 1px solid #01358d38;
    color: #01358D;
    font-weight: 700;
    font-size: 16px;
    height: 100px;
    margin-right: 20px;
    margin-bottom: 20px;
    max-width: 250px;
    cursor: pointer;
}
</style>
