<template>
    <div class="master-template">
        <div class="master-title d-flex justify-content-between">
            <h5> Wallets </h5>
        </div>

        <div class="master-content">
            <div class="data-table">
                <el-skeleton :loading="gettingWallets" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" v-for="row in 7" :key="row" style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                    </template>

                    <template #default>
                        <el-table :data="wallets" style="width: 100%">
                            <el-table-column label="Wallet Name" prop="display_wallet_name"></el-table-column>
                            <el-table-column label="Wallet Description" prop="description"></el-table-column>
                            <el-table-column label="Wallet Balance" prop="display_balance"></el-table-column>
                            <el-table-column label="Actions">
                                <template #default="scope">
                                    <el-dropdown @command="handleWalletActions">
                                        <el-button type="primary">
                                            Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item :command="'update-' + scope.$index">Update Wallet Balance</el-dropdown-item>
                                                <el-dropdown-item :command="'deposit-' + scope.$index"
                                                                  v-if="scope.row.allows_manual_transactions === 1">Deposit Into Wallet
                                                </el-dropdown-item>
                                                <el-dropdown-item :command="'withdraw-' + scope.$index"
                                                                  v-if="scope.row.allows_manual_transactions === 1"
                                                                  :disabled="walletTransferIsDisabled(scope.row)">Withdraw From Wallet
                                                </el-dropdown-item>
                                                <el-dropdown-item :command="'transfer-' + scope.$index"
                                                                  v-if="scope.row.allows_manual_transactions === 1"
                                                                  :disabled="walletTransferIsDisabled(scope.row)">Transfer to Another Wallet
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-skeleton>
            </div>
        </div>

        <!-- Update Wallet Balance -->
        <el-dialog v-model="updateWalletBalanceModalIsActive" width="30%" title="Update Wallet Balance"
                   :close-on-click-modal="false" :close-on-press-escape="false">

            <form method="post" @submit.prevent="updateWalletBalance" novalidate v-loading="updatingWalletBalance">
                <div class="form-group">
                    <label for="new-balance"> New balance </label>
                    <input type="text" class="form-control" v-model="newBalance" id="new-balance">
                    <div class="invalid-feedback"> Please provide a valid amount</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="updateWalletBalanceModalIsActive = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="updateWalletBalance"> Update</button>
                </div>
            </template>
        </el-dialog>

        <!-- Wallet Deposit -->
        <el-dialog
            v-model="depositModalIsActive"
            :title="`Deposit into ${activeWallet.display_wallet_name}`"
            v-if="activeWallet"
            width="30%"
        >

            <form @submit.prevent="depositIntoWallet" novalidate v-loading="depositingIntoWallet">
                <div class="form-group">
                    <label for="deposit-amount"> Deposit Amount </label>
                    <input type="number" class="form-control" v-model="depositAmount" placeholder="Deposit amount"
                           id="wallet-deposit-amount">
                    <div class="invalid-feedback"> Please provide a valid amount</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="depositModalIsActive = false" size="large" type="info"> Cancel</el-button>
                    <button class="btn btn-primary f-18 fw-700" @click="depositIntoWallet"> Confirm Deposit</button>
                </div>
            </template>
        </el-dialog>

        <!-- Wallet Withdrawal -->
        <el-dialog
            v-model="withdrawDialogIsActive"
            :title="`Withdraw from ${activeWallet.display_wallet_name}`"
            v-if="activeWallet"
            width="30%"
        >

            <form @submit.prevent="withdrawFromWallet" novalidate v-loading="withdrawing">
                <div class="form-group">
                    <label for="wallet-withdrawal-amount"> Amount to withdraw </label>
                    <input type="number" class="form-control" v-model="withdrawalAmount" placeholder="Amount to withdraw"
                           id="wallet-withdrawal-amount">
                    <div class="invalid-feedback"> Please provide a valid amount</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="withdrawDialogIsActive = false" size="large" type="info"> Cancel</el-button>
                    <button class="btn btn-primary f-18 fw-700" @click="withdrawFromWallet"> Confirm Withdrawal</button>
                </div>
            </template>
        </el-dialog>

        <!-- Wallet Transfer -->
        <el-dialog
            v-model="transferModalIsActive"
            :title="`Transfer from ${activeWallet.display_wallet_name}`"
            v-if="activeWallet"
            width="30%"
        >

            <form @submit.prevent="transfer" novalidate v-loading="transferring">
                <div class="form-group">
                    <label> Target Wallet </label>
                    <el-select v-model="transferDetails.target_wallet_id" size="large" filterable>
                        <el-option
                            v-for="wallet in wallets"
                            :key="wallet.id"
                            :label="wallet.display_wallet_name"
                            :value="wallet.id"
                            :disabled="(wallet.id === transferDetails.source_wallet_id) || (wallet.allows_manual_transactions !== 1)"></el-option>
                    </el-select>
                </div>

                <div class="form-group">
                    <label for="wallet-transfer-amount"> Transfer Amount </label>
                    <input type="number" class="form-control" v-model="transferDetails.transfer_amount" placeholder="Transfer amount"
                           id="wallet-transfer-amount">
                    <div class="invalid-feedback"> Please provide a valid amount</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="transferModalIsActive = false" size="large" type="info"> Cancel</el-button>
                    <button class="btn btn-primary f-18 fw-700" @click="transfer"> Confirm Transfer</button>
                </div>
            </template>
        </el-dialog>

    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import $ from 'jquery'

export default {
    name: "Wallets",

    data() {
        return {
            baseUrl: '/api/accounting/wallets',
            gettingWallets: false,
            wallets: [],
            activeWallet: null,

            updateWalletBalanceModalIsActive: false,
            updatingWalletBalance: false,
            newBalance: 0,

            depositingIntoWallet: false,
            depositModalIsActive: false,
            depositAmount: 0,

            withdrawDialogIsActive: false,
            withdrawing: false,
            withdrawalAmount: 0,

            transferring: false,
            transferModalIsActive: false,
            transferDetails: {
                source_wallet_id: null,
                target_wallet_id: null,
                transfer_amount: 0
            },
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
        ...mapGetters('system', ['getBasicPayload'])
    },

    created() {
        this.getWallets()
    },

    methods: {
        getWallets() {
            let payload = {
                store_id: this.getBasicPayload.store_id
            }

            this.gettingWallets = true
            axios.get('/api/accounting/wallets', {params: payload}).then(res => {
                this.gettingWallets = false
                this.wallets = res.data.data
            }).catch(err => {
                this.gettingWallets = false
                this.$message.error("Error getting wallets. Please try again")
            })
        },

        handleWalletActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            if (commandName === 'update') {
                this.activeWallet = JSON.parse(JSON.stringify(this.wallets[commandIndex]))
                this.newBalance = this.activeWallet.balance
                this.updateWalletBalanceModalIsActive = true
            }

            if (commandName === 'deposit') {
                this.activeWallet = this.wallets[commandIndex]
                this.depositAmount = 0
                this.depositModalIsActive = true
            }

            if (commandName === 'withdraw') {
                this.activeWallet = this.wallets[commandIndex]
                this.withdrawalAmount = 0
                this.withdrawDialogIsActive = true
            }

            if (commandName === 'transfer') {
                this.activeWallet = this.wallets[commandIndex]
                this.transferDetails.source_wallet_id = this.activeWallet.id
                this.transferDetails.transfer_amount = 0
                this.transferModalIsActive = true
            }
        },

        updateWalletBalance(e) {
            e.preventDefault()

            if (!this.numberIsValid(this.newBalance) || !this.numberIsNotNegative(this.newBalance)) {
                return $('#new-balance').addClass('is-invalid')
            } else {
                $('#new-balance').removeClass('is-invalid')
            }

            let payload = {
                wallet_id: this.activeWallet.id,
                user_id: this.getBasicPayload.user_id,
                new_balance: this.newBalance
            }

            this.updatingWalletBalance = true
            axios.post('/api/accounting/wallets/balance/update', payload).then(res => {
                this.updatingWalletBalance = false
                this.getWallets()
                this.updateWalletBalanceModalIsActive = false
                this.$message.success("Wallet updated successfully")
            }).catch(err => {
                this.updatingWalletBalance = false
                this.$message.error("Error updating wallet. Please try again.")
            })
        },

        depositIntoWallet(e) {
            e.preventDefault()

            let depositAmountInputField = $("#wallet-deposit-amount")
            depositAmountInputField.removeClass('is-invalid')
            if (!this.depositAmount || parseFloat(this.depositAmount) < 0) {
                depositAmountInputField.addClass('is-invalid')
                return this.$message.error("Please provide a valid amount")
            }

            let payload = {
                deposit_amount: this.depositAmount,
                wallet_id: this.activeWallet.id,
                current_user_id: this.currentUser.id
            }

            this.depositingIntoWallet = true
            axios.post(`${this.baseUrl}/deposit`, payload).then(_ => {
                this.depositingIntoWallet = false
                this.depositModalIsActive = false
                this.getWallets()
                this.$message.success("Deposit made successfully")
            }).catch(_ => {
                this.depositingIntoWallet = false
                this.$message.error("An error was encountered. Please try again.")
            })
        },

        withdrawFromWallet(e) {
            e.preventDefault()

            let withdrawalAmountInputField = $("#wallet-withdrawal-amount")
            withdrawalAmountInputField.removeClass('is-invalid')
            if (!this.withdrawalAmount || parseFloat(this.withdrawalAmount) < 0) {
                withdrawalAmountInputField.addClass('is-invalid')
                return this.$message.error("Please provide a valid amount")
            }

            let withdrawalAmountIsMoreThanWalletBalance = parseFloat(this.activeWallet.balance) < parseFloat(this.withdrawalAmount)
            if (withdrawalAmountIsMoreThanWalletBalance) {
                withdrawalAmountInputField.addClass('is-invalid')
                return this.$message.error("Withdrawal amount is more than current wallet balance")
            }

            let payload = {
                withdrawal_amount: this.withdrawalAmount,
                wallet_id: this.activeWallet.id,
                current_user_id: this.currentUser.id
            }

            this.withdrawing = true
            axios.post(`${this.baseUrl}/withdraw`, payload).then(_ => {
                this.withdrawing = false
                this.withdrawDialogIsActive = false
                this.getWallets()
                this.$message.success("Withdrawal made successfully")
            }).catch(_ => {
                this.withdrawing = false
                this.$message.error("An error was encountered. Please try again.")
            })
        },

        transfer(e) {
            e.preventDefault()

            if (!this.transferDetails.target_wallet_id) {
                return this.$message.error("Please select a target wallet")
            }

            let transferAmountInputField = $("#wallet-transfer-amount")
            transferAmountInputField.removeClass('is-invalid')
            if (!this.transferDetails.transfer_amount || parseFloat(this.transferDetails.transfer_amount) < 0) {
                transferAmountInputField.addClass('is-invalid')
                return this.$message.error("Please provide a valid amount")
            }

            let transferAmountIsMoreThanSourceWalletBalance = parseFloat(this.activeWallet.balance) < parseFloat(this.transferDetails.transfer_amount)
            if (transferAmountIsMoreThanSourceWalletBalance) {
                transferAmountInputField.addClass('is-invalid')
                return this.$message.error("Transfer amount is more than source wallet balance")
            }

            let payload = {
                ...this.transferDetails, ...{
                    store_id: this.currentUser.store_id,
                    current_user_id: this.currentUser.id
                }
            }

            this.transferring = true
            axios.post(`${this.baseUrl}/transfer`, payload).then(_ => {
                this.transferring = false
                this.transferModalIsActive = false
                this.getWallets()
                this.$message.success("Wallet transfer successfull")
            }).catch(_ => {
                this.transferring = false
                this.$message.error("An error was encountered. Please try again.")
            })
        },

        walletTransferIsDisabled(wallet) {
            return parseFloat(wallet.balance) <= 0
        }

    }
}
</script>

<style scoped>

</style>
