<template>
    <div class="master-template">
        <div class="master-title d-flex justify-content-between">
            <h5> Injections </h5>

            <div class="actions d-flex">
                <button class="btn btn-primary new-item-btn" @click="promptNewInjection">
                    <i class="fas fa-plus-circle"></i>New Injection
                </button>
            </div>
        </div>

        <div class="master-content">
            <div class="data-table-filters card shadow bg-white">
                <h5> Filters </h5>

                <div class="row">
                    <div class="form-group col-md-4">
                        <label> Start Date </label>
                        <el-date-picker
                            v-model="filters.begin"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>

                    <div class="form-group col-md-4 has-padding-left">
                        <label>End Date </label>
                        <el-date-picker
                            v-model="filters.end"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>
                </div>

                <div class="mt-3 d-flex">
                    <button class="btn btn-secondary mr-12" @click="filterInjections"> Filter Injections </button>
                    <el-button size="large" @click="resetInjectionsFilters">Reset Filters</el-button>
                </div>
            </div>

            <div class="data-table mt-3">
                <div class="data-table-summary d-flex justify-content-end">
                    <span class="summary"> Showing {{ injections.length }} out of {{ totalInjections }} injections </span>
                </div>

                <div class="data-table">
                    <el-skeleton :loading="gettingInjections" :throttle="200" animated>
                        <template #template>
                            <el-skeleton-item variant="h3" v-for="row in 7" :key="row" style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                        </template>

                        <template #default>
                            <el-table :data="injections" style="width: 100%">
                                <el-table-column label="Injected Amount" prop="display_amount"></el-table-column>
                                <el-table-column label="Injection Description" prop="description"></el-table-column>
                                <el-table-column label="Injection Date" prop="display_date"></el-table-column>
                                <el-table-column label="Actions">
                                    <template #default="scope">
                                        <el-dropdown @command="handleInjectionActions">
                                            <el-button type="primary">
                                                Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                            </el-button>
                                            <template #dropdown>
                                                <el-dropdown-menu>
                                                    <el-dropdown-item :command="'update-' + scope.$index">Update Injections</el-dropdown-item>
                                                    <el-dropdown-item :command="'void-' + scope.$index">Void Injection</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </el-skeleton>
                </div>
            </div>

            <!-- Pagination -->
            <div class="data-table-pagination d-flex justify-content-end mt-3">
                <el-pagination background layout="prev, pager, next" :total="totalInjections"
                               :page-size="10" v-model:current-page="currentPage" @current-change="getInjections" hide-on-single-page/>
            </div>
        </div>

        <!-- Add Injection Modal -->
        <el-dialog
            v-model="showNewInjectionDialog"
            title="New Injection"
            width="30%"
        >
            <form @submit.prevent="addNewInjection"
                  v-loading="addingInjection"
                  element-loading-text="Saving Injection"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="injection-amount"> Injection Amount </label>
                    <input type="text" id="injection-amount" class="form-control" v-model="newInjection.amount">
                    <div class="invalid-feedback"> Injection amount is a required field</div>
                </div>

                <div class="form-group">
                    <label class="required" for="injection-description"> Description </label>
                    <textarea id="injection-description" class="form-control" v-model="newInjection.description"></textarea>
                    <div class="invalid-feedback"> Injection description is a required field</div>
                </div>

                <div class="form-group">
                    <div class="mt-2">
                        <el-date-picker
                            v-model="newInjection.date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showNewInjectionDialog= false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addNewInjection"> Save Injection</button>
                </div>
            </template>
        </el-dialog>

        <!-- Update Injection Modal -->
        <el-dialog
            v-model="showUpdateInjectionDialog"
            title="Update Injection"
            width="30%"
        >
            <form @submit.prevent="updateInjection"
                  v-loading="updatingInjection"
                  element-loading-text="Saving Changes"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="new-injection-amount"> Injection Amount </label>
                    <input type="text" id="new-injection-amount" class="form-control" v-model="activeInjection.amount">
                    <div class="invalid-feedback"> Injection amount is a required field</div>
                </div>

                <div class="form-group">
                    <label class="required" for="new-injection-description"> Description </label>
                    <textarea id="new-injection-description" class="form-control" v-model="activeInjection.description"></textarea>
                    <div class="invalid-feedback"> Injection description is a required field</div>
                </div>

                <div class="form-group">
                    <div class="mt-2">
                        <el-date-picker
                            v-model="activeInjection.editing_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showUpdateInjectionDialog= false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="updateInjection"> Save Changes</button>
                </div>
            </template>
        </el-dialog>

        <!-- Void Injection Modal -->
        <el-dialog
            v-model="showVoidInjectionDialog"
            title="Void Injection"
            width="30%"
            v-if="activeInjection"
        >
            <form @submit.prevent="voidInjection"
                  v-loading="voidingInjection"
                  element-loading-text="Voiding injection"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="voiding-reason"> Why are you voiding this injection? </label>
                    <input type="text" id="voiding-reason" class="form-control" v-model="activeInjection.voiding_reason">
                    <div class="invalid-feedback"> Please provide a reason for voiding injection </div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showVoidInjectionDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="voidInjection"> Void Injection</button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {Delete, Edit} from "@element-plus/icons-vue";
import $ from 'jquery'

export default {
    name: "Injections",

    data() {
        return {
            baseUrl: '/api/accounting/injections',
            filters: {},
            gettingInjections: false,
            injections: [],
            currentPage: 1,
            totalInjections: 0,
            showNewInjectionDialog: false,
            newInjection: {},
            addingInjection: false,
            showUpdateInjectionDialog: false,
            showVoidInjectionDialog: false,
            activeInjection: {},
            updatingInjection: false,
            voidingInjection: false,
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),

        ...mapGetters('system', ['getBasicPayload']),

        Edit() {
            return Edit
        },

        Delete() {
            return Delete
        },
    },

    created() {
        this.getInjections()
    },

    methods: {
        getInjections() {
            let payload = {...this.getBasicPayload, page: this.currentPage - 1}
            this.gettingInjections = true
            axios.get(`${this.baseUrl}/paginated`, {params: payload}).then(res => {
                this.gettingInjections = false
                this.injections = res.data.data.content
                this.totalInjections = res.data.data.count
            }).catch(error => {
                this.gettingInjections = false
                this.$message.error('Error loading injections. Please reload the page')
            })
        },

        promptNewInjection() {
            this.newInjection = {...{date: this.getFormattedNow()}, ...this.getBasicPayload}
            this.showNewInjectionDialog = true
        },

        addNewInjection(e) {
            e.preventDefault()

            // Validate
            let dataIsValid = true
            if (!this.newInjection.amount || this.amountIsNotValid(this.newInjection.amount)) {
                dataIsValid = false
                $("#injection-amount").addClass('is-invalid')
            } else {
                $("#injection-amount").removeClass('is-invalid')
            }

            if (!this.newInjection.description) {
                dataIsValid = false
                $("#injection-description").addClass('is-invalid')
            } else {
                $("#injection-description").removeClass('is-invalid')
            }

            if (!this.newInjection.date) {
                dataIsValid = false
                this.$message.error('Please select a date')
            }

            if (dataIsValid) {
                this.addingInjection = true
                axios.post(`${this.baseUrl}/add`, this.newInjection).then(res => {
                    this.addingInjection = false
                    this.getInjections()
                    this.showNewInjectionDialog = false

                    this.$message.success('Injection added successfully')
                }).catch(error => {
                    this.addingInjection = false
                    this.$message.error('Error saving injection. Please try again')
                })
            } else {
                // this.$message.error('Please fill all required fields')
            }
        },

        handleInjectionActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            if (commandName === 'update') {
                this.activeInjection = JSON.parse(JSON.stringify(this.injections[commandIndex]))
                this.showUpdateInjectionDialog = true
            }

            if (commandName === 'void') {
                this.activeInjection = JSON.parse(JSON.stringify(this.injections[commandIndex]))
                this.showVoidInjectionDialog = true
            }
        },

        resetExpenseFilters() {
            this.filters = {}
            this.getInjections()
        },

        updateInjection(e) {
            e.preventDefault()

            let dataIsValid = true
            if (!this.activeInjection.amount || this.amountIsNotValid(this.activeInjection.amount)) {
                dataIsValid = false
                $("#new-injection-amount").addClass('is-invalid')
            } else {
                $("#new-injection-amount").removeClass('is-invalid')
            }

            if (!this.activeInjection.description) {
                dataIsValid = false
                $("#new-injection-description").addClass('is-invalid')
            } else {
                $("#new-injection-description").removeClass('is-invalid')
            }

            if (!this.activeInjection.editing_date) {
                dataIsValid = false
                this.$message.error('Please select a date')
            }

            if (dataIsValid) {
                let payload = {
                    id: this.activeInjection.id,
                    amount: this.activeInjection.amount,
                    description: this.activeInjection.description,
                    date: this.activeInjection.editing_date,
                    user_id: this.currentUser.id
                }

                this.updatingInjection = true
                axios.post(`${this.baseUrl}/update`, payload).then(res => {
                    this.updatingInjection = false
                    this.showUpdateInjectionDialog = false
                    this.getInjections()

                    this.$message.success('Injection updated successfully')
                }).catch(error => {
                    this.updatingInjection = false
                    this.$message.error('Error saving changes. Please try again')
                })
            }
        },

        voidInjection(e) {
            e.preventDefault()

            let dataIsValid = true
            if (!this.activeInjection.voiding_reason) {
                dataIsValid = false
                $("#voiding-reason").addClass('is-invalid')
            } else {
                $("#voiding-reason").removeClass('is-invalid')
            }

            if (dataIsValid) {
                let payload = {
                    id: this.activeInjection.id,
                    reason: this.activeInjection.voiding_reason,
                    user_id: this.currentUser.id
                }

                this.voidingInjection = true
                axios.post(`${this.baseUrl}/void`, payload).then(res => {
                    this.voidingInjection = false
                    this.showVoidInjectionDialog = false
                    this.getInjections()

                    this.$message.success('Injection voided successfully')
                }).catch(error => {
                    this.voidingInjection = false
                    this.$message.error('Error voiding injection. Please try again')
                })
            }
        }
    },
}
</script>

<style scoped>

</style>
