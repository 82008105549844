<template>
    <div class="w-100">
        <form id="setup-business-form" class="card shadow bg-white mb-2" @submit.prevent="setupBusiness" v-loading="settingUp">
            <h5 class="fw-600" style="font-size: 16px;"> Let's setup your business </h5>

            <div class="form-group">
                <label for="business-name" class="required"> What is the name of your business? </label>
                <input type="text" name="business_name" id="business-name" class="form-control" v-model="businessName">
                <div class="invalid-feedback">
                    Please provide a business name.
                </div>
            </div>

            <div class="form-group">
                <label class="required"> Business Type </label>
                <el-select v-model="selectedBusinessTypes" multiple filterable placeholder="Click to search and select" size="large">
                    <el-option
                        v-for="type in businessTypes"
                        :key="type.id"
                        :label="type.name"
                        :value="type.id"
                    />
                </el-select>
                <div id="businessTypesHelp" class="form-text">Select all that apply.</div>
                <div class="invalid-feedback" id="business-types-invalid-feedback">
                    Please select at least one option
                </div>
            </div>

            <div style="margin-top: 30px;">
                <button @click="setupBusiness" class="btn btn-radius btn-red mb-4 btn-block" id="sign-up-btn"> Setup Business</button>
            </div>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
import {mapState} from "vuex";

export default {
    name: "SetupBusinessComponent",
    props: ['user', 'business_types'],
    data() {
        return {
            settingUp: false,
            businessName: null,
            businessEmail: null,
            businessPhoneNumber: null,
            selectedBusinessTypes: [],
            businessLocation: null,
        }
    },

    mounted() {
        this.businessEmail = this.authenticatedUser.email
        this.businessPhoneNumber = this.authenticatedUser.phone
    },

    computed: {
        authenticatedUser() {
            return JSON.parse(this.user)
        },

        businessTypes() {
            return JSON.parse(this.business_types)
        },

        ...mapState('system', ['currentUser']),
    },
    methods: {
        onInput(phone, phoneObject, input) {
            if (phoneObject?.formatted) {
                this.businessPhoneNumber = phoneObject.formatted
            }
        },
        setupBusiness(e) {
            e.preventDefault()
            let formIsValid = true
            // Validate
            // Business name
            if (!this.businessName || this.businessName === '') {
                formIsValid = false
                $("#business-name").addClass('is-invalid')
            } else {
                $("#business-name").removeClass('is-invalid')
            }
            if (this.selectedBusinessTypes.length < 1) {
                formIsValid = false
                $("#business-types-invalid-feedback").css('display', 'block')
            } else {
                $("#business-types-invalid-feedback").css('display', 'none')
            }
            // Submit form
            if (formIsValid) {
                let payload = {
                    user_id: this.authenticatedUser.id,
                    business_name: this.businessName,
                    business_email: this.businessEmail,
                    business_phone: this.businessPhoneNumber,
                    business_types: this.selectedBusinessTypes,
                }
                this.settingUp = true
                axios.post('/api/business/setup', payload).then(res => {
                    this.$store.commit('system/updateCurrentUser', res.data.data)
                    this.$store.commit('system/resetNavKeys')
                    this.settingUp = false
                    return window.location.assign('/dashboard')
                }).catch(error => {
                    this.settingUp = false
                    this.$message.error('Please try again')
                })
            } else {
                $("#setup-business-btn").css('margin-top', '0')
            }
        },
    },
}
</script>
<style>
.el-select {
    width: 100% !important;
}

#setup-business-loader img {
    position: relative;
    width: 30%;
    display: block;
    border-radius: 15px;
}

.el-progress--line {
    margin-bottom: 15px;
    max-width: 400px;
}

/**
Tablet
 */
@media only screen and (max-width: 991px) {
    #setup-business-loader img {
        width: 60%;
    }
}

/**
Medium Tablet
 */
@media only screen and (max-width: 767px) {
    #setup-business-loader img {
        width: 100%;
    }
}
</style>
