<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "NewStockProduction"
})
</script>

<template>

</template>

<style scoped>

</style>
