<template>
    <div id="products-list-main" class="master-template">
        <div class="d-flex justify-content-between align-items-center master-title-div">
            <h5 class="master-title mb-0"> Product List </h5>
            <div class="actions" v-if="!gettingProducts">
                <button class="btn btn-outline-primary new-item-btn hide-sm hide-md">
                    <i class="fas fa-cloud-upload-alt"></i>Import From Excel
                </button>
                <button class="btn btn-primary new-item-btn" @click="promptAddProduct">
                    <i class="fas fa-plus-circle"></i>Add Product
                </button>
            </div>
        </div>

        <div class="master-content">
            <el-skeleton :loading="gettingProducts" :throttle="200" animated>
                <template #template>
                    <el-skeleton-item variant="h3" style="width: 100%; height: 120px; margin-bottom: 20px;"/>
                </template>
                <template #default>
                    <div class="data-table-filters card shadow-sm rounded bg-white hide-sm">
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 class="card-title"> Filters </h5>
                        </div>

                        <div class="row justify-content-between">
                            <div class="form-group col-12 col-md-4">
                                <label> Filter by name </label>
                                <input type="text" class="form-control" placeholder="Filter by product name"
                                       v-model="filters.name"
                                       @input="filterProducts">
                            </div>

                            <div class="form-group col-12 col-md-4">
                                <label> Filter by category </label>
                                <input type="text" class="form-control" placeholder="Filter by products category"
                                       v-model="filters.category"
                                       @input="filterProducts">
                            </div>

                            <div class="form-group col-12 col-md-3">
                                <label> Filter by sub category </label>
                                <input type="text" class="form-control" placeholder="Filter by products sub category"
                                       v-model="filters.subcategory"
                                       @input="filterProducts">
                            </div>
                        </div>
                    </div>

                    <div class="small-filters hide-lg hide-md">
                        <div class="row">
                            <div class="form-group col-12 mb-0 position-relative">
                                <input type="text" class="form-control has-prefix" placeholder="Search products"
                                       v-model="filters.name"
                                       @input="filterProductsMobile">
                                <i class="fas fa-search prefix"></i>
                            </div>
                        </div>
                    </div>
                </template>
            </el-skeleton>


            <div class="data-table mt-3">
                <el-skeleton :loading="gettingProducts" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" v-for="row in 9" :key="row" style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                    </template>
                    <template #default>
                        <el-table :data="displayableProducts" style="width: 100%"
                                  :empty-text="emptyText" class="hide-sm">
                            <el-table-column prop="label" label="Product/Service Name"/>
                            <el-table-column label="Category">
                                <template #default="scope">
                                    <span>{{ scope.row.sub_category.category.name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Sub Category">
                                <template #default="scope">
                                    <span>{{ scope.row.sub_category.name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="type" label="Type"/>
                            <el-table-column label="Variants">
                                <template #default="scope">
                                    <span v-if="scope.row.variants">{{ capitalizeEachWord((scope.row.variants).join(), ',', ', ') }}</span>
                                    <span v-else> None </span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Actions">
                                <template #default="scope">
                                    <el-dropdown @command="handleProductActions">
                                        <el-button type="primary">
                                            Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item :command="'update-' + scope.$index">Update Product Details</el-dropdown-item>
                                                <el-dropdown-item :command="'remove-' + scope.$index">Remove Product</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>

                        <!-- Mobile View -->
                        <div class="mobile-list hide-lg hide-md"></div>
                    </template>
                </el-skeleton>
            </div>
        </div>

        <!-- START OF ADD NEW PRODUCT MODAL -->
        <el-dialog
            v-model="showAddingProduct"
            title="Add New Product"
            width="50%"
        >
            <form @submit.prevent="addProduct"
                  v-loading="addingProduct"
                  element-loading-text="Adding Product"
                  id="new-product-form"
                  novalidate
            >
                <div class="row">
                    <div class="form-group col-md-6">
                        <label class="required" for="product-name"> Product Name </label>
                        <input type="text" id="product-name" class="form-control" v-model="newProduct.label" placeholder="Product name">
                        <div class="invalid-feedback"> Product name is required</div>
                    </div>

                    <div class="form-group col-md-6">
                        <label class="required"> Product Type </label>
                        <el-select
                            v-model="newProduct.type"
                            default-first-option
                            placeholder="Search or select a type"
                            size="large"
                        >
                            <el-option
                                v-for="(item, index) in ['Product', 'Service']"
                                :key="index"
                                :label="item"
                                :value="item"
                            />
                        </el-select>
                        <div class="invalid-feedback" id="new-product-type-feedback"> Product type is required</div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6">
                        <label> Product Category </label>
                        <el-select
                            v-model="newProduct.category_id"
                            default-first-option
                            filterable
                            placeholder="Search or select a category"
                            size="large"
                            no-data-text="Please add product categories first"
                        >
                            <el-option
                                v-for="category in categories"
                                :key="category.id"
                                :label="category.name"
                                :value="category.id"
                            />
                        </el-select>
                    </div>

                    <div class="form-group col-md-6">
                        <label class="required"> Product Sub Category </label>
                        <el-select
                            v-model="newProduct.sub_category_id"
                            default-first-option
                            filterable
                            placeholder="Search or select a sub category"
                            size="large"
                            no-data-text="Please select a category first"
                        >
                            <el-option
                                v-for="subCategory in matchingSubCategories"
                                :key="subCategory.id"
                                :label="subCategory.name"
                                :value="subCategory.id"
                            />
                        </el-select>
                        <div class="invalid-feedback" id="new-product-sub-category-feedback"> Product sub category is required</div>
                    </div>
                </div>

                <div class="row" v-if="newProduct.type === 'Product'">
                    <div class="form-group col-md-6">
                        <label class="required"> Primary Units </label>
                        <el-select
                            v-model="newProduct.unit_id"
                            default-first-option
                            filterable
                            allow-create
                            placeholder="Search or select a unit"
                            size="large"
                            no-data-text="No units found. Type a unit name and select it to add"
                        >
                            <el-option
                                v-for="unit in uoms"
                                :key="unit.id"
                                :label="unit.unit_name"
                                :value="unit.id"
                            />
                        </el-select>
                        <div id="new-product-uom-help" class="form-text">To add a new unit, type its full name and select it.</div>
                        <div class="invalid-feedback" id="new-product-uom-feedback"> Product units are required</div>
                    </div>

                    <div class="form-group col-md-6">
                        <label style="display: block;"> Product Has Variations </label>
                        <el-radio-group v-model="newProduct.has_variants" size="large">
                            <el-radio-button :label="true"> Yes</el-radio-button>
                            <el-radio-button :label="false"> No</el-radio-button>
                        </el-radio-group>
                        <div class="form-text"> Determines whether a product has size, color, etc variations.</div>
                    </div>
                </div>

                <!--                <div class="row" v-if="shouldDisplayPriceEntries">-->
                <!--                    <div class="form-group col-md-6">-->
                <!--                        <label class="required" for="new-product-selling-price" v-if="newProduct.type === 'Product'"> Selling Price </label>-->
                <!--                        <label class="required" for="new-product-selling-price" v-else> Service Cost </label>-->
                <!--                        <input type="text" id="new-product-selling-price" class="form-control" v-model="newProduct.selling_price"-->
                <!--                               placeholder="Selling price">-->
                <!--                        <div class="invalid-feedback"> Please provide a valid selling price</div>-->
                <!--                    </div>-->

                <!--                    <div class="form-group col-md-6">-->
                <!--                        <label for="new-product-buying-price" v-if="newProduct.type === 'Product'"> Buying Price </label>-->
                <!--                        <input type="text"-->
                <!--                               id="new-product-buying-price"-->
                <!--                               class="form-control" v-model="newProduct.buying_price"-->
                <!--                               placeholder="Buying price">-->
                <!--                        <div class="invalid-feedback"> Please provide a valid buying price</div>-->
                <!--                    </div>-->
                <!--                </div>-->

                <!-- Applicable Variations -->
                <div class="row" v-if="(newProduct.type === 'Product') && newProduct.has_variants">
                    <div class="form-group col-md-6">
                        <label class="required"> Select the variants that apply </label>
                        <el-select
                            v-model="newProduct.variants"
                            multiple
                            default-first-option
                            filterable
                            placeholder="Select a variant"
                            size="large"
                            no-data-text="No variants found."
                        >
                            <el-option
                                v-for="variant in ['color', 'size', 'mass', 'type', 'capacity']"
                                :key="variant"
                                :label="capitalizeEachWord(variant)"
                                :value="variant"
                            />
                        </el-select>
                        <div class="invalid-feedback" id="new-product-variants-feedback"> Select at least one variant</div>
                    </div>
                </div>

            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showAddingProduct = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addProduct"> Add Product</button>
                </div>
            </template>
        </el-dialog>
        <!-- END OF ADD NEW PRODUCT MODAL -->

        <!-- START OF UPDATE PRODUCT MODAL -->
        <el-dialog
            v-model="showUpdateProductDialog"
            title="Update Product Details"
            width="50%"
        >
            <form @submit.prevent="updateProduct"
                  v-loading="updatingProduct"
                  element-loading-text="Updating product details"
                  id="update-product-form"
                  novalidate
            >
                <div class="row">
                    <div class="form-group col-md-6">
                        <label class="required" for="update-product-name"> Product Name </label>
                        <input type="text" id="update-product-name" class="form-control" v-model="activeProduct.label" placeholder="Product name">
                        <div class="invalid-feedback"> Product name is required</div>
                    </div>

                    <div class="form-group col-md-6">
                        <label class="required"> Product Type </label>
                        <el-select
                            v-model="activeProduct.type"
                            default-first-option
                            placeholder="Search or select a type"
                            size="large"
                        >
                            <el-option
                                v-for="(item, index) in ['Product', 'Service']"
                                :key="index"
                                :label="item"
                                :value="item"
                            />
                        </el-select>
                        <div class="invalid-feedback" id="update-product-type-feedback"> Product type is required</div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6">
                        <label> Product Category </label>
                        <el-select
                            v-model="activeProduct.category_id"
                            default-first-option
                            filterable
                            placeholder="Search or select a category"
                            size="large"
                            no-data-text="Please add product categories first"
                        >
                            <el-option
                                v-for="category in categories"
                                :key="category.id"
                                :label="category.name"
                                :value="category.id"
                            />
                        </el-select>
                    </div>

                    <div class="form-group col-md-6">
                        <label class="required"> Product Sub Category </label>
                        <el-select
                            v-model="activeProduct.sub_category_id"
                            default-first-option
                            filterable
                            placeholder="Search or select a sub category"
                            size="large"
                            no-data-text="Please select a category first"
                        >
                            <el-option
                                v-for="subCategory in matchingSubCategories"
                                :key="subCategory.id"
                                :label="subCategory.name"
                                :value="subCategory.id"
                            />
                        </el-select>
                        <div class="invalid-feedback" id="update-product-sub-category-feedback"> Product sub category is required</div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6">
                        <label class="required"> Primary Units </label>
                        <el-select
                            v-model="activeProduct.unit_id"
                            default-first-option
                            filterable
                            allow-create
                            placeholder="Search or select a unit"
                            size="large"
                            no-data-text="No units found. Type a unit name and select it to add"
                            :disabled="activeProduct.type === 'Service'"
                        >
                            <el-option
                                v-for="unit in uoms"
                                :key="unit.id"
                                :label="unit.unit_name"
                                :value="unit.id"
                            />
                        </el-select>
                        <div id="update-product-uom-help" class="form-text">To add a new unit, type its full name and select it.</div>
                        <div class="invalid-feedback" id="update-product-uom-feedback"> Product units are required</div>
                    </div>

                    <div class="form-group col-md-6">
                        <label for="update-product-buying-price"> Buying Price </label>
                        <input type="text"
                               id="update-product-buying-price"
                               class="form-control" v-model="activeProduct.buying_price"
                               placeholder="Buying price"
                               :disabled="activeProduct.type === 'Service'">
                        <div class="invalid-feedback"> Please provide a valid buying price</div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6">
                        <label class="required" for="update-product-selling-price"> Selling Price </label>
                        <input type="text" id="update-product-selling-price" class="form-control" v-model="activeProduct.selling_price" placeholder="Selling price">
                        <div class="invalid-feedback"> Please provide a valid selling price</div>
                    </div>

                    <div class="form-group col-md-6">
                        <label> Variants </label>
                        <el-select
                            v-model="activeProduct.variants"
                            filterable
                            multiple
                            placeholder="Select or add variants"
                            size="large">
                            <el-option
                                v-for="variant in ['color', 'size', 'capacity']"
                                :key="variant"
                                :label="capitalizeEachWord(variant)"
                                :value="variant"
                            />
                        </el-select>
                    </div>
                </div>

            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showUpdateProductDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="updateProduct"> Save Changes</button>
                </div>
            </template>
        </el-dialog>
        <!-- END OFUPDATEPRODUCT MODAL -->
    </div>
</template>

<script>
import {mapState} from 'vuex'
import $ from 'jquery'
import {Delete, Edit} from "@element-plus/icons-vue";

export default {
    name: "ProductsListComponent",
    data() {
        return {
            baseUrl: '/api/products',
            gettingProducts: false,
            gettingCategories: false,
            gettingSubCategories: false,
            gettingUoms: false,
            products: [],
            displayableProducts: [],
            categories: [],
            subCategories: [],
            uoms: [],
            showAddingProduct: false,
            addingProduct: false,
            newProduct: {},
            disabledDate(time) {
                return time.getTime() < Date.now()
            },
            activeProduct: {},
            showUpdateProductDialog: false,
            updatingProduct: false,
            filters: {},

        }
    },

    created() {
        if (this.currentUser) {
            this.getMetaData()
        }
    },

    computed: {
        ...mapState('system', ['currentUser', 'loadingUserDetails', 'settings']),

        Edit() {
            return Edit
        },

        Delete() {
            return Delete
        },

        matchingSubCategories() {
            if (this.showUpdateProductDialog === true) {
                if (!this.activeProduct.category_id) {
                    return []
                }

                return this.subCategories.filter(subCat => subCat.category.id === this.activeProduct.category_id)
            } else {
                if (!this.newProduct.category_id) {
                    return []
                }

                return this.subCategories.filter(subCat => subCat.category.id === this.newProduct.category_id)
            }
        },

        emptyText() {
            if (!this.products || this.empty(this.products)) {
                return "You haven\'t added any products. Click the add product button to add your first product."
            }

            return "No products found. Please try a different filter."
        },

        variationLevel() {
            if (!this.settings) {
                return 'product'
            }

            let setting = this.settings.find(_setting => _setting.setting === 'PRODUCT_VARIATION_LEVEL')
            if (setting) {
                return setting.value
            }

            return 'product'
        },

        shouldDisplayPriceEntries(newProduct = true) {
            if (newProduct) {
                if (this.variationLevel === 'subcategory') {
                    if (this.newProduct.has_variants) {
                        return false
                    }
                }
            }

            return true
        },
    },

    watch: {
        loadingUserDetails(newStatus, oldStatus) {
            if (!newStatus) {
                this.getMetaData()
            }
        },
    },

    methods: {
        getMetaData() {
            this.getProductList()

            this.getUoms()

            this.getCategories()

            this.getSubCategories()

            if (!this.settings || this.empty(this.settings)) {
                this.$store.dispatch('system/getSettings')
            }
        },

        getProductList() {
            this.gettingProducts = true

            axios.get(`${this.baseUrl}/list`, {params: {business_id: this.currentUser.business.id}}).then(response => {
                this.gettingProducts = false
                this.products = response.data.data

                this.displayableProducts = this.products
            }).catch(error => {
                this.gettingProducts = false
                this.$message.error('An error was encountered. Please reload the page')
            })
        },

        getUoms() {
            axios.get('/api/products/classification/uoms/list').then(response => {
                this.uoms = response.data.data
            }).catch(error => {
                //
            })
        },

        getCategories() {
            axios.get('/api/products/classification/categories/list', {params: {business_id: this.currentUser.business.id}}).then(response => {
                this.categories = response.data.data
            }).catch(error => {
            })
        },

        getSubCategories() {
            axios.get('/api/products/classification/sub-categories/list', {params: {business_id: this.currentUser.business.id}}).then(response => {
                this.subCategories = response.data.data
            }).catch(error => {
            })
        },

        promptAddProduct() {
            this.newProduct = {}
            this.newProduct.type = 'Product'
            this.newProduct.business_id = this.currentUser.business.id
            this.newProduct.user_id = this.currentUser.id
            this.newProduct.buying_price = 0
            this.newProduct.selling_price = 0
            this.newProduct.has_variants = false

            this.showAddingProduct = true
        },

        addProduct(e) {
            e.preventDefault()

            // Validate
            let dataIsValid = true
            if (!this.newProduct.label || this.newProduct.label === '') {
                dataIsValid = false
                $("#product-name").addClass('is-invalid')
            } else {
                $("#product-name").removeClass('is-invalid')
            }

            if (!this.newProduct.type || this.newProduct.type === '') {
                dataIsValid = false
                $("#new-product-type-feedback").css('display', 'block')
            } else {
                $("#new-product-type-feedback").css('display', 'none')
            }

            if (!this.newProduct.sub_category_id) {
                dataIsValid = false
                $("#new-product-sub-category-feedback").css('display', 'block')
            } else {
                $("#new-product-sub-category-feedback").css('display', 'none')
            }

            if (!this.newProduct.unit_id && this.newProduct.type === 'Product') {
                dataIsValid = false
                $("#new-product-uom-feedback").css('display', 'block')
            } else {
                $("#new-product-uom-feedback").css('display', 'none')
            }

            // if (!this.newProduct.selling_price || isNaN(parseFloat(this.newProduct.selling_price))) {
            //     dataIsValid = false
            //     $("#new-product-selling-price").addClass('is-invalid')
            // } else {
            //     $("#new-product-selling-price").removeClass('is-invalid')
            // }

            if ((!this.newProduct.variants || this.empty(this.newProduct.variants)) && this.newProduct.type === 'Product') {
                dataIsValid = false
                $("#new-product-variants-feedback").css('display', 'block')
            } else {
                $("#new-product-variants-feedback").css('display', 'none')
            }

            console.log(this.newProduct)

            if (dataIsValid) {
                // Check if user has created new uom
                if ((typeof this.newProduct.unit_id) == 'string') {
                    this.newProduct.has_new_unit = 1
                }

                this.addingProduct = true
                axios.post(`${this.baseUrl}/add`, this.newProduct).then(response => {
                    this.products.push(response.data.data.product)
                    this.displayableProducts = this.products

                    if (this.newProduct.has_new_unit === 1 && response.data.data.uom) {
                        this.uoms.push(response.data.data.uom)
                    }

                    this.addingProduct = false
                    this.showAddingProduct = false
                    this.$message.success('Product added successfully')
                }).catch(error => {
                    this.addingProduct = false

                    if (error.response.status === 422) {
                        this.$message.error(error.response.data.message)
                    } else {
                        this.$message.error('An error was encountered. Please try again')
                    }
                })
            } else {
                this.$message.warning('Please fill all required fields')
            }
        },

        handleProductActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            if (commandName === 'update') {
                this.promptUpdateProduct(this.displayableProducts[commandIndex])
            }

            if (commandName === 'remove') {
                this.promptRemoveProduct(this.displayableProducts[commandIndex])
            }
        },

        promptUpdateProduct(product) {
            this.activeProduct = {
                id: product.id,
                label: product.label,
                type: product.type,
                category_id: product.sub_category.category.id,
                sub_category_id: product.sub_category.id,
                unit_id: product.units.id,
                buying_price: product.buying_price,
                selling_price: product.selling_price,
                variants: product.variants
            }

            this.showUpdateProductDialog = true
        },

        updateProduct(e) {
            e.preventDefault()

            // Validate
            let dataIsValid = true
            if (!this.activeProduct.label || this.activeProduct.label === '') {
                dataIsValid = false
                $("#update-product-name").addClass('is-invalid')
            } else {
                $("#update-product-name").removeClass('is-invalid')
            }

            if (!this.activeProduct.type || this.activeProduct.type === '') {
                dataIsValid = false
                $("#update-product-type-feedback").css('display', 'block')
            } else {
                $("#update-product-type-feedback").css('display', 'none')
            }

            if (!this.activeProduct.sub_category_id) {
                dataIsValid = false
                $("#update-product-sub-category-feedback").css('display', 'block')
            } else {
                $("#update-product-sub-category-feedback").css('display', 'none')
            }

            if (!this.activeProduct.unit_id && this.activeProduct.type === 'Product') {
                dataIsValid = false
                $("#update-product-uom-feedback").css('display', 'block')
            } else {
                $("#update-product-uom-feedback").css('display', 'none')
            }

            if (!this.activeProduct.selling_price || isNaN(parseFloat(this.activeProduct.selling_price))) {
                dataIsValid = false
                $("#update-product-selling-price").addClass('is-invalid')
            } else {
                $("#update-product-selling-price").removeClass('is-invalid')
            }

            if (dataIsValid) {
                // Check if user has created new uom
                if ((typeof this.activeProduct.unit_id) == 'string') {
                    this.activeProduct.has_new_unit = 1
                }

                this.updatingProduct = true
                axios.post(`${this.baseUrl}/update`, this.activeProduct).then(response => {
                    this.$store.dispatch('products/updateProduct', response.data.data.product)
                    this.displayableProducts = this.products

                    if (this.activeProduct.has_new_unit === 1 && response.data.data.uom) {
                        this.$store.commit('products/addUom', response.data.data.uom)
                    }
                    this.updatingProduct = false
                    this.showUpdateProductDialog = false
                    this.$message.success('Product updated successfully')
                }).catch(error => {
                    this.updatingProduct = false
                    console.log(error.response.data)

                    if (error.response.status === 422) {
                        this.$message.error(error.response.data.message)
                    } else {
                        this.$message.error('An error was encountered. Please try again')
                    }
                })
            } else {
                this.$message.warning('Please fill all required fields')
            }
        },

        promptRemoveProduct(product) {
            this.$confirm(
                'This action is permanent and will remove all stock entries under this category. Continue?',
                'Remove Category',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            )
                .then(() => {
                    axios.post(`${this.baseUrl}/remove`, {id: product.id}).then(response => {
                        this.$store.dispatch('products/removeProduct', product)
                        this.$message.success('Product removed successfully')

                        this.displayableProducts = this.products
                    }).catch(error => {
                        console.log(error.response.data)
                        this.$message.error('An error was encountered. Please try again')
                    })
                })
                .catch(() => {
                    //
                })
        },

        filterProducts() {
            // Reset filters
            this.displayableProducts = this.products

            // Product name
            if (this.filters.name) {
                this.displayableProducts = this.displayableProducts.filter(product => (product.label.toLowerCase()).includes(this.filters.name.toLowerCase()))
            }

            // Product category
            if (this.filters.category) {
                this.displayableProducts = this.displayableProducts.filter(product =>
                    (product.sub_category.category.name.toLowerCase()).includes(this.filters.category.toLowerCase()))
            }

            // Product sub category
            if (this.filters.subcategory) {
                this.displayableProducts = this.displayableProducts.filter(product =>
                    (product.sub_category.name.toLowerCase()).includes(this.filters.subcategory.toLowerCase()))
            }
        },

        filterProductsMobile() {
            // Reset filters
            this.displayableProducts = this.products

            if (this.filters.name) {
                this.displayableProducts = this.displayableProducts.filter(product =>
                    (product.label.toLowerCase()).includes(this.filters.name.toLowerCase()) ||
                    (product.sub_category.name.toLowerCase()).includes(this.filters.name.toLowerCase()) ||
                    (product.sub_category.category.name.toLowerCase()).includes(this.filters.name.toLowerCase())
                )
            }
        },
    },
}
</script>

<style scoped>
#no-products {
    height: 70vh;
}

#no-products span {
    display: block;
}
</style>
