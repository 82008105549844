<template>
    <div id="sales-list" class="master-template">
        <div class="master-title-div">
            <h5> Sales List </h5>
        </div>

        <div class="master-content">
            <div class="data-table-filters card shadow bg-white">
                <h5> Filter Sales </h5>

                <div class="row has-form-groups">
                    <div class="form-group col-md-4">
                        <label> Search by sale number </label>
                        <input type="text" class="form-control" v-model="filters.sale_number" placeholder="Search by sale number"
                               @keyup.enter="getPosSalesList">
                    </div>

                    <div class="form-group col-md-4">
                        <label> Search by sale date </label>
                        <el-date-picker
                            v-model="filters.sale_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>

                    <div class="form-group col-md-4">
                        <label> Search by customer </label>
                        <el-select size="large" filterable v-model="filters.customer_id">
                            <el-option v-for="customer in customers" :key="customer.id" :value="customer.id" :label="customer.name"></el-option>
                        </el-select>
                    </div>
                </div>

                <div class="mt-3 d-flex">
                    <button class="btn btn-secondary mr-12" @click="getPosSalesList"> Filter Sales</button>
                    <el-button size="large" @click="resetSalesFilters">Reset Filters</el-button>
                </div>
            </div>

            <div class="data-table-summary d-flex justify-content-end mt-4 px-1">
                <span class="summary"> Showing {{ sales.length }} out of {{ count }} items. </span>
            </div>

            <div class="data-table">
                <el-skeleton :loading="gettingSales" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" v-for="row in 9" :key="row" style="width: 100%; height: 20px; margin-bottom: 20px;"/>
                    </template>
                    <template #default>
                        <el-table :data="sales" style="width: 100%">
                            <el-table-column prop="display_sale_number" label="Sale Number"/>
                            <el-table-column prop="timestamp" label="Sale Date"/>
                            <el-table-column prop="display_sale_total" label="Sale Total"/>
                            <el-table-column prop="display_amount_paid" label="Amount Paid"/>
                            <el-table-column prop="customer" label="Customer"></el-table-column>
                            <el-table-column label="Sold By" prop="seller"></el-table-column>
                            <el-table-column label="Actions">
                                <template #default="scope">
                                    <el-dropdown @command="handleSaleActions">
                                        <el-button type="primary" plain>
                                            Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item :command="'view-' + scope.$index">View Sale Items</el-dropdown-item>
                                                <el-dropdown-item :command="'reprint-' + scope.$index"> Reprint Sale Receipt</el-dropdown-item>
                                                <el-dropdown-item :command="'void-' + scope.$index">Void Sale</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-skeleton>
            </div>

            <!-- Pagination -->
            <div class="data-table-pagination d-flex justify-content-end mt-3">
                <el-pagination background layout="prev, pager, next" :total="count"
                               :page-size="10" v-model:current-page="currentPage" @current-change="getPosSalesList" hide-on-single-page/>
            </div>
        </div>

        <!-- Void Sale Dialog -->
        <el-dialog title="Void Sale" width="30%" v-model="showVoidSaleDialog" v-if="toVoidSale">
            <form method="post" @submit.prevent="voidSale" v-loading="voidingSale" element-loading-text="Voiding sale. Please wait.">
                <div class="form-group">
                    <label for="reason"> Why are you voiding this sale? </label>
                    <textarea v-model="toVoidSale.reason" id="reason" class="form-control"></textarea>
                    <div class="invalid-feedback"> Please provide a reason</div>
                </div>
            </form>
            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showVoidSaleDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="voidSale"> Void Sale</button>
                </div>
            </template>
        </el-dialog>
        <!-- End Of Void Sale Dialog -->

        <!-- Sale Items Dialog -->
        <el-dialog :title="'Sale Items for Sale ' + activeSale.display_sale_number" width="50%" v-model="showSaleItemsDialog">
            <el-table :data="activeSale.items" style="width: 100%">
                <el-table-column prop="item_label" label="Sale Item"/>
                <el-table-column label="Item Price">
                    <template #default="scope">
                        {{ formatAmount(scope.row.selling_price) }}
                    </template>
                </el-table-column>
                <el-table-column prop="display_quantity" label="Quantity Sold"/>
                <el-table-column label="Discount">
                    <template #default="scope">
                        {{ formatAmount(scope.row.discount) }}
                    </template>
                </el-table-column>
                <el-table-column label="Item Total">
                    <template #default="scope">
                        {{ formatAmount(scope.row.item_total) }}
                    </template>
                </el-table-column>
            </el-table>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-end">
                    <button class="btn btn-primary" @click="showSaleItemsDialog = false"> Close</button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import $ from 'jquery'

export default {
    name: "SalesList",

    data() {
        return {
            baseUrl: '/api/sales',
            gettingSales: false,
            sales: [],
            count: 0,
            filters: {
                sale_number: null,
                customer_id: null,
                sale_date: null,
            },
            currentPage: 1,
            showVoidSaleDialog: false,
            toVoidSale: {},
            voidingSale: false,
            showSaleItemsDialog: false,
            activeSale: {},
            customers: [],
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
        ...mapGetters('system', ['getBasicPayload'])
    },

    created() {
        this.getPosSalesList()
        this.getCustomers()
    },

    methods: {
        getPosSalesList() {
            let payload = {
                ...this.getBasicPayload, ...{
                    page: (this.currentPage - 1),
                    sale_number_filter: this.filters.sale_number,
                    sale_date_filter: this.filters.sale_date,
                    customer_id_filter: this.filters.customer_id,
                }
            }

            this.gettingSales = true
            axios.get(`${this.baseUrl}`, {params: payload}).then(response => {
                this.gettingSales = false
                this.sales = response.data.data.content
                this.count = response.data.data.count
            }).catch(error => {
                this.gettingSales = false
                this.$message.error('An error was encountered. Please reload the page')
            })
        },

        getCustomers() {
            axios.get('/api/customers', {params: this.getBasicPayload}).then(res => {
                this.customers = res.data.data
            }).catch(error => {
            })
        },

        handleSaleActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            switch (commandName) {
                case 'void':
                    this.toVoidSale = {}
                    this.toVoidSale.id = this.sales[commandIndex].id
                    this.toVoidSale.index = commandIndex
                    this.showVoidSaleDialog = true
                    break

                case 'view':
                    this.activeSale = {}
                    this.activeSale = this.sales[commandIndex]
                    this.showSaleItemsDialog = true
                    break

                case 'reprint':
                    this.activeSale = this.sales[commandIndex]
                    axios.post('http://printer.test/api/sales/sales-receipt', {sale: JSON.stringify(this.activeSale)}).then(res => {
                        //
                    }).catch(error => {
                        this.$message.error('Receipt could not be printed printed')
                    })

                    break
                default:
                    break
            }
        },

        voidSale(e) {
            e.preventDefault()

            this.toVoidSale = {...this.toVoidSale, ...this.getBasicPayload}
            this.voidingSale = true
            axios.post(`${this.baseUrl}/void`, this.toVoidSale).then(res => {
                this.voidingSale = false
                this.sales.splice(this.toVoidSale.index, 1)
                this.$message.success('Sale voided successfully')
                this.showVoidSaleDialog = false
            }).catch(error => {
                this.voidingSale = false
                this.$message.error('An error was encountered. Please try again.')
            })
        },

        resetSalesFilters() {
            this.filters = {}
            this.currentPage = 1
            this.getPosSalesList()
        }
    },
}
</script>

<style scoped>

</style>
