export const procurementStore = {
    namespaced: true,
    state: () => ({
        procurementBaseUrl: '/api/procurement',
        suppliers: [],
    }),

    getters: {
        purchasesBaseUrl(state) {
            return `${state.procurementBaseUrl}/purchases`
        },

        suppliersBaseUrl(state) {
            return `${state.procurementBaseUrl}/suppliers`
        }
    },

    mutations: {
        setSuppliersList(state, suppliers) {
            state.suppliers = suppliers
        },
    },

    actions: {
        getSuppliers({state, commit, rootState}) {
            axios.get(`${state.procurementBaseUrl}/suppliers`, {params: {business_id: rootState.system.currentUser.business_id}})
                .then(response => {
                    commit('setSuppliersList', response.data.data)
                })
                .catch(error => {
                    console.log(error.response.data)
                })
        },

        resetAll({commit}) {
            commit('setSuppliersList', [])
        },
    },
}
