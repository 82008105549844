export const systemStore = {
    namespaced: true,
    state: () => ({
        navKey: 1,
        subNavKey: null,
        currentUser: null,
        settings: [],
        stores: []
    }),

    getters: {
        getBasicPayload(state, getters, rootState, _) {
            return {
                user_id: state.currentUser.id,
                store_id: state.currentUser.store_id,
                business_id: state.currentUser.business_id,
            };
        },

        businessHasMultipleStores(state) {
            return state.stores.length > 1
        },
    },

    mutations: {
        updateNavKey(state, value) {
            state.navKey = value
        },

        updateSubNavKey(state, value) {
            state.subNavKey = value
        },

        updateCurrentUser(state, payload) {
            state.currentUser = payload
        },

        updateBusiness(state, newName) {
            state.currentUser.business_name = newName
        },

        updateStore(state, newName) {
            state.currentUser.store_name = newName
        },

        resetNavKeys(state) {
            state.navKey = 1
            state.subNavKey = null
        },

        setSettings(state, settings) {
            state.settings = settings
        },

        setStores(state, stores) {
            state.stores = stores
        },
    },

    actions: {
        setActiveNavKey({state, commit, rootState}, navKey) {
            commit('updateNavKey', navKey)
        },

        setActiveSubNavKey({state, commit, rootState}, key) {
            commit('updateSubNavKey', key)
        },

        getSettings({state, commit}) {
            let payload = {
                store_id: state.currentUser.store_id,
                module: 'all'
            }

            axios.get('/api/settings/saved', {params: payload}).then(res => {
                commit('setSettings', res.data.data)
            }).catch(error => {})
        },

        fetchStores({state, commit}) {
            let payload = {
                business_id: state.currentUser.business_id,
            }

            axios.get('/api/business/stores', {params: payload}).then(res => {
                commit('setStores', res.data.data)
            }).catch(error => {})
        },


        resetAll({commit}) {
            commit('updateCurrentUser', null)
            commit('setSettings', [])
            commit('setStores', [])
            commit('resetNavKeys')
        },
    },
}
