<template>
    <div class="master-template">
        <div class="master-title-div d-flex justify-content-between align-items-center">
            <h5> Hi {{ currentUser.firstname }}, here's what happened in your stores today </h5>

            <div class="actions d-flex">
                <el-select v-model="selectedStoreId" filterable size="large"
                           placeholder="Select store" v-if="businessHasMultipleStores && can('manage_multiple_stores', currentUser)"
                           @change="getDashboardAnalytics">
                    <el-option v-for="store in stores" :key="store.id" :label="store.store_name"
                               :value="store.id"></el-option>
                </el-select>
            </div>
        </div>

        <hr>

        <div class="master-content" style="margin-top: 20px;">
            <div id="store-summary">
                <el-skeleton :loading="gettingStoreSummary" :throttle="200" animated>
                    <template #template>
                        <div class="analytic-cards d-flex justify-content-between">
                            <el-skeleton-item variant="rect" v-for="row in 4" :key="row"
                                              style="width: 22%; height: 95px;"/>
                        </div>
                    </template>
                    <template #default>
                        <div class="analytic-cards d-flex justify-content-between flex-wrap">
                            <!-- Collections -->
                            <div class="analytic-card card shadow flex-column" style="background-color: #DFECE0;">
                                <span class="analytic-card-title"> Total Collections </span>

                                <div class="analytic-card-icon d-flex justify-content-center align-items-center">
                                    <div class="flex-grow-1">
                                        <span class="analytic-card-value"> {{
                                                formatAmount(analytics.top_cards.collections)
                                            }} </span>
                                    </div>
                                    <i class="fas fa-hand-holding-usd"></i>
                                </div>
                            </div>

                            <!-- Sales -->
                            <div class="analytic-card card shadow flex-column"
                                 style="background-color: rgba(230, 226, 249, 1);">
                                <!--                    <div class="analytic-card card shadow d-flex flex-row" style="background-color: #E2EDF9;">-->
                                <span class="analytic-card-title"> Collections From Sales </span>

                                <div class="analytic-card-icon d-flex justify-content-center align-items-center">
                                    <div class="flex-grow-1">
                                        <span class="analytic-card-value"> {{
                                                formatAmount(analytics.top_cards.sales)
                                            }} </span>
                                    </div>
                                    <i class="fas fa-shopping-bag"></i>
                                </div>
                            </div>

                            <!-- Money Out -->
                            <div class="analytic-card card shadow flex-column" style="background-color: #f2d1d9;">
                                <span class="analytic-card-title"> Total Expenses </span>

                                <div class="analytic-card-icon d-flex justify-content-center align-items-center">
                                    <div class="flex-grow-1">
                                        <span class="analytic-card-value"> {{
                                                formatAmount(analytics.top_cards.money_out)
                                            }} </span>
                                    </div>
                                    <i class="fas fa-sign-out-alt"></i>
                                </div>
                            </div>

                            <!-- Profit -->
                            <div class="analytic-card card shadow flex-column" style="background-color: #98ed9e;">
                                <span class="analytic-card-title"> Gross Item Profit </span>

                                <div class="analytic-card-icon d-flex justify-content-center align-items-center">
                                    <div class="flex-grow-1">
                                        <span class="analytic-card-value"> {{
                                                formatAmount(analytics.top_cards.profit)
                                            }} </span>
                                    </div>
                                    <i class="fas fa-chart-line"></i>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-skeleton>
            </div>

            <div id="sales-by-day" class="mt-5">
                <el-skeleton :loading="gettingSalesByDay" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="rect" style="height: 300px; width: 100%;"/>
                    </template>
                    <template #default>
                        <div class="card shadow-sm w-100">
                            <h5 class="card-title"> Sales By Day Comparison This Week </h5>
                            <div class="card-content" id="dashboard-sales-summary" style="min-height: 500px;"></div>
                        </div>
                    </template>
                </el-skeleton>
            </div>
        </div>

    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export default {
    name: "Dashboard",

    data() {
        return {
            selectedStoreId: null,
            gettingSalesByDay: false,
            gettingStoreSummary: false,
            root: null,
            analytics: {
                top_cards: {
                    collections: 0,
                    money_out: 0,
                    sales: 0,
                    profit: 0,
                },
                summary: [],
            },
        }
    },

    computed: {
        ...mapState('system', ['currentUser', 'stores']),
        ...mapGetters('system', ['getBasicPayload', 'businessHasMultipleStores']),

        businessHasMoreThanOneStore() {
            return this.stores.length > 1
        },

        shouldShowOptionForStoreSelection() {
            return this.businessHasMoreThanOneStore && this.isAdmin(this.currentUser)
        },
    },

    created() {
        if (this.can('access_dashboard', this.currentUser)) {
            this.getDashboardAnalytics()
        }

        if (this.stores.length === 0) {
            this.$store.dispatch('system/fetchStores')
        }
    },

    mounted() {
        this.selectedStoreId = this.currentUser.store_id
    },

    beforeDestroy() {
        if (this.root) {
            this.root.dispose()
        }
    },

    methods: {
        getDashboardAnalytics() {
            if (!this.gettingAnalytics) {
                this.gettingAnalytics = true
            }

            let payload = {
                store_id: this.selectedStoreId ?? this.currentUser.store_id
            }

            axios.get('/api/business/analytics', {params: payload}).then(response => {
                this.analytics = response.data.data.analytics
                this.gettingAnalytics = false

                if (this.root) {
                    this.root.dispose()
                }


                setTimeout(() => {
                    this.renderSummaryChart()
                }, 1500)
            }).catch(error => {
                this.gettingAnalytics = false
                this.$message.error('Error loading analytics. Please reload the page')
            })
        },

        renderSummaryChart() {
            // Instantiate chart
            let root = am5.Root.new("dashboard-sales-summary");
            root.setThemes([am5themes_Animated.new(root)]);

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: true,
                    panY: true,
                    wheelX: "panX",
                    wheelY: "zoomX",
                    pinchZoomX: true
                })
            );

            // Add cursor
            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

            // Create Y-axis
            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {}),
                    maxDeviation: 0.3
                })
            );

            // Create X-Axis
            let xRenderer = am5xy.AxisRendererX.new(root, {minGridDistance: 30});
            xRenderer.grid.template.setAll({
                location: 0.5
            });
            let xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(root, {
                    renderer: xRenderer,
                    // renderer: am5xy.AxisRendererX.new(root, {}),
                    categoryField: "day",
                    tooltip: am5.Tooltip.new(root, {}),
                    maxDeviation: 0.3
                })
            );

            // Create series
            let series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: "Total Sales",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "sales",
                    categoryXField: "day",
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "{valueY}"
                    }),
                    sequencedInterpolation: true
                })
            );

            series.columns.template.setAll({cornerRadiusTL: 5, cornerRadiusTR: 5});
            series.columns.template.adapters.add("fill", function (fill, target) {
                return chart.get("colors").getIndex(series.columns.indexOf(target));
            });

            series.columns.template.adapters.add("stroke", function (stroke, target) {
                return chart.get("colors").getIndex(series.columns.indexOf(target));
            });

            // Set data
            xAxis.data.setAll(this.analytics.summary);
            series.data.setAll(this.analytics.summary);

            series.appear(1000);
            chart.appear(1000, 100);

            this.root = root
        },
    },
}
</script>

<style>
.analytic-card {
    outline: none;
    border: none;
    border-radius: 10px;
    padding: 12px 15px;
    width: 22%;
    flex-direction: row;
    position: relative;
}

.analytic-card span {
    display: block;
}

.analytic-card-title {
    font-weight: 700;
    font-size: 18px;
}

.analytic-card-value {
    font-weight: 800;
    font-size: 25px;
    margin-top: 12px;
}

.analytic-card-icon i {
    font-size: 30px;
}
</style>
