<script>
import {defineComponent} from 'vue'
import {mapGetters, mapState} from "vuex";
import Tip from "../../../components/shared/Tip.vue";
import SvgSymbols from "../../../components/shared/SvgSymbols.vue";

export default defineComponent({
    name: "StockItemHistoryReport",
    components: {SvgSymbols, Tip},

    data() {
        return {
            report: null,
            count: 0,
            period: null,
            fetchingReport: false,
            fetchingStockItems: false,
            stockItems: [],
            errorFetchingReport: false,
            filters: {
                start_date: null,
                end_date: null,
                stock_item_id: null
            },
            currentPage: 1
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
    },

    created() {
        this.getStockItems()
    },

    methods: {
        fetchReport() {
            this.errorFetchingReport = false

            if (!this.filters.stock_item_id) {
                return this.$message.error("Select a stock item first")
            }

            let payload = {
                start_date_filter: this.filters.start_date,
                end_date_filter: this.filters.end_date,
                store_id: this.currentUser.store_id,
                stock_item_id: this.filters.stock_item_id,
                page: this.currentPage - 1
            }
            this.fetchingReport = true
            axios.get('/api/reports/stock/item-history-report', {params: payload}).then(res => {
                this.fetchingReport = false
                this.report = res.data.data.transactions.content
                this.count = res.data.data.transactions.count
                this.period = res.data.data.period
            }).catch(error => {
                this.fetchingReport = false
                this.errorFetchingReport = true

                this.$message.error('An error was encountered. Please try again.')
            })
        },

        getStockItems() {
            this.fetchingStockItems = true
            axios.get('/api/stock/levels', {params: {store_id: this.currentUser.store_id}}).then(response => {
                this.fetchingStockItems = false
                this.stockItems = response.data.data
            }).catch(_ => {
                this.fetchingStockItems = false
            })
        },

        resetReportFilters() {
            this.filters = {}
            this.fetchReport()
        },
    },
})
</script>

<template>
    <div class="master-template is-report-page">
        <div class="report-content position-relative" style="width: 100%;">
            <div class="w-100 d-flex justify-content-between align-items-center">
                <h5 class="fw-700 f-22 mb-0"> Stock Item History Report </h5>

                <a href="/reports" class="btn btn-outline-primary new-item-btn">
                    <i class="fas fa-backward"></i>Back to Reports
                </a>
            </div>

            <div class="card report-filters shadow position-relative w-100 mt-4">
                <h5 class="f-18 fw-700"> Report filters </h5>

                <div class="row">
                    <div class="col-md-4 form-group">
                        <label> Stock Item </label>
                        <el-select size="large" v-model="filters.stock_item_id" filterable :loading="fetchingStockItems" style="width: 100%;">
                            <el-option v-for="item in stockItems" :key="item.id" :label="item.label" :value="item.id"></el-option>
                        </el-select>
                    </div>

                    <div class="col-md-4 form-group">
                        <label> Start Date </label>
                        <el-date-picker
                            v-model="filters.start_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                            style="width: 100%;"
                        >
                        </el-date-picker>
                    </div>

                    <div class="col-md-4 form-group">
                        <label> End Date </label>
                        <el-date-picker
                            v-model="filters.end_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                            style="width: 100%;"
                        >
                        </el-date-picker>
                    </div>
                </div>

                <div class="mt-3 d-flex">
                    <button class="btn btn-secondary mr-12" @click="fetchReport"> Fetch Report</button>
                    <el-button size="large" type="info" plain @click="resetReportFilters">Reset Filters</el-button>
                </div>
            </div>

            <div class="card shadow position-relative w-100 mt-4" v-loading="fetchingReport" element-loading-text="Generating report"
                 style="min-height: 200px;">
                <div v-if="errorFetchingReport">
                    <div class="master-tip">
                        <svg-symbols></svg-symbols>
                        <tip type="danger">
                            An error was encountered. Please try again.
                        </tip>
                    </div>
                    <button class="btn btn-primary" @click="fetchReport"> Try Again </button>
                </div>

                <div v-if="!filters.stock_item_id">
                    Select a stock item and click on "Fetch Report" to continue.
                </div>

                <div id="report-header" class="d-flex flex-column w-100" v-if="report && filters.stock_item_id && !errorFetchingReport">
                    <div id="business-name" class="d-flex w-100 text-center justify-content-center align-items-center">
                        {{ currentUser.business_name }}
                    </div>
                    <div id="report-title" class="d-flex w-100 text-center justify-content-center align-items-center">
                        Stock Item History Report
                    </div>

                    <div id="report-period" class="d-flex w-100 text-center justify-content-center align-items-center">
                        {{ period?.start }} to {{ period?.end }}
                    </div>
                </div>

                <div id="report-details" class="w-100" v-if="report && filters.stock_item_id && !errorFetchingReport">
                    <div class="data-table-summary d-flex justify-content-end mb-2">
                        <span class="summary"> Showing {{ report.length }} out of {{ count }} transactions </span>
                    </div>

                    <div class="data-table">
                        <el-table :data="report" style="width: 100%;">
                            <el-table-column prop="item" label="Stock Item" />
                            <el-table-column prop="action" label="Action" />
                            <el-table-column prop="starting_quantity" label="Quantity Before Operation" />
                            <el-table-column prop="ending_quantity" label="Quantity After Operation" />
                            <el-table-column prop="transaction_date" label="Date & Time" width="250" />
                            <el-table-column prop="transaction_type" label="Operation Type" />
                            <el-table-column prop="user" label="User" />
                        </el-table>

                        <div class="data-table-pagination d-flex justify-content-end mt-3">
                            <el-pagination background layout="prev, pager, next" :total="count"
                                           :page-size="10" v-model:current-page="currentPage" @current-change="fetchReport"
                                           hide-on-single-page/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>

</style>
