<template>
    <div class="d-flex justify-content-center align-items-center align-content-center master-template" v-loading="fetchingShiftReport"
         element-loading-text="Generating report">
        <div class="card shadow" style="width: 60%; position:relative;" v-if="shiftReport">
            <div id="report-header" class="d-flex flex-column w-100">
                <div id="business-name" class="d-flex w-100 text-center justify-content-center align-items-center">
                    {{ currentUser.business_name }}
                </div>
                <div id="report-title" class="d-flex w-100 text-center justify-content-center align-items-center">
                    Current Shift Summary
                </div>

                <div id="report-period" class="d-flex w-100 text-center justify-content-center align-items-center" v-if="shiftReport">
                    {{ shiftReport.shift?.start }} to {{ shiftReport.shift?.end }}
                </div>
            </div>

            <div id="report-details-header" class="w-100 d-flex justify-content-end">
                <span> TOTALS </span>
            </div>

            <div id="report-details" class="w-100" v-if="shiftReport">
                <h5 class="report-section-header is-first-child"> SALES SUMMARY </h5>

                <div class="report-item d-flex justify-content-between align-items-center">
                    <span class="label"> Total Sales Posted </span>
                    <span class="value"> {{ formatAmount(shiftReport.sales_summary?.total_sales) }} </span>
                </div>

                <div class="report-item d-flex justify-content-between align-items-center">
                    <span class="label"> Credit Sales </span>
                    <span class="value"> {{ formatAmount(shiftReport.sales_summary?.credit_sales) }} </span>
                </div>

                <h5 class="report-section-header"> COLLECTIONS </h5>

                <div class="report-item d-flex justify-content-between align-items-center">
                    <span class="label"> Cash Sales </span>
                    <span class="value"> {{ formatAmount(shiftReport.collections?.cash_sales) }} </span>
                </div>

                <div class="report-item d-flex justify-content-between align-items-center">
                    <span class="label"> MPESA Sales </span>
                    <span class="value"> {{ formatAmount(shiftReport.collections?.mpesa_sales) }} </span>
                </div>

                <div class="report-item d-flex justify-content-between align-items-center">
                    <span class="label"> Cash Pay Ins </span>
                    <span class="value"> {{ formatAmount(shiftReport.collections?.cash_pay_ins) }} </span>
                </div>

                <div class="report-item d-flex justify-content-between align-items-center">
                    <span class="label"> MPESA Pay Ins </span>
                    <span class="value"> {{ formatAmount(shiftReport.collections?.mpesa_pay_ins) }} </span>
                </div>

                <div class="report-item d-flex justify-content-between align-items-center">
                    <span class="label"> Cash Credit Sale Payments </span>
                    <span class="value"> {{ formatAmount(shiftReport.collections?.cash_credit_sale_payments) }} </span>
                </div>

                <div class="report-item d-flex justify-content-between align-items-center">
                    <span class="label"> MPESA Credit Sale Payments </span>
                    <span class="value"> {{ formatAmount(shiftReport.collections?.mpesa_credit_sale_payments) }} </span>
                </div>

                <div class="report-item d-flex justify-content-between align-items-center">
                    <span class="label is-total"> Total Collections</span>
                    <span class="value is-total"> {{ formatAmount(shiftReport.collections?.total_collections) }} </span>
                </div>

                <h5 class="report-section-header"> EXPENSES </h5>

                <div class="report-item d-flex justify-content-between align-items-center">
                    <span class="label"> Cash Expenses </span>
                    <span class="value"> {{ formatAmount(shiftReport.expenses?.cash_expenses) }} </span>
                </div>

                <div class="report-item d-flex justify-content-between align-items-center">
                    <span class="label"> MPESA Expenses </span>
                    <span class="value"> {{ formatAmount(shiftReport.expenses?.mpesa_expenses) }} </span>
                </div>

                <div class="report-item d-flex justify-content-between align-items-center">
                    <span class="label is-total"> Total Expenses </span>
                    <span class="value is-total"> {{ formatAmount(shiftReport.expenses?.total_expenses) }} </span>
                </div>

                <h5 class="report-section-header"> ACCOUNTS RECEIVABLE </h5>

                <div class="report-item d-flex justify-content-between align-items-center">
                    <span class="label"> Unpaid Credit Sales </span>
                    <span class="value"> {{ formatAmount(shiftReport.accounts_receivable?.unpaid_credit_sales) }} </span>
                </div>
            </div>

<!--
FIXME: Display just shift totals. Alternatively, make this a setting in app setting.
<div id="report-total-section" class="w-100" v-if="shiftReport">
                <div class="w-100 d-flex justify-content-between align-items-center report-total-item">
                    <span class="label"> Closing Cash Balance </span>
                    <span class="value"> {{ formatAmount(shiftReport.balances?.cash_balance) }} </span>
                </div>

                <div class="w-100 d-flex justify-content-between align-items-center report-total-item">
                    <span class="label"> Closing Bank/MPESA Balance </span>
                    <span class="value"> {{ formatAmount(shiftReport.balances?.mpesa_balance) }} </span>
                </div>

                <div class="w-100 d-flex justify-content-between align-items-center report-total-item">
                    <span class="label"> Total Closing Balance </span>
                    <span class="value"> {{ formatAmount(shiftReport.balances?.total_balance) }} </span>
                </div>
            </div>-->
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
    name: "CurrentShift",

    data() {
        return {
            shiftReport: null,
            fetchingShiftReport: false,
            errorFetchingShiftReport: false,
            updateShiftModalIsActive: false,
            updatingShift: false,
            cashDrawerAmounts: {
                opening_cash: 0,
                opening_mpesa: 0
            }
        }
    },

    computed: {
        ...mapGetters('system', ['getBasicPayload']),
        ...mapState('system', ['currentUser']),
    },

    created() {
        this.fetchShiftReport()
    },

    methods: {
        fetchShiftReport() {
            this.errorFetchingShiftReport = false
            this.fetchingShiftReport = true
            axios.get('/api/reports/sales/end-shift-report', {params: this.getBasicPayload}).then(res => {
                this.fetchingShiftReport = false
                this.shiftReport = res.data.data
            }).catch(error => {
                this.fetchingShiftReport = false
                this.errorFetchingShiftReport = true

                this.$message.error('An error was encountered. Please try again.')
            })
        },
    },
}
</script>

<style scoped>

</style>
