<script>
import {defineComponent} from 'vue'
import {mapState} from "vuex";
import SvgSymbols from "../../components/shared/SvgSymbols.vue";
import Tip from "../../components/shared/Tip.vue";
import $ from 'jquery'

export default defineComponent({
    name: "MyBusinessView",
    components: {Tip, SvgSymbols},

    data() {
        return {
            baseUrl: '/api/business',
            gettingStores: false,
            stores: [],
            errorGettingStores: false,

            updateBusinessModalIsActive: false,
            updatingBusiness: false,
            newBusinessName: null,
            errorUpdatingBusiness: false,

            activeStore: null,
            updateStoreModalIsActive: false,
            updatingStore: false,
            newStoreName: null,
            errorUpdatingStore: false,
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
    },

    created() {
        this.fetchStores()
    },

    methods: {
        fetchStores() {
            let payload = {
                business_id: this.currentUser.business_id
            }

            this.gettingStores = true
            axios.get(`${this.baseUrl}/stores`, {params: payload}).then(res => {
                this.gettingStores = false
                this.errorGettingStores = false
                this.stores = res.data.data

                this.$store.commit('system/setStores', this.stores)
            }).catch(_ => {
                this.gettingStores = false
                this.errorGettingStores = true
            })
        },

        promptUpdateBusiness() {
            this.newBusinessName = this.currentUser.business_name
            this.errorUpdatingBusiness = false
            this.updateBusinessModalIsActive = true
        },

        updateBusiness(e) {
            e?.preventDefault()

            if (!this.newBusinessName) {
                return $("#new-business-name").addClass('is-invalid')
            } else {
                $("#new-business-name").removeClass('is-invalid')
            }

            let payload = {
                current_user: this.currentUser,
                business_id: this.currentUser.business_id,
                new_business_name: this.newBusinessName
            }

            this.updatingBusiness = true
            axios.post(`${this.baseUrl}/update`, {payload: JSON.stringify(payload)}).then(res => {
                this.updatingBusiness = false
                this.errorUpdatingBusiness = false

                this.$store.commit('system/updateBusiness', res.data.data?.business_name)
                this.$message.success('Business updated successfully')
                this.updateBusinessModalIsActive = false
            }).catch(_ => {
                this.updatingBusiness = false
                this.errorUpdatingBusiness = true
            })
        },

        handleStoreActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            if (commandName === 'update') {
                this.activeStore = JSON.parse(JSON.stringify(this.stores[commandIndex]))
                this.errorUpdatingStore = false
                this.newStoreName = this.activeStore.store_name
                this.updateStoreModalIsActive = true
            }
        },

        updateStore(e) {
            e?.preventDefault()

            if (!this.newStoreName) {
                return $("#new-store-name").addClass('is-invalid')
            } else {
                $("#new-store-name").removeClass('is-invalid')
            }

            let payload = {
                current_user_id: this.currentUser.id,
                store_id: this.activeStore.id,
                new_store_name: this.newStoreName
            }

            this.updatingStore = true
            axios.post(`${this.baseUrl}/stores/update`, {payload: JSON.stringify(payload)}).then(res => {
                this.updatingStore = false
                this.errorUpdatingStore = false

                this.fetchStores()
                if (this.currentUser.store_id === this.activeStore.id) {
                    this.$store.commit('system/updateStore', res.data.data?.store_name)
                }
                this.$message.success('Store updated successfully')
                this.updateStoreModalIsActive = false

                this.$store.dispatch('system/fetchStores')
            }).catch(_ => {
                this.updatingStore = false
                this.errorUpdatingStore = true
            })
        },
    },
})
</script>

<template>
<div class="master-template">
    <div class="master-title-div d-flex justify-content-between align-items-center">
        <h5> {{ currentUser?.business_name }} </h5>

        <div class="actions">
            <button class="btn btn-primary new-item-btn" @click="promptUpdateBusiness">
                <i class="fas fa-edit"></i>Update Business Details
            </button>
        </div>
    </div>

    <div class="master-content card shadow-sm bg-white">
        <h5 class="f-18 fw-700"> Business Stores/Outlets </h5>

        <div class="master-tip" v-if="errorGettingStores">
            <svg-symbols></svg-symbols>
            <tip type="danger">
                An error was encountered while fetching store outlets. Please reload the page, and if the issue persists, contact System
                Administrator.
            </tip>
        </div>

        <div class="data-table table-responsive" v-else>
            <table class="table">
                <thead>
                <tr>
                    <th scope="col"> Store Name </th>
                    <th scope="col"> Subscription Package </th>
                    <th scope="col"> Package Amount </th>
                    <th scope="col"> Status </th>
                    <th scope="col"> Next Payment Date </th>
                    <th scope="col"> Actions </th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(store, index) in stores" :key="store.id">
                    <td> {{ store.store_name }} </td>
                    <td> {{ store.subscription?.package_name }} </td>
                    <td> {{ formatAmount(store.subscription?.package_amount) }} </td>
                    <td>
                        <el-button plain :type="store.subscription?.status === 'active' ? 'success' : 'danger'">
                            {{ store.subscription?.status }}</el-button>
                    </td>
                    <td> {{ store.subscription?.next_payment_date }} </td>
                    <td> <el-dropdown @command="handleStoreActions">
                        <el-button type="primary">
                            Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item :command="'update-' + index">Update Store
                                    Details
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown> </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- Update Business Dialog -->
    <el-dialog
        v-model="updateBusinessModalIsActive"
        title="Update Business Details"
        width="25%"
    >

        <div class="master-tip" v-if="errorUpdatingBusiness">
            <svg-symbols></svg-symbols>
            <tip type="danger">
                An error was encountered while updating business details. Please try again, and if the issue persists, contact System
                Administrator.
            </tip>
        </div>

        <form @submit.prevent="updateBusiness"
              v-loading="updatingBusiness"
              element-loading-text="Updating"
              novalidate
        >

            <div class="form-group">
                <label for="new-business-name"> Business Name </label>
                <input type="text" class="form-control" id="new-business-name" v-model="newBusinessName">
                <div class="invalid-feedback"> Please enter a valid business name </div>
            </div>
        </form>

        <template #footer>
            <div class="dialog-footer d-flex justify-content-between">
                <el-button @click="updateBusinessModalIsActive = false" size="large">Cancel</el-button>
                <button class="btn btn-primary" @click="updateBusiness"> Save Changes </button>
            </div>
        </template>
    </el-dialog>

    <!-- Update Store Dialog -->
    <el-dialog
        v-model="updateStoreModalIsActive"
        title="Update Store Details"
        width="25%"
    >

        <div class="master-tip" v-if="errorUpdatingStore">
            <svg-symbols></svg-symbols>
            <tip type="danger">
                An error was encountered while updating store details. Please try again, and if the issue persists, contact System
                Administrator.
            </tip>
        </div>

        <form @submit.prevent="updateStore"
              v-loading="updatingStore"
              element-loading-text="Updating"
              novalidate
        >

            <div class="form-group">
                <label for="new-store-name"> Store Name </label>
                <input type="text" class="form-control" id="new-store-name" v-model="newStoreName">
                <div class="invalid-feedback"> Please enter a valid store name </div>
            </div>
        </form>

        <template #footer>
            <div class="dialog-footer d-flex justify-content-between">
                <el-button @click="updateStoreModalIsActive = false" size="large">Cancel</el-button>
                <button class="btn btn-primary" @click="updateStore"> Save Changes </button>
            </div>
        </template>
    </el-dialog>
</div>
</template>

<style scoped>

</style>
