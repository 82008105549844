<template>
    <div id="pos-main-page">
        <pos-top-bar></pos-top-bar>

        <div id="pos-side-bar" class="bg-white shadow-sm">
            <ul>
                <li id="pos" class="active" @click="switchActiveTab('pos')">
                    <i class="fas fa-desktop"></i>
                    <span> POS </span>
                </li>

                <li id="shift" @click="switchActiveTab('shift')">
                    <i class="fas fa-user-clock"></i>
                    <span> Current Shift </span>
                </li>

                <li id="sales" @click="switchActiveTab('sales')">
                    <i class="fas fa-cash-register"></i>
                    <span> Recent Sales </span>
                </li>
            </ul>
        </div>

        <div id="pos-page-content">
            <div v-if="activeTab === 'pos'" class="pos-tab">
                <pos></pos>
            </div>

            <div v-else-if="activeTab === 'shift'" class="pos-tab">
                <current-shift></current-shift>
            </div>

            <div v-else class="pos-tab">
                <recent-sales></recent-sales>
            </div>
        </div>

    </div>
</template>

<script>
import {mapState} from 'vuex'
import PosTopBar from "./PosTopBar.vue";
import Pos from "./POS.vue";
import CurrentShift from "./CurrentShift.vue";
import RecentSales from "./RecentSales.vue";
import $ from 'jquery'

export default {
    name: "PosMain",

    components: {
        PosTopBar,
        Pos,
        CurrentShift,
        RecentSales
    },

    data() {
        return {
            activeTab: 'pos'
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
    },

    methods: {
        switchActiveTab(nextTab) {
            this.resetAnyActiveTab()
            this.activeTab = nextTab
            $(`#${nextTab}`).addClass('active')
        },

        resetAnyActiveTab() {
            $("#pos").removeClass('active')
            $("#shift").removeClass('active')
            $("#sales").removeClass('active')
        },
    },
}
</script>

<style>
#pos-main-page {
    background-color: #EFF4FB;
}

#pos-side-bar {
    height: calc(100vh - 80px);
    padding: 20px;
    width: 130px;
    position: fixed;
    left: 0;
    top: 80px;
}

#pos-side-bar ul {
    padding: 0;
    margin: 0;
}

#pos-side-bar ul li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    cursor: pointer;
    text-align: center;
}

#pos-side-bar ul li.active {
    border-bottom: 2px solid #01358D;
}

#pos-side-bar ul li i {
    margin-bottom: 8px;
    font-size: 18px;
}

#pos-side-bar ul li span {
    font-size: 18px;
    font-weight: 700;
}

#pos-side-bar ul li.active i, #pos-side-bar ul li.active span {
    color: #01358D;
}

#pos-page-content {
    margin-top: 80px;
    margin-left: 130px;
    padding: 25px;
    position: relative;
    min-height: calc(100vh - 80px);
}

.pos-tab {

}
</style>
