<template>
    <div class="master-template">
        <div class="master-title d-flex justify-content-between">
            <h5> Activity Report </h5>

            <div class="actions">
                <a href="/reports/audit" class="btn btn-outline-primary new-item-btn">
                    <i class="fas fa-backward"></i>Back to Audit Reports
                </a>
            </div>
        </div>

        <div class="master-content">
            <div class="data-table-filters card shadow bg-white">
                <h5> Report Filters </h5>

                <div class="row">
                    <div class="form-group col-md-4 has-padding-right">
                        <label> User </label>
                        <el-select
                            v-model="filters.user_id"
                            default-first-option
                            filterable
                            allow-create
                            size="large"
                            clearable
                        >
                            <el-option
                                v-for="user in systemUsers"
                                :key="user.id"
                                :label="user.name"
                                :value="user.id"
                            />
                        </el-select>
                    </div>

                    <div class="form-group col-md-4 has-padding-left has-padding-right">
                        <label style="display: block;"> Start Date </label>
                        <el-date-picker size="large"
                                        v-model="filters.start_date"
                                        type="date"
                                        placeholder="Pick a day"
                                        format="MMM D, YYYY"
                                        value-format="YYYY-MM-DD" style="width: 100%;" clearable/>
                    </div>

                    <div class="form-group col-md-4 has-padding-left">
                        <label style="display: block;"> End Date </label>
                        <el-date-picker size="large"
                                        v-model="filters.end_date"
                                        type="date"
                                        placeholder="Pick a day"
                                        format="MMM D, YYYY"
                                        value-format="YYYY-MM-DD" style="width: 100%;" clearable/>
                    </div>
                </div>

                <div class="mt-3 d-flex">
                    <button class="btn btn-secondary mr-12" @click="getReport"> Apply Filters</button>
                    <el-button size="large" @click="resetFilters">Reset Filters</el-button>
                </div>
            </div>

            <div class="data-table-summary d-flex justify-content-between mt-4 px-1">
                <span class="summary"></span>
                <span class="summary"> Showing {{ report.length }} out of {{ totalItems }} records. </span>
            </div>

            <div class="data-table">
                <el-skeleton :loading="gettingReport" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" v-for="row in 7" :key="row" style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                    </template>

                    <template #default>
                        <el-table :data="report" style="width: 100%" empty-text="No records found.">
                            <el-table-column prop="timestamp" label="Timestamp"/>
                            <el-table-column prop="user_name" label="User"/>
                            <el-table-column prop="activity" label="Activity"/>
                        </el-table>
                    </template>
                </el-skeleton>
            </div>

            <!-- Pagination -->
            <div class="data-table-pagination d-flex justify-content-end mt-3">
                <el-pagination background layout="prev, pager, next" :total="totalItems"
                               :page-size="10" v-model:current-page="currentPage" @current-change="getReport" hide-on-single-page/>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {Close} from "@element-plus/icons-vue";

export default {
    name: "ActivityReport",

    data() {
        return {
            gettingReport: false,
            report: [],
            currentPage: 1,
            totalItems: 0,
            filters: {}
        }
    },

    computed: {
        ...mapState('users', ['systemUsers']),
        ...mapGetters('system', ['getBasicPayload']),

        Close() {
            return Close
        },
    },

    created() {
        this.getReport()

        if (!this.systemUsers || this.systemUsers.length === 0) {
            this.$store.dispatch('users/getUsers')
        }
    },

    methods: {
        getReport() {
            let payload = {
                ...this.getBasicPayload,
                ...{
                    page: (this.currentPage - 1),
                    start_date: this.filters.start_date,
                    end_date: this.filters.end_date
                }
            }
            payload.user_id = this.filters.user_id

            this.gettingReport = true
            axios.get('/api/reports/audit/activity-report', {params: payload}).then(res => {
                this.gettingReport = false
                this.report = res.data.data.content
                this.totalItems = res.data.data.count
            }).catch(error => {
                this.gettingReport = false
                this.$message.error('Error generating report. Please reload the page')
            })
        },

        resetFilters() {
            this.filters = {}
            this.currentPage = 1
            this.getReport()
        },
    },
}
</script>

<style scoped>

</style>
