<script>
import {defineComponent} from 'vue'
import {mapGetters, mapState} from "vuex";

export default defineComponent({
    name: "BusinessOverviewReport",

    data() {
        return {
            report: null,
            fetchingReport: false,
            errorFetchingReport: false,
            filters: {
                start_date: null,
                end_date: null,
                store_id: null
            }
        }
    },

    computed: {
        ...mapGetters('system', ['getBasicPayload', 'businessHasMultipleStores']),
        ...mapState('system', ['currentUser', 'stores']),
    },

    created() {
        this.fetchReport()

        if (this.stores.length === 0) {
            this.$store.dispatch('system/fetchStores')
        }
    },

    mounted() {
        this.filters.store_id = this.currentUser.store_id
    },

    methods: {
        fetchReport() {
            this.errorFetchingReport = false

            let payload = {
                start_date: this.filters.start_date,
                end_date: this.filters.end_date,
                store_id: this.filters.store_id ?? this.currentUser.store_id,
                business_type: this.currentUser.business_type
            }
            this.fetchingReport = true
            axios.get('/api/reports/business/business-overview-report', {params: payload}).then(res => {
                this.fetchingReport = false
                this.report = res.data.data
            }).catch(error => {
                this.fetchingReport = false
                this.errorFetchingReport = true

                this.$message.error('An error was encountered. Please try again.')
            })
        },

        resetReportFilters() {
            this.filters = {
                store_id: this.currentUser.store_id
            }
            this.fetchReport()
        },
    },
})
</script>

<template>
    <div class="master-template is-report-page" v-loading="fetchingReport" element-loading-text="Generating report">
        <div v-if="errorFetchingReport" class="d-flex flex-column w-100">
            <p> An error occurred while generating report. </p>
            <button class="btn btn-primary new-item-btn" @click="fetchReport"> Try Again</button>
        </div>

        <div class="report-content position-relative" v-else>
            <div class="w-100 d-flex justify-content-between align-items-center">
                <h5 class="fw-700 f-22 mb-0"> Business Overview Report </h5>

                <a href="/reports" class="btn btn-outline-primary new-item-btn">
                    <i class="fas fa-backward"></i>Back to Reports
                </a>
            </div>

            <div class="card report-filters shadow position-relative w-100 mt-4">
                <h5 class="f-18 fw-700"> Report filters </h5>

                <div class="row">
                    <div class="col-md-4 form-group">
                        <label> Start Date </label>
                        <el-date-picker
                            v-model="filters.start_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>

                    <div class="col-md-4 form-group">
                        <label> End Date </label>
                        <el-date-picker
                            v-model="filters.end_date"
                            type="date"
                            placeholder="Pick a day"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD"
                            size="large"
                        >
                        </el-date-picker>
                    </div>

                    <div class="col-md-4 form-group" v-if="businessHasMultipleStores && can('manage_multiple_stores', currentUser)">
                        <label> Store </label>
                        <el-select v-model="filters.store_id" filterable size="large" placeholder="Select store">
                            <el-option v-for="store in stores" :key="store.id" :label="store.store_name"
                                       :value="store.id"></el-option>
                        </el-select>
                    </div>
                </div>

                <div class="mt-3 d-flex">
                    <button class="btn btn-secondary mr-12" @click="fetchReport"> Filter Report</button>
                    <el-button size="large" type="info" plain @click="resetReportFilters">Reset Filters</el-button>
                </div>
            </div>

            <div class="card shadow position-relative w-100 mt-4">
                <div id="report-header" class="d-flex flex-column w-100">
                    <div id="business-name" class="d-flex w-100 text-center justify-content-center align-items-center">
                        {{ currentUser.business_name }}
                    </div>
                    <div id="report-title" class="d-flex w-100 text-center justify-content-center align-items-center">
                        Business Overview Report
                    </div>

                    <div id="report-period" class="d-flex w-100 text-center justify-content-center align-items-center" v-if="report">
                        {{ report.period?.start }} to {{ report.period?.end }}
                    </div>
                </div>

                <div id="report-details-header" class="w-100 d-flex justify-content-end">
                    <span> TOTALS </span>
                </div>

                <div id="report-details" class="w-100" v-if="report">
                    <h5 class="report-section-header is-first-child"> SALES SUMMARY </h5>

                    <div class="report-item d-flex justify-content-between align-items-center">
                        <span class="label"> Total Sales Posted </span>
                        <span class="value"> {{ formatAmount(report.sales_summary?.total_sales) }} </span>
                    </div>

                    <div class="report-item d-flex justify-content-between align-items-center">
                        <span class="label"> Credit Sales </span>
                        <span class="value"> {{ formatAmount(report.sales_summary?.credit_sales) }} </span>
                    </div>

                    <h5 class="report-section-header"> COLLECTIONS </h5>

                    <div class="report-item d-flex justify-content-between align-items-center">
                        <span class="label"> Collections from sales </span>
                        <span class="value"> {{ formatAmount(report.collections?.sales) }} </span>
                    </div>

                    <div class="report-item d-flex justify-content-between align-items-center">
                        <span class="label"> Pay Ins </span>
                        <span class="value"> {{ formatAmount(report.collections?.pay_ins) }} </span>
                    </div>

                    <div class="report-item d-flex justify-content-between align-items-center">
                        <span class="label"> Credit Sale Payments </span>
                        <span class="value"> {{ formatAmount(report.collections?.credit_sale_payments) }} </span>
                    </div>

                    <div class="report-item d-flex justify-content-between align-items-center">
                        <span class="label is-total"> Total Collections</span>
                        <span class="value is-total"> {{ formatAmount(report.collections?.total_collections) }} </span>
                    </div>

                    <h5 class="report-section-header"> EXPENSES </h5>

                    <div class="report-item d-flex justify-content-between align-items-center">
                        <span class="label"> Operating Expenses </span>
                        <span class="value"> {{ formatAmount(report.expenses?.operational_expenses) }} </span>
                    </div>

                    <div class="report-item d-flex justify-content-between align-items-center">
                        <span class="label"> Staff Commission Paid </span>
                        <span class="value"> {{ formatAmount(report.expenses?.commission_paid) }} </span>
                    </div>

                    <div class="report-item d-flex justify-content-between align-items-center">
                        <span class="label is-total"> Total Expenses </span>
                        <span class="value is-total"> {{ formatAmount(report.expenses?.total_expenses) }} </span>
                    </div>

                    <h5 class="report-section-header"> INVESTMENTS </h5>

                    <div class="report-item d-flex justify-content-between align-items-center">
                        <span class="label"> Capital Injections </span>
                        <span class="value"> {{ formatAmount(report.investments?.capital_injections) }} </span>
                    </div>

                    <h5 class="report-section-header"> ACCOUNTS RECEIVABLE </h5>

                    <div class="report-item d-flex justify-content-between align-items-center">
                        <span class="label"> Unpaid Credit Sales </span>
                        <span class="value"> {{ formatAmount(report.accounts_receivable?.unpaid_credit_sales) }} </span>
                    </div>

                    <h5 class="report-section-header"> ACCOUNTS PAYABLE </h5>

                    <div class="report-item d-flex justify-content-between align-items-center">
                        <span class="label"> Staff Commission Owed </span>
                        <span class="value"> {{ formatAmount(report.accounts_payable?.commission_owed) }} </span>
                    </div>
                </div>

                <div id="report-total-section" class="w-100" v-if="report">
                    <div class="w-100 d-flex justify-content-between align-items-center report-total-item">
                        <span class="label"> Gross Revenue From Item Sales </span>
                        <span class="value"> {{ formatAmount(report.revenue?.item_profit) }} </span>
                    </div>

<!--                    <div class="w-100 d-flex justify-content-between align-items-center report-total-item">-->
<!--                        <span class="label"> Gross revenue (without unpaid credit sales) </span>-->
<!--                        <span class="value"> {{ formatAmount(report.revenue?.gross_revenue) }} </span>-->
<!--                    </div>-->

                    <div class="w-100 d-flex justify-content-between align-items-center report-total-item">
                        <span class="label"> Net Profit </span>
                        <span class="value"> {{ formatAmount(report.revenue?.net_profit) }} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
