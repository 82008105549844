export const usersStore = {
    namespaced: true,
    state: () => ({
        baseUrl: '/api/users',
        systemUsers: [],
        roles: [],
    }),

    getters: {},

    mutations: {
        setSystemUsers(state, users) {
            state.systemUsers = users
        },

        addNewUser(state, user) {
            state.systemUsers.push(user)
        },

        updateSystemUser(state, payload) {
            state.systemUsers[payload.index] = payload.user
        },

        removeUser(state, index) {
            state.systemUsers.splice(index, 1)
        },

        setSystemRoles(state, roles) {
            state.roles = roles
        },

        addNewRole(state, role) {
            state.roles.push(role)
        },

        updateRole(state, payload) {
            state.roles[payload.index] = payload.role
        },

        removeRole(state, index) {
            state.roles.splice(index, 1)
        },
    },

    actions: {
        resetAll({commit}) {
            commit('setSystemUsers', [])
            commit('setSystemRoles', [])
        },

        getUsers({commit, state, rootState}) {
            axios.get(`${state.baseUrl}/users`, {params: {store_id: rootState.system.currentUser.store_id}}).then(response => {
                commit('setSystemUsers', response.data.data)
            }).catch(error => {

            })
        }
    },
}
