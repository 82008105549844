<template>
    <div id="access-denied" class="card shadow-sm rounded w-100 d-flex justify-content-center align-items-center flex-column text-center">
        <img src="/assets/images/system/access_denied.png" alt="Access Denied">
        <h5 class="mt-3"> We are Sorry </h5>
        <p class="mt-2">
            You do not have the necessary permissions to access this page.
            <br>
            Please refer to your System Administrator.
        </p>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "AccessDenied",

    props: ['user'],

    computed: {
        ...mapState('system', ['currentUser']),
    },

    methods: {
        goToLogout() {},
    },
}
</script>

<style scoped>
#access-denied {
    min-height: 85vh;
}

#access-denied img {
    height: 50vh;
    position: relative;
}

#access-denied h5 {
    font-weight: 900;
    font-size: 25px;
    color: rgba(0, 0, 0, .7);
}

#access-denied p {}
</style>
