<script>
import {defineComponent} from 'vue'
import {mapGetters, mapState} from "vuex";
import $ from "jquery";

export default defineComponent({
    name: "CashFlowAccountsView",

    data() {
        return {
            baseUrl: '/api/transactions/cash-flow-accounts',
            gettingAccounts: false,
            accounts: [],
            activeAccount: null,
            selectedStoreId: null,

            updateAccountBalanceModalIsActive: false,
            updatingAccountBalance: false,
            newBalance: 0,

            depositingIntoAccount: false,
            depositModalIsActive: false,
            depositAmount: 0,

            withdrawDialogIsActive: false,
            withdrawing: false,
            withdrawalAmount: 0,

            transferring: false,
            transferModalIsActive: false,
            transferDetails: {
                source_account_id: null,
                target_account_id: null,
                transfer_amount: 0
            },
        }
    },

    computed: {
        ...mapState('system', ['currentUser', 'stores']),
        ...mapGetters('system', ['getBasicPayload', 'businessHasMultipleStores'])
    },

    created() {
        this.getAccounts()

        if (this.stores.length === 0) {
            this.$store.dispatch('system/fetchStores')
        }
    },

    mounted() {
        this.selectedStoreId = this.currentUser.store_id
    },

    methods: {
        getAccounts() {
            let payload = {
                store_id: this.selectedStoreId ?? this.currentUser.store_id
            }

            this.gettingAccounts = true
            axios.get(this.baseUrl, {params: payload}).then(res => {
                this.gettingAccounts = false
                this.accounts = res.data.data
            }).catch(err => {
                this.gettingAccounts = false
                this.$message.error("Error getting accounts. Please try again")
            })
        },

        handleAccountActions(command) {
            let commandName = command.split('-')[0]
            let commandIndex = command.split('-')[1]

            if (commandName === 'update') {
                this.activeAccount = JSON.parse(JSON.stringify(this.accounts[commandIndex]))
                this.newBalance = this.activeAccount.balance
                this.updateAccountBalanceModalIsActive = true
            }

            if (commandName === 'deposit') {
                this.activeAccount = this.accounts[commandIndex]
                this.depositAmount = 0
                this.depositModalIsActive = true
            }

            if (commandName === 'withdraw') {
                this.activeAccount = this.accounts[commandIndex]
                this.withdrawalAmount = 0
                this.withdrawDialogIsActive = true
            }

            if (commandName === 'transfer') {
                this.activeAccount = this.accounts[commandIndex]
                this.transferDetails.source_account_id = this.activeAccount.id
                this.transferDetails.transfer_amount = 0
                this.transferModalIsActive = true
            }
        },

        updateAccountBalance(e) {
            e.preventDefault()

            if (!this.numberIsValid(this.newBalance) || !this.numberIsNotNegative(this.newBalance)) {
                return $('#new-balance').addClass('is-invalid')
            } else {
                $('#new-balance').removeClass('is-invalid')
            }

            let payload = {
                account_id: this.activeAccount.id,
                user_id: this.getBasicPayload.user_id,
                new_balance: this.newBalance
            }

            this.updatingAccountBalance = true
            axios.post(`${this.baseUrl}/update`, payload).then(res => {
                this.updatingAccountBalance = false
                this.getAccounts()
                this.updateAccountBalanceModalIsActive = false
                this.$message.success("Account updated successfully")
            }).catch(err => {
                this.updatingAccountBalance = false
                this.$message.error("Error updating account. Please try again.")
            })
        },

        depositIntoAccount(e) {
            e.preventDefault()

            let depositAmountInputField = $("#account-deposit-amount")
            depositAmountInputField.removeClass('is-invalid')
            if (!this.depositAmount || parseFloat(this.depositAmount) < 0) {
                depositAmountInputField.addClass('is-invalid')
                return this.$message.error("Please provide a valid amount")
            }

            let payload = {
                deposit_amount: this.depositAmount,
                account_id: this.activeAccount.id,
                current_user_id: this.currentUser.id
            }

            this.depositingIntoAccount = true
            axios.post(`${this.baseUrl}/deposit`, payload).then(_ => {
                this.depositingIntoAccount = false
                this.depositModalIsActive = false
                this.getAccounts()
                this.$message.success("Deposit made successfully")
            }).catch(_ => {
                this.depositingIntoAccount = false
                this.$message.error("An error was encountered. Please try again.")
            })
        },

        withdrawFromAccount(e) {
            e.preventDefault()

            let withdrawalAmountInputField = $("#account-withdrawal-amount")
            withdrawalAmountInputField.removeClass('is-invalid')
            if (!this.withdrawalAmount || parseFloat(this.withdrawalAmount) < 0) {
                withdrawalAmountInputField.addClass('is-invalid')
                return this.$message.error("Please provide a valid amount")
            }

            let withdrawalAmountIsMoreThanAccountBalance = parseFloat(this.activeAccount.balance) < parseFloat(this.withdrawalAmount)
            if (withdrawalAmountIsMoreThanAccountBalance) {
                withdrawalAmountInputField.addClass('is-invalid')
                return this.$message.error("Withdrawal amount is more than current account balance")
            }

            let payload = {
                withdrawal_amount: this.withdrawalAmount,
                account_id: this.activeAccount.id,
                current_user_id: this.currentUser.id
            }

            this.withdrawing = true
            axios.post(`${this.baseUrl}/withdraw`, payload).then(_ => {
                this.withdrawing = false
                this.withdrawDialogIsActive = false
                this.getAccounts()
                this.$message.success("Withdrawal made successfully")
            }).catch(_ => {
                this.withdrawing = false
                this.$message.error("An error was encountered. Please try again.")
            })
        },

        transfer(e) {
            e.preventDefault()

            if (!this.transferDetails.target_account_id) {
                return this.$message.error("Please select a target account")
            }

            let transferAmountInputField = $("#account-transfer-amount")
            transferAmountInputField.removeClass('is-invalid')
            if (!this.transferDetails.transfer_amount || parseFloat(this.transferDetails.transfer_amount) < 0) {
                transferAmountInputField.addClass('is-invalid')
                return this.$message.error("Please provide a valid amount")
            }

            let transferAmountIsMoreThanSourceAccountBalance = parseFloat(this.activeAccount.balance) < parseFloat(this.transferDetails.transfer_amount)
            if (transferAmountIsMoreThanSourceAccountBalance) {
                transferAmountInputField.addClass('is-invalid')
                return this.$message.error("Transfer amount is more than source account balance")
            }

            let payload = {
                ...this.transferDetails, ...{
                    store_id: this.currentUser.store_id,
                    current_user_id: this.currentUser.id
                }
            }

            this.transferring = true
            axios.post(`${this.baseUrl}/transfer`, payload).then(_ => {
                this.transferring = false
                this.transferModalIsActive = false
                this.getAccounts()
                this.$message.success("Account transfer successfull")
            }).catch(_ => {
                this.transferring = false
                this.$message.error("An error was encountered. Please try again.")
            })
        },

        accountTransferIsDisabled(account) {
            return parseFloat(account.balance) <= 0
        }

    }
})
</script>

<template>
    <div class="master-template">
        <div class="master-title d-flex justify-content-between align-items-center">
            <h5> Cash Flow Accounts </h5>
            <div class="actions d-flex">
                <el-select v-model="selectedStoreId" filterable size="large"
                           placeholder="Select store" v-if="businessHasMultipleStores && can('manage_multiple_stores', currentUser)"
                           @change="getAccounts">
                    <el-option v-for="store in stores" :key="store.id" :label="store.store_name"
                               :value="store.id"></el-option>
                </el-select>
            </div>
        </div>

        <div class="master-content">
            <div class="data-table">
                <el-skeleton :loading="gettingAccounts" :throttle="200" animated>
                    <template #template>
                        <el-skeleton-item variant="h3" v-for="row in 7" :key="row" style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                    </template>

                    <template #default>
                        <el-table :data="accounts" style="width: 100%">
                            <el-table-column label="Account Name" prop="display_account_name"></el-table-column>
                            <el-table-column label="Account Description" prop="description"></el-table-column>
                            <el-table-column label="Account Balance" prop="formatted_balance"></el-table-column>
                            <el-table-column label="Actions">
                                <template #default="scope">
                                    <el-dropdown @command="handleAccountActions">
                                        <el-button type="primary">
                                            Actions <i class="fas fa-caret-down ml-2" style="margin-left: 10px;"></i>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item :command="'update-' + scope.$index">Update Account Balance</el-dropdown-item>
                                                <el-dropdown-item :command="'deposit-' + scope.$index">Deposit Into Account</el-dropdown-item>
                                                <el-dropdown-item :command="'withdraw-' + scope.$index"
                                                                  :disabled="accountTransferIsDisabled(scope.row)">Withdraw From Account
                                                </el-dropdown-item>
                                                <el-dropdown-item :command="'transfer-' + scope.$index"
                                                                  :disabled="accountTransferIsDisabled(scope.row)">Transfer to Another Account
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-skeleton>
            </div>
        </div>

        <!-- Update Account Balance -->
        <el-dialog v-model="updateAccountBalanceModalIsActive" width="30%" title="Update Account Balance"
                   :close-on-click-modal="false" :close-on-press-escape="false">

            <form method="post" @submit.prevent="updateAccountBalance" novalidate v-loading="updatingAccountBalance">
                <div class="form-group">
                    <label for="new-balance"> New balance </label>
                    <input type="text" class="form-control" v-model="newBalance" id="new-balance">
                    <div class="invalid-feedback"> Please provide a valid amount</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="updateAccountBalanceModalIsActive = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="updateAccountBalance"> Update</button>
                </div>
            </template>
        </el-dialog>

        <!-- Account Deposit -->
        <el-dialog
            v-model="depositModalIsActive"
            :title="`Deposit into ${activeAccount.display_account_name}`"
            v-if="activeAccount"
            width="30%"
        >

            <form @submit.prevent="depositIntoAccount" novalidate v-loading="depositingIntoAccount">
                <div class="form-group">
                    <label for="deposit-amount"> Deposit Amount </label>
                    <input type="number" class="form-control" v-model="depositAmount" placeholder="Deposit amount"
                           id="account-deposit-amount">
                    <div class="invalid-feedback"> Please provide a valid amount</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="depositModalIsActive = false" size="large" type="info"> Cancel</el-button>
                    <button class="btn btn-primary f-18 fw-700" @click="depositIntoAccount"> Confirm Deposit</button>
                </div>
            </template>
        </el-dialog>

        <!-- Account Withdrawal -->
        <el-dialog
            v-model="withdrawDialogIsActive"
            :title="`Withdraw from ${activeAccount.display_account_name}`"
            v-if="activeAccount"
            width="30%"
        >

            <form @submit.prevent="withdrawFromAccount" novalidate v-loading="withdrawing">
                <div class="form-group">
                    <label for="account-withdrawal-amount"> Amount to withdraw </label>
                    <input type="number" class="form-control" v-model="withdrawalAmount" placeholder="Amount to withdraw"
                           id="account-withdrawal-amount">
                    <div class="invalid-feedback"> Please provide a valid amount</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="withdrawDialogIsActive = false" size="large" type="info"> Cancel</el-button>
                    <button class="btn btn-primary f-18 fw-700" @click="withdrawFromAccount"> Confirm Withdrawal</button>
                </div>
            </template>
        </el-dialog>

        <!-- Account Transfer -->
        <el-dialog
            v-model="transferModalIsActive"
            :title="`Transfer from ${activeAccount.display_account_name}`"
            v-if="activeAccount"
            width="30%"
        >

            <form @submit.prevent="transfer" novalidate v-loading="transferring">
                <div class="form-group">
                    <label> Target Account </label>
                    <el-select v-model="transferDetails.target_account_id" size="large" filterable>
                        <el-option
                            v-for="account in accounts"
                            :key="account.id"
                            :label="account.display_account_name"
                            :value="account.id"
                            :disabled="account.id === transferDetails.source_account_id"></el-option>
                    </el-select>
                </div>

                <div class="form-group">
                    <label for="account-transfer-amount"> Transfer Amount </label>
                    <input type="number" class="form-control" v-model="transferDetails.transfer_amount" placeholder="Transfer amount"
                           id="account-transfer-amount">
                    <div class="invalid-feedback"> Please provide a valid amount</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="transferModalIsActive = false" size="large" type="info"> Cancel</el-button>
                    <button class="btn btn-primary f-18 fw-700" @click="transfer"> Confirm Transfer</button>
                </div>
            </template>
        </el-dialog>

    </div>
</template>

<style scoped>

</style>
