<template>
    <div id="product-sub-categories">
        <div class="master-subtitle-div d-flex justify-content-between align-items-center">
            <h5> Product Sub Categories </h5>

            <div>
                <button class="btn btn-sm btn-outline-primary new-item-btn" @click="refreshList">
                    <i class="fas fa-sync"></i>Refresh List
                </button>

                <button class="btn btn-primary btn-sm new-item-btn" @click="promptAddNewSubCategory">
                    <i class="fas fa-plus-circle"></i>Add New Sub Category
                </button>
            </div>
        </div>

        <div class="data-table mt-3">
            <div class="data-table-filters card bg-white">
                <div class="d-flex align-items-center">
                    <h5> Sub Category Filters </h5>
                    <el-button type="danger" :icon="Close" plain @click="clearFilters"
                               style="margin-left: 12px; margin-top: -9px;" size="small">Clear Filters
                    </el-button>
                </div>

                <div class="row mt-3">
                    <div class="form-group col-md-5">
                        <input type="text" class="form-control" v-model="filters.name"
                               placeholder="Filter by sub category name" @input="filterSubCategories">
                    </div>

                    <div class="form-group col-md-5 has-padding-left">
                        <el-select v-model="filters.category_id" filterable @change="filterSubCategories" size="large"
                                   placeholder="Filter by category">
                            <el-option v-for="category in productCategories" :key="category.id" :label="category.name"
                                       :value="category.id"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>

            <div class="data-table-summary d-flex justify-content-end mt-4">
                <span>
                    Showing {{ displayableSubCategories.length }} out of {{ displayableSubCategories.length }} sub categories
                </span>
            </div>

            <el-skeleton :loading="gettingProductSubCategories" :throttle="200" animated>
                <template #template>
                    <el-skeleton-item variant="h3" v-for="row in 7" :key="row"
                                      style="width: 100%; height: 25px; margin-bottom: 20px;"/>
                </template>

                <template #default>
                    <el-table :data="displayableSubCategories" style="width: 100%"
                              :empty-text="'No sub categories found. ' + emptyText">
                        <el-table-column prop="name" label="Sub Category Name"/>
                        <el-table-column prop="category_name" label="Category"/>
                        <el-table-column prop="created_at" label="Date Created"/>
                        <el-table-column label="Actions">
                            <template #default="scope">
                                <el-button size="small" type="primary" :icon="Edit"
                                           @click="promptUpdateSubCategory(scope.$index, scope.row)"
                                >Update
                                </el-button
                                >
                                <el-button
                                    size="small"
                                    type="danger"
                                    :icon="Delete"
                                    @click="promptRemoveSubCategory(scope.$index, scope.row)">
                                    Remove
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </el-skeleton>
        </div>

        <!-- START OF ADD NEW SubCategory MODAL -->
        <el-dialog
            v-model="showAddSubCategoryDialog"
            title="Add Sub Category"
            width="30%"
        >
            <form @submit.prevent="addNewSubCategory"
                  v-loading="addingNewSubCategory"
                  element-loading-text="Adding Sub Category"
                  id="new-subcategory-form"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="subcategory-name"> Sub Category Name </label>
                    <input type="text" id="subcategory-name" class="form-control" v-model="newSubCategory.name"
                           placeholder="Sub category name">
                    <div class="invalid-feedback"> {{ subCategoryNameErrorMessage }}</div>
                </div>

                <div class="form-group">
                    <label class="required"> Category </label>
                    <el-select v-model="newSubCategory.category_id" filterable size="large">
                        <el-option v-for="category in productCategories" :key="category.id" :label="category.name"
                                   :value="category.id"></el-option>
                    </el-select>
                    <div class="invalid-feedback" id="subcategory-category-id"> Please select a category</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showAddSubCategoryDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="addNewSubCategory"> Add Sub Category</button>
                </div>
            </template>
        </el-dialog>
        <!-- END OF ADD NEW CATEGORY MODAL -->

        <!-- START OF UPDATE SubCategory MODAL -->
        <el-dialog
            v-model="showUpdateSubCategoryDialog"
            title="Update Sub Category Details"
            width="30%"
        >
            <form @submit.prevent="updateSubCategory"
                  v-loading="updatingNewSubCategory"
                  element-loading-text="Updating Sub Category"
                  id="update-subcategory-form"
                  novalidate
            >
                <div class="form-group">
                    <label class="required" for="update-subcategory-name"> Sub Category Name </label>
                    <input type="text" id="update-subcategory-name" class="form-control"
                           v-model="activeSubCategory.name"
                           placeholder="Sub category name">
                    <div class="invalid-feedback"> SubCategory name is required</div>
                </div>

                <div class="form-group">
                    <label class="required"> Category </label>
                    <el-select v-model="activeSubCategory.category_id" filterable size="large">
                        <el-option v-for="category in productCategories" :key="category.id" :label="category.name"
                                   :value="category.id"></el-option>
                    </el-select>
                    <div class="invalid-feedback" id="update-subcategory-category-id"> Please select a category</div>
                </div>
            </form>

            <template #footer>
                <div class="dialog-footer d-flex justify-content-between">
                    <el-button @click="showUpdateSubCategoryDialog = false" size="large">Cancel</el-button>
                    <button class="btn btn-primary" @click="updateSubCategory"> Save Changes</button>
                </div>
            </template>
        </el-dialog>
        <!-- END OF ADD UPDATE CATEGORY MODAL -->

    </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import $ from 'jquery'

import {Delete, Edit, Close} from '@element-plus/icons-vue'

export default {
    name: "ProductSubCategories",

    data() {
        return {
            filters: {},
            displayableSubCategories: [],
            newSubCategory: {},
            showAddSubCategoryDialog: false,
            addingNewSubCategory: false,
            activeSubCategory: {},
            showUpdateSubCategoryDialog: false,
            updatingNewSubCategory: false,
            subCategoryNameErrorMessage: 'Sub category name is required'
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
        ...mapState('stock', ['productCategories', 'productSubCategories', 'gettingProductSubCategories']),

        ...mapGetters('stock', ['getClassificationBaseUrl']),

        Edit() {
            return Edit
        },

        Delete() {
            return Delete
        },

        Close() {
            return Close
        },

        emptyText() {
            return (this.filters.name || this.filters.category_id) ?
                'Try a different filter.' : 'Click on the Add New Sub category button to add a new subcategory.'
        },

        getSubCategoriesBaseUrl() {
            return `${this.getClassificationBaseUrl}/subcategories`
        },
    },

    created() {
        // Only fetch if store is empty or null
        if (this.empty(this.productSubCategories)) {
            this.$store.dispatch('stock/getProductSubCategories')
            this.displayableSubCategories = this.productSubCategories
        } else {
            this.displayableSubCategories = this.productSubCategories
        }
    },

    methods: {
        getSubCategoryIndex(queryId) {
            return this.productSubCategories.findIndex(subcategory => subcategory.id === queryId)
        },

        promptAddNewSubCategory() {
            this.newSubCategory = {}
            this.showAddSubCategoryDialog = true
        },

        addNewSubCategory(e) {
            e.preventDefault()

            // Validation
            let dataIsValid = true
            this.subCategoryNameErrorMessage = 'Sub category name is required'
            if (!this.newSubCategory.name || this.newSubCategory.name === '') {
                dataIsValid = false
                $("#subcategory-name").addClass('is-invalid')
            } else {
                $("#subcategory-name").removeClass('is-invalid')
            }

            if (!this.newSubCategory.category_id) {
                dataIsValid = false
                $("#subcategory-category-id").css('display', 'block')
            } else {
                $("#subcategory-category-id").css('display', 'none')
            }

            if (dataIsValid) {
                let payload = {
                    user_id: this.currentUser.id,
                    name: this.newSubCategory.name,
                    category_id: this.newSubCategory.category_id
                }

                this.addingNewSubCategory = true
                axios.post(`${this.getSubCategoriesBaseUrl}/add`, payload).then(response => {
                    this.$store.commit('stock/addSubCategory', response.data.data)
                    this.displayableSubCategories = this.productSubCategories

                    this.addingNewSubCategory = false
                    this.showAddSubCategoryDialog = false
                    this.$message.success('Sub category added successfully')
                }).catch(error => {
                    this.addingNewSubCategory = false

                    if (error.response && error.response.status === 422) {
                        this.subcategoryNameErrorMessage = 'A sub category with this name already exists'
                        $("#subcategory-name").addClass('is-invalid')
                        this.$message.error(this.subcategoryNameErrorMessage)
                    } else {
                        this.$message.error('An error was encountered. Please try again')
                    }
                })
            } else {
                this.$message.warning('Please fill all fields')
            }
        },

        promptUpdateSubCategory(index, subcategory) {
            this.activeSubCategory = subcategory
            this.showUpdateSubCategoryDialog = true
        },

        updateSubCategory(e) {
            e.preventDefault()

            // Validation
            let dataIsValid = true
            if (!this.activeSubCategory.name || this.activeSubCategory.name === '') {
                dataIsValid = false
                $("#update-subcategory-name").addClass('is-invalid')
            } else {
                $("#update-subcategory-name").removeClass('is-invalid')
            }

            if (!this.activeSubCategory.category_id) {
                dataIsValid = false
                $("#update-subcategory-category-id").css('display', 'block')
            } else {
                $("#update-subcategory-category-id").css('display', 'none')
            }

            if (dataIsValid) {
                let payload = {
                    id: this.activeSubCategory.id,
                    user_id: this.currentUser.id,
                    name: this.activeSubCategory.name,
                    category_id: this.activeSubCategory.category_id,
                }

                this.updatingNewSubCategory = true
                axios.post(`${this.getSubCategoriesBaseUrl}/update`, payload).then(response => {
                    this.updatingNewSubCategory = false

                    let existingSubCategoryIndex = this.getSubCategoryIndex(response.data.data.id)
                    if (existingSubCategoryIndex !== -1) {
                        this.$store.commit('stock/updateSubCategory', {
                            index: existingSubCategoryIndex,
                            subcategory: response.data.data
                        })
                        this.displayableSubCategories = this.productSubCategories
                    }

                    this.showUpdateSubCategoryDialog = false
                    this.$message.success('Sub category updated successfully')
                }).catch(error => {
                    this.updatingNewSubCategory = false
                    this.$message.error('An error was encountered. Please try again')
                })
            } else {
                this.$message.warning('Please fill all fields')
            }
        },

        promptRemoveSubCategory(index, subcategory) {
            this.$confirm(
                'This action is permanent and will remove all products under this sub category. Continue?',
                'Remove Sub Category',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            )
                .then(() => {
                    axios.post(`${this.getSubCategoriesBaseUrl}/remove`, {
                        id: subcategory.id,
                        user_id: this.currentUser.id
                    })
                        .then(response => {
                            let existingSubCategoryIndex = this.getSubCategoryIndex(subcategory.id)
                            if (existingSubCategoryIndex !== -1) {
                                this.$store.commit('stock/removeSubCategory', existingSubCategoryIndex)
                                this.displayableSubCategories = this.productSubCategories
                            }

                            this.$message.success('Sub category removed successfully')
                        }).catch(error => {
                        this.$message.error('An error was encountered. Please try again')
                    })
                })
                .catch(() => {
                    //
                })
        },

        filterSubCategories() {
            this.displayableSubCategories = this.productSubCategories
            if (this.filters.name) {
                this.displayableSubCategories = this.displayableSubCategories
                    .filter(subCategory => (subCategory.name.toLowerCase()).includes(this.filters.name.toLowerCase()))
            }

            if (this.filters.category_id) {
                this.displayableSubCategories = this.displayableSubCategories
                    .filter(subCategory => subCategory.category_id === this.filters.category_id)
            }
        },

        clearFilters() {
            this.filters = {}
            this.displayableSubCategories = this.productSubCategories
        },

        refreshList() {
            this.$store.dispatch('stock/getProductSubCategories')
            this.displayableSubCategories = this.productSubCategories
        }
    },
}
</script>

<style scoped>

</style>
