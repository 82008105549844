<template>
    <div class="master-template">
        <div class="master-title d-flex justify-content-between">
            <h5> Opening and Closing Stocks Report </h5>

            <div class="actions">
                <a href="/reports" class="btn btn-outline-primary new-item-btn">
                    <i class="fas fa-backward"></i>Back to Reports
                </a>
            </div>
        </div>

        <div class="master-content">
            <div class="data-table-filters card shadow bg-white">
                <h5> Report Filters </h5>

                <div class="row">
                    <div class="form-group col-md-4 has-padding-right">
                        <label> Filter by item </label>
                        <el-select
                            v-model="filters.stock_item_id"
                            default-first-option
                            filterable
                            allow-create
                            size="large"
                            clearable
                        >
                            <el-option
                                v-for="user in stockLevels"
                                :key="user.id"
                                :label="user.label"
                                :value="user.id"
                            />
                        </el-select>
                    </div>

                    <div class="form-group col-md-4 has-padding-left has-padding-right">
                        <label style="display: block;"> Start Date </label>
                        <el-date-picker size="large"
                                        v-model="filters.start_date"
                                        type="date"
                                        placeholder="Pick a day"
                                        format="MMM D, YYYY"
                                        value-format="YYYY-MM-DD" style="width: 100%;" clearable/>
                    </div>

                    <div class="form-group col-md-4 has-padding-left">
                        <label style="display: block;"> End Date </label>
                        <el-date-picker size="large"
                                        v-model="filters.end_date"
                                        type="date"
                                        placeholder="Pick a day"
                                        format="MMM D, YYYY"
                                        value-format="YYYY-MM-DD" style="width: 100%;" clearable/>
                    </div>
                </div>

                <div class="mt-3 d-flex">
                    <button class="btn btn-secondary mr-12" @click="getReport"> Apply Filters</button>
                    <el-button size="large" @click="clearFilters">Reset Filters</el-button>
                </div>
            </div>

            <div class="data-table-summary d-flex justify-content-between mt-4 px-1">
                <span class="summary"></span>
                <span class="summary"> Showing {{ report.length }} out of {{ itemCount }} items. </span>
            </div>

            <div class="data-table is-report mt-3">
                <el-table :data="report" style="width: 100%; margin-top: 20px;" v-loading="gettingReport" element-loading-text="Generating report">
                    <el-table-column prop="product" label="Item"/>
                    <el-table-column prop="opening_stock" label="Opening stock"/>
                    <el-table-column label="Added Stock" v-if="!empty(additionTransactionKeys)">
                        <el-table-column :label="key" v-for="(key, index) in additionTransactionKeys" :key="index">
                            <template #default="scope">
                                <span v-if="scope.row.addition_transactions.hasOwnProperty(key)">{{
                                        scope.row.addition_transactions[key]
                                    }}&nbsp;{{ `${scope.row.units}s` }}</span>
                                <span v-else>{{ `0 ${scope.row.units}s` }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="Reduced Stock" v-if="!empty(reductionTransactionKeys)">
                        <el-table-column :label="key" v-for="(key, index) in reductionTransactionKeys" :key="index">
                            <template #default="scope">
                                <span v-if="scope.row.reduction_transactions.hasOwnProperty(key)">{{
                                        scope.row.reduction_transactions[key]
                                    }}&nbsp;{{ `${scope.row.units}s` }}</span>
                                <span v-else>{{ `0 ${scope.row.units}s` }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="closing_stock" label="Closing Stock"/>
                </el-table>
            </div>

            <!-- Pagination -->
            <div class="data-table-pagination d-flex justify-content-end mt-3">
                <el-pagination background layout="prev, pager, next" :total="itemCount"
                               :page-size="10" v-model:current-page="currentPage" @current-change="getReport" hide-on-single-page/>
            </div>
        </div>
    </div>
</template>

<script>
import {Close} from '@element-plus/icons-vue'
import {mapGetters, mapState} from "vuex";

export default {
    name: "OpeningClosingStocksReport",

    data() {
        return {
            gettingReport: false,
            report: [],
            additionTransactionKeys: [],
            reductionTransactionKeys: [],
            stockLevels: [],
            itemCount: 0,
            filters: [],
            currentPage: 1
        }
    },

    computed: {
        ...mapState('system', ['currentUser']),
        ...mapGetters('system', ['getBasicPayload']),

        Close() {
            return Close
        },
    },

    created() {
        this.getReport()
    },

    methods: {
        getReport() {
            let payload = {
                store_id: this.currentUser.store_id,
                stock_item_id: this.filters.stock_item_id,
                page: (this.currentPage - 1),
                start_date: this.filters.start_date,
                end_date: this.filters.end_date
            }

            this.gettingReport = true
            axios.get('/api/reports/stock/opening-closing-stock-report', {params: payload}).then(res => {
                this.gettingReport = false
                this.report = res.data.data.items.content
                this.itemCount = res.data.data.items.count
                this.additionTransactionKeys = res.data.data.addition_transaction_keys
                this.reductionTransactionKeys = res.data.data.reduction_transaction_keys

                this.getStockLevels()
            }).catch(error => {
                this.gettingReport = false
                this.$message.error('Error generating report. Please reload the page')
            })
        },

        getStockLevels() {
            axios.get(`/api/stock/levels`, {params: this.getBasicPayload}).then(response => {
                this.stockLevels = response.data.data
            }).catch(error => {
                // pass
            })
        },

        clearFilters() {
            this.filters = {}
            this.currentPage = 1
            this.getReport()
        },
    },
}
</script>

<style>
.data-table.is-report .el-table thead.is-group th.el-table__cell {
    background-color: #c5cbd5 !important;
}
</style>
