<template>
<div id="business-settings" class="master-template">
    <div class="master-title-div">
        <h5 class="master-title"> My Business </h5>
    </div>

    <div class="master-content">
        <div class="card shadow shadow">
            <el-tabs v-model="activeTab">
                <el-tab-pane label="Business Details" name="details">
                    <business-details></business-details>
                </el-tab-pane>

                <el-tab-pane label="Terminals / Branches" name="terminals">
                    <business-terminals></business-terminals>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</div>
</template>

<script>
import BusinessDetails from "./BusinessDetails";
import BusinessTerminals from "./BusinessTerminals";

export default {
    name: "BusinessSettings",

    components: {
        BusinessDetails,
        BusinessTerminals
    },

    data() {
        return {
            activeTab: 'details',
        }
    },
}
</script>

<style scoped>

</style>
