import {createStore} from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

// Modules
import {systemStore} from "./modules/system";
import {posStore} from "./modules/pos";
import {stockStore} from "./modules/stock";
import {procurementStore} from "./modules/procurement";
import {usersStore} from "./modules/users";

// Create a store instance
export const store = createStore({
    modules: {
        system: systemStore,
        pos: posStore,
        stock: stockStore,
        procurement: procurementStore,
        users: usersStore,
    },
    state() {
        return {}
    },

    mutations: {},
    plugins: [vuexLocal.plugin]
})
