<template>
    <div id="stock-reports">
        <div class="master-title-div d-flex justify-content-between">
            <h5> Stock Reports </h5>
        </div>

        <div class="master-content">
            <div class="reports-container d-flex flex-wrap">
                <el-skeleton :throttle="200" animated :loading="gettingStockReports">
                    <template #template>
                        <el-skeleton-item variant="rect" v-for="row in 12" :key="row"
                                          style="width: 300px; height: 270px; margin-right: 20px; margin-bottom: 20px;"/>
                    </template>

                    <template #default>
                        <a :href="report.destination" v-for="(report, index) in stockReports" :key="index">
                            <div class="report card bg-white shadow">
                                <h5> {{ report.name }} </h5>
                                <hr>
                                <p> {{ report.description }} </p>
                            </div>
                        </a>

                        <p v-if="empty(stockReports)"> No reports found. </p>
                    </template>
                </el-skeleton>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "StockReports",

    data() {
        return {
            gettingStockReports: false,
            stockReports: [],
        }
    },

    created() {
        this.getStockReports()
    },

    methods: {
        getStockReports() {
            this.gettingStockReports = true
            axios.get('/api/reports/modular', {params: {module: 'stocks'}}).then(res => {
                this.gettingStockReports = false
                this.stockReports = res.data.data
            }).catch(error => {
                this.gettingStockReports = false
            })
        },
    },
}
</script>

<style scoped>

</style>
